import {authAxios} from '../config/axiosConfig'

// Get my profile
export const myProfile = async () => {
  return authAxios.get('/users/me')
}

// Update profile
export const updateProfile = async profileData => {
  return authAxios.patch('/users/updateUser', profileData)
}

// Get job titles
export const getAllJobTitles = async () => {
  return authAxios.get('/users/allJobTitles')
}

// Upload attach file
export const uploadAttachFile = async uploadData => {
  return authAxios.post('/users/uploadAttachments', uploadData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

// Upload profile image
export const uploadUserImage = async userData => {
  return authAxios.post('/users/updateUserImage', userData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
