import React from 'react'
import {useToasts} from 'react-toast-notifications'
import {Button, Confirm, Header, Tab, Table} from 'semantic-ui-react'

import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import usePagination from '../../hooks/use-pagination'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {formatDate} from '../../utils/date-format'
import {getUserFullname} from '../../utils/user'
import {getVacationType} from '../../utils/vacation'
import PaginationWithLimit from '../pagination-with-limit'

function VacationsHistory() {
  const [data, setData] = React.useState([])
  const [deletingVacation, setDeletingVacation] = React.useState('')

  const {page, limit, onPageChange, onLimitChange} = usePagination()

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const {run: deleteVacation, isLoading: loadingVacationDeletion} = useAsync()

  const onCancelVacation = ({_id: vacationId, ...vacation}) => {
    if (!vacationId) return

    setDeletingVacation(vacation)
  }

  const handleDeleteVacation = ({id: vacationId}) => {
    deleteVacation(authAxios.delete(`/vacations/${vacationId}`))
      .then(() => {
        let newData = data?.data?.filter(v => v._id !== vacationId)
        setData({...data, data: newData})

        addToast(selectedContent.successfulOperation, {appearance: 'success'})
        setDeletingVacation('')
      })
      .catch(e => {
        setDeletingVacation('')
        e.errors?.map(e => addToast(e.message[lang], {appearance: 'error'}))
      })
  }

  React.useEffect(() => {
    if (page)
      run(authAxios.get(`/vacations?page=${page}&limit=${limit}`))
        .then(({data}) => setData(data))
        .catch(e =>
          e.errors?.map(e => addToast(e.message[lang], {appearance: 'error'})),
        )
  }, [run, addToast, page, limit, lang])

  return (
    <Tab.Pane loading={isLoading} className="border-0 m-0 p-0">
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{selectedContent.leaveStatus}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.from}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.to}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.suggestions}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.status}</Table.HeaderCell>
            <Table.HeaderCell>
              {selectedContent.rejectionReason}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {selectedContent.awaitingApprovalFrom}
            </Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.actions}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.data?.map(vacation => (
            <Table.Row
              key={vacation._id}
              negative={vacation.status === 'Rejected'}
              positive={vacation.status === 'Approved'}
            >
              <Table.Cell>
                <Header as="h4" image>
                  <Header.Content>
                    {selectedContent[vacation.leaveStatus]}
                    <Header.Subheader className="mt-1">
                      {getVacationType(vacation, selectedContent)}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
              <Table.Cell
                className={`${
                  vacation.status === 'Pending' &&
                  vacation.leaveStatus === 'Planned'
                    ? 'bg-gray-100'
                    : ''
                }`}
              >
                {formatDate(vacation.start)}
              </Table.Cell>
              <Table.Cell
                className={`${
                  vacation.status === 'Pending' &&
                  vacation.leaveStatus === 'Planned'
                    ? 'bg-gray-100'
                    : ''
                }`}
              >
                {formatDate(vacation.end)}
              </Table.Cell>
              <Table.Cell
                className={`${
                  vacation.leaveStatus !== 'Planned' ? 'bg-gray-100' : ''
                }`}
              >
                {vacation.suggestions.length > 0 ? (
                  <div>
                    {vacation.suggestions.map(s => (
                      <div className="mb-1">
                        <strong>{selectedContent.from}</strong>:{' '}
                        {formatDate(s.start)},{' '}
                        <strong>{selectedContent.to}</strong>:{' '}
                        {formatDate(s.end)}
                      </div>
                    ))}
                  </div>
                ) : null}
              </Table.Cell>
              <Table.Cell>
                {selectedContent[vacation.status.toLowerCase()]}
              </Table.Cell>
              <Table.Cell
                width={4}
                className={`${
                  vacation.status !== 'Rejected' ? 'bg-gray-100' : ''
                }`}
              >
                {vacation.rejectionReason}
              </Table.Cell>
              <Table.Cell>
                {getUserFullname(vacation.awaitingApprovalFrom, lang)}
              </Table.Cell>
              <Table.Cell>
                <Button
                  className="btn-danger"
                  loading={deletingVacation === vacation._id}
                  onClick={() => onCancelVacation(vacation)}
                  disabled={vacation.status !== 'Pending'}
                >
                  {selectedContent.cancel}
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <PaginationWithLimit
        totalPages={data?.totalPages}
        page={page}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />

      {deletingVacation ? (
        <Confirm
          content={`${
            lang === 'en'
              ? `Are you sure your want to delete ${getVacationType(
                  deletingVacation,
                  selectedContent,
                )} request?`
              : `هل أنت متأكد من أنك تريد حذف طلب ${getVacationType(
                  deletingVacation,
                  selectedContent,
                )} ؟`
          }`}
          open={deletingVacation}
          onCancel={() => setDeletingVacation(null)}
          size="small"
          confirmButton={() => (
            <Button
              onClick={() => handleDeleteVacation(deletingVacation)}
              size="small"
              negative
              loading={loadingVacationDeletion}
            >
              Delete
            </Button>
          )}
        />
      ) : null}
    </Tab.Pane>
  )
}

export default VacationsHistory
