import {Formik} from 'formik'
import React from 'react'
import {Button, Form, Tab} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import FormikControl from '../formik/FormikControl'
import * as Yup from 'yup'
import useAsync from '../../hooks/useAsync'
import {authAxios} from '../../config/axiosConfig'
import {useToasts} from 'react-toast-notifications'
import deepEqual from 'deep-equal'

function VacationSettings({vacations, setVacations, isLoading}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading: isUpdating} = useAsync()
  const {addToast} = useToasts()

  const vacationSettingsSchema = Yup.object({
    nOfAnnualVacation: Yup.number()
      .min(0, `Days number are not allowed to be negative!`)
      .optional(),
    nOfCanBeMigrated: Yup.number()
      .min(0, `Days number are not allowed to be negative!`)
      .optional(),
    nOfNewbornLeave: Yup.number()
      .min(0, `Days number are not allowed to be negative!`)
      .optional(),
    nOfMaternityLeave: Yup.number()
      .min(0, `Days number are not allowed to be negative!`)
      .optional(),
    nOfEidAlAdhaStation: Yup.number()
      .min(0, `Days number are not allowed to be negative!`)
      .optional(),
    nOfEidAlFitrStation: Yup.number()
      .min(0, `Days number are not allowed to be negative!`)
      .optional(),
    nOfNationalDayStation: Yup.number()
      .min(0, `Days number are not allowed to be negative!`)
      .optional(),
  })

  const handleUpdateVacationDays = values => {
    run(authAxios.put('/settings', values))
      .then(({data}) => {
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
        setVacations(data?.data)
      })
      .catch(e =>
        e?.errors.map(e => addToast(e.message[lang], {appearance: 'error'})),
      )
  }

  return (
    <Tab.Pane className="m-0 p-0 border-0">
      <Formik
        initialValues={
          vacations || {
            nOfAnnualVacation: 0,
            nOfCanBeMigrated: 0,
            nOfNewbornLeave: 0,
            nOfMaternityLeave: 0,
            nOfEidAlAdhaStation: 0,
            nOfEidAlFitrStation: 0,
            nOfNationalDayStation: 0,
          }
        }
        enableReinitialize
        validationSchema={vacationSettingsSchema}
        onSubmit={handleUpdateVacationDays}
      >
        {formik => {
          return (
            <Form
              className="max-w-xl mx-auto"
              loading={isLoading || isUpdating}
              onSubmit={formik.submitForm}
            >
              <FormikControl
                control="input"
                name="nOfAnnualVacation"
                label={selectedContent.nOfAnnualVacation}
              />
              <FormikControl
                control="input"
                name="nOfCanBeMigrated"
                label={selectedContent.nOfCanBeMigrated}
              />
              <FormikControl
                control="input"
                name="nOfNewbornLeave"
                label={selectedContent.nOfNewbornLeave}
              />
              <FormikControl
                control="input"
                name="nOfMaternityLeave"
                label={selectedContent.nOfMaternityLeave}
              />
              <FormikControl
                control="input"
                name="nOfEidAlAdhaStation"
                label={selectedContent.nOfEidAlAdhaStation}
              />
              <FormikControl
                control="input"
                name="nOfEidAlFitrStation"
                label={selectedContent.nOfEidAlFitrStation}
              />
              <FormikControl
                control="input"
                name="nOfNationalDayStation"
                label={selectedContent.nOfNationalDayStation}
              />
              <Button
                type="submit"
                className="mt-8 w-100 btn-primary"
                disabled={deepEqual(formik.initialValues, formik.values)}
              >
                {selectedContent.submit}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Tab.Pane>
  )
}

export default VacationSettings
