import React from 'react'
import {Button, Grid, Icon, Image} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import userIcon from '../../assets/images/user.svg'
import {timeFromNow} from '../../utils/date-format'
import messageIcon from '../../assets/images/icons/messages.svg'
import Auth from '../../config/auth'
import {IoCheckmarkDoneSharp, IoCheckmarkOutline} from 'react-icons/io5'
import useAsync from '../../hooks/useAsync'
import {authAxios} from '../../config/axiosConfig'
import {useSocket} from '../../context/socket-context'
import {getUserFullname} from '../../utils/user'

export default function ActiveConversation({
  isMedium,
  isCollapsed,
  activeConversation,
}) {
  const [sendingMessage, setSendingMessage] = React.useState(false)
  const [messages, setMessages] = React.useState([])
  const activeConversationRef = React.useRef()
  const socket = useSocket()
  const {run} = useAsync()
  const receiver = activeConversation?.members.filter(
    m => m.user._id !== Auth.getUserId(),
  )[0].user

  const onSendMessage = React.useCallback(
    (messageText, setMessageText) => {
      const type = 'text'
      const data = messageText

      if (!messageText) return

      setSendingMessage(true)
      socket?.emit(
        'send message',
        {
          from: Auth.getUserId(),
          type,
          data,
          conversationId: activeConversation._id,
        },
        () => {
          setSendingMessage(false)
          setMessageText('')
        },
      )
    },
    [socket, activeConversation._id],
  )

  // const onSendMessage = (messageText, setMessageText) => {
  // }

  React.useEffect(() => {
    run(authAxios.get(`/chats/messages/${activeConversation?._id}`)).then(
      ({data}) => {
        setMessages(data)
      },
    )

    // update ref
    activeConversationRef.current = activeConversation

    // mark all messages from the other user as read
    socket?.emit('change conversation', activeConversation._id)
  }, [activeConversation, socket, run])

  // socket listeners
  React.useEffect(() => {
    const onReceiveMessage = message => {
      // if the message came from the same conversation, update the chat
      // and emit to the server that we've read the message
      if (message.conversationId === activeConversationRef.current._id) {
        setMessages(prev => [...prev, message])
        socket?.emit('active conversation', activeConversationRef.current._id)
      }
    }

    const onMessageStatusUpdate = messages => {
      if (messages[0]?.conversationId === activeConversationRef.current._id) {
        setMessages(messages)
      }
    }

    socket?.on('receive message', onReceiveMessage)
    socket?.on('messages status update', onMessageStatusUpdate)

    return () => {
      socket?.off('receive message', onReceiveMessage)
      socket?.off('messages status update', onMessageStatusUpdate)
    }
  }, [socket])

  return (
    <Grid.Column
      width={isMedium ? (isCollapsed ? 15 : 10) : 10}
      className={isMedium ? 'p-0' : ''}
    >
      <div class="flex flex-col h-full shadow-md rounded-xl">
        <Header receiver={receiver} />
        <MessageList messages={messages} />
        <MessageBox onSendMessage={onSendMessage} isLoading={sendingMessage} />
      </div>
    </Grid.Column>
  )
}

function Header({receiver}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  return (
    <div className="bg-gray-200 p-3 rounded-t-xl">
      <div className="flex items-center">
        <Image
          src={receiver?.avatar || userIcon}
          className="w-16 h-16 rounded-full"
        />
        <div className="flex flex-col ltr:ml-4 rtl:mr-4 text-gray-800">
          <p className="font-semibold text-lg">
            {getUserFullname(receiver, lang)}
          </p>
          <p className="text-sm">
            {receiver.isActive
              ? selectedContent.online
              : `${selectedContent.activeFrom} ${timeFromNow(
                  receiver?.lastActiveAt,
                  lang,
                )}`}
          </p>
        </div>
      </div>
    </div>
  )
}

function MessageList({messages}) {
  const scrollRef = React.useRef(null)
  const [lang] = useLanguage()
  const selectedContent = content[lang]
  React.useEffect(() => {
    scrollRef.current?.scrollIntoView()
  }, [messages.length])

  return (
    <div className="flex-grow h-0 overflow-y-auto p-3 md:px-8 lg:px-24 w-full">
      {messages.length === 0 ? (
        <div
          className="absolute top-1/2 left-1/2 flex flex-col justify-center items-center w-full"
          style={{
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Image src={messageIcon} className="w-40 h-40" />
          <p className="text-primary font-semibold text-lg">
            {selectedContent.noMessages}
          </p>
        </div>
      ) : (
        messages.map(m => (
          <Message message={m} own={m.from === Auth.getUserId()} />
        ))
      )}
      <div ref={scrollRef} />
    </div>
  )
}

function Message({message, own}) {
  const date = new Date(message.createdAt)
  const hour =
    date.getHours() > 12 ? date.getHours() - 12 : 12 - date.getHours()
  const min =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const amOrPm = date.getHours() < 12 ? 'AM' : 'PM'

  return (
    <div className="flex flex-col">
      <div
        className={`max-w-sm rounded-t-xl px-3 py-1 mb-1 ${
          own
            ? 'ml-auto bg-primary text-white rounded-bl-xl mr-2'
            : 'bg-gray-200 rounded-br-xl ml-2 mr-auto'
        }`}
      >
        <div className="text-base">{message.message}</div>
        <div className="flex justify-end items-center ltr:-mr-1 rtl:-ml-1">
          <div
            className={`text-sm text-right ltr:mr-2 rtl:ml-2 ${
              own ? 'text-blue-100' : 'text-gray-500'
            }`}
          >
            {hour}:{min} {amOrPm}
          </div>
          <div
            className={`${
              message.isRead
                ? own
                  ? 'text-yellow-400'
                  : 'text-green-600'
                : own
                ? 'text-blue-100'
                : 'text-gray-500'
            }`}
          >
            {own &&
              (message.isRead ? (
                <IoCheckmarkDoneSharp />
              ) : (
                <IoCheckmarkOutline />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function MessageBox({onSendMessage, isLoading}) {
  const [lang] = useLanguage()
  const [text, setText] = React.useState('')

  function handleKeyPress(e) {
    if (e.which === 13 /** Enter key */ && e.ctrlKey) sendMessage()
  }

  function sendMessage() {
    onSendMessage(text, setText)
  }

  return (
    <div className="px-4 py-5 bg-gray-100 border-t-2 border-gray-200 flex rounded-b-xl">
      <textarea
        autoFocus
        cols="1"
        rows="1"
        className="rounded-full w-full px-4 pb-1 pt-2 focus:outline-none focus:ring-2 ring-primary"
        value={text}
        onChange={e => setText(e.target.value)}
        onKeyUp={handleKeyPress}
        style={{resize: 'none'}}
      />

      {/* <button className="w-10 h-10 bg-gray-200 text-gray-600 p-2 rounded-full ml-4">
        <Icon name="camera" />
      </button>

      <button className="w-10 h-10 bg-gray-200 text-gray-600 p-2 rounded-full ml-4">
        <Icon name="microphone" />
      </button> */}

      <Button
        className="ltr:ml-4 rtl:mr-4 bg-primary text-white rounded-full py-2 px-3 w-10 h-10"
        onClick={sendMessage}
        loading={isLoading}
      >
        <Icon name="send" flipped={lang === 'ar' && 'horizontally'} />
      </Button>
    </div>
  )
}
