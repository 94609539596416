import {Formik} from 'formik'
import React from 'react'
import {useToasts} from 'react-toast-notifications'
import {Button, Dropdown, Form, Message} from 'semantic-ui-react'
import * as Yup from 'yup'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import FormikControl from '../formik/FormikControl'
import {
  COMPENSTAORY_VACATION_TYPES,
  getCompensatoryVacationTypes,
  getFixedEndVacationTypes,
  getVariableEndVacationTypes,
} from '../../constants/vacation'
import {nOfDaysBetween} from '../../utils/date-format'

function NewVacationRequest({onCreate}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const [leaveStatus, setLeaveStauts] = React.useState('')

  let vacationForm = null
  switch (leaveStatus) {
    case 'Planned':
      vacationForm = <NewPlannedVacation onCreate={onCreate} />
      break
    case 'Compensatory':
      vacationForm = <NewCompensatoryVacation onCreate={onCreate} />
      break
    case 'VariableEnd':
      vacationForm = <NewVariableEndVacation onCreate={onCreate} />
      break
    case 'FixedEnd':
      vacationForm = <NewFixedEndVacation onCreate={onCreate} />
      break
    default:
      vacationForm = null
  }

  return (
    <section className="my-10 max-w-lg mx-auto">
      <label htmlFor="leaveStatus" className="font-bold text-primary">
        {selectedContent.leaveStatus}
      </label>
      <Dropdown
        id="leaveStatus"
        className="mt-2"
        options={[
          {key: 'fxedEnd', text: selectedContent.FixedEnd, value: 'FixedEnd'},
          {
            key: 'VariableEnd',
            text: selectedContent.VariableEnd,
            value: 'VariableEnd',
          },
          {
            key: 'Planned',
            text: selectedContent.Planned,
            value: 'Planned',
          },
          {
            key: 'Compensatory',
            text: selectedContent.Compensatory,
            value: 'Compensatory',
          },
        ]}
        fluid
        selection
        placeholder={selectedContent.leaveStatus}
        onChange={(e, {value}) => setLeaveStauts(value)}
      />
      <section className="mt-10">{vacationForm}</section>
    </section>
  )
}

function NewPlannedVacation({onCreate}) {
  const [remainingDays, setRemainingDays] = React.useState(0)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const plannedVacationSchema = Yup.object({
    firstStart: Yup.string().required(selectedContent.required),
    firstEnd: Yup.string().required(selectedContent.required),
    secondStart: Yup.string().optional(),
    secondEnd: Yup.string().when('secondStart', {
      is: Boolean,
      then: Yup.string().required(selectedContent.required),
      otherwise: Yup.string().optional(),
    }),
    thirdStart: Yup.string().optional(),
    thirdEnd: Yup.string().when('thirdStart', {
      is: Boolean,
      then: Yup.string().required(selectedContent.required),
      otherwise: Yup.string().optional(),
    }),
  })

  const createNewPlannedVacation = (values, {resetForm}) => {
    let suggestions = []
    let valuesKeys = Object.keys(values)
    for (let i = 0; i < valuesKeys.length; i += 2) {
      let start = values[valuesKeys[i]]
      let end = values[valuesKeys[i + 1]]

      if (start) {
        if (start < end) suggestions.push({start, end})
        else {
          addToast(`From date can't be after end date`, {appearance: 'error'})
          return
        }
      }
    }

    let requestBody = {
      leaveStatus: 'Planned',
      vacationType: 'Annual',
      suggestions,
    }

    run(authAxios.post('/vacations', requestBody))
      .then(() => {
        resetForm({})
        onCreate()
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
      })
      .catch(e => e.errors.map(e => addToast(e.message, {appearance: 'error'})))
  }

  // get remaining days
  React.useEffect(() => {
    run(authAxios.get('/vacations/remaining-days'))
      .then(({data}) => setRemainingDays(data?.data?.annualDaysLeft))
      .catch(e =>
        e.errors.map(e => addToast(e.message[lang], {appearance: 'error'})),
      )
  }, [run, addToast])

  return (
    <>
      <p className="text-gray-600">
        {selectedContent.remainingAnnualVacationDays}:{' '}
        <strong className="text-black">{remainingDays}</strong>
      </p>
      <Formik
        initialValues={{
          firstStart: '',
          firstEnd: '',
          secondStart: '',
          secondEnd: '',
          thirdStart: '',
          thirdEnd: '',
        }}
        validationSchema={plannedVacationSchema}
        onSubmit={createNewPlannedVacation}
      >
        {formik => (
          <Form
            onSubmit={formik.submitForm}
            loading={isLoading}
            autocomplete="off"
          >
            <h4 className="mb-4 mt-10 text-primary">
              {selectedContent.firstSuggestion}
            </h4>
            <FormikControl
              name="firstStart"
              label={selectedContent.from}
              control="date"
              className="my-2"
            />
            <FormikControl
              name="firstEnd"
              label={selectedContent.to}
              control="date"
              className="my-2"
            />

            <h4 className="mb-4 mt-10 text-primary">
              {selectedContent.secondSuggestion}
            </h4>
            <FormikControl
              name="secondStart"
              label={selectedContent.from}
              control="date"
              className="my-2"
            />
            <FormikControl
              name="secondEnd"
              label={selectedContent.to}
              control="date"
              className="my-2"
            />

            <h4 className="mb-4 mt-10 text-primary">
              {selectedContent.thirdSuggestion}
            </h4>
            <FormikControl
              name="thirdStart"
              label={selectedContent.from}
              control="date"
              className="my-2"
            />
            <FormikControl
              name="thirdEnd"
              label={selectedContent.to}
              control="date"
              className="my-2"
            />
            <Button
              className="btn-primary w-full mt-10"
              type="submit"
              loading={isLoading}
            >
              {selectedContent.submit}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

function NewVariableEndVacation({onCreate}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const variableEndSchema = Yup.object({
    vacationType: Yup.string().required(selectedContent.required),
    from: Yup.string().required(selectedContent.required),
    to: Yup.string().required(selectedContent.required),
  })

  const createNewVariableEndVacation = (values, {resetForm}) => {
    let requestBody = {
      leaveStatus: 'VariableEnd',
      vacationType: values.vacationType,
      start: values.from,
      end: values.to,
    }

    run(authAxios.post('/vacations', requestBody))
      .then(() => {
        resetForm()
        onCreate()
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
      })
      .catch(e => e.errors.map(e => addToast(e.message, {appearance: 'error'})))
  }

  return (
    <>
      <Formik
        initialValues={{vacationType: '', from: '', to: ''}}
        validationSchema={variableEndSchema}
        onSubmit={createNewVariableEndVacation}
      >
        {formik => (
          <Form
            autocomplete="off"
            onSubmit={formik.submitForm}
            loading={isLoading}
          >
            <FormikControl
              name="vacationType"
              control="select"
              options={getVariableEndVacationTypes(lang)}
              label={{
                children: selectedContent.vacationType,
                htmlFor: 'vacationType',
              }}
              placeholder={selectedContent.vacationType}
              search
              searchInput={{id: 'vacationType'}}
            />

            <FormikControl
              name="from"
              label={selectedContent.from}
              control="date"
              className="mb-5"
            />
            <FormikControl
              name="to"
              label={selectedContent.to}
              control="date"
              className="mb-6"
            />
            <Button className="btn-primary w-full mt-6" type="submit">
              {selectedContent.submit}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

function NewFixedEndVacation({onCreate}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const fixedEndSchema = React.useMemo(
    () =>
      Yup.object({
        vacationType: Yup.string()
          .oneOf(getFixedEndVacationTypes().map(f => f.value))
          .required(selectedContent.required),
        from: Yup.string().required(selectedContent.required),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const createNewFixedEndVacation = (values, {resetForm}) => {
    let start = values.from

    let requestBody = {
      leaveStatus: 'FixedEnd',
      vacationType: values.vacationType,
      start,
    }

    run(authAxios.post('/vacations', requestBody))
      .then(() => {
        resetForm()
        onCreate()
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
      })
      .catch(e => e.errors.map(e => addToast(e.message, {appearance: 'error'})))
  }

  return (
    <>
      <Formik
        initialValues={{vacationType: '', from: ''}}
        validationSchema={fixedEndSchema}
        onSubmit={createNewFixedEndVacation}
      >
        {formik => (
          <Form
            autocomplete="off"
            onSubmit={formik.submitForm}
            loading={isLoading}
          >
            <FormikControl
              name="vacationType"
              control="select"
              options={getFixedEndVacationTypes(lang)}
              label={selectedContent.vacationType}
              placeholder={selectedContent.vacationType}
            />
            <FormikControl
              name="from"
              label={selectedContent.from}
              control="date"
              className="mb-5"
            />

            <Button className="btn-primary w-full mt-6" type="submit">
              {selectedContent.submit}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

function NewCompensatoryVacation({onCreate}) {
  const [vacations, setVacations] = React.useState({
    alAdhaCompensationDays: 0,
    alFitrCompensationDays: 0,
    nationalDayCompensationDays: 0,
  })

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading, isIdle, isSuccess} = useAsync()
  const {addToast} = useToasts()

  const compensatoryVacationSchema = Yup.object({
    vacationType: Yup.string()
      .oneOf(Object.keys(COMPENSTAORY_VACATION_TYPES))
      .required(selectedContent.required),
    from: Yup.string().required(selectedContent.required),
    to: Yup.string().optional(),
  })

  const createNewCompensatoryVacation = (values, {resetForm}) => {
    let start = values.from
    let end = ''

    if (values.to) {
      end = values.to
      let compensatoryDays = ''

      if (values.vacationType === COMPENSTAORY_VACATION_TYPES.AlAdha)
        compensatoryDays = 'alAdhaCompensationDays'
      else if (values.vacationType === COMPENSTAORY_VACATION_TYPES.AlFitr)
        compensatoryDays = 'alFitrCompensationDays'
      else if (values.vacationType === COMPENSTAORY_VACATION_TYPES.NationalDay)
        compensatoryDays = 'nationalDayCompensationDays'

      if (nOfDaysBetween(start, end) > vacations[compensatoryDays]) {
        addToast('Not enough days', {appearance: 'error'})
        return
      }
    }

    let requestBody = {
      leaveStatus: 'Compensatory',
      vacationType: values.vacationType,
      start,
      ...(end && {end}),
    }

    run(authAxios.post('/vacations', requestBody))
      .then(() => {
        resetForm()
        onCreate()
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
      })
      .catch(e => e.errors.map(e => addToast(e.message, {appearance: 'error'})))
  }

  // get remaining days
  React.useEffect(() => {
    run(authAxios.get('/vacations/remaining-days'))
      .then(({data}) => setVacations(data?.data))
      .catch(e => e.errors.map(e => addToast(e.message, {appearance: 'error'})))
  }, [run, addToast])

  return (
    <>
      <div className="mb-8 text-gray-600">
        <p className="mb-2">
          {selectedContent.remainingCompensatoryVacationDays}{' '}
          {selectedContent.alAdha}:{' '}
          <strong className="text-black">
            {vacations.alAdhaCompensationDays}
          </strong>
        </p>
        <p className="mb-2">
          {selectedContent.remainingCompensatoryVacationDays}{' '}
          {selectedContent.alFitr}:{' '}
          <strong className="text-black">
            {vacations.alFitrCompensationDays}
          </strong>
        </p>
        <p className="mb-2">
          {selectedContent.remainingCompensatoryVacationDays}{' '}
          {selectedContent.nationalDay}:{' '}
          <strong className="text-black">
            {vacations.nationalDayCompensationDays}
          </strong>
        </p>
      </div>

      <Formik
        initialValues={{vacationType: '', from: '', to: ''}}
        validationSchema={compensatoryVacationSchema}
        onSubmit={createNewCompensatoryVacation}
      >
        {!vacations.alAdhaCompensationDays &&
        !vacations.alFitrCompensationDays &&
        !vacations.nationalDayCompensationDays &&
        (!isLoading || !isIdle || !isSuccess) ? (
          <Message
            warning
            header={selectedContent.noDaysLeftMessage}
            list={[
              selectedContent.noCompensatoryDaysError,
              selectedContent.noCompensatoryDaysInstruction,
            ]}
          />
        ) : (
          formik => (
            <Form
              loading={isLoading}
              onSubmit={formik.submitForm}
              autocomplete="off"
            >
              <FormikControl
                name="vacationType"
                control="select"
                options={getCompensatoryVacationTypes(lang)}
                label={selectedContent.vacationType}
                placeholder={selectedContent.vacationType}
              />
              <FormikControl
                name="from"
                label={selectedContent.from}
                control="date"
                className="mb-5"
              />
              <FormikControl
                name="to"
                label={selectedContent.to}
                control="date"
                className="mb-6"
              />

              <Button className="btn-primary w-full mt-6" type="submit">
                {selectedContent.submit}
              </Button>
            </Form>
          )
        )}
      </Formik>
    </>
  )
}

export default NewVacationRequest
