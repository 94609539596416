import {Fragment, useState} from 'react'
import ReactCodeInput from 'react-code-input'
import {Button, Form, Icon, Image} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const PersonalInfoStep = ({nextStep, errorContent, values}) => {
  const [lang] = useLanguage()
  const infoContent = content[lang]
  const [state, setState] = useState({
    avatar: values.avatar || '',
    firstNameEN: values.firstNameEN || '',
    lastNameEN: values.lastNameEN || '',
    firstNameAR: values.firstNameAR || '',
    lastNameAR: values.lastNameAR || '',
    idNumber: values.idNumber || '',
  })
  const [errors, setErrors] = useState('')
  const [displayImg, setDisplayImg] = useState('')
  const props = {
    inputStyle: {
      margin: '4px',
      MozAppearance: 'textfield',
      width: '35px',
      borderRadius: '3px',
      fontSize: '24px',
      fontWeight: 'bold',
      height: '45px',
      textAlign: 'center',
      paddingLeft: '7px',
      backgroundColor: 'white',
      color: '#148f64',
      padding: '4px',
      border: '1px solid #148f64',
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '15px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'black',
      color: '#148f64',
      border: '1px solid #148f64',
    },
    onChange: value => {
      setState({...state, idNumber: value})
    },
  }

  const handleOnChangeInput = (e, name, value) => {
    setErrors('')
    setState({...state, [name]: value})
  }

  const handleOnSelectedFile = e => {
    setState({...state, avatar: e.target.files[0]})

    const reader = new FileReader()

    reader.onload = () => {
      if (reader.readyState === 2) {
        setDisplayImg(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const handleOnClickSubmit = () => {
    if (
      state.code === '' &&
      state.idNumber === '' &&
      state.firstNameEN &&
      state.lastNameEN === '' &&
      state.firstNameAR &&
      state.lastNameAR === ''
    ) {
      setErrors('all')
      return errorContent({isError: true, errorType: 'empty'})
    }
    if (state.firstName === '') {
      setErrors('first')
      return errorContent({isError: true, errorType: 'firstName'})
    }
    if (state.lastName === '') {
      setErrors('last')
      return errorContent({isError: true, errorType: 'lastName'})
    }
    if (state.firstNameAR === '') {
      setErrors('firstAR')
      return errorContent({isError: true, errorType: 'firstName'})
    }
    if (state.lastNameAR === '') {
      setErrors('lastAR')
      return errorContent({isError: true, errorType: 'lastName'})
    }
    // if (state.avatar === '') {
    //   setErrors('avatar')
    //   return errorContent({isError: true, errorType: 'avatar'})
    // }
    if (state.idNumber === '') {
      setErrors('idNumber')
      return errorContent({isError: true, errorType: 'idNumber'})
    }
    errorContent({isError: false, errorType: ''})
    nextStep({step: 5, type: 'step', data: state})
  }
  return (
    <Fragment>
      <Form.Field className="my-6">
        <div className="text-center">
          <input
            type="file"
            id="img-input"
            accept="image/*"
            className="hidden"
            onChange={handleOnSelectedFile}
          />
          <label htmlFor="img-input">
            <div className="flex items-center justify-center w-20 h-20 bg-gray-100 rounded-full mx-auto cursor-pointer">
              <Image
                src={displayImg}
                className={`rounded-full w-20 h-20 ${
                  displayImg !== '' ? 'visible' : 'hidden'
                }`}
              />
              <Icon
                name="camera"
                className={`text-3xl text-primary -mt-2 mx-0 ${
                  displayImg !== '' ? 'hidden' : 'visible'
                }`}
              />
            </div>
          </label>
          <p className="my-2">Upload picture</p>
        </div>
      </Form.Field>

      <Form.Group widths="equal">
        <Form.Field width="8">
          <label className="text-primary">{infoContent.firstName}</label>
          <Form.Input
            placeholder={infoContent.firstName}
            value={state.firstNameEN}
            name="firstNameEN"
            error={errors === 'all' || errors === 'first' ? true : false}
            onChange={(e, {value}) =>
              handleOnChangeInput(e, e.target.name, value)
            }
          />
        </Form.Field>
        <Form.Field width="8">
          <label className="text-primary">{infoContent.lastName}</label>
          <Form.Input
            placeholder={infoContent.lastName}
            value={state.lastNameEN}
            name="lastNameEN"
            error={errors === 'all' || errors === 'last' ? true : false}
            onChange={(e, {value}) =>
              handleOnChangeInput(e, e.target.name, value)
            }
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field width="8">
          <label className="text-primary">{infoContent.firstNameAR}</label>
          <Form.Input
            placeholder={infoContent.firstNameAR}
            value={state.firstNameAR}
            name="firstNameAR"
            error={errors === 'all' || errors === 'firstAR' ? true : false}
            onChange={(e, {value}) =>
              handleOnChangeInput(e, e.target.name, value)
            }
          />
        </Form.Field>
        <Form.Field width="8">
          <label className="text-primary">{infoContent.lastNameAR}</label>
          <Form.Input
            placeholder={infoContent.lastNameAR}
            value={state.lastNameAR}
            name="lastNameAR"
            error={errors === 'all' || errors === 'lastAR' ? true : false}
            onChange={(e, {value}) =>
              handleOnChangeInput(e, e.target.name, value)
            }
          />
        </Form.Field>
      </Form.Group>

      <Form.Field>
        <label className="text-primary">ID Number</label>
        <ReactCodeInput
          type="text"
          className="text-center w-100"
          fields={10}
          {...props}
          value={state.idNumber}
        />
      </Form.Field>

      <div className="my-6 text-center">
        <Button
          content={infoContent.next}
          className="btn-primary"
          onClick={handleOnClickSubmit}
        />
      </div>
    </Fragment>
  )
}

export default PersonalInfoStep
