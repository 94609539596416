import React from 'react'
import {Button, Grid} from 'semantic-ui-react'
import ActiveConversation from '../../components/ChatComponents/active-conversation'
import Conversations from '../../components/ChatComponents/coversations'
import NewChatModal from '../../components/ChatComponents/newChatModal'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useMediaQuery from '../../hooks/use-media-query'
import {content} from '../../localization/content'

export default function ChatPage() {
  const [isCollapsed, setIsCollapsed] = React.useState(true)
  const [activeConversation, setActiveConversation] = React.useState({})
  const [conversations, setConversations] = React.useState([])

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const isMedium = useMediaQuery('(max-width: 992px)')
  const {setShowModal} = React.useContext(StateContext)

  function onAddNewConversation(conv) {
    const existingConversation = conversations.filter(c => c._id === conv._id)
    if (!existingConversation.length) {
      setConversations(prev => [...prev, conv])
    }
    setActiveConversation(conv)
  }

  return (
    <section className="h-full">
      <NewChatModal newConversation={v => onAddNewConversation(v)} />
      <div className="flex mb-4 justify-between">
        <h2 className="text-primary mb-3 sm:mb-0">{selectedContent.chat}</h2>
        <Button
          className="btn-primary"
          onClick={() => setShowModal({modalName: 'newChat', data: null})}
        >
          {selectedContent.newConversation}
        </Button>
      </div>

      <Grid>
        <Grid.Row>
          <Conversations
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            conversations={conversations}
            setConversations={setConversations}
            activeConversation={activeConversation}
            setActiveConversation={setActiveConversation}
          />
          {activeConversation._id ? (
            <ActiveConversation
              isMedium={isMedium}
              isCollapsed={isCollapsed}
              activeConversation={activeConversation}
            />
          ) : null}
        </Grid.Row>
      </Grid>
    </section>
  )
}
