import {createContext, useEffect, useState} from 'react'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {useQuery} from '../../hooks/useQuery'
import {content} from '../../localization/content'
import {
  addEmployeesForShifts,
  getEmployeesByShiftId,
} from '../../services/shiftService'
import borderIcon from '../../assets/images/ic_border.svg'
import {Button, Form, Grid, Icon, Image} from 'semantic-ui-react'
import DraggableBoxTarget from './DraggableBoxTarget'
import DraggableBoxList from './DraggableBoxList'
import {useToasts} from 'react-toast-notifications'
import {formatDate, formatTime} from '../../utils/date-format'
import moment from 'moment'

export const CardContext = createContext({
  markSelected: null,
  revertChange: null,
})

const AddEmpShifts = () => {
  const query = useQuery()
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const contentLang = content[lang]
  const [employees, setEmployees] = useState([])
  const [shiftData, setShiftData] = useState(null)
  const {addToast} = useToasts()

  useEffect(() => {
    run(getEmployeesByShiftId(query.get('id')))
      .then(({data}) => {
        // console.log(data)
        setEmployees(data.data.selectedEmployees)
        setShiftData(data.data.shiftData)
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  const markSelected = id => {
    const shEmpArr = [...shiftData.employees]
    const empArr = [...employees]
    const i = empArr?.findIndex(obj => obj._id === id)
    shEmpArr.push(empArr[i])
    empArr.splice(i, 1)

    setEmployees(empArr)
    setShiftData({
      ...shiftData,
      employees: shEmpArr,
    })
  }

  const revertChange = id => {
    const shEmpArr = [...shiftData.employees]
    const empArr = [...employees]
    const i = shEmpArr?.findIndex(obj => obj._id === id)

    empArr.push(shEmpArr[i])
    shEmpArr.splice(i, 1)

    setEmployees(empArr)
    setShiftData({
      ...shiftData,
      employees: shEmpArr,
    })
  }

  const handleOnSubmit = () => {
    let employeesIds = []
    shiftData.employees.map((emp, i) => {
      const ind = shiftData.employees.findIndex(o => o._id === emp._id)

      employeesIds.push(emp._id)
    })
    // console.log(employeesIds)
    run(
      addEmployeesForShifts({
        employees: employeesIds,
        shiftId: query.get('id'),
      }),
    )
      .then(({data}) => {
        // console.log(data)
        addToast(data.message[lang], {appearance: 'success'})
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <CardContext.Provider value={{markSelected, revertChange}}>
      <section>
        <div className="flex items-center justify-between">
          <h2 className="text-primary m-0">{contentLang.addEmployees}</h2>
        </div>

        <div className="mt-10 ltr:ml-10 rtl:mr-10">
          <Form loading={isLoading}>
            <div className="flex items-center mb-3">
              <Image
                src={borderIcon}
                width="3px"
                height="3px"
                className="ltr:mr-2 rtl:ml-2"
                alt="border icon"
              />
              <p className="text-xl font-bold mx-3 text-goldPrimary">
                {contentLang.shiftDate}:
              </p>
              <div>
                <p className="text-xl text-subTitleText">
                  {formatDate(shiftData?.startDate)} ({' '}
                  {moment(shiftData?.startDate).locale(lang).format('dddd')} )
                  <Icon name="arrow right" className="mx-2" />{' '}
                  {formatDate(shiftData?.endDate)} ({' '}
                  {moment(shiftData?.endDate).locale(lang).format('dddd')} )
                </p>
              </div>
            </div>

            <div className="mt-5">
              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {contentLang.shiftTime}:
                </p>
                <div
                  className="text-gray-500 flex justify-center items-center mt-2"
                  dir={lang === 'en' ? 'ltr' : 'rtl'}
                >
                  <p className="mb-0 text-xl">
                    {formatTime(shiftData?.startDate)}{' '}
                  </p>
                  <Icon name={'arrow right'} className="text-lg -mt-2 mx-2" />{' '}
                  <p className="mb-0 text-xl">
                    {formatTime(shiftData?.endDate)}
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {contentLang.departments}:
                </p>
              </div>

              <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                {shiftData?.masterDepartment.length > 0 &&
                  shiftData?.masterDepartment.map((d, i) => (
                    <p className="text-xl text-subTitleText" key={i}>
                      {d['name' + lang.toUpperCase()]}
                    </p>
                  ))}
              </div>
            </div>

            <div className="mb-3">
              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {contentLang.subDepartment}:
                </p>
              </div>

              <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                {shiftData?.department.length > 0 &&
                  shiftData?.department.map((d, i) => (
                    <p className="text-xl text-subTitleText" key={i}>
                      {d['name' + lang.toUpperCase()]}
                    </p>
                  ))}
              </div>
            </div>

            <div className="mb-3">
              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {contentLang.responsibilities}:
                </p>
              </div>

              <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                <p className="text-xl text-subTitleText">
                  {shiftData?.responsibilities}
                </p>
              </div>
            </div>

            <hr className="my-10" />

            <div className="relative">
              <p className="text-center mb-2 text-gray-400">
                {contentLang.useDragFeature}
              </p>

              <div
                className="absolute top-1/2 left-1/2"
                style={{transform: 'translate(-50%, -50%)'}}
              >
                <Icon
                  name="arrow circle right"
                  className="text-2xl m-0 text-primary"
                />
              </div>
              <Grid doubling columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <DraggableBoxList data={employees} />
                  </Grid.Column>

                  <Grid.Column>
                    <DraggableBoxTarget data={shiftData?.employees} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>

            <div className="text-center my-5">
              <Button
                content={contentLang.save}
                className="btn-primary"
                disabled={shiftData?.employees?.length === 0 ? true : false}
                onClick={handleOnSubmit}
              />
            </div>
          </Form>
        </div>
      </section>
    </CardContext.Provider>
  )
}

export default AddEmpShifts
