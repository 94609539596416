import {useContext} from 'react'
import {Button, Icon} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import StateContext from '../../../context/stateContext'
import {content} from '../../../localization/content'

const EditAttachments = () => {
  const [lang] = useLanguage()
  const {setShowModal} = useContext(StateContext)
  const attachmentLang = content[lang]

  const handleOnClickButton = type => {
    setShowModal({modalName: type, data: null})
  }
  return (
    <div className="my-10 p-3">
      <ul>
        <li className="mb-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center w-1/2">
              <Icon
                name="circle"
                className="-mt-1 text-primary ltr:mr-5 rtl:ml-5"
              />
              <p className="text-primary font-bold text-xl">
                {attachmentLang.cvFile}
              </p>
            </div>

            <div className="w-1/2 flex justify-center items-center">
              <Button
                content={attachmentLang.attachFile}
                icon="attach"
                className="bg-transparent text-goldPrimary"
                onClick={() => handleOnClickButton('cv')}
              />
            </div>
          </div>
        </li>
        <li className="mb-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center w-1/2">
              <Icon
                name="circle"
                className="-mt-1 text-primary ltr:mr-5 rtl:ml-5"
              />
              <p className="text-primary font-bold text-xl">
                {attachmentLang.businessCard}
              </p>
            </div>

            <div className="w-1/2 flex justify-center items-center">
              <Button
                content={attachmentLang.attachFile}
                icon="attach"
                className="bg-transparent text-goldPrimary"
                onClick={() => handleOnClickButton('businessCard')}
              />
            </div>
          </div>
        </li>

        <li className="mb-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center w-1/2">
              <Icon
                name="circle"
                className="-mt-1 text-primary ltr:mr-5 rtl:ml-5"
              />
              <p className="text-primary font-bold text-xl">
                {attachmentLang.residenceCard}
              </p>
            </div>

            <div className="w-1/2 flex justify-center items-center">
              <Button
                content={attachmentLang.attachFile}
                icon="attach"
                className="bg-transparent text-goldPrimary"
                onClick={() => handleOnClickButton('residenceCard')}
              />
            </div>
          </div>
        </li>

        <li className="mb-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center w-1/2">
              <Icon
                name="circle"
                className="-mt-1 text-primary ltr:mr-5 rtl:ml-5"
              />
              <p className="text-primary font-bold text-xl">
                {attachmentLang.PACertificate}
              </p>
            </div>

            <div className="w-1/2 flex justify-center items-center">
              <Button
                content={attachmentLang.attachFile}
                icon="attach"
                className="bg-transparent text-goldPrimary"
                onClick={() => handleOnClickButton('PACertificate')}
              />
            </div>
          </div>
        </li>

        <li className="mb-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center w-1/2">
              <Icon
                name="circle"
                className="-mt-1 text-primary ltr:mr-5 rtl:ml-5"
              />
              <p className="text-primary font-bold text-xl">
                {attachmentLang.CPRCertificate}
              </p>
            </div>

            <div className="w-1/2 flex justify-center items-center">
              <Button
                content={attachmentLang.attachFile}
                icon="attach"
                className="bg-transparent text-goldPrimary"
                onClick={() => handleOnClickButton('CPRCerificate')}
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default EditAttachments
