import {Fragment, useState} from 'react'
import {Button, Form, Icon} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const EmailStep = ({nextStep, errorContent, values}) => {
  const [lang] = useLanguage()
  const [state, setState] = useState({
    email: values?.email || '',
    password: values?.password || '',
    confirmPassword: values?.confirmPassword || '',
    errorType: '',
  })
  const [showPassword, setShowPassword] = useState('')
  const authContent = content[lang]

  const handleOnChangeInput = (name, value) => {
    errorContent({isError: false})
    setState({...state, [name]: value})
  }

  const handleOnClickShowPassword = type => {
    if (showPassword === type) {
      setShowPassword('')
    } else {
      setShowPassword(type)
    }
  }
  const handleOnClickNext = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (
      state.email === '' ||
      state.password === '' ||
      state.confirmPassword === ''
    ) {
      errorContent({
        isError: true,
        errorType: 'empty',
      })
      setState({...state, errorType: 'all'})
      return
    }
    if (state.password !== state.confirmPassword) {
      errorContent({
        isError: true,
        errorType: 'password',
      })
      setState({...state, errorType: 'password'})

      return
    }
    if (!re.test(state.email)) {
      errorContent({
        isError: true,
        errorType: 'email',
      })
      setState({...state, errorType: 'email'})

      return
    }
    errorContent({isError: false, errorType: ''})
    nextStep({
      step: 2,
      type: 'step',
      data: {
        email: state.email,
        password: state.password,
        confirmPassword: state.confirmPassword,
      },
    })
  }
  return (
    <Fragment>
      <Form.Field>
        <label className="text-primary main-font">{authContent.email}</label>
        <Form.Input
          placeholder={authContent.email}
          type="email"
          name="email"
          value={state.email}
          error={
            state.errorType === 'email' || state.errorType === 'all'
              ? true
              : false
          }
          onChange={(e, {value}) => handleOnChangeInput(e.target.name, value)}
        />
      </Form.Field>

      <Form.Field className="relative">
        <label className="text-primary">{authContent.password}</label>
        <Form.Input
          placeholder="*******"
          type={showPassword === 'password' ? 'text' : 'password'}
          name="password"
          value={state.password}
          icon={
            <Icon
              name={showPassword === 'password' ? 'eye slash' : 'eye'}
              onClick={() => handleOnClickShowPassword('password')}
              circular
              link
            />
          }
          error={
            state.errorType === 'password' || state.errorType === 'all'
              ? true
              : false
          }
          onChange={(e, {value}) => handleOnChangeInput(e.target.name, value)}
        />
        {/* <Icon
          name={showPassword === 'password' ? 'eye slash' : 'eye'}
          className={`absolute rtl:left-5 ltr:right-5 ${state.error ? 'top-20' : "top-9"} ` }
          onClick={() => handleOnClickShowPassword('password')}
        /> */}
      </Form.Field>

      <Form.Field className="relative">
        <label className="text-primary">{authContent.confirmPassword}</label>
        <Form.Input
          placeholder="********"
          type={showPassword === 'confirm' ? 'text' : 'password'}
          value={state.confirmPassword}
          icon={
            <Icon
              name={showPassword === 'confirm' ? 'eye slash' : 'eye'}
              onClick={() => handleOnClickShowPassword('confirm')}
              circular
              link
            />
          }
          name="confirmPassword"
          error={
            state.errorType === 'password' || state.errorType === 'all'
              ? true
              : false
          }
          onChange={(e, {value}) => handleOnChangeInput(e.target.name, value)}
        />
        {/* <Icon
          name={showPassword === 'confirm' ? 'eye slash' : 'eye'}
          className={`absolute rtl:left-5 ltr:right-5 ${state.error ? 'top-20' : "top-9"} ` }
          onClick={() => handleOnClickShowPassword('confirm')}
        /> */}
      </Form.Field>

      <div className="my-5 text-center">
        <Button
          content={authContent.next}
          className="main-font btn-primary"
          onClick={handleOnClickNext}
        />
      </div>
    </Fragment>
  )
}

export default EmailStep
