import moment from 'moment'
import {useContext} from 'react'
import {useHistory} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import {Grid, Icon, Image} from 'semantic-ui-react'
import borderIcon from '../../assets/images/ic_border.svg'
import userIcon from '../../assets/images/user.svg'
import Auth from '../../config/auth'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import routes from '../../routes'
import {deleteShift} from '../../services/shiftService'
import {formatDate, formatTime} from '../../utils/date-format'

const ViewNormalShifts = ({normalShifts, onUpdate}) => {
  const [lang] = useLanguage()
  const normalLang = content[lang]
  const history = useHistory()
  const {setShowModal} = useContext(StateContext)
  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const handleOnDelete = id => {
    run(deleteShift({shiftId: id, type: 'normalShifts'}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        onUpdate(true)
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <div className="mt-5">
      <div className="text-center">
        <p className="text-2xl font-bold text-primary">
          {normalShifts['name' + lang.toUpperCase()]}
        </p>
        <div
          className="text-gray-500 flex justify-center items-center mt-2"
          dir={lang === 'en' ? 'ltr' : 'rtl'}
        >
          <p className="mb-0">{formatTime(normalShifts.startDate)}</p>
          <Icon
            name={lang === 'en' ? 'arrow right' : 'arrow left'}
            className="text-lg -mt-2 mx-2"
          />{' '}
          <p className="mb-0">{formatTime(normalShifts.endDate)}</p>
        </div>
        {Auth.isManagerOrHigher() && (
          <div className="mt-5 ">
            <Icon
              name="edit"
              size="large"
              className="mx-2 text-blue-500 cursor-pointer"
              onClick={() =>
                setShowModal({modalName: 'editShift', data: normalShifts})
              }
            />

            <Icon
              name="user plus"
              size="large"
              className="mx-2 text-primary cursor-pointer"
              onClick={() =>
                history.push(routes.addEmpShifts + '?id=' + normalShifts._id)
              }
            />
            <Icon
              name="trash"
              size="large"
              className="mx-2 text-red-800 cursor-pointer"
              onClick={() => handleOnDelete(normalShifts._id)}
            />
          </div>
        )}
      </div>

      <div className="my-10">
        <Grid doubling columns={2}>
          <Grid.Row>
            <Grid.Column>
              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {normalLang.shiftDate}:
                </p>
                <div>
                  <p className="text-xl text-subTitleText">
                    {formatDate(normalShifts.startDate)} ({' '}
                    {moment(normalShifts.startDate).locale(lang).format('dddd')}{' '}
                    )
                    <Icon name="arrow right" className="mx-2" />{' '}
                    {formatDate(normalShifts.endDate)} ({' '}
                    {moment(normalShifts.endDate).locale(lang).format('dddd')} )
                  </p>
                </div>
              </div>

              <div className="mb-3">
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {normalLang.defaultVacation}:
                  </p>
                </div>

                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                  {normalShifts.defaultVacation?.map((d, i) => (
                    <p className="text-xl text-subTitleText" key={i}>
                      {d}
                    </p>
                  ))}
                </div>
              </div>

              <div className="mb-3">
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {normalLang.department}:
                  </p>
                </div>

                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                  {normalShifts.masterDepartment?.map((dd, i) => (
                    <p className="text-xl text-subTitleText" key={i}>
                      {dd['name' + lang.toUpperCase()]}
                    </p>
                  ))}
                </div>
              </div>

              <div className="mb-3">
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {normalLang.subDepartment}:
                  </p>
                </div>

                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                  {normalShifts.department?.map((dd, i) => (
                    <p className="text-xl text-subTitleText" key={i}>
                      {dd['name' + lang.toUpperCase()]}
                    </p>
                  ))}
                </div>
              </div>

              <div className="mb-3">
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {normalLang.gender}:
                  </p>
                </div>

                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                  {normalShifts.gender?.map((g, i) => (
                    <p className="text-xl text-subTitleText" key={i}>
                      {g}
                    </p>
                  ))}
                </div>
              </div>

              <div className="mb-3">
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {normalLang.responsibilities}:
                  </p>
                </div>

                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                  <p className="text-xl text-subTitleText">
                    {normalShifts?.responsibilities}
                  </p>
                </div>
              </div>
            </Grid.Column>

            <Grid.Column>
              <div className="text-center">
                <p className="text-primary font-bold mb-2 text-xl">
                  {normalLang.employees}
                </p>
              </div>
              <div
                className=" border-2 overflow-y-auto border-gray-200 p-3 rounded-lg"
                style={{height: '350px'}}
              >
                {normalShifts.employees?.length === 0 ? (
                  <div className="flex items-center justify-center h-full">
                    <p className="text-primary">
                      There is no employees to display
                    </p>
                  </div>
                ) : (
                  <ul>
                    {normalShifts.employees.map((em, i) => (
                      <li className="p-2" key={i}>
                        <div className="flex items-center bg-gray-100 rounded-lg p-2">
                          <div className="ltr:mr-5 rtl:ml-5">
                            <Image
                              src={userIcon}
                              alt="user icon"
                              width="50"
                              height="50"
                            />
                          </div>

                          <div>
                            <p className="text-gray-800 font-bold">
                              {em['firstName' + lang.toUpperCase()]}{' '}
                              {em['lastName' + lang.toUpperCase()]}
                            </p>
                            <p className="text-gray-500">
                              {em?.jobTitle !== ''
                                ? em?.jobTitle['name' + lang.toUpperCase()]
                                : ''}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

export default ViewNormalShifts
