import {useEffect, useState} from 'react'
import {Grid, Image, Tab} from 'semantic-ui-react'
import borderIcon from '../../assets/images/ic_border.svg'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import {formatDate} from '../../utils/date-format'

const PersonalInfo = ({userData}) => {
  const [lang] = useLanguage()
  const profileLang = content[lang]

  const [personals, setPersonals] = useState('')

  useEffect(() => {
    if (!userData) return
    console.log(userData)
    setPersonals(userData)
    return () => {}
  }, [userData])

  return (
    <Tab.Pane
      attached={false}
      loading={personals === '' ? true : false}
      className={`border-none`}
    >
      {personals !== '' && (
        <Grid doubling columns="2">
          <Grid.Row>
            <Grid.Column>
              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.firstName}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.user.firstNameEN}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.lastName}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.user.lastNameEN}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.firstNameAR}:{' '}
                </p>
                <p className="text-xl text-subTitleText font-Almarai">
                  {personals.user.firstNameAR}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.lastNameAR}:{' '}
                </p>
                <p className="text-xl text-subTitleText font-Almarai">
                  {personals.user.lastNameAR}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.idNumber}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.user.idNumber}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.computerNumber}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.computerNumber}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.qualification}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.qualification}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.specialization}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.specialization}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.phone}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.user.phoneNumber}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.email}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.user.email}
                </p>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.birthDate}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {formatDate(personals.birthDate)}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.religion}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.religion}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.nationality}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.nationality}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.gender}:{' '}
                </p>
                <p className="text-xl text-subTitleText">{personals.gender}</p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.address}:{' '}
                </p>
                <p className="text-xl text-subTitleText">{personals.address}</p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.bankAccount}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.bankAccount}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.SCFHSNumber}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {personals.SCFHSNumber}
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Tab.Pane>
  )
}

export default PersonalInfo
