import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {
  Button,
  Dropdown,
  Form,
  PaginationProps,
  Header,
  Icon,
  Image,
  Input,
  Loader,
  Message,
  Modal,
  Pagination,
  Table,
  Transition,
} from 'semantic-ui-react'
import Fuse from 'fuse.js'
import fileDownload from 'js-file-download'
import * as Yup from 'yup'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import useAsync from '../../hooks/useAsync'
import {useToasts} from 'react-toast-notifications'
import {authAxios} from '../../config/axiosConfig'
import {Formik} from 'formik'
import FormikControl from '../../components/formik/FormikControl'
import FuseHighlight from '../../components/FuseHighlight'
import {keys} from '../../config/keys'
import {getSubdepartmentsFromMasterDepartment} from '../../utils/handleDepartments'
import {getUserFullname} from '../../utils/user'
import StateContext from '../../context/stateContext'
import AddResponsibilitiesModal from '../../components/employee/addResponsibilities.modal'
import Auth from '../../config/auth'
import {
  approveEmployee,
  getResponsibilities,
} from '../../services/employeeService'
import PaginationTable from '../../shared/paginationTable.shared'
import TransferUserModal from '../../components/employee/transferUserModal'
import routes from '../../routes'
import {FaUserCircle} from 'react-icons/fa'

export default function Employees() {
  let fuse = React.useRef(null)
  const [data, setData] = React.useState([])
  const [search, setSearch] = React.useState('')
  const [searchResult, setSearchResult] = React.useState([])
  const [isOpen, setIsOpen] = React.useState(false)
  const {setShowModal} = useContext(StateContext)
  const [state, setState] = useState({
    page: 1,
    itemsPerPage: 10,
    begin: 1,
    end: 10,
  })

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {run: runExport, isLoading: isLoadingExport} = useAsync()
  const {addToast} = useToasts()
  const [updateEmployees, setUpdateEmployees] = useState(false)
  const history = useHistory()

  React.useEffect(() => {
    run(authAxios.get('/users/employees'))
      .then(({data}) => {
        let modifiedData = data?.data?.map(e => ({
          ...e,
          user: {
            ...e.user,
            nameAR: `${e.user.firstNameAR} ${e.user.lastNameAR}`,
            nameEN: `${e.user.firstNameEN} ${e.user.lastNameEN}`,
          },
        }))

        setData(modifiedData)
      })
      .catch(e =>
        e?.errors?.map(e => addToast(e.message[lang], {appearance: 'error'})),
      )
  }, [run, addToast, updateEmployees])

  React.useEffect(() => {
    let keys = []

    if (lang === 'en') {
      keys = [
        'user.nameEN',
        'jobTitle.nameEN',
        'user.department.nameEN',
        'user.idNumber',
        'computerNumber',
        {name: 'user.nameEN', weight: 2},
      ]
    } else {
      keys = [
        'user.nameAR',
        'jobTitle.nameAR',
        'user.department.nameAR',
        'user.idNumber',
        'computerNumber',
        {name: 'user.nameAR', weight: 2},
      ]
    }

    fuse.current = new Fuse(data || [], {
      keys,
      includeMatches: true,
      threshold: 0.1,
    })

    if (searchResult.length > 0) {
      // console.log(searchResult)
      let sResults = []
      searchResult?.map(s => {
        // console.log(s._id)
        data?.map(d => {
          // console.log(s.item._id)
          if (d._id === s.item._id) {
            sResults.push({
              ...s,
              item: d,
            })
          }
        })
      })
      setSearchResult(sResults)
    }
  }, [data, lang, run])

  const handleSearch = e => {
    const {value} = e.target
    setSearch(value)
    setSearchResult(fuse.current.search(value))
  }

  const handleExport = () => {
    runExport(
      authAxios.get('/users/export', {responseType: 'arraybuffer'}),
    ).then(({data}) => {
      // console.log(data)
      fileDownload(data, `${selectedContent.employees}.pdf`)
    })
  }

  const handleDeleteUser = userId => {
    run(authAxios.post('/users/deleteUser', {userId: userId}))
      .then(({data}) => {
        console.log(data)
        addToast(data.message[lang], {appearance: 'success'})
      })
      .catch(e => {
        console.log(e)
      })
  }

  const tableBody = employee => (
    <Table.Row key={employee?._id}>
      <Table.Cell selectable>
        <Link to={`/employees/details?id=${employee.user._id}`}>
          <Header as="h4" image>
            {employee?.user?.avatar ? (
              <Image src={employee.user.avatar} rounded size="small" />
            ) : (
              <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-3 rtl:ml-3" />
            )}
            <Header.Content>
              {getUserFullname(employee.user, lang)}
              <Header.Subheader>
                {employee.user?.department?.['name' + lang.toUpperCase()]}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Link>
      </Table.Cell>
      <Table.Cell>
        {employee.jobTitle?.['name' + lang.toUpperCase()]}
      </Table.Cell>
      <Table.Cell>{employee.user.idNumber}</Table.Cell>
      <Table.Cell>{employee.computerNumber}</Table.Cell>
      <Table.Cell>{employee.nationality}</Table.Cell>

      <Table.Cell>
        <Dropdown
          icon="settings"
          floating
          button
          className="icon mx-auto"
          loading={isLoading}
        >
          <Dropdown.Menu className="ltr:-left-40 rtl:-right-40">
            {!employee.user.isApproved && (
              <Fragment>
                <Dropdown.Item
                  label={{
                    color: 'green',
                    empty: true,
                    circular: true,
                  }}
                  text={selectedContent.approve}
                  onClick={() => handleApprove(employee?.user?._id)}
                />
              </Fragment>
            )}

            {Auth.isHR() ? (
              <Dropdown.Item
                label={{
                  color: 'yellow',
                  empty: true,
                  circular: true,
                }}
                text={selectedContent.addResponsibility}
                onClick={() =>
                  setShowModal({
                    modalName: 'addRes',
                    data: {
                      userId: employee?.user?._id,
                      responsibilities: employee?.user?.responsibilities,
                    },
                  })
                }
              />
            ) : null}
            <Dropdown.Item
              label={{
                color: 'teal',
                empty: true,
                circular: true,
              }}
              text={selectedContent.transferUser}
              onClick={() =>
                setShowModal({
                  modalName: 'transferUser',
                  data: {
                    user: employee?.user,
                    department: employee?.user?.department,
                  },
                })
              }
            />

            <Dropdown.Item
              label={{
                color: 'teal',
                empty: true,
                circular: true,
              }}
              text={selectedContent.edit}
              onClick={() =>
                history.push(
                  routes.editEmployeeProfile + '?id=' + employee?.user?._id,
                )
              }
            />
            {/* <Dropdown.Item
              label={{
                color: 'red',
                empty: true,
                circular: true,
              }}
              text={selectedContent.delete}
              onClick={() => handleDeleteUser(employee?.user?._id)}
            /> */}
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )

  const searchableTable = hit => {
    return (
      <Table.Row key={hit?.item?._id}>
        <Table.Cell selectable>
          <Link to={`/employees/details?id=${hit?.item?.user._id}`}>
            <Header as="h4" image>
              {hit?.item?.user?.avatar ? (
                <Image src={hit.item.user.avatar} rounded size="small" />
              ) : (
                <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-3 rtl:ml-3" />
              )}
              <Header.Content>
                {lang === 'en' ? (
                  <>
                    <FuseHighlight attribute="user.nameEN" hit={hit} />
                    <Header.Subheader>
                      <FuseHighlight
                        attribute="user.department.nameEN"
                        hit={hit}
                      />
                    </Header.Subheader>
                  </>
                ) : (
                  <>
                    <FuseHighlight attribute="user.nameAR" hit={hit} />
                    <Header.Subheader>
                      <FuseHighlight
                        attribute="user.department.nameAR"
                        hit={hit}
                      />
                    </Header.Subheader>
                  </>
                )}
              </Header.Content>
            </Header>
          </Link>
        </Table.Cell>
        <Table.Cell>
          {lang === 'en' ? (
            <FuseHighlight attribute="jobTitle.nameEN" hit={hit} />
          ) : (
            <FuseHighlight attribute="jobTitle.nameAR" hit={hit} />
          )}
        </Table.Cell>
        <Table.Cell>
          <FuseHighlight attribute="user.idNumber" hit={hit} />
        </Table.Cell>
        <Table.Cell>
          <FuseHighlight attribute="computerNumber" hit={hit} />
        </Table.Cell>
        <Table.Cell>{hit?.item?.nationality}</Table.Cell>
        <Table.Cell>
          <Dropdown
            icon="settings"
            floating
            button
            className="icon mx-auto"
            loading={isLoading}
          >
            <Dropdown.Menu className="ltr:-left-40 rtl:-right-40">
              {!hit?.item?.user?.isApproved && (
                <Fragment>
                  <Dropdown.Item
                    label={{
                      color: 'green',
                      empty: true,
                      circular: true,
                    }}
                    text={selectedContent.approve}
                    onClick={() => handleApprove(hit?.item?.user?._id)}
                  />
                </Fragment>
              )}

              <Dropdown.Item
                label={{
                  color: 'yellow',
                  empty: true,
                  circular: true,
                }}
                text={selectedContent.addResponsibility}
                onClick={() =>
                  setShowModal({
                    modalName: 'addRes',
                    data: {
                      userId: hit?.item?.user?._id,
                      responsibilities: hit?.item?.user?.responsibilities,
                    },
                  })
                }
              />
              <Dropdown.Item
                label={{
                  color: 'teal',
                  empty: true,
                  circular: true,
                }}
                text={selectedContent.transferUser}
                onClick={() =>
                  setShowModal({
                    modalName: 'transferUser',
                    data: {
                      userId: hit?.item?.user?._id,
                      department: hit?.item?.user?.department,
                    },
                  })
                }
              />
              <Dropdown.Item
                label={{
                  color: 'teal',
                  empty: true,
                  circular: true,
                }}
                text={selectedContent.edit}
                onClick={() =>
                  history.push(
                    routes.editEmployeeProfile + '?id=' + hit?.item?.user?._id,
                  )
                }
              />
            </Dropdown.Menu>
          </Dropdown>
        </Table.Cell>
      </Table.Row>
    )
  }

  // const handleOnPageChange = (e, {activePage}) => {
  //   setState({...state, page: activePage})
  // }

  const handleApprove = userId => {
    // console.log(userId)
    run(approveEmployee({userId: userId}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        setUpdateEmployees(prev => !prev)
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <section className="">
      <AddResponsibilitiesModal
        updateEmployees={v => setUpdateEmployees(prev => !prev)}
      />
      <TransferUserModal
        updateEmployees={v => setUpdateEmployees(prev => !prev)}
      />
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <h2 className="text-primary mb-3 sm:mb-0">
          {selectedContent.employees}
        </h2>
        <div>
          <Button
            className="btn-secondary ltr:mr-4 rtl:ml-4 mb-3 sm:mb-0 w-full sm:w-auto"
            onClick={handleExport}
            loading={isLoadingExport}
          >
            {selectedContent.exportToPdf}
          </Button>
          <Button
            className="btn-primary w-full sm:w-auto"
            onClick={() => setIsOpen(true)}
          >
            {selectedContent.addEmployee}
          </Button>
        </div>
      </div>
      <div className="mx-0 mt-5 mb-10 flex flex-col">
        <Input
          className="w-100 md:w-2/5 lg:w-1/3"
          icon="search"
          loading={isLoading}
          disabled={isLoading}
          placeholder={selectedContent.search + '...'}
          onChange={handleSearch}
          value={search}
        />
        <span className="text-gray-500 mt-2">
          {selectedContent.searchEmployees}
        </span>
      </div>

      <div className="mt-7">
        <div className="overflow-x-auto">
          <Table celled singleLine selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{selectedContent.employee}</Table.HeaderCell>
                <Table.HeaderCell>{selectedContent.jobTitle}</Table.HeaderCell>
                <Table.HeaderCell>{selectedContent.idNumber}</Table.HeaderCell>
                <Table.HeaderCell>
                  {selectedContent.computerNumber}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {selectedContent.Nationality}
                </Table.HeaderCell>
                <Table.HeaderCell>{selectedContent.actions}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {isLoading && search.length === 0 && data.length === 0 ? (
              <Table.Body>
                <Table.Row textAlign="center">
                  <Table.Cell colSpan={8}>
                    <Icon
                      name="asterisk"
                      size="large"
                      className="text-primary"
                      loading
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body>
                {search
                  ? searchResult
                      ?.slice(
                        (state.page - 1) * state.itemsPerPage,
                        (state.page - 1) * state.itemsPerPage +
                          state.itemsPerPage,
                      )
                      ?.map(hit => searchableTable(hit))
                  : data
                      ?.slice(
                        (state.page - 1) * state.itemsPerPage,
                        (state.page - 1) * state.itemsPerPage +
                          state.itemsPerPage,
                      )
                      ?.map(e => tableBody(e))}
                {!isLoading && data?.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan={8} textAlign="center" warning>
                      {selectedContent.emptyEmployees}
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </Table.Body>
            )}
          </Table>
        </div>

        <div className="mt-1">
          {search.length > 0 && (
            <PaginationTable
              page={state.page}
              itemsPerPage={state.itemsPerPage}
              data={search}
              pageChange={v =>
                setState({...state, page: v.page, begin: v.begin})
              }
              totalPages={search.length / state.itemsPerPage}
            />
          )}
          {search.length === 0 && data.length > 0 && (
            <PaginationTable
              page={state.page}
              itemsPerPage={state.itemsPerPage}
              data={data}
              pageChange={v =>
                setState({...state, page: v.page, begin: v.begin})
              }
              totalPages={data.length / state.itemsPerPage}
            />
          )}
        </div>
      </div>
      <NewEmployeeModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        updateEmployees={v => setUpdateEmployees(prev => !prev)}
      />
    </section>
  )
}

const NewEmployeeModal = ({isOpen, setIsOpen, updateEmployees}) => {
  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const [departments, setDepartments] = React.useState([])
  const [resOptions, setResOptions] = useState([])
  const [departmentsOptions, setDepartmentsOptions] = React.useState([])
  const [masterDepartmentsOptions, setMasterDepartmentsOptions] =
    React.useState([])
  const [errors, setErrors] = React.useState([])
  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()
  const roleOptions = [
    {key: '0', text: selectedContent.central, value: keys.ROLES.master},
    {key: '1', text: selectedContent.master, value: keys.ROLES.manager},
    {key: '2', text: selectedContent.manager, value: keys.ROLES.manager},

    {key: '3', text: selectedContent.secretary, value: keys.ROLES.secretary},
    {key: '4', text: selectedContent.employee, value: keys.ROLES.user},
    {key: '5', text: selectedContent.hr, value: keys.ROLES.hr},
    {key: '6', text: selectedContent.it, value: keys.ROLES.it},
    {key: '7', text: selectedContent.exUser, value: keys.ROLES.exUser},
  ]

  useEffect(() => {
    run(getResponsibilities())
      .then(({data}) => {
        // console.log(data)
        let resData = []
        data.data.map((d, i) => {
          resData.push({
            key: i + 1,
            text: d['name' + lang.toUpperCase()],
            value: d.id,
          })
        })
        setResOptions(resData)
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  const employeeValidation = Yup.object({
    firstNameEN: Yup.string().required(selectedContent.required),
    lastNameEN: Yup.string().required(selectedContent.required),
    firstNameAR: Yup.string().required(selectedContent.required),
    lastNameAR: Yup.string().required(selectedContent.required),
    email: Yup.string().required(selectedContent.required),
    role: Yup.string()
      .oneOf(Object.keys(keys.ROLES))
      .required(selectedContent.required),
    masterDepartment: Yup.string().when('role', {
      is: 'exUser',
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(selectedContent.required),
    }),
    department: Yup.string().when(['role', 'isMaster'], {
      is: (role, isMaster) => {
        if ([keys.ROLES.exUser, keys.ROLES.master].includes(role) || isMaster) {
          return true
        } else {
          return false
        }
      },
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(selectedContent.required),
    }),
    // .when('isMaster', {
    //   is: true,
    //   then: Yup.string().nullable(),
    //   otherwise: Yup.string().required(selectedContent.required),
    // }),
  })

  function handleNewEmployee(values) {
    let body = {...values, password: '123456'}

    if (values.isMaster) {
      delete body.department
    }

    run(authAxios.post('/auth/signup', body))
      .then(() => {
        setIsOpen(false)
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
        updateEmployees(true)
      })
      .catch(e => {
        console.log(e)
        if (Array.isArray(e.errors))
          setErrors(e.errors.map(e => e.message[lang]))
      })
  }

  React.useEffect(() => {
    run(authAxios.get('/departments/departments')).then(({data}) => {
      let departmentsOptions = []
      let masterDepartmentsOptions = []

      data?.data?.departments?.map(d =>
        departmentsOptions.push({
          key: d._id,
          text: d['name' + lang.toUpperCase()],
          value: d._id,
        }),
      )
      data?.data?.masterDepartments?.map(d =>
        masterDepartmentsOptions.push({
          key: d._id,
          text: d['name' + lang.toUpperCase()],
          value: d._id,
        }),
      )

      setDepartments(data?.data?.departments)
      setDepartmentsOptions(departmentsOptions)
      setMasterDepartmentsOptions(masterDepartmentsOptions)
    })
  }, [run])

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Header>{selectedContent.newEmployee}</Modal.Header>
      <Modal.Content image scrolling>
        <div className="w-full mb-10">
          {errors.length > 0 ? (
            <Message
              negative
              header={selectedContent.error}
              list={errors}
              className="max-w-lg mx-auto mb-10"
            />
          ) : null}
          <Formik
            validationSchema={employeeValidation}
            initialValues={{
              firstNameEN: '',
              lastNameEN: '',
              firstNameAR: '',
              lastNameAR: '',
              email: '',
              isMaster: false,
              masterDepartment: '',
              department: '',
              role: '',
              phoneNumber: '',
              responsibilities: [],
            }}
            onSubmit={handleNewEmployee}
          >
            {formik => (
              <Form
                className="max-w-lg w-full mx-auto"
                onSubmit={formik.submitForm}
                loading={isLoading}
              >
                <FormikControl
                  name="firstNameEN"
                  label={selectedContent.firstName}
                  control="input"
                />
                <FormikControl
                  name="lastNameEN"
                  label={selectedContent.lastName}
                  control="input"
                />
                <FormikControl
                  name="firstNameAR"
                  label={selectedContent.firstNameAR}
                  control="input"
                />
                <FormikControl
                  name="lastNameAR"
                  label={selectedContent.lastNameAR}
                  control="input"
                />
                <FormikControl
                  type="email"
                  name="email"
                  label={selectedContent.email}
                  control="input"
                />
                <FormikControl
                  name="phoneNumber"
                  label={selectedContent.phone}
                  control="input"
                />
                <FormikControl
                  label={selectedContent.password}
                  control="input"
                  autocomplete={false}
                  value="123456"
                  disabled={true}
                />
                <FormikControl
                  name="role"
                  label={selectedContent.role}
                  control="select"
                  options={roleOptions}
                />
                {formik.values.role !== 'exUser' && (
                  <Fragment>
                    <FormikControl
                      name="masterDepartment"
                      label={selectedContent.department}
                      control="select"
                      options={masterDepartmentsOptions}
                    />
                    {[keys.ROLES.manager].includes(formik.values.role) ? (
                      <FormikControl
                        name="isMaster"
                        label={selectedContent.isMaster}
                        control="checkbox"
                      />
                    ) : null}
                    <Transition.Group duration={300} animation="fade up">
                      {!formik.values.isMaster &&
                      ![keys.ROLES.master].includes(formik.values.role) ? (
                        <FormikControl
                          name="department"
                          label={selectedContent.subDepartment}
                          control="select"
                          options={getSubdepartmentsFromMasterDepartment(
                            formik.values.masterDepartment,
                            departments || departmentsOptions,
                            lang,
                          )}
                        />
                      ) : null}
                    </Transition.Group>
                  </Fragment>
                )}

                {/* <FormikControl
                  name="masterDepartment"
                  label={selectedContent.department}
                  control="select"
                  options={masterDepartmentsOptions}
                />
                {formik.values.role === 'manager' ? (
                  <FormikControl
                    name="isMaster"
                    label={selectedContent.isMaster}
                    control="checkbox"
                  />
                ) : null}
                <Transition.Group duration={300} animation="fade up">
                  {!formik.values.isMaster ? (
                    <FormikControl
                      name="department"
                      label={selectedContent.subDepartment}
                      control="select"
                      options={getSubdepartmentsFromMasterDepartment(
                        formik.values.masterDepartment,
                        departments || departmentsOptions,
                        lang,
                      )}
                    />
                  ) : null}
                </Transition.Group> */}

                <FormikControl
                  name="responsibilities"
                  label={selectedContent.responsibilities}
                  control="dropdown"
                  multiple
                  selection
                  clearable
                  options={resOptions}
                />
                <Button type="submit" className="btn-primary w-full mt-5">
                  {selectedContent.add}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Content>
      <Modal.Actions className="border-0 mt-10 bg-white"></Modal.Actions>
    </Modal>
  )
}
