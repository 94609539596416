import React, {useState} from 'react'
// import "../src/assets/css/semantic/semantic.rtl.min.css";
import {LanguageProvider} from './context/languageContext'
import StateContext from './context/stateContext'
import Routes from './routes/routes'
import {ToastProvider} from 'react-toast-notifications'
import ToastContainer from './components/ToastContainer'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {UserProvider} from './context/UserContext'

// const socket = io.connect("http://localhost:3003");

function App() {
  const [authTitle, setAuthTitle] = useState(null)
  const [showModal, setShowModal] = useState({modalName: '', data: null})
  const [showEventData, setShowEventData] = useState({})

  return (
    <ToastProvider
      autoDismiss
      components={{ToastContainer: ToastContainer}}
      placement="top-center"
    >
      <UserProvider>
        <LanguageProvider>
          <StateContext.Provider
            value={{
              authTitle,
              setAuthTitle,
              showModal,
              setShowModal,
              showEventData,
              setShowEventData,
            }}
          >
            <DndProvider backend={HTML5Backend}>
              <div className="App main-font">
                <Routes />
              </div>
            </DndProvider>
          </StateContext.Provider>
        </LanguageProvider>
      </UserProvider>
    </ToastProvider>
  )
}

export default App
