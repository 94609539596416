export default function formatError(error, lang) {
  let formatted = []
  if (Array.isArray(error?.errors)) {
    error.errors.forEach(e => {
      if (e.field)
        formatted.push(`${e.field}: ${e.message[lang] || e.message || e.msg}`)
      else formatted.push(`${e.message[lang] || e.message || e.msg}`)
      console.log(e.message[lang])
    })
    return formatted
  }
}
