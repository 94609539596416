import {Link} from 'react-router-dom'
import {Grid, Image} from 'semantic-ui-react'
import requestIcon from '../../assets/images/icons/requests.svg'
import employeeIcon from '../../assets/images/icons/users.svg'
import vacationIcon from '../../assets/images/icons/vacations.svg'
import meetingIcon from '../../assets/images/icons/meeting.svg'
import performanceIcon from '../../assets/images/icons/performance.svg'
import shiftsIcon from '../../assets/images/icons/shifts.svg'
import ticketsIcon from '../../assets/images/icons/ticket.svg'
import contactIcon from '../../assets/images/icons/contact.svg'
import appointmentsIcon from '../../assets/images/icons/appointment-icon.svg'
import sahlIcon from '../../assets/images/icons/sahl.svg'
import tasksIcon from '../../assets/images/icons/tasks.svg'
import memoIcon from '../../assets/images/icons/memo_icon.svg'

import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import routes from '../../routes'
import Auth from '../../config/auth'
import {useEffect, useState} from 'react'

const Dashboard = () => {
  const [lang] = useLanguage()
  const dashboardLang = content[lang]
  const [dashboardItems, setDashboardItems] = useState([])

  useEffect(() => {
    let dashboardItemsArr = []
    if (!Auth.isExUser()) {
      dashboardItemsArr.push(
        {
          label: 'requests',
          link: 'requests',
          icon: requestIcon,
        },
        {
          label: 'employees',
          link: 'employees',
          icon: employeeIcon,
        },
        {
          label: 'vacations',
          link: 'vacations',
          icon: vacationIcon,
        },
        {
          label: 'performance',
          link: 'performance',
          icon: performanceIcon,
        },
        {
          label: 'shifts',
          link: 'shifts',
          icon: shiftsIcon,
        },
        {
          label: 'sahl',
          link: 'sahl',
          icon: sahlIcon,
        },
        {
          label: 'tasks',
          link: 'tasks',
          icon: tasksIcon,
        },
        {
          label: 'tickets',
          link: 'tickets',
          icon: ticketsIcon,
        },
        {
          label: 'internalMemos',
          link: 'internalMemos',
          icon: memoIcon,
        },
        {
          label: 'contacts',
          link: 'contacts',
          icon: contactIcon,
        },
      )
    }

    if (Auth.isHR() || Auth.isSecretary() || Auth.isAdmin())
      dashboardItemsArr.push({
        label: 'appointments',
        link: 'appointments',
        icon: appointmentsIcon,
      })

    dashboardItemsArr.push({
      label: 'meetings',
      link: 'meetings',
      icon: meetingIcon,
    })

    dashboardItemsArr = dashboardItemsArr.filter(function (el) {
      return (
        typeof el != 'object' || Array.isArray(el) || Object.keys(el).length > 0
      )
    })
    setDashboardItems(dashboardItemsArr)
  }, [])
  return (
    <Grid doubling columns={3}>
      <Grid.Row textAlign="center" className="flex justify-center">
        {dashboardItems.map((items, i) => (
          <Grid.Column key={i} className="mb-3">
            <Link
              to={routes[items.link]}
              className={`${
                i % 2 !== 0 ? 'ml-5' : ''
              } text-primary p-8 text-center mb-3`}
            >
              <Image src={items.icon} className="w-36 mx-auto" />
            </Link>
            <p className="text-lg text-center font-semibold">
              {dashboardLang[items.label]}
            </p>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  )
}

export default Dashboard
