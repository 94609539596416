import React from 'react'
import {Button, Tab} from 'semantic-ui-react'
import {useHistory, useLocation} from 'react-router-dom'

import ActiveTransactions from '../../components/sahl/ActiveTransactions'
import NewTransaction from '../../components/sahl/NewTransaction'
import TransactionObservation from '../../components/sahl/TransactionObservation'
import TransactionReview from '../../components/sahl/TransactionReview'
import TransactionsHistory from '../../components/sahl/TransactionsHistory'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import routes from '../../routes'

function Sahl() {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)

  const history = useHistory()
  const {pathname} = useLocation()

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  function onTabChange(e, {panes, activeIndex}) {
    history.push(`${routes.sahl}/${panes[activeIndex].route}`)
  }

  const panes = React.useMemo(
    () => [
      {
        menuItem: selectedContent.activeTransactions,
        route: selectedContent.activeTransactions,
        render: () => <ActiveTransactions />,
      },
      {
        menuItem: selectedContent.history,
        route: selectedContent.history,
        render: () => <TransactionsHistory />,
      },
      {
        menuItem: selectedContent.observation,
        route: selectedContent.observation,
        render: () => <TransactionObservation />,
      },
      {
        menuItem: selectedContent.review,
        route: selectedContent.review,
        render: () => <TransactionReview />,
      },
    ],
    [selectedContent],
  )

  React.useEffect(() => {
    for (let i = 0; i < panes.length; i++)
      if (pathname.split('?')[0].endsWith(panes[i].route)) setActiveIndex(i)
  }, [panes, pathname])

  return (
    <section>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
        <h2 className="text-primary mb-5 sm:mb-0">{selectedContent.sahl}</h2>
        <Button className="btn-primary" onClick={() => setIsModalOpen(true)}>
          {selectedContent.newTransaction}
        </Button>
      </div>
      <div className="mt-8">
        <div style={{width: '99%'}}>
          <Tab
            menu={{
              secondary: true,
              pointing: true,
              className: 'flex flex-wrap',
            }}
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={onTabChange}
          />
        </div>
      </div>

      <NewTransaction isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </section>
  )
}

export default Sahl
