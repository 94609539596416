import React from 'react'
import {Dropdown, Pagination} from 'semantic-ui-react'

const PaginationWithLimit = ({
  page,
  limit,
  totalPages,
  onPageChange,
  onLimitChange,
}) => {
  return (
    <div className="flex justify-end mt-4">
      <Dropdown
        className="w-24 ltr:mr-4 rtl:ml-4"
        placeholder="Limit"
        value={limit}
        defaultValue="10"
        fluid
        selection
        onChange={(e, data) => onLimitChange(data.value)}
        options={[
          {text: 10, value: '10', key: 10},
          {text: 25, value: '25', key: 25},
          {text: 50, value: '50', key: 50},
        ]}
      />
      <Pagination
        pointing
        secondary
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        activePage={page}
        totalPages={totalPages}
        onPageChange={(e, data) => onPageChange(data.activePage)}
      />
    </div>
  )
}

export default PaginationWithLimit
