import {useContext, useEffect, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {Button, Header, Modal, Icon, Form} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {
  addResponsibilities,
  getResponsibilities,
} from '../../services/employeeService'

const AddResponsibilitiesModal = ({updateEmployees}) => {
  const [open, setOpen] = useState(false)
  const {showModal, setShowModal} = useContext(StateContext)
  const [modalData, setModalData] = useState('')
  const [lang] = useLanguage()
  const modalLang = content[lang]
  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()
  const [state, setState] = useState({
    resOptions: [],
    responsibilities:
      (showModal.modalName === 'addRes' &&
        showModal.data?.responsibilities.map((r, i) => {
          return r.id
        })) ||
      [],
  })

  useEffect(() => {
    if (showModal.modalName === 'addRes') {
      // console.log(showModal.data)
      setOpen(true)
      setModalData(showModal.data)
      run(getResponsibilities())
        .then(({data}) => {
          // console.log(data)
          let resData = []
          data.data.map((d, i) => {
            resData.push({
              key: i + 1,
              text: d['name' + lang.toUpperCase()],
              value: d.id,
            })
          })
          setState({
            ...state,
            resOptions: resData,
          })
        })
        .catch(e => {
          console.log(e)
        })

      const cr = []
      showModal.data?.responsibilities.map((r, i) => {
        cr.push(r.id)
      })
      // console.log(cr)
      setState({...state, responsibilities: cr})
    } else {
      setOpen(false)
      setModalData('')
    }
  }, [showModal])

  const handleOnChangeRes = (e, {value}) => {
    // console.log(value)
    if (value.includes(0)) {
      let allValues = []
      state.resOptions.map((r, i) => {
        if (r.value !== 0) allValues.push(r.value)
      })
      // console.log(allValues)
      // console.log(state.responsibilities)
      setState({...state, responsibilities: allValues})
    } else {
      setState({...state, responsibilities: value})
    }
  }

  const handleOnClickAdd = () => {
    console.log(modalData, state.responsibilities)
    run(
      addResponsibilities({
        userId: modalData.userId,
        responsibilities: state.responsibilities,
      }),
    )
      .then(({data}) => {
        // console.log(data)
        addToast(data.message[lang], {appearance: 'success'})
        setState({...state, responsibilities: []})
        updateEmployees(true)
        setShowModal({modalName: '', data: null})
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <Modal
      closeIcon
      open={open}
      className="main-font"
      closeOnDimmerClick={false}
      onClose={() => setShowModal({modalName: '', data: null})}
      onOpen={() => setOpen(true)}
    >
      <Header content={modalLang.addResponsibility} />
      <Modal.Content>
        <Form loading={isLoading}>
          <Form.Field>
            <Form.Dropdown
              fluid
              selection
              multiple
              clearable
              defaultValue={state.responsibilities}
              label={modalLang.responsibilities}
              options={state.resOptions}
              onChange={(e, {value}) =>
                setState({...state, responsibilities: value})
              }
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="btn-danger"
          onClick={() => setShowModal({modalName: '', data: null})}
        >
          <Icon name="remove" /> {modalLang.discard}
        </Button>
        <Button className="btn-primary" onClick={handleOnClickAdd}>
          <Icon name="checkmark" /> {modalLang.addResponsibility}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AddResponsibilitiesModal
