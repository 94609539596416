import {authAxios} from '../config/axiosConfig'

// Get all IT users
export const getItUsers = async () => {
  return authAxios.get('/users/allItUsers')
}

// Get ticket types
export const getTicketTypes = async () => {
  return authAxios.get('/tickets/ticketTypes')
}

// Get all assigned tickets
export const getAssignedTicket = async () => {
  return authAxios.get('/tickets/assignedTickets')
}

// Approve ticket
export const approveTicket = async ticketData => {
  return authAxios.post('/tickets/approveTicket', ticketData)
}

// Reject Ticket
export const rejectTicket = async ticketData => {
  return authAxios.post('/tickets/rejectTicket', ticketData)
}

// Add Comment
export const addComment = async ticketData => {
  return authAxios.patch('/tickets/updateTicketNotes', ticketData)
}

// Ticket Actions
export const ticketActions = async ticketId => {
  return authAxios.patch('/tickets/ticketActions', ticketId)
}

// Resend ticket
export const resendTicket = async ticketData => {
  return authAxios.patch('/tickets/resendTicket', ticketData)
}
