import {useContext} from 'react'
import {useDrop} from 'react-dnd'
import {itemTypes} from '../../utils/draggableItems'
import {CardContext} from './addEmpShifts'
import DraggableEmployeeList from './DraggableEmployeeList'

const DraggableBoxTarget = ({data}) => {
  const {markSelected} = useContext(CardContext)
  const [{isOver}, drop] = useDrop({
    accept: itemTypes.CARD,
    drop: (item, monitor) => markSelected(item.id),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })
  return (
    <div
      className={`border-2 border-gray-100 overflow-y-auto rounded-lg ${
        isOver ? 'bg-gray-200 transition-colors duration-500' : ''
      }`}
      style={{height: '400px'}}
      ref={drop}
    >
      <ul className="p-2">
        {data?.length > 0 &&
          data?.map((emp, i) => (
            <DraggableEmployeeList empData={emp} type="target" key={i} />
          ))}
      </ul>
    </div>
  )
}

export default DraggableBoxTarget
