import {useContext} from 'react'
import {useDrag} from 'react-dnd'
import {Icon, Image} from 'semantic-ui-react'
import userIcon from '../../assets/images/user.svg'
import {keys} from '../../config/keys'
import {useLanguage} from '../../context/languageContext'
import {formatDate} from '../../utils/date-format'
import {itemTypes} from '../../utils/draggableItems'
import {CardContext} from './addEmpShifts'

const DraggableEmployeeList = ({empData, type}) => {
  const {revertChange} = useContext(CardContext)
  const [lang] = useLanguage()

  const [{isDragging}, drag] = useDrag({
    type: itemTypes.CARD,
    item: {
      type: itemTypes.CARD,
      id: empData._id,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <li
      className={`p-3 mb-2 hover:${
        empData.isVacation ? 'bg-red-400' : 'bg-gray-200'
      } transition-colors duration-300 cursor-pointer ${
        empData.isVacation ? 'bg-red-300' : 'bg-gray-100'
      } rounded-lg relative`}
      ref={drag}
      style={{opacity: isDragging ? '0.5' : '1'}}
    >
      {type === 'target' && (
        <div
          className="absolute -top-2 ltr:-right-2 rtl:-left-2"
          onClick={() => revertChange(empData._id)}
        >
          <Icon name="times circle" className="text-lg text-red-700" />
        </div>
      )}
      <div className={`flex items-center`}>
        <div className="ltr:mr-4 rtl:ml-4">
          <Image src={userIcon} width="50" alt="user image" />
        </div>
        <div>
          <p className="text-gray-800 font-bold">
            {empData['firstName' + lang.toUpperCase()]}{' '}
            {empData['lastName' + lang.toUpperCase()]}
          </p>
          <p className="text-gray-500">
            {empData.jobTitle
              ? empData.jobTitle['name' + lang.toUpperCase()]
              : ''}
          </p>
          {empData.isVacation && (
            <p>
              Requested vacation from {formatDate(empData.isVacation.start)} to{' '}
              {formatDate(empData.isVacation.end)}
            </p>
          )}
        </div>
      </div>
    </li>
  )
}

export default DraggableEmployeeList
