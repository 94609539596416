import jwt_decode from 'jwt-decode'
import {keys} from './keys'

let token = localStorage.getItem('token')

const Auth = {
  isAuth: () => {
    try {
      jwt_decode(token)
      return true
    } catch (e) {
      return false
    }
  },

  getToken: () => {
    return token
  },

  setToken: val => {
    token = val
    localStorage.setItem('token', token)
    return token
  },

  getUserId: () => {
    const userId = JSON.parse(localStorage.getItem('user') || '[]')
    return userId?._id
  },

  isExpired: myToken => {
    const decodeToken = jwt_decode(myToken)
    const now = new Date().getTime() / 1000
    if (!decodeToken || now > decodeToken.exp) {
      return true
    } else {
      return false
    }
  },

  getDataFromToken: givenToken => {
    const decodeToken = jwt_decode(givenToken || token)
    return decodeToken
  },

  haveEmployeesPermission: () => {
    const user = JSON.parse(localStorage.getItem('user') || '[]')

    const resFilter = user.responsibilities.filter(
      r => r.id === keys.RESPONSIBILITIES.employees,
    )

    if (resFilter.length === 0 && ![keys.ROLES.admin].includes(user.role)) {
      return false
    }
    return true
  },

  haveMeetingsPermission: () => {
    const user = JSON.parse(localStorage.getItem('user') || '[]')

    const resFilter = user.responsibilities.filter(
      r => r.id === keys.RESPONSIBILITIES.meetings,
    )

    if (
      (resFilter.length === 0 && ![keys.ROLES.admin].includes(user.role)) ||
      [keys.ROLES.exUser].includes(user.role)
    ) {
      return false
    }
    return true
  },

  havePerformancePermission: () => {
    const user = JSON.parse(localStorage.getItem('user') || '[]')
    const resFilter = user.responsibilities.filter(
      r => r.id === keys.RESPONSIBILITIES.ratings,
    )

    if (resFilter?.length === 0 && ![keys.ROLES.admin].includes(user.role)) {
      return false
    }
    return true
  },

  haveTicketsPermissions: () => {
    const user = JSON.parse(localStorage.getItem('user') || '[]')

    const resFilter = user.responsibilities.filter(
      r => r.id === keys.RESPONSIBILITIES.tickets,
    )

    if (resFilter.length === 0 && ![keys.ROLES.admin].includes(user.role)) {
      return false
    }
    return true
  },

  isExUser: () => {
    const user = JSON.parse(localStorage.getItem('user') || '[]')

    if ([keys.ROLES.exUser].includes(user?.role)) {
      return true
    }
    return false
  },

  isManagerOrIT: () => {
    const userRole = JSON.parse(localStorage.getItem('user') || '[]')
    if (
      ![
        keys.ROLES.it,
        keys.ROLES.admin,
        keys.ROLES.manager,
        keys.ROLES.master,
      ].includes(userRole?.role)
    ) {
      return false
    }

    if (keys.ROLES.manager === userRole?.role)
      if (userRole?.department?.type !== 'it') return false

    return true
  },

  isHR: () => {
    const user = JSON.parse(localStorage.getItem('user') || '[]')

    if (
      [
        keys.ROLES.hr,
        keys.ROLES.admin,
        keys.ROLES.master,
        keys.ROLES.viceManager,
      ].includes(user?.role) /* &&
      user?.department?.manager !== user?._id */
    ) {
      // Only vice manager of the admin department can pass
      if (user?.role === keys.ROLES.viceManager && !user?.department?.isAdmin)
        return false

      return true
    }

    return false
  },

  isManager: () => {
    const userRole = JSON.parse(localStorage.getItem('user') || '[]')
    if (
      [
        keys.ROLES.manager,
        keys.ROLES.admin,
        keys.ROLES.master,
        keys.ROLES.viceManager,
      ].includes(userRole?.role)
      // !userRole?.department?.isMaster
    ) {
      const roleData = {
        isAuth: true,
        _id: userRole?._id,
        department: userRole?.department?._id,
        departmentNameEN: userRole?.department?.nameEN,
        departmentNameAR: userRole?.department?.nameAR,
      }

      return roleData
    } else {
      return false
    }
  },

  isViceManager: () => {
    const userRole = JSON.parse(localStorage.getItem('user') || '[]')

    if ([keys.ROLES.viceManager].includes(userRole?.role)) {
      return true
    } else {
      return false
    }
  },

  isMaster: () => {
    const userRole = JSON.parse(localStorage.getItem('user') || '[]')

    if (
      [keys.ROLES.admin, keys.ROLES.master, keys.ROLES.viceManager].includes(
        userRole?.role,
      )
    ) {
      if (
        userRole?.role === keys.ROLES.viceManager &&
        !userRole?.department?.isAdmin
      )
        return false
      return {
        isAuth: true,
        _id: userRole?._id,
        department: userRole?.department?._id,
        departmentNameEN: userRole?.department?.nameEN,
        departmentNameAR: userRole?.department?.nameAR,
      }
    } else {
      return false
    }
  },

  isSecretary: () => {
    const userRole = JSON.parse(localStorage.getItem('user') || '[]')
    if (
      [keys.ROLES.secretary, keys.ROLES.admin, keys.ROLES.master].includes(
        userRole?.role,
      )
    ) {
      const roleData = {
        isAuth: true,
        department: userRole?.department?._id,
        departmentName: userRole?.department?.name,
      }
      return roleData
    }
    return false
  },

  isManagerOrHigher: () => {
    const decodeToken = jwt_decode(token)
    if (
      [
        keys.ROLES.manager,
        keys.ROLES.viceManager,
        keys.ROLES.master,
        keys.ROLES.admin,
      ].includes(decodeToken?.role)
    )
      return true
    else return false
  },

  isAdmin: () => {
    const decodeToken = jwt_decode(token)
    if (decodeToken?.role === 'admin') return true
    else return false
  },

  logout: () => {
    token = ''
    localStorage.setItem('token', '')
  },
}

export default Auth
