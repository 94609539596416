import {useContext, useEffect, useState} from 'react'
import {Button, Form, Modal} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import {content} from '../../localization/content'
import {BsFillTrashFill} from 'react-icons/bs'
import useAsync from '../../hooks/useAsync'
import {deleteMeetings} from '../../services/meetingService'
import {useToasts} from 'react-toast-notifications'

const DeleteMeetingModal = ({deleteMeeting}) => {
  const [open, setOpen] = useState(false)
  const [lang] = useLanguage()
  const deleteModal = content[lang]
  const {showModal, setShowModal} = useContext(StateContext)
  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  useEffect(() => {
    if (showModal.modalName === 'deleteMeeting') {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [showModal])

  const handleOnClickDelete = () => {
    run(deleteMeetings({meetingId: showModal.data?.id}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        deleteMeeting(true)
        setShowModal({modalName: '', data: null})
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <Modal open={open} closeOnDimmerClick={false}>
      <Modal.Header>{deleteModal.deleteMeeting}</Modal.Header>
      <Modal.Content>
        <Form loading={isLoading}>
          <div className="text-center">
            <BsFillTrashFill size={50} className="text-primary mx-auto mb-5" />
            <p className="text-xl">
              {deleteModal.deleteConfirmText}{' '}
              <span className="text-primary font-bold">
                {showModal?.data?.name}
              </span>{' '}
              ?{' '}
            </p>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions className="text-center">
        <Button
          content={deleteModal.discard}
          disabled={isLoading}
          className="btn-danger"
          onClick={() => setShowModal({modalName: '', data: null})}
        />

        <Button
          content={deleteModal.delete}
          className="btn-primary"
          disabled={isLoading}
          icon="trash"
          onClick={handleOnClickDelete}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteMeetingModal
