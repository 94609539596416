import {useState, useEffect} from 'react'

export default function useLocalStorage(
  key,
  defaultValue = '',
  serialize = val => val,
) {
  const [state, setState] = useState(
    () => window.localStorage.getItem(key) || defaultValue,
  )

  useEffect(() => {
    window.localStorage.setItem(key, state)
  }, [key, state])

  return [serialize(state), setState]
}
