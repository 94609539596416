import React from 'react'
import {Button, Header, Icon, Image, Input, Tab, Table} from 'semantic-ui-react'
import Fuse from 'fuse.js'
import {formatDate} from '../../utils/date-format'
import {getUserFullname} from '../../utils/user'
import {content} from '../../localization/content'
import {useLanguage} from '../../context/languageContext'
import {RiEyeFill} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import FuseHighlight from '../FuseHighlight'
import {FaUserCircle} from 'react-icons/fa'

function TransactionsTable({isLoading, data, onSearchChange = () => null}) {
  const [search, setSearch] = React.useState('')
  const [searchResult, setSearchResult] = React.useState([])
  const [viewSearchResult, setViewSearchResult] = React.useState(false)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  let fuse = React.useRef(null)

  const handleSearch = (searchQuery = search) => {
    if (search) setViewSearchResult(true)
    else setViewSearchResult(false)

    onSearchChange(searchQuery || '')
    setSearchResult(fuse.current.search(searchQuery))
  }

  React.useEffect(() => {
    const keys = [
      'transactionNumber',
      'statement',
      {name: 'transactionNumber', weight: 2},
    ]

    fuse.current = new Fuse(data || [], {
      keys,
      includeMatches: true,
      threshold: 0.6,
    })
  }, [data])

  React.useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const tableBody = transaction => (
    <Table.Row
      key={transaction._id}
      positive={transaction.transactionStatus === 'completed'}
      negative={transaction.transactionStatus === 'rejected'}
    >
      <Table.Cell>{transaction.transactionNumber}</Table.Cell>
      <Table.Cell>{transaction.statement}</Table.Cell>
      <Table.Cell>{formatDate(transaction.transactionDate)}</Table.Cell>
      <Table.Cell>
        {transaction.employees.map((e, i) => (
          <div className={`${i > 0 ? 'mt-3' : ''}`} key={i}>
            <Header as="h4" image>
              {e?.avatar ? (
                <Image src={e.avatar} rounded size="small" />
              ) : (
                <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-3 rtl:ml-3" />
              )}
              <Header.Content>{getUserFullname(e, lang)}</Header.Content>
            </Header>
          </div>
        ))}
      </Table.Cell>
      <Table.Cell>{selectedContent[transaction.transactionStatus]}</Table.Cell>
      <Table.Cell textAlign="center">
        <Link to={`/sahl/transaction/${transaction._id}`}>
          <Button>
            <RiEyeFill />
          </Button>
        </Link>
      </Table.Cell>
    </Table.Row>
  )

  const searchTable = hit => (
    <Table.Row
      key={hit?.item?._id}
      positive={hit?.item?.transactionStatus === 'completed'}
      negative={hit?.item?.transactionStatus === 'rejected'}
    >
      <Table.Cell>
        <FuseHighlight attribute="transactionNumber" hit={hit} />
      </Table.Cell>
      <Table.Cell>
        <FuseHighlight attribute="statement" hit={hit} />
      </Table.Cell>
      <Table.Cell>{formatDate(hit?.item?.transactionDate)}</Table.Cell>
      <Table.Cell>
        {hit?.item?.employees.map((e, i) => (
          <div className={`${i > 0 ? 'mt-3' : ''}`} key={i}>
            <Header as="h4" image>
              {e?.avatar ? (
                <Image src={e.avatar} rounded size="small" />
              ) : (
                <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-3 rtl:ml-3" />
              )}
              <Header.Content>{getUserFullname(e, lang)}</Header.Content>
            </Header>
          </div>
        ))}
      </Table.Cell>
      <Table.Cell>{selectedContent[hit?.item?.transactionStatus]}</Table.Cell>
      <Table.Cell textAlign="center">
        <Link to={`/sahl/transaction/${hit?.item?._id}`}>
          <Button>
            <RiEyeFill />
          </Button>
        </Link>
      </Table.Cell>
    </Table.Row>
  )

  return (
    <Tab.Pane loading={isLoading}>
      <div className="mx-0 mt-5 mb-10 flex flex-col">
        <div className="ui icon input w-100 md:w-2/5 lg:w-1/3">
          <input
            icon="search"
            placeholder={selectedContent.search + '...'}
            onKeyDown={e => (e.key === 'Enter' ? handleSearch(search) : null)}
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
          <Icon name="search" />
        </div>
        <span className="text-gray-500 mt-2">
          {selectedContent.searchTransactions}
        </span>
      </div>

      <Table celled singleLine selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {selectedContent.transactionNumber}
            </Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.statement}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.deliveryDate}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.assignees}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.status}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.actions}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.length > 0 ? (
            viewSearchResult ? (
              searchResult.map(hit => searchTable(hit))
            ) : (
              data.map(t => tableBody(t))
            )
          ) : (
            <Table.Row>
              <Table.Cell colSpan={8} textAlign="center" warning>
                {selectedContent.noTransactionsAvailable}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Tab.Pane>
  )
}

export default TransactionsTable
