import {authAxios} from '../config/axiosConfig'

export const createRating = ratingData => {
  return authAxios.post('/ratings/createRating', ratingData)
}

// Get my ratings
export const getMyRatings = () => {
  return authAxios.get('/ratings/myRatings')
}

// Get rating history
export const getRatingHistory = ({page, limit}) => {
  return authAxios.get(`/ratings/ratingHistory?page=${page}&limit=${limit}`)
}
