import {useHistory} from 'react-router-dom'
import {Fragment, useEffect, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {DateInput} from 'semantic-ui-calendar-react'
import {Accordion, Button, Form, Icon, Rating} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import useAsync from '../../../hooks/useAsync'
import {useQuery} from '../../../hooks/useQuery'
import {content} from '../../../localization/content'
import {createRating} from '../../../services/ratingService'
import {getSettings} from '../../../services/settingsService'
import {formatDate, stringToDate} from '../../../utils/date-format'
import {useUser} from '../../../context/UserContext'

const RatingSection = () => {
  const [lang] = useLanguage()
  const ratingLang = content[lang]
  const [activeIndex, setActiveIndex] = useState(0)
  const query = useQuery()
  const [user, setUser] = useUser()
  const parsedUser = JSON.parse(user)
  const {addToast} = useToasts()
  const [state, setState] = useState({
    userId: query.get('id'),
    ratingData: [],
    ratingDate: '',
  })
  const {run, isLoading} = useAsync()
  const history = useHistory()

  useEffect(() => {
    if (!query.get('id')) {
      addToast('No user to rate', {appearance: 'error'})
      history.push('/performance')
      return
    }
    run(getSettings())
      .then(({data}) => {
        // console.log(data)
        setState({...state, ratingData: data.data.ratingSettings})
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  const handleClick = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }
  const handleOnRate = (value, i, j) => {
    const arr = [...state.ratingData]
    arr[i].totalRate = (parseInt(arr[i].totalRate) || 0) + value
    arr[i].fields[j].rating = value
    setState({
      ...state,
      ratingData: arr,
    })
  }

  const handleOnClick = () => {
    // console.log(state)
    let ratingCounts = {
      star1: 0,
      star2: 0,
      star3: 0,
      star4: 0,
      star5: 0,
    }
    state.ratingData.map((field, i) => {
      field.fields.map((count, j) => {
        ratingCounts['star' + count.rating] += 1
      })
    })

    // console.log(ratingCounts)

    run(createRating({...state, ratingCounts}))
      .then(({data}) => {
        if (state.userId === parsedUser._id) {
          setUser(JSON.stringify({...parsedUser, avgRate: data.data.avgRate}))
        }
        addToast(data.message[lang], {appearance: 'success'})
      })
      .catch(e => {
        console.log(e)
        addToast('Something went wrong', {appearance: 'error'})
      })
  }
  return (
    <section>
      <div className="flex items-center justify-between mb-7">
        <h2 className="text-primary m-0">{ratingLang.monthlyRating}</h2>
      </div>

      <div className="mt-4">
        <Form loading={isLoading}>
          <Form.Field className="w-1/2">
            <label className="text-primary">{ratingLang.ratingBy}</label>
            <DateInput
              value={formatDate(state.ratingDate)}
              placeholder={ratingLang.ratingBy}
              className="mb-1"
              onChange={(e, {value}) =>
                setState({...state, ratingDate: stringToDate(value)})
              }
            />
            <small className="text-gray-500">
              {ratingLang.ratingFilterContent}
            </small>
          </Form.Field>
          <Accordion fluid styled className="mt-8">
            {state.ratingData?.length > 0 &&
              state.ratingData.map((performances, i) => (
                <Fragment key={i}>
                  <Accordion.Title
                    active={activeIndex === i}
                    index={i}
                    onClick={handleClick}
                  >
                    <Icon name="dropdown" />
                    {performances['name' + lang.toUpperCase()]}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === i}>
                    {performances.fields.map((field, j) => (
                      <div
                        className="flex items-center justify-between mb-3"
                        key={j}
                      >
                        <div className="flex items-center w-5/6">
                          <Icon
                            name="circle"
                            className="text-primary text-lg -mt-2 rtl:ml-3 ltr:mr-3"
                          />
                          <p className="text-lg font-semibold">
                            {field['name' + lang.toUpperCase()]}
                          </p>
                        </div>
                        <div>
                          <Rating
                            icon="star"
                            defaultRating={0}
                            maxRating={5}
                            onRate={(e, data) =>
                              handleOnRate(data.rating, i, j)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </Accordion.Content>
                </Fragment>
              ))}
          </Accordion>

          <div className="mt-5 text-center">
            <Button
              content={ratingLang.save}
              className="btn-primary"
              onClick={handleOnClick}
            />
          </div>
        </Form>
      </div>
    </section>
  )
}

export default RatingSection
