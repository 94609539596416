import {Fragment, useState} from 'react'
import {Link} from 'react-router-dom'
import {useToasts} from 'react-toast-notifications'
import {Button, Form} from 'semantic-ui-react'
import {keys} from '../../config/keys'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {sendEmailForgetPassword} from '../../services/authService'

const ForgetPassword = () => {
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const forgetLang = content[lang]
  const [email, setEmail] = useState('')
  const {addToast} = useToasts()
  const [resetLink, setResetLink] = useState('')

  const handleOnCLickForget = () => {
    run(sendEmailForgetPassword({emailOrPhone: email}))
      .then(({data}) => {
        setResetLink(data.data.resetToken)
        addToast(forgetLang.createdResetLink, {
          appearance: 'success',
        })
      })
      .catch(e => {
        console.log(e)
        e.errors.map(error => {
          addToast(error.message[lang], {appearance: 'error'})
        })
      })
  }

  return (
    <div className="auth-content">
      {resetLink === '' && (
        <Fragment>
          <div className="py-4">
            <p className="font-semibold text-lg text-primary">
              {forgetLang.forgetP}
            </p>
            <p className="text-sm">{forgetLang.passwordLink}</p>
          </div>

          <Form loading={isLoading} className="mt-4">
            <Form.Field>
              <label className="text-primary">{forgetLang.email}</label>
              <Form.Input
                placeholder={forgetLang.email}
                type="email"
                onChange={(e, {value}) => setEmail(value)}
              />
            </Form.Field>

            <div className="text-center my-4">
              <Button
                content={forgetLang.send}
                className="btn-primary"
                onClick={handleOnCLickForget}
              />
            </div>
          </Form>
        </Fragment>
      )}

      {resetLink !== '' && (
        <div className="text-center">
          <p>{forgetLang.resetDesc}</p>
          <Link
            to={`/auth/resetPassword/${resetLink}`}
            className="text-gray-500 hover:text-blue-600 transition-colors duration-300"
          >
            <p className="overflow-ellipsis overflow-hidden my-3">
              {keys.SERVER_URL + '/auth/resetPassword/' + resetLink}
            </p>
          </Link>
        </div>
      )}
    </div>
  )
}

export default ForgetPassword
