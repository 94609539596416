import {Fragment, useEffect, useState} from 'react'
import {Icon, Table} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import {formatDate} from '../../../utils/date-format'

const UpdateHistory = ({userData}) => {
  const [lang] = useLanguage()
  const updateLang = content[lang]
  const [updateHistory, setUpdateHistory] = useState([])

  useEffect(() => {
    if (userData === '') return
    setUpdateHistory(userData)
  }, [userData])
  return (
    <Table celled color="green" selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Update Type</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Update Information
          </Table.HeaderCell>

          <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Approved By</Table.HeaderCell>
          <Table.HeaderCell textAlign="center"></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {updateHistory.length > 0 ? (
          updateHistory.map((history, i) => (
            <Table.Row key={i}>
              <Table.Cell textAlign="center">{i + 1}</Table.Cell>
              <Table.Cell textAlign="center">Job Informations</Table.Cell>
              <Table.Cell>
                {history.department && (
                  <>
                    <div className="flex items-center py-2">
                      <p className="font-bold w-1/4 text-goldPrimary">
                        {updateLang[history.department]}:{' '}
                      </p>
                      <span className="flex font-normal text-gray-800 ltr:ml-5 rtl:mr-5">
                        {history.department['name' + lang.toUpperCase()]}
                      </span>
                    </div>
                    <hr />
                  </>
                )}
                {Object.keys(history.updateRequestData).map(
                  (requestsData, j) => {
                    return (
                      <Fragment key={j}>
                        <div className="flex items-center py-2">
                          <p className="font-bold w-1/4 text-goldPrimary">
                            {updateLang[requestsData]}:{' '}
                          </p>
                          <span className="flex font-normal text-gray-800 ltr:ml-5 rtl:mr-5">
                            {requestsData === 'jobTitle'
                              ? history.updateRequestData.jobTitle[
                                  'name' + lang.toUpperCase()
                                ]
                              : requestsData === 'employeeType'
                              ? history.updateRequestData.employeeType[
                                  'name' + lang.toUpperCase()
                                ]
                              : requestsData === 'hiringDate' ||
                                requestsData === 'contractStartDate' ||
                                requestsData === 'contractEndDate' ||
                                requestsData === 'workStartDate'
                              ? formatDate(
                                  history.updateRequestData[requestsData],
                                )
                              : history.updateRequestData[requestsData]}
                          </span>
                        </div>
                        <hr />
                      </Fragment>
                    )
                  },
                )}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className={`${history.requestStatus.toLowerCase()}`}
              >
                {updateLang[history.requestStatus.toLowerCase()]}
              </Table.Cell>

              <Table.Cell textAlign="center">
                {history.approvedBy &&
                  history.approvedBy['firstName' + lang.toUpperCase()] +
                    ' ' +
                    history.approvedBy['lastName' + lang.toUpperCase()]}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon name="times circle" color="red" size="large" />
                {/* <div className="flex items-center">
                      <Dropdown
                        icon="settings"
                        floating
                        button
                        className="icon mx-auto"
                      >
                        <Dropdown.Menu className="ltr:-left-32 rtl:-right-32">
                          <Dropdown.Header
                            icon="tags"
                            content="Filter by tag"
                          />
                          <Dropdown.Divider />
                          <Dropdown.Item
                            label={{color: 'red', empty: true, circular: true}}
                            text=""
                          />
                          <Dropdown.Item
                            label={{color: 'blue', empty: true, circular: true}}
                            text="Announcement"
                          />
                          <Dropdown.Item
                            label={{
                              color: 'black',
                              empty: true,
                              circular: true,
                            }}
                            text="Discussion"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.Cell colSpan={9} textAlign="center" warning>
              {updateLang.emptyQualifications}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

export default UpdateHistory
