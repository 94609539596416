import React, {useContext, useEffect, useState} from 'react'
import '../assets/css/auth.css'
import {Image} from 'semantic-ui-react'
import mainLogo from '../assets/images/asas_logo.svg'
import {Link, Redirect, Route, Switch, useHistory} from 'react-router-dom'
import usFlag from '../assets/images/United-States.svg'
import saFlag from '../assets/images/ksa_flag.svg'
import {useLanguage} from '../context/languageContext'
import LoginPage from '../pages/auth/login'
import {content} from '../localization/content'
import StateContext from '../context/stateContext'
import RegisterPage from '../pages/auth/register'
import Auth from '../config/auth'
import ForgetPassword from '../pages/auth/forgetPassword'
import ResetPassword from '../pages/auth/resetPassword'
import useMediaQuery from '../hooks/use-media-query'

const AuthLayout = props => {
  const [lang, setLang] = useLanguage()
  const [title, setTitle] = useState('')
  const {authTitle} = useContext(StateContext)
  const authContent = content[lang]
  const history = useHistory()

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return
    if (Auth.isAuth()) {
      history.push('/')
    }
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return
    const pathName = window.location.pathname
    if (pathName.endsWith('/login')) {
      setTitle(authContent.login)
    } else if (pathName.endsWith('forgetPassword')) {
      setTitle(authContent.forgetP)
    } else if (pathName.endsWith('resetPassword')) {
      setTitle(authContent.resetP)
    } else {
      setTitle(authContent.signup)
    }
    return () => {
      isMounted = false
    }
  }, [lang])

  useEffect(() => {
    let isMounted = true
    if (!isMounted || authTitle === null) return
    setTitle(authTitle.title)
    return () => {
      isMounted = false
    }
  }, [authTitle, lang])
  const isSmall = useMediaQuery('(max-width: 992px)')

  const handleOnClick = language => {
    setLang(language)
    // window.location.reload();
  }
  return (
    <div className="main-auth-content">
      <div className={`${isSmall ? 'w-full px-5 my-5' : 'px-20'} auth-card`}>
        <div className="auth-title py-10">
          <h2 className="text-primary main-font mb-5 font-bold text-2xl">
            {title}
          </h2>
          <Image src={mainLogo} className="my-0 mx-auto" alt="Asas logo" />
          <small>Asas for smart solutions</small>
        </div>
        <hr />
        <Switch>
          <Route path="/auth/login" exact component={LoginPage} />
          <Route path="/auth/register" component={RegisterPage} />
          <Route path="/auth/forgetPassword" component={ForgetPassword} />
          <Route path="/auth/resetPassword/:link" component={ResetPassword} />
          <Redirect to="/auth/login" />
        </Switch>
        <div className="auth-actions">
          <p
            className={` mb-2 text-sm ${
              window.location.pathname.endsWith('login') ? 'visible' : 'hidden'
            }`}
          >
            {authContent.noAccount}
            <Link className="mx-1" to="/auth/register">
              {authContent.signup}
            </Link>{' '}
          </p>
          <p
            className={`mb-2 text-sm ${
              window.location.pathname.endsWith('register')
                ? 'visible'
                : 'hidden'
            }`}
          >
            {authContent.haveAccount}
            <Link className="mx-1" to="/auth/login">
              {authContent.login}
            </Link>{' '}
          </p>
          <p
            className={`${
              window.location.pathname.endsWith('forgetPassword') ||
              window.location.pathname.endsWith('resetPassword')
                ? 'hidden'
                : 'visible'
            } mb-4 text-sm`}
          >
            {authContent.forgetPassword}
            <Link to="/auth/forgetPassword" className="mx-1">
              {authContent.resetPassword}
            </Link>
          </p>
          <div className="auth-lang">
            <Image
              src={usFlag}
              alt="US Language"
              onClick={() => handleOnClick('en')}
            />
            <Image
              src={saFlag}
              alt="SA Language"
              onClick={() => handleOnClick('ar')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AuthLayout)
