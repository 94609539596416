import {keys} from '../config/keys'

export const getCharaterList = (char, data, lang) => {
  let prefixers
  //   console.log(keys.prefix['ar'][0].a)
  if (lang === 'ar') {
    if (keys.prefix['ar'][0].a.includes(char.substring(0, 1).toLowerCase())) {
      prefixers = {
        prefix: 'أ',
        users: data,
      }
    } else {
      const preIndex = keys.prefix['ar'].findIndex(
        o => o === char.substring(0, 1).toLowerCase(),
      )

      if (preIndex === -1) {
        prefixers = {
          prefix: '#',
          users: data,
        }
      } else {
        prefixers = {
          prefix: keys.prefix['ar'][preIndex],
          users: data,
        }
      }
    }
  } else {
    const preIndex = keys.prefix['en'].findIndex(
      o => o === char.substring(0, 1).toLowerCase(),
    )

    if (preIndex === -1) {
      prefixers = {
        prefix: '#',
        users: data,
      }
    } else {
      prefixers = {
        prefix: keys.prefix['en'][preIndex],
        users: data,
      }
    }
  }
  //   if (keys.prefix[lang].includes(data.substring(0, 1).toLowerCase())) {
  //   }
  return prefixers
}
