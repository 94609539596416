import {Button, Form, Grid, Image} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import useAsync from '../../../hooks/useAsync'
import {content} from '../../../localization/content'
import {Fragment, useEffect, useState} from 'react'
import {Formik} from 'formik'
import FormikControl from '../../formik/FormikControl'
import borderIcon from '../../../assets/images/ic_border.svg'
import {useToasts} from 'react-toast-notifications'
import {useHistory} from 'react-router'
import Auth from '../../../config/auth'
import {getAllDepartments} from '../../../services/dashboardService'
import {getJobTile, getSubDepartments} from '../../../utils/handleDepartments'
import {createUpdateRequest} from '../../../services/requestService'
import {getAllJobTitles, updateProfile} from '../../../services/profileService'
import routes from '../../../routes'
import {useQuery} from '../../../hooks/useQuery'

const EditJobInfo = ({userData, storeProfile, editType}) => {
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const [jobData, setJobData] = useState('')
  const history = useHistory()
  const jobLang = content[lang]
  const [isMaster, setIsMaster] = useState(false)
  const {addToast} = useToasts()
  const [allDepartments, setAllDepartments] = useState([])
  const [allJobTitles, setAllJobTitles] = useState('')
  const [empType, setEmpType] = useState([])
  const [jobTitleData, setJobTitleData] = useState([])
  const [departments, setDepartments] = useState([])
  const [subDepartments, setSubDepartments] = useState([])
  const [state, setState] = useState({
    department: '',
    subDepartment: '',
    employeeType: '',
    jobTitle: '',
  })
  const query = useQuery()

  useEffect(() => {
    if (!userData) return

    run(getAllJobTitles())
      .then(({data}) => {
        setAllJobTitles(data.data)
        let typeData = []
        data.data.employeeType.map(types => {
          typeData.push({
            key: types.id,
            text: types['name' + lang.toUpperCase()],
            value: types.id,
          })
        })
        setEmpType(typeData)
      })
      .catch(e => {
        console.log(e)
      })
    run(getAllDepartments())
      .then(({data}) => {
        let departmentArr = []
        setAllDepartments(data.data)
        if (data.data.masterDepartments.length > 0) {
          data.data.masterDepartments.map((department, i) => {
            departmentArr.push({
              key: i,
              text: department['name' + lang.toUpperCase()],
              value: department._id,
            })
          })
          setDepartments(departmentArr)
        }

        if (userData.user.department.isMaster) {
          setIsMaster(true)
          setState({...state, department: userData.user.department._id})
        } else {
          const filtered = getSubDepartments(
            [userData.user.department.masterDepartment._id],
            data.data,
            lang,
          )
          setSubDepartments(filtered)
          setState({
            ...state,
            department: userData.user.department.masterDepartment._id,
            subDepartment: userData.user.department._id,
          })
        }
      })
      .catch(e => {
        console.log(e)
      })

    setJobData(userData)
  }, [userData, lang])

  useEffect(() => {
    if (allDepartments.length === 0) return
    let departmentID
    let subDepartmentID

    if (userData.user.department.isMaster) {
      departmentID = userData.user.department._id
      subDepartmentID = ''
    } else if (!userData.user.department.isMaster) {
      departmentID = userData.user.department.masterDepartment._id
      subDepartmentID = userData.user.department?._id
    }
    setState({
      ...state,
      department: departmentID,
      subDepartment: subDepartmentID,
    })
    const setCurrent = getSubDepartments([departmentID], allDepartments, lang)
    setSubDepartments(setCurrent)
  }, [allDepartments])

  useEffect(() => {
    if (allJobTitles === '') return
    const jobTitles = getJobTile(
      [userData.employeeType?.id],
      allJobTitles.jobTitle,
      lang,
    )

    setJobTitleData(jobTitles)

    setState({
      ...state,
      employeeType: Number(userData.employeeType?.id),
      jobTitle: userData.jobTitle && userData.jobTitle['id'],
    })
  }, [allJobTitles, userData])

  const handleOnChangeCheckbox = (e, {value, checked}) => {
    setIsMaster(prev => !prev)
    if (!checked) {
      const subDepartment = getSubDepartments(
        [state.department],
        allDepartments,
        lang,
      )
      setSubDepartments(subDepartment)
    } else {
      setState({...state, subDepartment: ''})
    }
  }

  const handleOnChangeDepartment = (e, {value}) => {
    setState({...state, department: value, subDepartment: ''})

    const subDepartment = getSubDepartments([value], allDepartments, lang)
    setSubDepartments(subDepartment)
  }

  const handleOnChangeEmployeeType = (e, {value}) => {
    setState({...state, employeeType: value})
    const jobTitles = getJobTile([value], allJobTitles.jobTitle, lang)

    setJobTitleData(jobTitles)
  }

  const editJobInfo = values => {
    let allUpdated = {
      ...values,
      employeeType: values.employeeType,
      jobTitle: values.jobTitle,
    }
    let newDepartment

    if (isMaster) {
      if (state.department === userData.user.department._id) {
        newDepartment = undefined
      } else {
        newDepartment = state.department
      }
    } else {
      if (
        state.subDepartment === userData.user.department.masterDepartment._id
      ) {
        newDepartment = undefined
      } else {
        newDepartment = state.subDepartment
      }
    }

    let updatedJobInfo = {}

    for (let key of Object.keys(allUpdated)) {
      if (jobData[key] !== allUpdated[key] && allUpdated[key]) {
        updatedJobInfo[key] = allUpdated[key]
      }
    }

    const userId =
      editType === 'employee'
        ? query.get('id')
        : JSON.parse(localStorage.getItem('user'))._id

    if (userId.length === 0) {
      Auth.logout()
      history.push('/')
      addToast(jobLang.noAccess, {appearance: 'error'})
      return
    }

    if (editType === 'employee') {
      run(
        updateProfile({
          userId: userId,
          ...updatedJobInfo,
          ...newDepartment,
        }),
      )
        .then(({data}) => {
          storeProfile(data.data)
          addToast(data.message[lang], {appearance: 'success'})
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      run(
        createUpdateRequest({
          userId: userId._id,
          updatedData: updatedJobInfo,
          department: newDepartment,
        }),
      )
        .then(({data}) => {
          storeProfile(data.data)

          addToast(data.message[lang], {appearance: 'success'})
        })
        .catch(e => {
          console.log(e)
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              history.push(routes.login)
              addToast(error.message[lang], {appearance: 'error'})
              return
            }
          })
        })
    }
  }
  return (
    <Fragment>
      {jobData !== '' && (
        <Formik
          initialValues={{
            jobDescription: jobData.jobDescription,
            employeeDegree: jobData.employeeDegree,
            rank: jobData.rank || '',
            isMaster: isMaster,
            careerAngel: jobData.careerAngel || '',
            employeeType: userData?.employeeType?.id || '',
            jobTitle: userData?.jobTitle?.id || '',
            hiringDate: jobData.hiringDate || '',
            contractStartDate: jobData.contractStartDate || '',
            contractEndDate: jobData.contractEndDate || '',
            jobNumber: jobData.jobNumber,
            salary: jobData.salary || '',
          }}
          enableReinitialize
          onSubmit={editJobInfo}
        >
          {formik => (
            <Form
              loading={isLoading}
              onSubmit={formik.submitForm}
              className="lg:overflow-hidden mt-10"
            >
              <Grid doubling columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    {/* <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="workPlace"
                            label={jobLang.workPlace}
                            placeholder={jobLang.workPlace}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field> */}

                    {/* <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="region"
                            label={jobLang.region}
                            placeholder={jobLang.region}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field> */}

                    {/* <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="administration"
                            label={jobLang.administration}
                            placeholder={jobLang.administration}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field> */}

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="isMaster"
                            control="checkbox"
                            checked={formik.values.isMaster}
                            label={jobLang.isMaster}
                            onClick={handleOnChangeCheckbox}
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <label className="font-bold text-base mt-4 text-primary">
                            {jobLang.department}
                          </label>

                          <Form.Dropdown
                            placeholder={jobLang.department}
                            fluid
                            search
                            clearable
                            selection
                            className="mt-2"
                            value={state.department}
                            options={departments}
                            onChange={handleOnChangeDepartment}
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field
                      className={`${
                        formik.values.isMaster ? 'hidden' : 'visible'
                      }`}
                    >
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <label className="font-bold text-base mt-4 text-primary">
                            {jobLang.subDepartment}
                          </label>

                          <Form.Dropdown
                            placeholder={jobLang.subDepartment}
                            fluid
                            search
                            clearable
                            value={state.subDepartment}
                            selection
                            className="mt-2"
                            options={subDepartments}
                            onChange={(e, {value}) =>
                              setState({...state, subDepartment: value})
                            }
                          />
                        </div>
                      </div>
                    </Form.Field>

                    {/* <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="city"
                            label={jobLang.city}
                            placeholder={jobLang.city}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field> */}

                    {/* <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="employeeType"
                            label={jobLang.employee}
                            placeholder={jobLang.employee}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field> */}

                    <Form.Field required>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="employeeType"
                            control="dropdown"
                            fluid
                            selection
                            clearable
                            required
                            label={jobLang.employee}
                            placeholder={jobLang.employee}
                            options={empType}
                          />
                          {/* <Form.Dropdown
                            placeholder={jobLang.employee}
                            fluid
                            search
                            clearable
                            required
                            label={
                              <label className="font-bold text-base mt-4 text-primary">
                                {jobLang.employee}
                              </label>
                            }
                            value={state?.employeeType?.id}
                            selection
                            className="mt-2"
                            options={empType}
                            onChange={handleOnChangeEmployeeType}
                          /> */}
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="jobTitle"
                            control="dropdown"
                            fluid
                            selection
                            clearable
                            required
                            label={jobLang.jobTitle}
                            options={
                              formik.values.employeeType !== '' &&
                              allJobTitles?.jobTitle
                                ? getJobTile(
                                    [formik.values.employeeType],
                                    allJobTitles.jobTitle,
                                    lang,
                                  )
                                : null
                            }
                          />
                          {/* <Form.Dropdown
                            placeholder={jobLang.jobTitle}
                            fluid
                            search
                            clearable
                            label={
                              <label className="font-bold text-base mt-4 text-primary">
                                {jobLang.jobTitle}
                              </label>
                            }
                            required
                            value={formik.values.jobTitle}
                            selection
                            className="mt-2"
                            options={jobTitleData}
                            onChange={(e, {value}) =>
                              setState({...state, jobTitle: value})
                            }
                          /> */}
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="employeeDegree"
                            label={jobLang.degree}
                            placeholder={jobLang.degree}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="rank"
                            label={jobLang.careerLevel}
                            placeholder={jobLang.careerLevel}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="careerAngel"
                            label={jobLang.careerAngel}
                            placeholder={jobLang.careerAngel}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column>
                    {/* <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="jobTitle"
                            label={jobLang.jobTitle}
                            placeholder={jobLang.jobTitle}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field> */}

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="jobDescription"
                            label={jobLang.jobDescription}
                            placeholder={jobLang.jobDescription}
                            control="textarea"
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="hiringDate"
                            label={
                              jobLang.hiringDate + ' / ' + jobLang.workStartDate
                            }
                            control="date"
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="contractStartDate"
                            label={jobLang.contractStartDate}
                            control="date"
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="contractEndDate"
                            label={jobLang.contractEndDate}
                            control="date"
                          />
                        </div>
                      </div>
                    </Form.Field>

                    {/* <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="workStartDate"
                            label={jobLang.workStartDate}
                            control="date"
                          />
                        </div>
                      </div>
                    </Form.Field> */}

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="jobNumber"
                            label={jobLang.jobNumber}
                            placeholder={jobLang.jobNumber}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="salary"
                            label={jobLang.basicSalary}
                            placeholder={jobLang.basicSalary}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="my-10 text-center">
                {editType === 'employee' ? (
                  <Button
                    type="submit"
                    content={jobLang.save}
                    className="btn-primary"
                  />
                ) : (
                  <Button
                    type="submit"
                    content={jobLang.requestUpdate}
                    className="btn-primary"
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  )
}

export default EditJobInfo
