import React from 'react'
import {useParams} from 'react-router'
import {Tab} from 'semantic-ui-react'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {useQuery} from '../../hooks/useQuery'
import {content} from '../../localization/content'
import {getUserFullname} from '../../utils/user'
import Attachments from '../profileComponents/attachments'
import JobInfo from '../profileComponents/jobInfo'
import PersonalInfo from '../profileComponents/perosnal'
import Qualifications from '../profileComponents/qualifications'
import UserVacation from '../profileComponents/userVacation'

function EmployeeProfile() {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const params = useParams()
  const query = useQuery()
  const {run, data} = useAsync()

  const panes = [
    {
      menuItem: selectedContent.personalInfo,
      render: () => <PersonalInfo userData={data?.data} />,
    },
    {
      menuItem: selectedContent.jobInfo,
      render: () => <JobInfo userData={data?.data} />,
    },
    {
      menuItem: selectedContent.trainings,
      render: () => <Qualifications userData={data?.data} />,
    },
    {
      menuItem: selectedContent.attachments,
      render: () => <Attachments userData={data?.data} />,
    },
    {
      menuItem: selectedContent.vacations,
      render: () => <UserVacation userData={data?.data} />,
    },
  ]

  React.useEffect(() => {
    if (!query.get('id')) return
    run(authAxios.get(`/users/userById/${query.get('id')}`))
  }, [run])

  return (
    <section>
      <h2 className="text-primary">
        {getUserFullname(data?.data?.user, lang)}
      </h2>
      <div className="mt-8">
        <Tab menu={{secondary: true, pointing: true}} panes={panes} />
      </div>
    </section>
  )
}

export default EmployeeProfile
