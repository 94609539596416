import moment from 'moment'
import {useContext, useEffect, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {Accordion, Button, Tab} from 'semantic-ui-react'
import AnuallyPerformance from '../../../components/perfomanceComponents/annuallyPerformance'
import ChooseEmployeeModal from '../../../components/perfomanceComponents/chooseEmployeeModal'
import MonthlyPerformance from '../../../components/perfomanceComponents/monthlyPerformance'
import PerformanceHistory from '../../../components/perfomanceComponents/PerformanceHistory'
import Auth from '../../../config/auth'
import {useLanguage} from '../../../context/languageContext'
import StateContext from '../../../context/stateContext'
import useAsync from '../../../hooks/useAsync'
import {content} from '../../../localization/content'
import {getMyRatings} from '../../../services/ratingService'
import 'moment/locale/ar'
import 'moment/locale/en-ca'
import {useSocket} from '../../../context/socket-context'

const PerformancePage = () => {
  const [lang] = useLanguage()
  const performanceLang = content[lang]
  const {setShowModal} = useContext(StateContext)
  const {addToast} = useToasts()
  const {run, isLoading} = useAsync()
  const [monthlyPanels, setMonthlyPanels] = useState([])
  const [annualPanels, setAnnualPanels] = useState([])
  const [updateRatings, setUpdateRatings] = useState(false)

  const socket = useSocket()
  const panes = [
    {
      menuItem: {
        content: performanceLang.monthlyPerformance,
      },
      render: () => (
        <Tab.Pane loading={isLoading}>
          <Accordion panels={monthlyPanels} exclusive={false} fluid />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        content: performanceLang.annuallyPerformance,
      },
      render: () => (
        <Tab.Pane loading={isLoading}>
          <Accordion panels={annualPanels} exclusive={false} fluid />
        </Tab.Pane>
      ),
    },
    {
      ...(Auth.havePerformancePermission() && {
        menuItem: {
          content: performanceLang.history,
        },
        render: () => (
          <Tab.Pane loading={isLoading}>
            <PerformanceHistory />
          </Tab.Pane>
        ),
      }),
    },
  ]

  useEffect(() => {
    socket?.on('notification', msg => {
      if (![msg.type].includes('performance')) return
      if (![msg.message.users].includes(Auth.getUserId())) return
      addToast(msg.message['title' + lang.toUpperCase()], {
        appearance: 'info',
      })
      setUpdateRatings(prev => !prev)
    })
  }, [socket])

  useEffect(() => {
    run(getMyRatings())
      .then(({data}) => {
        // console.log(data.data)
        let monthlyData = []
        let annualData = []
        data.data.monthlyRatings[0]?.data.map((m, i) => {
          monthlyData.push({
            key: `month-${i}`,
            title: {
              content: (
                <label className="text-primary font-bold text-lg">
                  {moment(m.months.ratingMonth + 1, 'M')
                    .locale(lang)
                    .format('MMMM')}
                </label>
              ),
            },
            content: {
              content: <MonthlyPerformance ratings={m.months} />,
            },
          })
        })

        data.data.annualRatings.map((y, i) => {
          annualData.push({
            key: `year-${i}`,
            title: {
              content: (
                <label className="text-primary font-bold text-lg">
                  {y._id}
                </label>
              ),
            },
            content: {
              content: <AnuallyPerformance months={y.months} />,
            },
          })
        })

        setMonthlyPanels(monthlyData)
        setAnnualPanels(annualData)
      })
      .catch(e => {
        console.error(e)
      })
  }, [updateRatings])

  // useEffect(() => {
  //   if (ratingData === undefined) return
  //   let panelData = []
  //   ratingData[0].data.map((m, i) => {
  //     panelData.push({
  //       key: `month-${i}`,
  //       title: {
  //         content: (
  //           <label className="text-primary font-bold text-lg">
  //             {moment(m.months.ratingMonth + 1, 'M').format('MMMM')}
  //           </label>
  //         ),
  //       },
  //       content: {
  //         content: <MonthlyPerformance ratings={m.months} index={i} />,
  //       },
  //     })
  //   })

  //   setMonthlyPanels(panelData)
  // }, [myRates])

  return (
    <section className="w-auto">
      <ChooseEmployeeModal />
      <div className="flex items-center justify-between">
        <h2 className="text-primary m-0">{performanceLang.performance}</h2>
        {Auth.isHR() || Auth.havePerformancePermission() ? (
          <div>
            <Button
              className="btn-primary"
              onClick={() =>
                setShowModal({modalName: 'chooseEmployee', data: null})
              }
            >
              {performanceLang.addPerformance}
            </Button>
          </div>
        ) : null}
      </div>

      <div className="mt-8 overflow-x-auto">
        <div style={{width: '99%'}}>
          <Tab
            menu={{
              secondary: true,
              pointing: true,
              className: 'flex flex-wrap',
            }}
            panes={panes}
          />
        </div>
      </div>
    </section>
  )
}

export default PerformancePage
