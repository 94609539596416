import {Fragment, useEffect, useState} from 'react'
import {Button, Form, Transition} from 'semantic-ui-react'
import {keys} from '../../../config/keys'
import {useLanguage} from '../../../context/languageContext'
import useAsync from '../../../hooks/useAsync'
import {content} from '../../../localization/content'
import {getAllDepartments} from '../../../services/dashboardService'
import {getSubDepartments} from '../../../utils/handleDepartments'

// const roleOptions = [
//   {key: '0', text: 'Manager', value: keys.ROLES.manager},
//   {key: '1', text: 'Secretary', value: keys.ROLES.secretary},
//   {key: '2', text: 'Employee', value: keys.ROLES.user},
//   {key: '3', text: 'Human Resources', value: keys.ROLES.hr},
//   {key: '4', text: 'Information Technology', value: keys.ROLES.it},
// ]

const ManagerListStep = ({nextStep, role, errorContent}) => {
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const [allDepartments, setAllDepartments] = useState([])
  const [departments, setDepartments] = useState([])
  const [subDepartments, setSubDepartments] = useState([
    {key: '0', text: 'No results', value: 0, disabled: true},
  ])
  const managerContent = content[lang]
  const [state, setState] = useState({
    isMaster: true,
    department: '',
    subDepartment: '',
    role: 'manager',
  })
  const [errors, setErrors] = useState('')

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return

    run(getAllDepartments())
      .then(({data}) => {
        let departmentArr = []
        setAllDepartments(data.data)
        if (data.data.masterDepartments.length > 0) {
          data.data.masterDepartments.map((department, i) => {
            departmentArr.push({
              key: i,
              text: department['name' + lang.toUpperCase()],
              value: department._id,
            })
          })
          if (state.department !== '') {
            const getSubDepartment = getSubDepartments(
              [state.department],
              data.data,
              lang,
            )
            setSubDepartments(getSubDepartment)
          }
          setDepartments(departmentArr)
        }
      })
      .catch(e => {
        console.log(e)
      })
    return () => {
      isMounted = false
    }
  }, [lang])

  useEffect(() => {
    setState({...state, isMaster: role === 'user' ? false : true})
  }, [role])

  const roleOptions = [
    {key: '0', text: managerContent.manager, value: keys.ROLES.manager},
    {key: '1', text: managerContent.secretary, value: keys.ROLES.secretary},
    {key: '2', text: managerContent.employee, value: keys.ROLES.user},
    {key: '3', text: managerContent.hr, value: keys.ROLES.hr},
    {key: '4', text: managerContent.it, value: keys.ROLES.it},
  ]

  const handleOnChangeDepartment = (e, {value}) => {
    setState({...state, department: value, subDepartment: ''})
    if (role === 'user') {
      const getSubDepartment = getSubDepartments([value], allDepartments, lang)
      setSubDepartments(getSubDepartment)
    }
  }

  const handleOnClickSubmit = () => {
    if (role === 'user') {
      if (state.department === '' && state.subDepartment === '') {
        setErrors('all')
        return errorContent({isError: true, errorType: 'empty'})
      }
      if (state.department === '' || state.subDepartment === '') {
        if (state.department === '') {
          setErrors('department')
        } else {
          setErrors('subDepartment')
        }

        return errorContent({isError: true, errorType: 'department'})
      }
      if (state.role === '') {
        return errorContent({isError: true, errorType: 'role'})
      }
    } else {
      if (!state.isMaster) {
        if (state.department === '' && state.subDepartment === '') {
          setErrors('all')

          return errorContent({isError: true, errorType: 'empty'})
        }
        if (state.department === '' || state.subDepartment === '') {
          if (state.department === '') {
            setErrors('department')
          } else {
            setErrors('subDepartment')
          }
          return errorContent({isError: true, errorType: 'department'})
        }
      } else {
        if (state.department === '') {
          setErrors('department')

          return errorContent({isError: true, errorType: 'department'})
        }
      }
    }
    errorContent({isError: false, errorType: ''})
    setErrors('')

    nextStep({step: 6, type: 'submit', data: state})
  }

  return (
    <Fragment>
      <Form.Field>
        {/* <Form.Checkbox
          checked={state.isMaster}
          label={managerContent.isMaster}
          className={`${role === 'user' ? 'hidden' : 'visible'} text-primary`}
          onChange={(e, {value, checked}) =>
            setState({...state, isMaster: !state.isMaster})
          }
        /> */}
      </Form.Field>
      {role === 'user' && (
        <Form.Field>
          <label
            className={`${role === 'user' ? 'visible' : 'hidden'} text-primary`}
          >
            {managerContent.jobRole}
          </label>
          <Form.Dropdown
            fluid
            search
            selection
            defaultValue={state.role}
            options={roleOptions}
            disabled={isLoading}
            error={errors === 'all' || errors === 'role' ? true : false}
            placeholder={managerContent.selectRole}
            onChange={(e, {value}) => setState({...state, role: value})}
          />
        </Form.Field>
      )}

      <Form.Field>
        <label className="text-primary">{managerContent.department}</label>
        <Form.Dropdown
          fluid
          search
          selection
          clearable
          defaultValue={state.department}
          options={departments}
          disabled={isLoading}
          error={errors === 'all' || errors === 'department' ? true : false}
          placeholder="Select department"
          onChange={handleOnChangeDepartment}
        />
      </Form.Field>

      {role === 'user' && (
        <Form.Field>
          <label className="text-primary">{managerContent.subDepartment}</label>

          <Form.Dropdown
            fluid
            search
            selection
            clearable
            defaultValue={state.subDepartment}
            disabled={isLoading}
            placeholder="Select sub-department"
            error={
              errors === 'all' || errors === 'subDepartment' ? true : false
            }
            options={subDepartments}
            onChange={(e, {value}) =>
              setState({...state, subDepartment: value})
            }
          />
        </Form.Field>
      )}

      <div className="my-5 text-center">
        <Button
          content={managerContent.signup}
          className="btn-primary"
          onClick={handleOnClickSubmit}
        />
      </div>
    </Fragment>
  )
}

export default ManagerListStep
