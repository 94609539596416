import {Fragment, useEffect, useState} from 'react'
import {Icon, Table} from 'semantic-ui-react'
import {keys} from '../../config/keys'
import moment from 'moment'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'

const QualificationTable = ({tableHeaders, data, type}) => {
  const [lang] = useLanguage()
  const tableLang = content[lang]
  const [attachments, setAttachments] = useState([])
  useEffect(() => {
    if (!data) return
    setAttachments(data)
  }, [data])

  return (
    <Table celled color="green" selectable>
      <Table.Header>
        <Table.Row>
          {tableHeaders.length > 0 &&
            tableHeaders.map((tableH, i) => (
              <Table.HeaderCell textAlign="center" key={i}>
                {tableH}
              </Table.HeaderCell>
            ))}
          <Table.HeaderCell textAlign="center"></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {type === 'qualifications' && (
          <Fragment>
            {attachments.length > 0 ? (
              attachments.map((qualification, i) => (
                <Table.Row key={i}>
                  <Table.Cell textAlign="center">{i + 1}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {qualification.qualificationName}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {qualification.universityName}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {moment(qualification.graduationYear).year()}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {qualification.rate}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {qualification.degree}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <a href={qualification.attachedFile} target="_blank">
                      <Icon
                        name="eye"
                        className=" text-goldPrimary cursor-pointer"
                        size="large"
                      />
                    </a>
                  </Table.Cell>

                  <Table.Cell textAlign="center">
                    <Icon name="times circle" color="red" size="large" />
                    {/* <div className="flex items-center">
                      <Dropdown
                        icon="settings"
                        floating
                        button
                        className="icon mx-auto"
                      >
                        <Dropdown.Menu className="ltr:-left-32 rtl:-right-32">
                          <Dropdown.Header
                            icon="tags"
                            content="Filter by tag"
                          />
                          <Dropdown.Divider />
                          <Dropdown.Item
                            label={{color: 'red', empty: true, circular: true}}
                            text=""
                          />
                          <Dropdown.Item
                            label={{color: 'blue', empty: true, circular: true}}
                            text="Announcement"
                          />
                          <Dropdown.Item
                            label={{
                              color: 'black',
                              empty: true,
                              circular: true,
                            }}
                            text="Discussion"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={8} textAlign="center" warning>
                  {tableLang.emptyQualifications}
                </Table.Cell>
              </Table.Row>
            )}
          </Fragment>
        )}
        {type === 'courses' && (
          <Fragment>
            {attachments.length > 0 ? (
              attachments.map((course, i) => (
                <Table.Row key={i}>
                  <Table.Cell textAlign="center">{i + 1}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {course.courseName}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {course.destination}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {moment(course.date).format('DD-MM-YYYY')}
                  </Table.Cell>
                  <Table.Cell textAlign="center">{course.duration}</Table.Cell>
                  <Table.Cell textAlign="center">{course.rate}</Table.Cell>
                  <Table.Cell textAlign="center">{course.degree}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <a href={course.attachedFile} target="_blank">
                      <Icon
                        name="eye"
                        className="text-goldPrimary cursor-pointer"
                        size="large"
                      />
                    </a>
                  </Table.Cell>

                  <Table.Cell textAlign="center">
                    <Icon name="times circle" color="red" size="large" />
                    {/* <div className="flex items-center">
                      <Dropdown
                        icon="settings"
                        floating
                        button
                        className="icon mx-auto"
                      >
                        <Dropdown.Menu className="ltr:-left-32 rtl:-right-32">
                          <Dropdown.Header
                            icon="tags"
                            content="Filter by tag"
                          />
                          <Dropdown.Divider />
                          <Dropdown.Item
                            label={{color: 'red', empty: true, circular: true}}
                            text=""
                          />
                          <Dropdown.Item
                            label={{color: 'blue', empty: true, circular: true}}
                            text="Announcement"
                          />
                          <Dropdown.Item
                            label={{
                              color: 'black',
                              empty: true,
                              circular: true,
                            }}
                            text="Discussion"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={9} textAlign="center" warning>
                  {tableLang.emptyQualifications}
                </Table.Cell>
              </Table.Row>
            )}
          </Fragment>
        )}
      </Table.Body>
    </Table>
  )
}

export default QualificationTable
