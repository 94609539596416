import React from 'react'
import {useHistory, useLocation} from 'react-router'
import {Tab} from 'semantic-ui-react'

import EmployeesOnVacation from '../../components/vacation-request/EmployeesOnVacation'
import IncommingVacationRequests from '../../components/vacation-request/IncommingVacationRequests'
import NewVacationRequest from '../../components/vacation-request/NewVacationRequest'
import VacationsHistory from '../../components/vacation-request/VacationsHistory'
import Auth from '../../config/auth'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import routes from '../../routes'

function Vacation() {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const history = useHistory()
  const {pathname} = useLocation()

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const onSuccessfulNewVacation = () => {
    history.push(`${routes.vacations}/${panes[panes.length - 1].route}`)
  }

  function onTabChange(e, {panes, activeIndex}) {
    history.push(`${routes.vacations}/${panes[activeIndex].route}`)
  }

  const panes = React.useMemo(() => {
    if (Auth.isManagerOrHigher())
      return [
        {
          menuItem: selectedContent.employeesOnVacation,
          route: content.en.employeesOnVacation,
          render: () => <EmployeesOnVacation />,
        },
        {
          menuItem: selectedContent.incommingRequests,
          route: content.en.incommingRequests,
          render: () => <IncommingVacationRequests />,
        },
        {
          menuItem: selectedContent.submitRequest,
          route: content.en.submitRequest,
          render: () => (
            <NewVacationRequest onCreate={onSuccessfulNewVacation} />
          ),
        },
        {
          menuItem: selectedContent.history,
          route: content.en.history,
          render: () => <VacationsHistory />,
        },
      ]
    else
      return [
        {
          menuItem: selectedContent.submitRequest,
          route: content.en.submitRequest,
          render: () => (
            <NewVacationRequest onCreate={onSuccessfulNewVacation} />
          ),
        },
        {
          menuItem: selectedContent.history,
          route: content.en.history,
          render: () => <VacationsHistory />,
        },
      ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  React.useEffect(() => {
    for (let i = 0; i < panes.length; i++)
      if (pathname.split('?')[0].endsWith(panes[i].route)) setActiveIndex(i)
  }, [panes, pathname])

  return (
    <div>
      <h2 className="text-primary mb-8">{selectedContent.vacations}</h2>

      <div className="mt-8">
        <div style={{width: '99%'}}>
          <Tab
            menu={{
              secondary: true,
              pointing: true,
              className: 'flex flex-wrap',
            }}
            panes={panes}
            onTabChange={onTabChange}
            activeIndex={activeIndex}
          />
        </div>
      </div>
    </div>
  )
}

export default Vacation
