import {Fragment, useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import {Button, Dropdown, Grid, Icon, Image, Table} from 'semantic-ui-react'
import CreateMeetingModal from '../../../components/meetingComponents/createModal'
import Auth from '../../../config/auth'
import {useLanguage} from '../../../context/languageContext'
import StateContext from '../../../context/stateContext'
import useAsync from '../../../hooks/useAsync'
import {content} from '../../../localization/content'
import {getMyMeetings, updateMeetings} from '../../../services/meetingService'
import {formatDate, formatTime} from '../../../utils/date-format'
import borderIcon from '../../../assets/images/ic_border.svg'
import UpdateMeetingModal from '../../../components/meetingComponents/updateMeetingModal'
import ExcuseModal from '../../../components/meetingComponents/excuseModal'
import DeleteMeetingModal from '../../../components/meetingComponents/deleteMeetingModal'
import {useSocket} from '../../../context/socket-context'
import usePagination from '../../../hooks/use-pagination'
import PaginationWithLimit from '../../../components/pagination-with-limit'

const MeetingPage = () => {
  const {setShowModal} = useContext(StateContext)
  const [lang] = useLanguage()
  const {run, data, isLoading} = useAsync()
  const meetingLang = content[lang]
  const {addToast} = useToasts()
  const history = useHistory()
  const [meetings, setMeetings] = useState([])
  const [view, setView] = useState('')
  const [updateMeeting, setUpdateMeeting] = useState(false)
  const {page, limit, onPageChange, onLimitChange} = usePagination()

  const socket = useSocket()

  useEffect(() => {
    socket?.on('notification', msg => {
      if (
        !['meetings'].includes(msg.type) &&
        msg.message.users.includes(Auth.getUserId())
      )
        return
      setUpdateMeeting(prev => !prev)
    })
  }, [socket])

  useEffect(() => {
    if (page)
      run(getMyMeetings({page, limit}))
        .then(({data}) => {
          setMeetings(data.data)
        })
        .catch(e => {
          console.log(e)
        })
  }, [run, updateMeeting, page, limit])

  const handleExcuse = id => {
    const meetingData = meetings.filter(obj => obj._id === id)
    const isExcused = meetingData[0].employees.map(emp => {
      if (String(emp.empId._id) === String(Auth.getUserId())) {
        if (emp.isExcuse) {
          return true
        } else {
          return false
        }
      }
    })

    var filtered = isExcused.filter(function (x) {
      return x !== undefined
    })

    if (!filtered[0]) {
      return (
        <Dropdown.Item
          icon="times"
          className="text-yellow-500"
          text="Excuse"
          onClick={() => handleUpdate('excuse', id)}
        />
      )
    } else {
      return false
    }
  }

  const handleOnClickView = id => {
    if (view === id) {
      setView('')
    } else {
      setView(id)
    }
  }

  const handleUpdate = (type, values) => {
    switch (type) {
      case 'delete':
        setShowModal({modalName: 'deleteMeeting', data: values})
        return
      case 'started':
        run(updateMeetings({meetingId: values, meetingStatus: type}))
          .then(({data}) => {
            addToast(data.message[lang], {appearance: 'success'})
            setUpdateMeeting(prev => !prev)
          })
          .catch(e => {
            console.log(e)
          })
        return
      case 'ended':
        run(updateMeetings({meetingId: values, meetingStatus: type}))
          .then(({data}) => {
            addToast(data.message[lang], {appearance: 'success'})
            setUpdateMeeting(prev => !prev)
          })
          .catch(e => {
            console.log(e)
          })
        return
      case 'excuse':
        setShowModal({modalName: 'excuseMeeting', data: {meetingId: values}})
        return

      default:
        return
    }
  }

  const handleOnClickCreate = () => {
    setShowModal({modalName: 'Meeting', data: null})
  }

  const handleOnClickEdit = id => {
    const index = meetings.findIndex(obj => obj._id === id)
    setShowModal({modalName: 'updateMeeting', data: meetings[index]})
  }

  const handleOnClickPrint = id => {
    history.push(`/meetings/printMeeting?id=${id}`)
  }
  return (
    <section>
      <CreateMeetingModal
        storeValue={value => setUpdateMeeting(prev => !prev)}
      />
      <UpdateMeetingModal
        storeValue={value => setUpdateMeeting(prev => !prev)}
      />
      <ExcuseModal storeValues={value => setUpdateMeeting(prev => !prev)} />
      <DeleteMeetingModal
        deleteMeeting={v => setUpdateMeeting(prev => !prev)}
      />
      <div className="flex items-center justify-between">
        <h2 className="text-primary">{meetingLang.meetings}</h2>
        {/* {Auth.isMeetingAuth() && (
            <div className="p-2">
              <Button
                content="Create Meeting"
                className="btn-primary"
                onClick={handleOnClickCreate}
              />
            </div>
          )} */}
        {Auth.isManagerOrHigher() ||
        Auth.isSecretary() !== false ||
        Auth.haveMeetingsPermission() ? (
          <div className="p-2">
            <Button
              content={meetingLang.createMeeting}
              className="btn-primary"
              onClick={handleOnClickCreate}
            />
          </div>
        ) : null}
      </div>

      <div className="my-3">
        <Table celled color="green" sortable>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>#</Table.HeaderCell>

              <Table.HeaderCell>{meetingLang.meetingTopic}</Table.HeaderCell>
              <Table.HeaderCell>{meetingLang.date}</Table.HeaderCell>
              <Table.HeaderCell>{meetingLang.time}</Table.HeaderCell>
              <Table.HeaderCell>{meetingLang.department}</Table.HeaderCell>
              <Table.HeaderCell>{meetingLang.status}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {meetings.length > 0 &&
              meetings.map((meeting, i) => (
                <Fragment key={i}>
                  <Table.Row textAlign="center">
                    <Table.Cell
                      selectable
                      width="1"
                      className="cursor-pointer"
                      onClick={() => handleOnClickView(meeting._id)}
                    >
                      <Icon
                        name={view === meeting._id ? 'angle up' : 'angle down'}
                        className="text-xl text-primary m-0"
                      />
                    </Table.Cell>

                    <Table.Cell>{meeting.meetingTopic}</Table.Cell>
                    <Table.Cell>{formatDate(meeting.meetingDate)}</Table.Cell>
                    <Table.Cell width="4">
                      <p className="font-bold text-primary ">
                        {meetingLang.from}:{' '}
                        <span className="text-black font-normal">
                          {formatTime(meeting.meetingStartTime)}
                        </span>
                      </p>
                      <p className="font-bold text-primary">
                        {meetingLang.to}:{' '}
                        <span className="text-black font-normal">
                          {formatTime(meeting.meetingEndTime)}
                        </span>
                      </p>
                    </Table.Cell>
                    <Table.Cell>
                      {meeting.department.map(department => (
                        <p key={department._id}>
                          {department['name' + lang.toUpperCase()]}
                        </p>
                      ))}
                    </Table.Cell>
                    <Table.Cell
                      className={`${meeting.meetingStatus.toLowerCase()} font-semibold`}
                      positive={
                        meeting.meetingStatus.toLowerCase() === 'started'
                          ? true
                          : false
                      }
                      negative={
                        meeting.meetingStatus.toLowerCase() === 'ended'
                          ? true
                          : false
                      }
                      warning={
                        meeting.meetingStatus.toLowerCase() === 'pending'
                          ? true
                          : false
                      }
                    >
                      {meeting.employees.map(emp => {
                        if (emp.empId._id === Auth.getUserId()) {
                          if (emp.isExcuse) {
                            return (
                              <p className="text-red-800">
                                {meetingLang.excused}
                              </p>
                            )
                          } else {
                            return meetingLang[meeting.meetingStatus]
                          }
                        }
                      })}
                    </Table.Cell>
                    <Table.Cell>
                      <div className="flex items-center">
                        <Dropdown
                          icon="settings"
                          floating
                          button
                          className="icon mx-auto"
                        >
                          <Dropdown.Menu className="ltr:-left-24 rtl:-right-24">
                            {meeting.createdBy._id === Auth.getUserId() && (
                              <Fragment>
                                {meeting.meetingStatus === 'pending' && (
                                  <Dropdown.Item
                                    label={{
                                      color: 'green',
                                      empty: true,
                                      circular: true,
                                    }}
                                    text={meetingLang.startMeeting}
                                    onClick={() =>
                                      handleUpdate('started', {id: meeting._id})
                                    }
                                  />
                                )}
                                {meeting.meetingStatus === 'started' && (
                                  <Dropdown.Item
                                    label={{
                                      color: 'yellow',
                                      empty: true,
                                      circular: true,
                                    }}
                                    text={meetingLang.endMeeting}
                                    onClick={() =>
                                      handleUpdate('ended', {id: meeting._id})
                                    }
                                  />
                                )}
                              </Fragment>
                            )}

                            <Dropdown.Item
                              icon="eye"
                              text={meetingLang.view}
                              onClick={() => setView(meeting._id)}
                            />

                            {meeting.meetingStatus === 'pending' &&
                              handleExcuse(meeting._id)}

                            {meeting.meetingStatus === 'ended' &&
                            meeting.createdBy._id === Auth.getUserId() ? (
                              <Dropdown.Item
                                icon="print"
                                text={meetingLang.print}
                                className="text-green-600"
                                onClick={() => handleOnClickPrint(meeting._id)}
                              />
                            ) : null}

                            {meeting.createdBy._id === Auth.getUserId() && (
                              <Fragment>
                                {meeting.meetingStatus === 'pending' && (
                                  <Dropdown.Item
                                    icon="edit"
                                    text={meetingLang.edit}
                                    onClick={() =>
                                      handleOnClickEdit(meeting._id)
                                    }
                                  />
                                )}

                                <Dropdown.Item
                                  icon="trash"
                                  className="text-red-700"
                                  text={meetingLang.delete}
                                  onClick={() =>
                                    handleUpdate('delete', {
                                      id: meeting._id,
                                      name: meeting.meetingTopic,
                                    })
                                  }
                                />
                              </Fragment>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                  {view === meeting._id && (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        {/* <div
                            className="absolute top-2 right-5 rtl:left-5"
                            onClick={() => setView('')}
                          >
                            <Icon
                              name="angle up"
                              className="text-xl cursor-pointer text-primary"
                            />
                          </div> */}
                        <Grid doubling columns={2} className="py-5">
                          <Grid.Row>
                            <Grid.Column>
                              <div className="flex items-center mb-3">
                                <Image
                                  src={borderIcon}
                                  width="2px"
                                  height="2px"
                                  className="ltr:mr-2 rtl:ml-2"
                                  alt="border icon"
                                />
                                <p className="font-bold mx-3 text-primary">
                                  {meetingLang.meetingTopic}:{' '}
                                </p>
                                <p className=" text-subTitleText">
                                  {meeting.meetingTopic}
                                </p>
                              </div>

                              <div className="flex items-center mb-3">
                                <Image
                                  src={borderIcon}
                                  width="2px"
                                  height="2px"
                                  className="ltr:mr-2 rtl:ml-2"
                                  alt="border icon"
                                />
                                <p className="font-bold mx-3 text-primary">
                                  {meetingLang.meetingTopicAR}:{' '}
                                </p>
                                <p className=" text-subTitleText">
                                  {meeting.meetingTopicAR}
                                </p>
                              </div>

                              <div className="flex items-center mb-3">
                                <Image
                                  src={borderIcon}
                                  width="2px"
                                  height="2px"
                                  className="ltr:mr-2 rtl:ml-2"
                                  alt="border icon"
                                />
                                <p className="font-bold mx-3 text-primary">
                                  {meetingLang.meetingDate}:{' '}
                                </p>
                                <p className=" text-subTitleText">
                                  {formatDate(meeting.meetingDate)}
                                </p>
                              </div>

                              <div className="flex items-center mb-3">
                                <Image
                                  src={borderIcon}
                                  width="2px"
                                  height="2px"
                                  className="ltr:mr-2 rtl:ml-2"
                                  alt="border icon"
                                />
                                <p className="font-bold mx-3 text-primary">
                                  {meetingLang.meetingTime}:{' '}
                                </p>
                                <div className="flex items-center">
                                  <p className="text-gray-600">
                                    {meetingLang.from}:{' '}
                                    {formatTime(meeting.meetingStartTime)}
                                  </p>
                                  <p className=" text-gray-600 mx-4">
                                    {meetingLang.to}:{' '}
                                    {formatTime(meeting.meetingEndTime)}
                                  </p>
                                </div>
                              </div>

                              <div className="flex items-center mb-3">
                                <Image
                                  src={borderIcon}
                                  width="2px"
                                  height="2px"
                                  className="ltr:mr-2 rtl:ml-2"
                                  alt="border icon"
                                />
                                <p className="font-bold mx-3 text-primary">
                                  {meetingLang.createdBy}:{' '}
                                </p>
                                <p className="text-gray-600">
                                  {
                                    meeting.createdBy[
                                      'firstName' + lang.toUpperCase()
                                    ]
                                  }{' '}
                                  {
                                    meeting.createdBy[
                                      'lastName' + lang.toUpperCase()
                                    ]
                                  }
                                </p>
                              </div>
                            </Grid.Column>

                            <Grid.Column>
                              <div className="mb-3">
                                <div className="flex items-center">
                                  <Image
                                    src={borderIcon}
                                    width="2px"
                                    height="2px"
                                    className="ltr:mr-2 rtl:ml-2"
                                    alt="border icon"
                                  />
                                  <p className="font-bold mx-3 text-primary">
                                    {meetingLang.department}:{' '}
                                  </p>
                                </div>
                                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                                  {meeting.department.map((department, i) => (
                                    <p className=" text-subTitleText" key={i}>
                                      {department['name' + lang.toUpperCase()]}
                                    </p>
                                  ))}
                                </div>
                              </div>

                              <div className=" mb-3">
                                <div className="flex items-center">
                                  <Image
                                    src={borderIcon}
                                    width="2px"
                                    height="2px"
                                    className="ltr:mr-2 rtl:ml-2"
                                    alt="border icon"
                                  />
                                  <p className="font-bold mx-3 text-primary">
                                    {meetingLang.employees}:{' '}
                                  </p>
                                </div>
                                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                                  {meeting.employees.map((employee, i) => (
                                    <div className="flex" key={i}>
                                      <p
                                        className={`${
                                          employee.isExcuse
                                            ? 'text-red-700'
                                            : 'text-subTitleText'
                                        } ltr:mr-2 rtl:ml-2`}
                                        key={i}
                                      >
                                        {
                                          employee.empId[
                                            'firstName' + lang.toUpperCase()
                                          ]
                                        }{' '}
                                        {
                                          employee.empId[
                                            'lastName' + lang.toUpperCase()
                                          ]
                                        }
                                      </p>
                                      {employee.isExcuse && (
                                        <p className="text-red-800">
                                          <strong>Reason: </strong>
                                          {employee.reason}
                                        </p>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="mb-3">
                                <div className="flex items-center">
                                  <Image
                                    src={borderIcon}
                                    width="2px"
                                    height="2px"
                                    className="ltr:mr-2 rtl:ml-2"
                                    alt="border icon"
                                  />
                                  <p className="font-bold mx-3 text-primary">
                                    {meetingLang.meetingAgenda}:{' '}
                                  </p>
                                </div>
                                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                                  {meeting.meetingAgenda &&
                                    Object.keys(meeting.meetingAgenda).map(
                                      (agenda, i) => (
                                        <p
                                          className=" text-subTitleText ltr:mr-2 rtl:ml-2"
                                          key={i}
                                        >
                                          {meeting.meetingAgenda[agenda]}
                                        </p>
                                      ),
                                    )}
                                </div>
                              </div>

                              <div className="flex items-center mb-3">
                                <Image
                                  src={borderIcon}
                                  width="2px"
                                  height="2px"
                                  className="ltr:mr-2 rtl:ml-2"
                                  alt="border icon"
                                />
                                <p className="font-bold mx-3 text-primary">
                                  {meetingLang.duration}:{' '}
                                </p>
                                <p className="text-gray-600">
                                  {meeting.duration}
                                </p>
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Fragment>
              ))}

            {meetings.length === 0 && !isLoading ? (
              <Table.Row>
                <Table.Cell colSpan={8} textAlign="center" warning>
                  {meetingLang.emptyMeetings}
                </Table.Cell>
              </Table.Row>
            ) : null}

            {isLoading && meetings.length === 0 ? (
              <Table.Row textAlign="center">
                <Table.Cell colSpan={8}>
                  <Icon
                    name="asterisk"
                    size="large"
                    className="text-primary"
                    loading
                  />
                </Table.Cell>
              </Table.Row>
            ) : null}
          </Table.Body>
        </Table>
      </div>

      <PaginationWithLimit
        page={page}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        totalPages={data?.totalPages}
      />
    </section>
  )
}

export default MeetingPage
