import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Button, Form, Grid, Image, Modal, Tab} from 'semantic-ui-react'
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import useAsync from '../../hooks/useAsync'
import {authAxios} from '../../config/axiosConfig'
import {useToasts} from 'react-toast-notifications'
import {formatDate, formatTime, nOfHoursBetween} from '../../utils/date-format'
import FormikControl from '../formik/FormikControl'
import {getUserFullname} from '../../utils/user'
import Auth from '../../config/auth'
import {useSocket} from '../../context/socket-context'
import {FaFile, FaUserCircle} from 'react-icons/fa'

function AllPermissionRequests() {
  const [isOpen, setIsOpen] = React.useState('')
  const [selectedRequest, setSelectedRequest] = React.useState('')

  const [lang] = useLanguage()
  const allPermissionsContent = content[lang]

  const {run, isLoading, data} = useAsync()
  const {addToast} = useToasts()
  const [updateUpcomming, setUpdateUpcomming] = useState(false)

  const socket = useSocket()

  const getRequests = async () => {
    await run(authAxios.get('/requests/permissions/requests')).catch(e => {
      e?.errors.map(error => {
        return addToast(error.message[lang], {appearance: 'error'})
      })
    })
  }

  useEffect(() => {
    socket?.on('notification', msg => {
      if (msg.type !== 'permissionRequest') return
      if (!msg.message.users.includes(Auth.getUserId())) return
      if ([msg.message.from].includes(Auth.getUserId())) return
      setUpdateUpcomming(prev => !prev)
    })
  }, [socket])

  React.useEffect(() => {
    getRequests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUpcomming])

  const handleOpenPermissionDetails = request => {
    setIsOpen(true)
    setSelectedRequest(request)
  }

  return (
    <Tab.Pane loading={isLoading} className="m-0 p-0 border-0">
      <Grid>
        <Grid.Row>
          {data?.data?.map(r => (
            <Grid.Column
              mobile="16"
              // tablet="8"
              computer="7"
              largeScreen="5"
              className="flex flex-shrink-0 mb-8 items-start"
            >
              {r?.creator?.avatar ? (
                <Image src={r.creator.avatar} circular size="tiny" />
              ) : (
                <FaUserCircle className="inline-block text-primary w-20 h-20 ltr:mr-3 rtl:ml-3" />
              )}
              <div className="ltr:ml-5 rtl:mr-5">
                <h3 className="mt-1">{getUserFullname(r?.creator, lang)}</h3>
                <h5 className="text-gray-500 m-0 mb-3">
                  {r?.creator?.department[`name${lang.toUpperCase()}`]}
                </h5>
                <p className="text-gray-700 m-0">
                  <strong className="ltr:mr-2 rtl:ml-2">
                    {allPermissionsContent.from}:
                  </strong>
                  {formatTime(r?.for?.start)}
                </p>
                <p className="text-gray-700 m-0 mb-3">
                  <strong className="ltr:mr-2 rtl:ml-2">
                    {allPermissionsContent.to}:
                  </strong>
                  {formatTime(r?.for?.end)}
                </p>
                <p className="text-gray-700 m-0">
                  <strong className="ltr:mr-2 rtl:ml-2">
                    {allPermissionsContent.duration}:
                  </strong>
                  {nOfHoursBetween(r?.for?.start, r?.for?.end)} H
                </p>

                <p className="text-gray-700 m-0 mb-3">
                  <strong className="ltr:mr-2 rtl:ml-2">
                    {allPermissionsContent.date}:
                  </strong>
                  {formatDate(r?.for?.date)}
                </p>

                <div className="my-1">
                  {r?.for?.attachment?.link ? (
                    <FaFile className="text-primary" />
                  ) : null}
                </div>

                <Link
                  className="text-primary hover:underline"
                  onClick={() => handleOpenPermissionDetails(r)}
                >
                  {allPermissionsContent.moreDetails}
                </Link>
              </div>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>

      <PermissionDetailsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        request={selectedRequest}
        updateRequests={getRequests}
      />
    </Tab.Pane>
  )
}

const PermissionDetailsModal = ({
  isOpen,
  setIsOpen,
  request,
  updateRequests,
}) => {
  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const [isSecondModalOpen, setIsSecondModalOpen] = React.useState(false)
  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const rejectionSchema = Yup.object({
    rejectionReason: Yup.string()
      .required('Required')
      .trim()
      .min(3, 'Too short')
      .max(1000, 'Too long'),
  })

  const handleRequestRejection = values => {
    run(
      authAxios.post(`/requests/${request._id}/reject`, {
        rejectionReason: values.rejectionReason,
      }),
    )
      .then(({data}) => {
        updateRequests(true)
        addToast(data.message[lang], {appearance: 'success'})
        setIsSecondModalOpen(false)
        setIsOpen(false)
      })
      .catch(e => {
        console.log(e)
        e?.errors?.map(error => {
          return addToast(error.message[lang], {appearance: 'error'})
        })
      })
  }

  const handleRequestAcceptance = () => {
    run(authAxios.post(`/requests/${request._id}/approve`)).then(() => {
      updateRequests()
      setIsOpen(false)
    })
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      size="small"
      closeIcon
    >
      <Modal.Content image scrolling className="flex flex-col">
        <div class="px-4 md:px-8">
          {request?.creator?.avatar ? (
            <Image
              src={request.creator?.avatar}
              className="rounded-full w-24 h-24 mb-4"
            />
          ) : (
            <FaUserCircle className="inline-block text-primary w-20 h-20 ltr:mr-3 rtl:ml-3 mb-4" />
          )}
          <h3>{getUserFullname(request?.creator, lang)}</h3>
          <h5 className="text-gray-500 mb-5">
            {request?.creator?.department[`name${lang.toUpperCase()}`]}
          </h5>
          <div class="flex">
            <p className="text-gray-700 m-0">
              <strong className="ltr:mr-2 rtl:ml-2">
                {selectedContent.from}:
              </strong>
              {formatTime(request?.for?.start)}
            </p>
            <p className="text-gray-700 m-0 ltr:ml-4 rtl:mr-4">
              <strong className="ltr:mr-2 rtl:ml-2">
                {selectedContent.to}:
              </strong>
              {formatTime(request?.for?.end)}
            </p>
            <p className="text-gray-700 m-0 ltr:ml-4 rtl:mr-4">
              <strong className="ltr:mr-2 rtl:ml-2">
                {selectedContent.duration}:
              </strong>
              {nOfHoursBetween(request?.for?.start, request?.for?.end)} H
            </p>
          </div>

          <p className="text-gray-700 m-0">
            <strong className="ltr:mr-2 rtl:ml-2">
              {selectedContent.date}:
            </strong>
            {formatDate(request?.for?.date)}
          </p>

          <h4 className="text-gray-700 m-0 mt-4">
            <strong>{selectedContent.reason}:</strong>
          </h4>
          <p>{request?.for?.reason}</p>

          <div className="flex items-end">
            <p className="ltr:mr-2 rtl:ml-2 mt-2">
              {selectedContent.attachments}
            </p>

            {request?.for?.attachment?.link ? (
              <a
                rel="noreferrer"
                href={request.for.attachment.link}
                target="_blank"
                className="underline"
              >
                {selectedContent.clickToOpen}
              </a>
            ) : (
              selectedContent.noAttachments
            )}
          </div>
        </div>
      </Modal.Content>
      <div class="flex justify-end m-3">
        <Form loading={isLoading}>
          <Button
            onClick={() => setIsSecondModalOpen(true)}
            className="btn-danger ltr:mr-5 rtl:ml-5"
          >
            {selectedContent.reject}{' '}
            <AiOutlineClose size="14" className="inline-block" />
          </Button>
          <Button onClick={handleRequestAcceptance} className="btn-primary">
            {selectedContent.accept}{' '}
            <AiOutlineCheck size="14" className="inline-block" />
          </Button>
        </Form>
      </div>

      <Modal
        onClose={() => setIsSecondModalOpen(false)}
        open={isSecondModalOpen}
        size="small"
      >
        <Modal.Header>{selectedContent.rejectionReason}</Modal.Header>

        <Formik
          initialValues={{rejectionReason: ''}}
          validationSchema={rejectionSchema}
          onSubmit={handleRequestRejection}
        >
          {formik => (
            <>
              <Modal.Content>
                <Form onSubmit={formik.submitForm}>
                  <FormikControl
                    control="textarea"
                    name="rejectionReason"
                    placeholder={selectedContent.reason}
                    className="mb-2"
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={formik.handleSubmit} className="btn-danger">
                  {selectedContent.reject}{' '}
                  <AiOutlineClose size="14" className="inline-block" />
                </Button>
              </Modal.Actions>
            </>
          )}
        </Formik>
      </Modal>
    </Modal>
  )
}

export default AllPermissionRequests
