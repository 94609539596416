import {authAxios} from '../config/axiosConfig'

// Get my chats
export const getMyChats = async () => {
  return authAxios.get('/chats/myChats')
}

// Get all chat users
export const getAllChatUsers = async () => {
  return authAxios.get('/chats/allChatUsers')
}

// Start new chat
export const startNewChat = async userId => {
  return authAxios.post('/chats/conversation', userId)
}

// Send new message
export const sendNewMessage = async message => {
  return authAxios.post('/chats/newMessages', message)
}

// Get chat by chat Id
export const getChatByChatId = async chatId => {
  return authAxios.get('/chats/getChatByChatId/' + chatId)
}
