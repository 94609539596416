import moment from 'moment'
import React from 'react'
import {Accordion, Icon} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import MonthlyPerformance from './monthlyPerformance'

const AnuallyPerformance = ({months}) => {
  const [accordionRatings, setAccordionRatings] = React.useState([])
  const [lang] = useLanguage()

  React.useEffect(() => {
    const monthlyData = []
    months?.map((m, i) => {
      // we don't need to infinitly change the structure when changing between month and annual tabs
      if (Array.isArray(m.ratings)) m.ratings = m.ratings[0]

      monthlyData.push({
        key: `month-${i}`,
        title: {
          content: (
            <label className="text-primary font-bold text-lg">
              {moment(m.ratingMonth + 1, 'M')
                .locale(lang)
                .format('MMMM')}
            </label>
          ),
        },
        content: {
          content: <MonthlyPerformance ratings={m} />,
        },
      })
    })
    setAccordionRatings(monthlyData)
  }, [months])

  return (
    <div className="my-1">
      <div className="ltr:ml-10 rtl:mr-10">
        <ul>
          <li className="flex items-center">
            <div className="ltr:ml-2 rtl:mr-2">
              {/* {months.map((r, i) => (
                <div className="flex items-center mb-2" key={i}>
                  <Icon
                    name="circle notch"
                    className="text-lg -mt-2 text-primary"
                  />
                  <p className="text-lg ltr:ml-3 rtl:mr-3">
                    <strong className="text-goldPrimary">
                      {r['name' + lang.toUpperCase()]}:
                    </strong>{' '}
                    <span className="ltr:ml-2 rtl:mr-2 text-gray-500">
                      {r.totalRate}
                    </span>
                  </p>
                </div>
              ))} */}
              <Accordion panels={accordionRatings} exclusive={false} fluid />
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default AnuallyPerformance
