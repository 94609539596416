import {Fragment, useState} from 'react'
import ReactCodeInput from 'react-code-input'
import {Button, Form} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const VerifyPhone = ({errorContent, nextStep, values}) => {
  const [lang] = useLanguage()
  const verifyContent = content[lang]
  const [state, setState] = useState({
    code: values.code || '',
  })
  const props = {
    inputStyle: {
      margin: '4px',
      MozAppearance: 'textfield',
      width: '35px',
      borderRadius: '3px',
      fontSize: '24px',
      fontWeight: 'bold',
      height: '45px',
      textAlign: 'center',
      paddingLeft: '7px',
      backgroundColor: 'white',
      color: '#148f64',
      padding: '4px',
      border: '1px solid #148f64',
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '15px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'black',
      color: '#148f64',
      border: '1px solid #148f64',
    },
    onChange: value => {
      setState({...state, code: value})
    },
  }

  const handleOnClickSubmit = () => {
    if (state.code === '') {
      return errorContent({
        isError: true,
        errorType: 'verify',
      })
    }
    errorContent({
      isError: false,
      errorType: '',
    })
    nextStep({
      step: 4,
      type: 'step',
      data: {
        code: state.code,
      },
    })
  }
  return (
    <Fragment>
      <Form.Field>
        <ReactCodeInput
          type="text"
          className="text-center w-100"
          fields={6}
          {...props}
        />
      </Form.Field>

      <div className="my-5 text-center">
        <Button
          content={verifyContent.next}
          className="btn-primary"
          onClick={handleOnClickSubmit}
        />
      </div>
    </Fragment>
  )
}

export default VerifyPhone
