import {content} from '../localization/content'

export function getFixedEndVacationTypes(lang = 'en') {
  const selectedContent = content[lang]

  return [
    {
      key: 'newBorn',
      text: selectedContent.newborn,
      value: 'NewBorn',
    },
    {
      key: 'maternityLeave',
      text: selectedContent.maternity,
      value: 'Maternity',
    },
  ]
}

export function getVariableEndVacationTypes(lang = 'en') {
  const selectedContent = content[lang]

  return [
    {
      key: 'annual',
      text: selectedContent.annual,
      value: 'Annual',
    },
    {
      key: 'aDeathCase',
      text: selectedContent.variableEndVacationTypes.aDeathCase,
      value: 'aDeathCase',
    },
    {
      key: 'leaveDialysisAndPeriodicTreatmentSessions',
      text:
        selectedContent.variableEndVacationTypes
          .leaveDialysisAndPeriodicTreatmentSessions,
      value: 'leaveDialysisAndPeriodicTreatmentSessions',
    },
    {
      key: 'outdoorSportsLeave',
      text: selectedContent.variableEndVacationTypes.outdoorSportsLeave,
      value: 'outdoorSportsLeave',
    },
    {
      key: 'indoorSportsLeave',
      text: selectedContent.variableEndVacationTypes.indoorSportsLeave,
      value: 'indoorSportsLeave',
    },
    {
      key: 'externalCulturalOrSocialLeave',
      text:
        selectedContent.variableEndVacationTypes.externalCulturalOrSocialLeave,
      value: 'externalCulturalOrSocialLeave',
    },
    {
      key: 'internalCulturalOrSocialLeave',
      text:
        selectedContent.variableEndVacationTypes.internalCulturalOrSocialLeave,
      value: 'internalCulturalOrSocialLeave',
    },
    {
      key: 'participateInReliefWork',
      text: selectedContent.variableEndVacationTypes.participateInReliefWork,
      value: 'participateInReliefWork',
    },
    {
      key: 'childcareLeave',
      text: selectedContent.variableEndVacationTypes.childcareLeave,
      value: 'childcareLeave',
    },
    {
      key: 'specialLeave',
      text: selectedContent.variableEndVacationTypes.specialLeave,
      value: 'specialLeave',
    },
    {
      key: 'sickLeave',
      text: selectedContent.variableEndVacationTypes.sickLeave,
      value: 'sickLeave',
    },
    {
      key: 'exceptionalLeave',
      text: selectedContent.variableEndVacationTypes.exceptionalLeave,
      value: 'exceptionalLeave',
    },
    {
      key: 'emission',
      text: selectedContent.variableEndVacationTypes.emission,
      value: 'emission',
    },
    {
      key: 'leaveToTakeExams',
      text: selectedContent.variableEndVacationTypes.leaveToTakeExams,
      value: 'leaveToTakeExams',
    },
    {
      key: 'internalLeave',
      text: selectedContent.variableEndVacationTypes.internalLeave,
      value: 'internalLeave',
    },
    {
      key: 'other',
      text: selectedContent.variableEndVacationTypes.other,
      value: 'other',
    },
  ]
}

export function getCompensatoryVacationTypes(lang = 'en') {
  const selectedContent = content[lang]

  return [
    {
      key: 'AlAdha',
      text: selectedContent.alAdha,
      value: 'AlAdha',
    },
    {
      key: 'AlFitr',
      text: selectedContent.alFitr,
      value: 'AlFitr',
    },
    {
      key: 'NationalDay',
      text: selectedContent.nationalDay,
      value: 'NationalDay',
    },
  ]
}

export const COMPENSTAORY_VACATION_TYPES = {
  AlAdha: 'AlAdha',
  AlFitr: 'AlFitr',
  NationalDay: 'NationalDay',
}
