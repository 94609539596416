import React from 'react'
import {Link} from 'react-router-dom'
import {
  Button,
  Checkbox,
  Dimmer,
  Form,
  Header,
  Image,
  Loader,
  Message,
  Modal,
  Tab,
  Table,
} from 'semantic-ui-react'
import {RiMenuFill} from 'react-icons/ri'
import * as Yup from 'yup'
import {Formik} from 'formik'

import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import useAsync from '../../hooks/useAsync'
import {useToasts} from 'react-toast-notifications'
import {authAxios} from '../../config/axiosConfig'
import {formatDate, nOfDaysBetween} from '../../utils/date-format'
import FormikControl from '../formik/FormikControl'
import {AiOutlineClose} from 'react-icons/ai'
import {getUserFullname} from '../../utils/user'
import {getVacationType} from '../../utils/vacation'
import {FaUserCircle} from 'react-icons/fa'
import usePagination from '../../hooks/use-pagination'
import PaginationWithLimit from '../pagination-with-limit'

function IncommingVacationRequests() {
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedVacation, setSelectedVacation] = React.useState(null)
  const [data, setData] = React.useState([])
  const {page, limit, onPageChange, onLimitChange} = usePagination()

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const getVacations = () => {
    run(authAxios.get(`/vacations/requests?page=${page}&limit=${limit}`))
      .then(({data}) => {
        setData(data)
      })
      .catch(e =>
        e.errors.map(e => addToast(e.message[lang], {appearance: 'error'})),
      )
  }

  const handleOpenVacationDetails = vacation => {
    setSelectedVacation(vacation)
    setIsOpen(true)
  }

  React.useEffect(() => {
    if (page) getVacations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit])

  return (
    <Tab.Pane className="border-0 m-0 p-0" loading={isLoading}>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{selectedContent.employee}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.leaveStatus}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.suggestions}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.from}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.to}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.details}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.data?.map(vacation => (
            <Table.Row key={vacation._id}>
              <Table.Cell>
                <Header as="h4" image>
                  {vacation?.user?.avatar ? (
                    <Image src={vacation?.user.avatar} rounded size="small" />
                  ) : (
                    <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-3 rtl:ml-3" />
                  )}
                  <Header.Content>
                    {getUserFullname(vacation.user, lang)}
                    <Header.Subheader>
                      {vacation.user.department['name' + lang.toUpperCase()]}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" image>
                  <Header.Content>
                    {selectedContent[vacation.leaveStatus]}
                    <Header.Subheader className="mt-1">
                      {getVacationType(vacation, selectedContent)}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
              <Table.Cell
                className={`${
                  vacation.leaveStatus !== 'Planned' ? 'bg-gray-100' : ''
                }`}
              >
                {vacation.suggestions.length > 0 ? (
                  <div>
                    {vacation.suggestions.map(s => (
                      <div className="mb-1">
                        <strong>{selectedContent.from}</strong>:{' '}
                        {formatDate(s.start)},{' '}
                        <strong>{selectedContent.to}</strong>:{' '}
                        {formatDate(s.end)}
                      </div>
                    ))}
                  </div>
                ) : null}
              </Table.Cell>
              <Table.Cell
                className={`${
                  vacation.status === 'Pending' &&
                  vacation.leaveStatus === 'Planned'
                    ? 'bg-gray-100'
                    : ''
                }`}
              >
                {formatDate(vacation.start)}
              </Table.Cell>
              <Table.Cell
                className={`${
                  vacation.status === 'Pending' &&
                  vacation.leaveStatus === 'Planned'
                    ? 'bg-gray-100'
                    : ''
                }`}
              >
                {formatDate(vacation.end)}
              </Table.Cell>
              <Table.Cell selectable>
                <Link onClick={() => handleOpenVacationDetails(vacation)}>
                  <RiMenuFill className="text-primary" />
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <PaginationWithLimit
        page={page}
        limit={limit}
        totalPages={data?.totalPages}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />

      <VacatinDetailsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        vacation={selectedVacation}
        updateVacations={getVacations}
      />
    </Tab.Pane>
  )
}

const VacatinDetailsModal = ({
  isOpen,
  setIsOpen,
  vacation,
  updateVacations,
}) => {
  const [selectedSuggestions, setSelectedSuggestions] = React.useState({
    0: false,
    1: false,
    2: false,
  })
  const [error, setError] = React.useState('')
  const [isRejectionModalOpen, setIsRejectionModalOpen] = React.useState(false)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading, data: vacationDetails} = useAsync()
  const {addToast} = useToasts()

  const handleSuggestionSelection = suggestionIndex => {
    setSelectedSuggestions({
      ...selectedSuggestions,
      [suggestionIndex]: !selectedSuggestions[suggestionIndex],
    })
  }

  const handleAcceptingSuggestions = () => {
    const hasSelectedNothing = Object.values(selectedSuggestions).every(x => !x)
    if (hasSelectedNothing) {
      setError(`You have to select at least one suggestion`)
      return
    } else {
      setError('')
    }

    let approvedSuggestions = []
    for (let suggestion in selectedSuggestions) {
      if (selectedSuggestions[suggestion])
        approvedSuggestions.push(vacation.suggestions[suggestion]._id)
    }

    run(
      authAxios.post(`/vacations/${vacation._id}/approve`, {
        approvedSuggestions,
      }),
    )
      .then(() => {
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
        updateVacations()
        setIsOpen(false)
      })
      .catch(e => {
        if (
          e.errors[0].message ===
          `Approved suggestions array can't exceed lenght of 1`
        )
          setError(selectedContent.errors.youCanOnlySelectOneSuggestion)
      })
  }

  const handleAcceptingVacation = () => {
    run(authAxios.post(`/vacations/${vacation._id}/approve`))
      .then(() => {
        updateVacations()
        setIsOpen(false)
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
      })
      .catch(e =>
        e?.errors.map(error => {
          return addToast(error.message[lang], {appearance: 'error'})
        }),
      )
  }

  const handleRejectingVacation = values => {
    setIsRejectionModalOpen(false)
    run(
      authAxios.post(`/vacations/${vacation._id}/reject`, {
        rejectionReason: values.rejectionReason,
      }),
    )
      .then(() => {
        updateVacations()
        setIsOpen(false)
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
      })
      .catch(e =>
        e?.errors.map(error => {
          return addToast(error.message[lang], {appearance: 'error'})
        }),
      )
  }

  const rejectionSchema = Yup.object({
    rejectionReason: Yup.string()
      .trim()
      .min(3, selectedContent.tooShort)
      .max(1000, selectedContent.tooLong)
      .required(selectedContent.required),
  })

  React.useEffect(() => {
    if (isOpen && vacation)
      run(authAxios.get(`/vacations/requests/${vacation?._id}`)).catch(e =>
        e.errors.map(e => addToast(e.message[lang], {appearance: 'error'})),
      )
  }, [run, vacation, addToast, isOpen])

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      <Modal.Content image className="flex-col pb-10" scrolling>
        {vacation?.user?.avatar ? (
          <img
            alt="avatar"
            className="object-cover w-44 h-44 rounded-full shadow-md my-10 mx-auto"
            src={vacation?.user.avatar}
            rounded
            size="small"
          />
        ) : (
          <FaUserCircle className="my-8 text-primary w-32 h-32 text-center mx-auto" />
        )}

        <Modal.Description className="px-4 sm:px-8">
          <Header as="h3" className="text-center mb-8">
            <Header.Content>
              {vacation?.user ? getUserFullname(vacation.user, lang) : null}
              <Header.Subheader>
                {vacation?.user.department['name' + lang.toUpperCase()]}
              </Header.Subheader>
            </Header.Content>
          </Header>

          <div className="flex justify-center mb-8">
            <div>
              <h4 className="font-bold text-primary">
                {selectedContent.leaveStatus}
              </h4>
              <p>{selectedContent[vacation?.leaveStatus]}</p>
            </div>
            <div className="ltr:ml-20 rtl:mr-20">
              <h4 className="font-bold text-primary">
                {selectedContent.vacationType}
              </h4>
              <p>
                {selectedContent.variableEndVacationTypes[
                  vacation?.vacationType
                ] || selectedContent[vacation?.vacationType.toLowerCase()]}
              </p>
            </div>
          </div>

          <Dimmer inverted active={isLoading}>
            <Loader inverted />
          </Dimmer>

          {vacation?.leaveStatus === 'Planned' ? (
            <>
              {vacation?.suggestions.map((suggestion, i) => (
                <>
                  <h3 className="mb-4">
                    {selectedContent.suggestion} {i + 1}
                  </h3>
                  <VacationDetails
                    vacation={suggestion}
                    overlappingVacations={
                      vacationDetails?.data?.overlappingVacations &&
                      vacationDetails?.data?.overlappingVacations[i]
                    }
                  />
                </>
              ))}

              <div>
                <h3 className="mb-4">
                  {selectedContent.selectSuggestionsToApprove}
                </h3>
                {vacation?.suggestions.map((suggestion, i) => (
                  <Checkbox
                    label={`${selectedContent.suggestion} ${i + 1}`}
                    className="ltr:mr-8 rtl:ml-8"
                    onChange={() => handleSuggestionSelection(i)}
                    checked={selectedSuggestions[i]}
                  />
                ))}

                {error ? (
                  <Message negative header="Error" content={error} />
                ) : null}

                <div className="flex justify-end mt-8">
                  <div>
                    <Button
                      className="btn-danger ltr:mr-4 rtl:ml-4"
                      onClick={() => setIsRejectionModalOpen(true)}
                    >
                      {selectedContent.reject}
                    </Button>
                    <Button
                      className="btn-primary"
                      onClick={handleAcceptingSuggestions}
                    >
                      {selectedContent.acceptSelectedSuggestions}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <VacationDetails
                vacation={vacationDetails?.data?.vacation}
                overlappingVacations={
                  vacationDetails?.data?.overlappingVacations
                }
              />
              <div className="flex justify-end mt-8">
                <div>
                  <Button
                    className="btn-danger ltr:mr-4 rtl:ml-4"
                    onClick={() => setIsRejectionModalOpen(true)}
                  >
                    {selectedContent.reject}
                  </Button>
                  <Button
                    className="btn-primary"
                    onClick={handleAcceptingVacation}
                  >
                    {selectedContent.accept}
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Description>
      </Modal.Content>

      <Modal
        onClose={() => setIsRejectionModalOpen(false)}
        open={isRejectionModalOpen}
        size="small"
      >
        <Modal.Header>{selectedContent.rejectionReason}</Modal.Header>

        <Formik
          initialValues={{rejectionReason: ''}}
          validationSchema={rejectionSchema}
          onSubmit={handleRejectingVacation}
        >
          {formik => (
            <>
              <Modal.Content>
                <Form onSubmit={formik.submitForm}>
                  <FormikControl
                    control="textarea"
                    name="rejectionReason"
                    placeholder={selectedContent.reason}
                    className="mb-2"
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={formik.handleSubmit} className="btn-danger">
                  {selectedContent.reject}{' '}
                  <AiOutlineClose size="14" className="inline-block" />
                </Button>
              </Modal.Actions>
            </>
          )}
        </Formik>
      </Modal>
    </Modal>
  )
}

const VacationDetails = ({vacation, overlappingVacations}) => {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  console.log(vacation)

  return (
    <div className="mb-20">
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{selectedContent.from}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.to}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.duration}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={1}>{formatDate(vacation?.start)}</Table.Cell>
            <Table.Cell width={1}>{formatDate(vacation?.end)}</Table.Cell>
            <Table.Cell width={1}>
              {nOfDaysBetween(vacation?.start, vacation?.end) + 1}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <div className="mb-4">
        <h4 className="font-bold text-primary mb-4">
          {selectedContent.overlappingVacations || 'Overlapping vacations'}
        </h4>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{selectedContent.employee}</Table.HeaderCell>
              <Table.HeaderCell>{selectedContent.leaveStatus}</Table.HeaderCell>
              <Table.HeaderCell>{selectedContent.from}</Table.HeaderCell>
              <Table.HeaderCell>{selectedContent.to}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {overlappingVacations?.map(detail => (
              <Table.Row key={detail._id}>
                <Table.Cell width={1}>
                  <Header as="h5" image>
                    {detail?.user?.avatar ? (
                      <Image src={detail?.user.avatar} rounded size="small" />
                    ) : (
                      <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-3 rtl:ml-3" />
                    )}
                    <Header.Content>
                      {getUserFullname(detail.user, lang)}
                      <Header.Subheader>
                        {detail.user.department['name' + lang.toUpperCase()]}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell width={2}>
                  <Header as="h5" image>
                    <Header.Content>
                      {selectedContent[detail.leaveStatus]}
                      <Header.Subheader>
                        {getVacationType(detail, selectedContent)}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell width={1}>{formatDate(detail.start)}</Table.Cell>
                <Table.Cell width={1}>{formatDate(detail.end)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}

export default IncommingVacationRequests
