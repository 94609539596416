import React from 'react'
import {useToasts} from 'react-toast-notifications'
import {Tab} from 'semantic-ui-react'
import DepartmentSettings from '../../components/settings/department-settings'
import RatingSettings from '../../components/settings/RatingSettings'
import VacationSettings from '../../components/settings/VacationSettings'
import Auth from '../../config/auth'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'

function Settings() {
  const [vacationSettings, setVacationSettings] = React.useState(null)
  const [ratingSettings, setRatingSettings] = React.useState(null)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const panes = [
    Auth.isManagerOrHigher() && {
      menuItem: selectedContent.department,
      render: () => <DepartmentSettings />,
    },
  ]
  if (Auth.isHR()) {
    console.log('hererere')
    panes.push(
      {
        menuItem: selectedContent.vacations,
        render: () => (
          <VacationSettings
            vacations={vacationSettings}
            setVacations={setVacationSettings}
            isLoading={isLoading}
          />
        ),
      },
      {
        menuItem: selectedContent.ratings,
        render: () => (
          <RatingSettings
            ratings={ratingSettings}
            setRatings={setRatingSettings}
            isLoading={isLoading}
          />
        ),
      },
    )
  }

  React.useEffect(() => {
    if (Auth.isHR())
      run(authAxios.get('/settings'))
        .then(({data}) => {
          setVacationSettings(data?.data.vacationSettings)
          setRatingSettings({
            settingId: data.data._id,
            ratings: data?.data.ratingSettings,
          })
        })
        .catch(e =>
          e?.errors.map(e => addToast(e.message[lang], {appearance: 'error'})),
        )
  }, [run, addToast, lang])

  return (
    <section>
      <h2 className="text-primary">{selectedContent.settings}</h2>
      <div className="mt-8">
        <Tab menu={{secondary: true, pointing: true}} panes={panes} />
      </div>
    </section>
  )
}

export default Settings
