import React, {useEffect, useState} from 'react'
import {Button, Tab} from 'semantic-ui-react'
import AssignedTicketsTable from '../../components/tickets/AssignedTicketsTable'
import AssignTicketModal from '../../components/tickets/assignTicketModal'
import CommentTicketModal from '../../components/tickets/commentTicketModal'
import MyTickets from '../../components/tickets/MyTickets'
import NewTicket from '../../components/tickets/NewTicket'
import RejectTicketModal from '../../components/tickets/rejectTicketModal'
import ResendTicketModal from '../../components/tickets/resendReasonModal'
import TicketsHistory from '../../components/tickets/TicketsHistory'
import ViewTicketModal from '../../components/tickets/ViewTicket'
import Auth from '../../config/auth'
import {useLanguage} from '../../context/languageContext'
import {useSocket} from '../../context/socket-context'
import {content} from '../../localization/content'

function Tickets() {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [updateTickets, setUpdateTickets] = useState(false)

  const socket = useSocket()

  useEffect(() => {
    socket?.on('notification', msg => {
      if (msg.type !== 'tickets') return
      if (!msg.message.users.includes(Auth.getUserId())) return
      if ([msg.message.from].includes(Auth.getUserId())) return
      setUpdateTickets(prev => !prev)
    })
  }, [socket])

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  let panes = []

  if (Auth.haveTicketsPermissions()) {
    panes.push(
      {
        menuItem: selectedContent.history,
        render: () => (
          <TicketsHistory
            updateHistory={updateTickets}
            setUpdateHistory={() => setUpdateTickets(prev => !prev)}
          />
        ),
      },
      {
        menuItem: selectedContent.assignedTickets,
        render: () => (
          <AssignedTicketsTable
            updateHistory={updateTickets}
            setUpdateHistory={v => setUpdateTickets(prev => !prev)}
          />
        ),
      },
    )
  }

  if (!Auth.isExUser()) {
    panes.push({
      menuItem: selectedContent.myTickets,
      render: () => <MyTickets updateHistory={updateTickets} />,
    })
  }

  return (
    <section>
      <ViewTicketModal updateTicket={v => setUpdateTickets(prev => !prev)} />
      <RejectTicketModal updateTicket={v => setUpdateTickets(prev => !prev)} />
      <CommentTicketModal updateTicket={v => setUpdateTickets(prev => !prev)} />
      <AssignTicketModal updateTicket={v => setUpdateTickets(prev => !prev)} />
      <ResendTicketModal updateTicket={v => setUpdateTickets(prev => !prev)} />

      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
        <h2 className="text-primary mb-5 sm:mb-0">{selectedContent.tickets}</h2>
        {!Auth.isExUser() && (
          <Button className="btn-primary" onClick={() => setIsModalOpen(true)}>
            {selectedContent.newTicket}
          </Button>
        )}
      </div>

      <div className="mt-8">
        <div style={{width: '99%'}}>
          <Tab
            menu={{
              secondary: true,
              pointing: true,
              className: 'flex flex-wrap',
            }}
            panes={panes}
          />
        </div>
      </div>

      <NewTicket
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        updateTickets={v => setUpdateTickets(prev => !prev)}
      />
    </section>
  )
}

export default Tickets
