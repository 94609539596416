import moment from 'moment'
import {useContext, useEffect, useState} from 'react'
import {DateInput, TimeInput} from 'semantic-ui-calendar-react'
import {Form, Modal, Grid, Image, Button, Icon} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import {content} from '../../localization/content'
import {formatDate, formatTime, stringToDate} from '../../utils/date-format'
import borderIcon from '../../assets/images/ic_border.svg'
import {Fragment} from 'react'
import useAsync from '../../hooks/useAsync'
import {getAllDepartments} from '../../services/dashboardService'
import {getSubDepartments} from '../../utils/handleDepartments'
import {useToasts} from 'react-toast-notifications'
import {editShift} from '../../services/shiftService'

const EditShiftModal = ({onUpdate}) => {
  const {showModal, setShowModal} = useContext(StateContext)
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const modalLang = content[lang]
  const {addToast} = useToasts()
  const [open, setOpen] = useState(false)
  const [next, setNext] = useState(false)
  const [allDepartments, setAllDepartments] = useState([])
  const [departments, setDepartments] = useState([])
  const [subDepartments, setSubDepartments] = useState([])

  const [state, setState] = useState({
    nameEN: '',
    nameAR: '',
    dateFrom: '',
    dateTo: '',
    timeFrom: '',
    timeTo: '',
    responsibilities: '',
    departments: [],
    subDepartments: [],
    defaultVacation: [],
    gender: [],
  })

  useEffect(() => {
    if (showModal.modalName === 'editShift') {
      setOpen(true)

      run(getAllDepartments())
        .then(({data}) => {
          // console.log(data)
          setAllDepartments(data.data)
          let departmentData = []
          data.data.masterDepartments.map((d, i) => {
            departmentData.push({
              key: d._id,
              text: d['name' + lang.toUpperCase()],
              value: d._id,
            })
          })
          setDepartments(departmentData)
        })
        .catch(e => {
          console.log(e)
        })

      setState({
        ...state,
        nameAR: showModal.data?.nameAR,
        nameEN: showModal.data?.nameEN,
        gender: showModal.data?.gender,
        dateFrom: showModal.data?.startDate,
        dateTo: showModal.data?.endDate,
        responsibilities: showModal.data?.responsibilities,
        timeFrom: showModal.data?.startDate,
        timeTo: showModal.data?.endDate,
        defaultVacation: showModal.data?.defaultVacation,
      })
    } else {
      setOpen(false)
    }
  }, [showModal])

  useEffect(() => {
    if (allDepartments.length === 0) return
    let oldDep = []
    let oldSubDep = []

    showModal.data?.department.map(d => {
      oldSubDep.push(d._id)
      // return oldDep.push(d.masterDepartment)
    })

    showModal.data?.masterDepartment.map(d => {
      oldDep.push(d._id)
    })

    oldDep = oldDep.filter(function (elem, index, self) {
      return index === self.indexOf(elem)
    })
    // console.log(oldDep)
    const filtered = getSubDepartments(oldDep, allDepartments, lang)
    setSubDepartments(filtered)
    setState({...state, departments: oldDep, subDepartments: oldSubDep})
  }, [allDepartments])

  const handleOnChangeDepartment = (e, {value}) => {
    setState({...state, departments: value})
    const filtered = getSubDepartments(value, allDepartments, lang)

    setSubDepartments(filtered)
  }

  const handleOnChangeSubDepartment = (e, {value}) => {
    setState({...state, subDepartments: value})
  }

  const handleOnChangeTime = (value, type) => {
    setState({...state, ['time' + type]: moment(value, 'hh:mm A').format()})
  }

  const handleOnChangeCheckbox = (value, checked) => {
    let arr = [...state.defaultVacation]
    if (checked) {
      arr.push(value)
    } else {
      const i = arr.findIndex(o => o === value)
      arr.splice(i, 1)
    }
    setState({...state, defaultVacation: arr})
  }

  const handleOnClickBtn = type => {
    if (type === 'next') {
      setNext(true)
    } else {
      const updatedData = {
        nameEN: state.nameEN,
        nameAR: state.nameAR,
        startDate: state.dateFrom,
        endDate: state.dateTo,
        startTime: state.timeFrom,
        endTime: state.timeTo,
        responsibilities: state.responsibilities,
        defaultVacation: state.defaultVacation,
        masterDepartment: state.departments,
        department: state.subDepartments,
        gender: state.gender,
      }
      // console.log(updatedData)
      run(
        editShift({
          shiftId: showModal.data._id,
          type: 'normalShifts',
          updatedData: updatedData,
        }),
      )
        .then(({data}) => {
          // console.log(data)
          addToast(data.message[lang], {appearance: 'success'})
          setShowModal({modalName: '', data: null})
          setState({
            nameEN: '',
            nameAR: '',
            dateFrom: '',
            dateTo: '',
            timeFrom: '',
            timeTo: '',
            resposibilities: '',
            departments: [],
            subDepartments: [],
            defaultVacation: [],
            gender: [],
          })

          setNext(false)
          onUpdate(true)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
  return (
    <Modal
      open={open}
      className="main-font"
      size="tiny"
      closeOnDimmerClick={false}
      closeIcon
      onClose={() => setShowModal({modalName: '', data: null})}
    >
      <Modal.Header>
        {next && (
          <Icon
            name="angle left"
            size="large"
            className="ltr:mr-3 rtl:ml-3 cursor-pointer"
            onClick={() => setNext(false)}
          />
        )}
        {modalLang.editShift}
      </Modal.Header>
      <Modal.Content>
        <Form loading={isLoading}>
          {!next && (
            <Fragment>
              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.Input
                      label={<label className="text-primary">Shift Name</label>}
                      defaultValue={state.nameEN}
                      placeholder="Shift Name"
                      onChange={(e, {value}) =>
                        setState({...state, nameEN: value})
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.Input
                      label={
                        <label className="text-primary">
                          Shift Name in Arabic
                        </label>
                      }
                      defaultValue={state.nameAR}
                      placeholder="Shift Name in Arabic"
                      onChange={(e, {value}) =>
                        setState({...state, nameAR: value})
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <hr />

              <div className="my-3">
                <label className="font-bold text-primary">Shift Date</label>

                <Form.Field className="mt-3">
                  <div className="flex items-center">
                    <Image
                      src={borderIcon}
                      width="5px"
                      height="5px"
                      className="ltr:mr-5 rtl:ml-5"
                      alt="border icon"
                    />
                    <div className="w-full">
                      <Form.Group widths="equal">
                        <Form.Field>
                          <DateInput
                            value={formatDate(state.dateFrom)}
                            label={<label className="text-primary">From</label>}
                            onChange={(e, {value}) =>
                              setState({
                                ...state,
                                dateFrom: stringToDate(value),
                              })
                            }
                          />
                        </Form.Field>

                        <Form.Field>
                          <DateInput
                            value={formatDate(state.dateTo)}
                            label={<label className="text-primary">To</label>}
                            onChange={(e, {value}) =>
                              setState({...state, dateTo: stringToDate(value)})
                            }
                          />
                        </Form.Field>
                      </Form.Group>
                    </div>
                  </div>
                </Form.Field>

                <Form.Field className="mt-3">
                  <div className="flex items-center">
                    <Image
                      src={borderIcon}
                      width="5px"
                      height="5px"
                      className="ltr:mr-5 rtl:ml-5"
                      alt="border icon"
                    />
                    <div className="w-full">
                      <Form.Group widths="equal">
                        <Form.Field>
                          <TimeInput
                            name="from"
                            value={formatTime(state.timeFrom)}
                            label={
                              <label className="text-primary">
                                {modalLang.from}
                              </label>
                            }
                            timeFormat="AMPM"
                            onChange={(e, {value}) =>
                              handleOnChangeTime(value, 'From')
                            }
                          />
                        </Form.Field>
                        <Form.Field>
                          <TimeInput
                            name="to"
                            value={formatTime(state.timeTo)}
                            label={
                              <label className="text-primary">
                                {modalLang.to}
                              </label>
                            }
                            timeFormat="AMPM"
                            onChange={(e, {value}) =>
                              handleOnChangeTime(value, 'To')
                            }
                          />
                        </Form.Field>
                      </Form.Group>
                    </div>
                  </div>
                </Form.Field>
              </div>

              <hr />

              <div className="my-3">
                <label className="font-bold text-primary">
                  Default Vacations
                </label>
                <Grid doubling columns={3} className="mt-2">
                  <Grid.Row>
                    <Grid.Column className="mb-2">
                      <Form.Field>
                        <Form.Checkbox
                          checked={state.defaultVacation.includes('Friday')}
                          label="Friday"
                          value="Friday"
                          // defaultChecked={state.defaultVacation.includes('Friday')}
                          onChange={(e, {value, checked}) =>
                            handleOnChangeCheckbox(value, checked)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column className="mb-2">
                      <Form.Field>
                        <Form.Checkbox
                          checked={state.defaultVacation.includes('Saturday')}
                          label="Saturday"
                          // defaultChecked={state.defaultVacation.includes('Saturday')}
                          value="Saturday"
                          onChange={(e, {value, checked}) =>
                            handleOnChangeCheckbox(value, checked)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column className="mb-2">
                      <Form.Field>
                        <Form.Checkbox
                          checked={state.defaultVacation.includes('Sunday')}
                          label="Sunday"
                          value="Sunday"
                          // defaultChecked={state.defaultVacation.includes('Sunday')}
                          onChange={(e, {value, checked}) =>
                            handleOnChangeCheckbox(value, checked)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column className="mb-2">
                      <Form.Field>
                        <Form.Checkbox
                          checked={state.defaultVacation.includes('Monday')}
                          // defaultChecked={state.defaultVacation.includes('Monday')}

                          label="Monday"
                          value="Monday"
                          onChange={(e, {value, checked}) =>
                            handleOnChangeCheckbox(value, checked)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column className="mb-2">
                      <Form.Field>
                        <Form.Checkbox
                          checked={state.defaultVacation.includes('Tuesday')}
                          // defaultChecked={state.defaultVacation.includes('Tuesday')}

                          label="Tuesday"
                          value="Tuesday"
                          onChange={(e, {value, checked}) =>
                            handleOnChangeCheckbox(value, checked)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column className="mb-2">
                      <Form.Field>
                        <Form.Checkbox
                          checked={state.defaultVacation.includes('Wednesday')}
                          // defaultChecked={state.defaultVacation.includes('Wednesday')}
                          label="Wednesday"
                          value="Wednesday"
                          onChange={(e, {value, checked}) =>
                            handleOnChangeCheckbox(value, checked)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column className="mb-2">
                      <Form.Field>
                        <Form.Checkbox
                          checked={state.defaultVacation.includes('Thursday')}
                          // defaultChecked={state.defaultVacation.includes('Thursday')}
                          label="Thursday"
                          value="Thursday"
                          onChange={(e, {value, checked}) =>
                            handleOnChangeCheckbox(value, checked)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Fragment>
          )}

          {next && (
            <Fragment>
              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.Dropdown
                      placeholder={modalLang.department}
                      fluid
                      search
                      clearable
                      label={modalLang.department}
                      defaultValue={state.departments}
                      multiple
                      selection
                      className="mt-2"
                      options={departments}
                      onChange={handleOnChangeDepartment}
                    />
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.Dropdown
                      placeholder={modalLang.department}
                      fluid
                      search
                      clearable
                      label={modalLang.subDepartment}
                      multiple
                      defaultValue={state.subDepartments}
                      selection
                      className="mt-2"
                      options={subDepartments}
                      onChange={handleOnChangeSubDepartment}
                    />
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.Dropdown
                      placeholder={modalLang.gender}
                      fluid
                      search
                      clearable
                      label={modalLang.gender}
                      multiple
                      selection
                      defaultValue={state.gender}
                      className="mt-2"
                      options={[
                        {key: 0, text: modalLang.male, value: 'Male'},
                        {key: 1, text: modalLang.female, value: 'Female'},
                      ]}
                      onChange={(e, {value}) =>
                        setState({...state, gender: value})
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.TextArea
                      placeholder="Responsibilities"
                      label="Responsibilities"
                      defaultValue={state.responsibilities}
                      onChange={(e, {value}) =>
                        setState({...state, responsibilities: value})
                      }
                    ></Form.TextArea>
                  </div>
                </div>
              </Form.Field>
            </Fragment>
          )}

          <div className="my-3 text-center">
            <Button
              content={next ? modalLang.submit : modalLang.next}
              className="btn-primary"
              onClick={() => handleOnClickBtn(next ? 'submit' : 'next')}
            />
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default EditShiftModal
