import {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import {Button, Form, Icon} from 'semantic-ui-react'
import Auth from '../../config/auth'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {resetPassword} from '../../services/authService'

const ResetPassword = () => {
  const {run, isLoading} = useAsync()
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    errorType: '',
  })
  const [lang] = useLanguage()
  const resetLang = content[lang]
  const [showPassword, setShowPassword] = useState('')
  const params = useParams()
  const {addToast} = useToasts()
  const history = useHistory()
  const [userId, setUserId] = useState('')

  useEffect(() => {
    const isExpired = Auth.isExpired(params.link)
    if (isExpired === true) {
      history.push('/auth/login')
      addToast(resetLang.resetLinkExpired, {appearance: 'error'})
      return
    } else {
      const user = Auth.getDataFromToken(params.link)
      setUserId(user._id)
    }
  }, [])

  const handleOnCLickReset = () => {
    if (state.password === '' && state.confirmPassword === '') {
      setState({...state, errorType: 'all'})
      addToast(resetLang.invalidPassword, {appearance: 'error'})
      return
    }
    if (state.password !== state.confirmPassword) {
      setState({...state, errorType: 'all'})
      addToast(resetLang.invalidPassword, {appearance: 'error'})
      return
    }
    console.log(userId)
    run(resetPassword({userId: userId, password: state.password}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        history.push('/auth/login')
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <div className="auth-content">
      <div className="py-4">
        <p className="font-semibold text-lg text-primary">{resetLang.resetP}</p>
        <p className="text-sm">{resetLang.resetPassText}</p>
      </div>

      <Form loading={isLoading} className="mt-4">
        <Form.Field>
          <label className="text-primary">{resetLang.password}</label>
          <Form.Input
            placeholder="*******"
            type={showPassword === 'password' ? 'text' : 'password'}
            icon={
              <Icon
                name={showPassword === 'password' ? 'eye slash' : 'eye'}
                onClick={() => setShowPassword('password')}
                circular
                link
              />
            }
            onChange={(e, {value}) => setState({...state, password: value})}
            error={
              state.errorType === 'password' || state.errorType === 'all'
                ? true
                : false
            }
          />
        </Form.Field>

        <Form.Field>
          <label className="text-primary">{resetLang.confirmPassword}</label>
          <Form.Input
            placeholder="*******"
            type={showPassword === 'confirm' ? 'text' : 'password'}
            icon={
              <Icon
                name={showPassword === 'confirm' ? 'eye slash' : 'eye'}
                onClick={() => setShowPassword('confirm')}
                circular
                link
              />
            }
            onChange={(e, {value}) =>
              setState({...state, confirmPassword: value})
            }
            error={
              state.errorType === 'password' || state.errorType === 'all'
                ? true
                : false
            }
          />
        </Form.Field>

        <div className="text-center my-4">
          <Button
            content={resetLang.resetP}
            className="btn-primary"
            onClick={handleOnCLickReset}
          />
        </div>
      </Form>
    </div>
  )
}

export default ResetPassword
