import {Accordion, Button} from 'semantic-ui-react'
import _ from 'lodash'
import {content} from '../../localization/content'
import {useLanguage} from '../../context/languageContext'
import QualificationTable from './qualificationTable'
import {useContext, useEffect, useState} from 'react'
import StateContext from '../../context/stateContext'
import {useQuery} from '../../hooks/useQuery'

const Qualifications = ({userData}) => {
  const {setShowModal} = useContext(StateContext)
  const [lang] = useLanguage()
  const qualificationLang = content[lang]
  const [qualifications, setQualifications] = useState('')
  const [courses, setCourses] = useState('')
  const query = useQuery()

  useEffect(() => {
    if (!userData) return
    setQualifications(userData.qualifications)
    setCourses(userData.courses)
  }, [userData])

  const panels = [
    {
      key: 'panel-0',
      title: {
        content: (
          <div className="flex w-full justify-between">
            <label className="hover:bg-green-100  transition-colors duration-300 py-2 px-4 rounded-lg cursor-pointer  text-xl">
              {qualificationLang.qualifications}
            </label>
            {query.get('edit') && (
              <Button
                content={qualificationLang.add}
                className="btn-primary"
                icon="plus"
                onClick={() => handleOnClickAdd('qualification')}
              />
            )}
          </div>
        ),
        className: 'flex items-center',
        target: 'label',
      },
      content: {
        content: (
          <QualificationTable
            tableHeaders={[
              '#',
              qualificationLang.qualificationName,
              qualificationLang.universityName,
              qualificationLang.graduationYear,
              qualificationLang.rate,
              qualificationLang.degree,
              qualificationLang.attachedFile,
            ]}
            type="qualifications"
            data={qualifications}
          />
        ),
      },
    },
    {
      key: 'panel-1',
      title: {
        content: (
          <div className="flex w-full justify-between">
            <label className="hover:bg-green-100  transition-colors duration-300 py-2 px-4 rounded-lg cursor-pointer  text-xl">
              {qualificationLang.courses}
            </label>
            {query.get('edit') && (
              <Button
                content={qualificationLang.add}
                className="btn-primary"
                icon="plus"
                onClick={() => handleOnClickAdd('course')}
              />
            )}
          </div>
        ),
        className: 'flex items-center',
        target: 'label',
      },
      content: {
        content: (
          <QualificationTable
            tableHeaders={[
              '#',
              qualificationLang.courseName,
              qualificationLang.destination,
              qualificationLang.date,
              qualificationLang.duration,
              qualificationLang.rate,
              qualificationLang.degree,
              qualificationLang.attachedFile,
            ]}
            type="courses"
            data={courses}
          />
        ),
      },
    },
  ]

  const handleOnClickAdd = type => {
    setShowModal({modalName: type, data: null})
  }

  return (
    <>
      <Accordion
        defaultActiveIndex={[0, 1]}
        panels={panels}
        exclusive={false}
        fluid
      />
    </>
  )
}

export default Qualifications
