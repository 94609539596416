import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

// Globals
import './assets/css/tailwind.css'
import './assets/css/rating.scss'
import './assets/css/app.css'

// Component specific
import './components/appointment/calendar-style.css'

ReactDOM.render(<App />, document.getElementById('root'))
