import React from 'react'
import {
  Button,
  Form,
  Icon,
  Image,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {getCharaterList} from '../../utils/characterList'
import {IoIosMail, IoMdContact, IoIosHome, IoIosCall} from 'react-icons/io'
import {Formik} from 'formik'
import FormikControl from '../../components/formik/FormikControl'
import * as Yup from 'yup'
import {useToasts} from 'react-toast-notifications'
import {
  createContact,
  deleteContact,
  getContacts,
  updateContact,
} from '../../services/contactService'
import Auth from '../../config/auth'
import Fuse from 'fuse.js'
import FuseHighlight from '../../components/FuseHighlight'
import {getUserFullname} from '../../utils/user'

export default function Contacts() {
  const [contacts, setContacts] = React.useState([])
  const [prefixedContacts, setPrefixedContacts] = React.useState([])
  const [isOpen, setIsOpen] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [editing, setEditing] = React.useState('')

  let fuse = React.useRef(null)
  const [search, setSearch] = React.useState([])
  const [searchResult, setSearchResult] = React.useState([])

  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const {run, isLoading} = useAsync()

  React.useEffect(() => {
    run(getContacts()).then(({data}) => {
      let contactsArr = []
      const modifiedData = data.data?.map(e => ({
        ...e,
        nameEN: `${e.firstNameEN} ${e.lastNameEN}`,
        nameAR: `${e.firstNameAR} ${e.lastNameAR}`,
      }))

      setContacts(modifiedData)
      modifiedData.forEach((d, i) => {
        // sorting
        const prefixChar = getCharaterList(
          d['firstName' + lang.toUpperCase()],
          d,
          lang,
        )
        const isExistPrefix = contactsArr.findIndex(
          o => o.prefix === prefixChar.prefix,
        )

        if (isExistPrefix !== -1) {
          contactsArr[isExistPrefix].users.push(prefixChar.users)
        } else {
          contactsArr.push({
            prefix: prefixChar.prefix,
            users: [prefixChar.users],
          })
        }
      })
      contactsArr.sort(function (a, b) {
        return a.prefix - b.prefix || a.prefix.localeCompare(b.prefix, [lang])
      })

      setPrefixedContacts(contactsArr)
    })
  }, [reload, lang])

  React.useEffect(() => {
    let keys = []
    if (lang === 'en') {
      keys = [
        'nameEN',
        'email',
        'company',
        'address',
        'phoneNumber',
        {name: 'nameEN', weight: 2},
      ]
    } else {
      keys = [
        'nameAR',
        'email',
        'company',
        'address',
        'phoneNumber',
        {name: 'nameEN', weight: 2},
      ]
    }

    fuse.current = new Fuse(contacts, {
      keys,
      includeMatches: true,
      threshold: 0.1,
    })
  }, [contacts, lang, run])

  const handleSearch = e => {
    const {value} = e.target
    setSearch(value)
    setSearchResult(fuse.current.search(value))
  }

  const onEdit = id => {
    setEditing(id)
    setIsOpen(true)
  }

  return (
    <div>
      <Loader active={isLoading} />
      <div className="flex items-center justify-between">
        <h1 className="text-primary text-2xl">{selectedContent.contacts}</h1>
        {Auth.isHR() || Auth.isSecretary() || Auth.isManagerOrHigher() ? (
          <Button className="btn-primary" onClick={() => setIsOpen(true)}>
            {selectedContent.newContact}
          </Button>
        ) : null}
      </div>

      <div className="mx-0 mt-5 mb-8 flex flex-col">
        <Form.Input
          className="w-100 md:w-2/5 lg:w-1/3"
          icon="search"
          loading={isLoading}
          disabled={isLoading}
          placeholder={selectedContent.search + '...'}
          onChange={handleSearch}
          value={search}
        />
      </div>

      {searchResult.length > 0
        ? searchResult.map(sr => (
            <ContactItem
              contact={sr}
              onEdit={onEdit}
              onReload={() => setReload(prev => !prev)}
              searchResult
            />
          ))
        : prefixedContacts.map(c => (
            <ContactItem
              contact={c}
              onEdit={onEdit}
              onReload={() => setReload(prev => !prev)}
            />
          ))}

      <ContactInfoModal
        editing={editing}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setReload={setReload}
        setEditing={setEditing}
      />
    </div>
  )
}

function ContactItem({contact, onEdit, onReload, searchResult}) {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState('')
  const [viewDetails, setViewDetails] = React.useState({})
  const [isViewing, setIsViewing] = React.useState(false)

  const extraInfo = React.useRef([
    {
      icon: IoIosMail,
      prop: 'email',
    },
    {
      icon: IoIosCall,
      prop: 'phoneNumber',
    },
    {
      icon: IoIosHome,
      prop: 'address',
    },
  ])

  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const onView = item => {
    setIsViewing(true)
    setViewDetails(item)
  }

  const onDelete = id => {
    run(deleteContact(id)).then(() => {
      setOpenDeleteConfirmation('')
      onReload()
      addToast(selectedContent.successfulOperation, {appearance: 'success'})
    })
  }

  let view = null
  if (searchResult) {
    view = (
      <div className="flex flex-wrap mt-4 mb-8 gap-4">
        <div
          className={`relative flex flex-col w-96 px-4 py-4 shadow-md rounded-lg border border-gray-100 mb-4
            `}
          key={contact.item._id}
        >
          <div className="flex">
            {contact.item.avatar?.link ? (
              <Image
                src={contact.item.avatar?.link}
                className="rounded-full w-14 h-14"
              />
            ) : (
              <IoMdContact size={52} className="text-primary" />
            )}
            <div className="ltr:ml-4 rtl:mr-4 mt-1 text-lg">
              {/* <p>{contact.item['name' + lang.toUpperCase()]}</p> */}

              {lang === 'en' ? (
                <FuseHighlight attribute="nameEN" hit={contact} />
              ) : (
                <FuseHighlight attribute="nameAR" hit={contact} />
              )}
              <p className="text-sm text-green-600">{contact.item.jobtitle}</p>
            </div>
          </div>

          <div className="my-4">
            {extraInfo.current.map(e => (
              <div className="flex items-center mb-2">
                <e.icon
                  size={24}
                  className="text-primary bg-green-50 p-1 rounded-full"
                />
                <FuseHighlight attribute={e.prop} hit={contact} />
                {/* <p className="ltr:ml-2 rtl:mr-2">{contact.item[e.prop]}</p> */}
              </div>
            ))}

            <div className="bg-gray-100 px-2 py-1 rounded-lg mt-4">
              {contact.item.notes}
            </div>
          </div>

          {/* Actions */}
          {Auth.isHR() ? (
            <div className="mt-4 flex justify-between">
              <Button className="w-1/3" onClick={() => onView(contact.item)}>
                {selectedContent.view}
              </Button>
              <Button
                className="bg-green-100 text-primary w-1/3"
                onClick={() => onEdit(contact.item)}
              >
                {selectedContent.edit}
              </Button>
              <Button
                className="bg-red-100 text-red-700 w-1/3"
                onClick={() => setOpenDeleteConfirmation(contact.item)}
              >
                {selectedContent.delete}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    )
  } else {
    view = (
      <>
        <h2 className="text-primary">{contact.prefix.toUpperCase()}</h2>
        <hr />
        <div className="flex flex-wrap mt-4 mb-8 gap-4">
          {contact.users.map((u, i) => (
            <div
              className={`relative flex flex-col w-96 px-4 py-4 shadow-md rounded-lg border border-gray-100 mb-4
            `}
              key={u._id}
            >
              <div className="flex">
                {u.avatar?.link ? (
                  <Image
                    src={u.avatar?.link}
                    className="rounded-full w-14 h-14"
                  />
                ) : (
                  <IoMdContact size={52} className="text-primary" />
                )}
                <div className="ltr:ml-4 rtl:mr-4 mt-1 text-lg">
                  <p>{u['name' + lang.toUpperCase()]}</p>
                  <p className="text-sm text-green-600">{u.jobtitle}</p>
                </div>
              </div>

              <div className="my-4">
                {extraInfo.current.map(e => (
                  <div className="flex items-center mb-2">
                    <e.icon
                      size={24}
                      className="text-primary bg-green-50 p-1 rounded-full"
                    />
                    <p className="ltr:ml-2 rtl:mr-2">{u[e.prop]}</p>
                  </div>
                ))}

                <div className="bg-gray-100 px-2 py-1 rounded-lg mt-4">
                  {u.notes}
                </div>
              </div>

              {/* Actions */}
              {Auth.isHR() ? (
                <div className="mt-4 flex justify-between">
                  <Button className="w-1/3" onClick={() => onView(u)}>
                    {selectedContent.view}
                  </Button>
                  <Button
                    className="bg-green-100 text-primary w-1/3"
                    onClick={() => onEdit(u)}
                  >
                    {selectedContent.edit}
                  </Button>
                  <Button
                    className="bg-red-100 text-red-700 w-1/3"
                    onClick={() => setOpenDeleteConfirmation(u)}
                  >
                    {selectedContent.delete}
                  </Button>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </>
    )
  }

  return (
    <div>
      {view}
      <Modal
        open={isViewing}
        size="small"
        closeIcon
        closeOnEscape
        onClose={() => {
          setIsViewing(false)
          setViewDetails({})
        }}
        dimmer
      >
        <Modal.Content image scrolling className="flex flex-col">
          <div class="px-4 md:px-8">
            {viewDetails?.avatar?.link ? (
              <Image
                src={viewDetails?.avatar?.link}
                className="rounded-full w-14 h-14"
              />
            ) : (
              <IoMdContact size={64} className="text-primary" />
            )}

            <h2 className="mt-4">{getUserFullname(viewDetails, lang)}</h2>
            <p className="text-sm text-green-600">{viewDetails.jobtitle}</p>
            <div className="my-4">
              {extraInfo.current.map(e => (
                <div className="flex items-center mb-2">
                  <e.icon
                    size={24}
                    className="text-primary bg-green-50 p-1 rounded-full"
                  />
                  <p className="ltr:ml-2 rtl:mr-2">{viewDetails[e.prop]}</p>
                </div>
              ))}

              <div className="mt-4">
                <p className="text-gray-500">{selectedContent.notes}</p>
                <p>{viewDetails.notes}</p>
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>

      <Modal
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation('')}
        size="small"
        className="main-font"
      >
        <Modal.Content image scrolling className="flex flex-col">
          {selectedContent.removalConfirm}{' '}
          {openDeleteConfirmation['firstName' + lang.toUpperCase()]}{' '}
          {openDeleteConfirmation['lastName' + lang.toUpperCase()]}
          <div className="flex justify-end">
            <div className="mt-5 text-center">
              <Button onClick={() => setOpenDeleteConfirmation('')}>
                {selectedContent.discard}
              </Button>
              <Button
                className="btn-danger"
                onClick={() => onDelete(openDeleteConfirmation._id)}
                loading={isLoading}
              >
                {selectedContent.delete}
              </Button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}

function ContactInfoModal({isOpen, setIsOpen, setReload, editing, setEditing}) {
  const [displayImg, setDisplayImg] = React.useState('')
  const [errors, setErrors] = React.useState([])
  const [avatar, setAvatar] = React.useState('')
  // const phoneRegExp = React.useRef(
  //   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
  // )

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  function handleOnSelectedFile(e) {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setDisplayImg(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0])
    setAvatar(e.target.files[0])
  }

  const newContactValidation = Yup.object({
    firstNameEN: Yup.string()
      .min(3)
      .max(255)
      .required(selectedContent.required),
    lastNameEN: Yup.string().min(3).max(255).required(selectedContent.required),
    firstNameAR: Yup.string()
      .min(3)
      .max(255)
      .required(selectedContent.required),
    lastNameAR: Yup.string().min(3).max(255).required(selectedContent.required),
    email: Yup.string().max(255).required(selectedContent.required),
    phoneNumber: Yup.string().max(50).required(selectedContent.required),
    address: Yup.string().min(5).max(255).required(selectedContent.required),
    title: Yup.string().min(2).max(50).required(selectedContent.required),
    jobtitle: Yup.string().max(50).required(selectedContent.required),
    company: Yup.string().min(3).max(255).required(selectedContent.required),
    department: Yup.string().min(3).max(255).optional(),
    notes: Yup.string().max(255).optional(),
    addToSystem: Yup.bool().required(),
  })

  const createNewContact = values => {
    const formData = new FormData()
    formData.append('avatar', avatar)
    Object.keys(values).forEach(k => formData.append(k, values[k]))

    run(createContact(formData))
      .then(() => {
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
        setIsOpen(false)
        setReload(prev => !prev)
        setErrors([])
      })
      .catch(e => setErrors(e?.errors?.map(e => e?.message)))
  }

  const editContact = values => {
    const formData = new FormData()
    if (avatar) formData.append('avatar', avatar)
    Object.keys(values).forEach(k => formData.append(k, values[k]))

    run(updateContact(editing._id, formData))
      .then(() => {
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
        setIsOpen(false)
        setReload(prev => !prev)
        setErrors([])
      })
      .catch(e => setErrors(e?.errors?.map(e => e?.message)))
  }

  const handleOnSubmit = values => {
    if (editing) {
      editContact(values)
    } else {
      createNewContact(values)
    }
  }

  React.useEffect(() => {
    if (editing && editing.avatar?.link) setDisplayImg(editing.avatar.link)
  }, [editing])

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setEditing('')
        setIsOpen(false)
      }}
      className="main-font"
    >
      <Modal.Header>
        {editing ? selectedContent.editContact : selectedContent.newContact}
      </Modal.Header>
      <Modal.Content image scrolling className="flex flex-col">
        <Form.Field className="my-6">
          <div className="text-center">
            <input
              type="file"
              id="img-input"
              accept="image/*"
              className="hidden"
              onChange={handleOnSelectedFile}
            />
            <label htmlFor="img-input">
              <div className="flex items-center justify-center w-20 h-20 bg-gray-100 rounded-full mx-auto cursor-pointer">
                <Image
                  src={displayImg}
                  className={`rounded-full w-20 h-20 ${
                    displayImg !== '' ? 'visible' : 'hidden'
                  }`}
                />
                <Icon
                  name="camera"
                  className={`text-3xl text-primary -mt-2 mx-0 ${
                    displayImg !== '' ? 'hidden' : 'visible'
                  }`}
                />
              </div>
            </label>
            <p className="my-2">{selectedContent.upload}</p>
          </div>
        </Form.Field>

        <Formik
          validationSchema={newContactValidation}
          initialValues={{
            firstNameEN: editing.firstNameEN || '',
            lastNameEN: editing.lastNameEN || '',
            firstNameAR: editing.firstNameAR || '',
            lastNameAR: editing.lastNameAR || '',
            email: editing.email || '',
            phoneNumber: editing.phoneNumber || '',
            address: editing.address || '',
            title: editing.title || '',
            jobtitle: editing.jobtitle || '',
            company: editing.company || '',
            department: editing.department || '',
            notes: editing.notes || '',
            addToSystem: Boolean(editing.userId),
          }}
          onSubmit={handleOnSubmit}
          enableReinitialize
        >
          {formik => (
            <Form
              className="max-w-lg w-full mx-auto"
              onSubmit={formik.submitForm}
              loading={isLoading}
            >
              <FormikControl
                name="firstNameEN"
                label={selectedContent.firstName}
                control="input"
              />
              <FormikControl
                name="lastNameEN"
                label={selectedContent.lastName}
                control="input"
              />
              <FormikControl
                name="firstNameAR"
                label={selectedContent.firstNameAR}
                control="input"
              />
              <FormikControl
                name="lastNameAR"
                label={selectedContent.lastNameAR}
                control="input"
              />
              <FormikControl
                name="address"
                label={selectedContent.address}
                control="input"
              />
              <FormikControl
                name="jobtitle"
                label={selectedContent.jobTitle}
                control="input"
              />
              <FormikControl
                name="title"
                label={selectedContent.title}
                control="input"
              />
              <FormikControl
                name="company"
                label={selectedContent.company}
                control="input"
              />
              <FormikControl
                name="department"
                label={selectedContent.department}
                control="input"
              />

              <FormikControl
                type="email"
                name="email"
                label={selectedContent.email}
                control="input"
              />

              <FormikControl
                name="phoneNumber"
                label={selectedContent.phone}
                control="input"
              />

              <FormikControl
                name="notes"
                label={selectedContent.notes}
                control="textarea"
              />

              <FormikControl
                name="addToSystem"
                label={selectedContent.addToSystem}
                control="checkbox"
                checked={formik.values.addToSystem}
              />

              {formik.values.addToSystem ? (
                <FormikControl
                  label={selectedContent.password}
                  control="input"
                  autocomplete={false}
                  value="123456"
                  disabled={true}
                />
              ) : null}
              {errors.length > 0 ? (
                <Message
                  negative
                  header={selectedContent.error}
                  list={errors}
                  className="max-w-lg mx-auto mb-10"
                />
              ) : null}

              <Button type="submit" className="btn-primary w-full mt-5">
                {editing ? selectedContent.update : selectedContent.add}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
