import {Formik} from 'formik'
import React from 'react'
import {Button, Form, Grid, Image} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import FormikControl from '../../formik/FormikControl'
import borderIcon from '../../../assets/images/ic_border.svg'
import useAsync from '../../../hooks/useAsync'
import {updateProfile} from '../../../services/profileService'
import {useQuery} from '../../../hooks/useQuery'
import {useToasts} from 'react-toast-notifications'

function EditUserVacations({userData, storeProfile}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const {addToast} = useToasts()

  const query = useQuery()
  const {run, isLoading} = useAsync()

  const onUpdate = values => {
    const userId = query.get('id')
    const vacations = {
      ...userData.vacations,
      ...values,
    }

    const requestBody = {userId, vacations}
    run(updateProfile(requestBody)).then(({data}) => {
      storeProfile(data.data)
      addToast(data.message[lang], {appearance: 'success'})
    })
  }

  return (
    <div>
      <Formik
        initialValues={{
          alAdhaCompensationDays: userData.vacations.alAdhaCompensationDays,
          alFitrCompensationDays: userData.vacations.alFitrCompensationDays,
          nationalDayCompensationDays:
            userData.vacations.nationalDayCompensationDays,
          annualDaysLeft: userData.vacations.annualDaysLeft,
        }}
        enableReinitialize
        onSubmit={onUpdate}
      >
        {formik => (
          <Form loading={isLoading} onSubmit={() => formik.handleSubmit()}>
            <Grid doubling columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <FormikControl
                          name="alAdhaCompensationDays"
                          label={selectedContent.alAdha}
                          placeholder={selectedContent.AlAdha}
                          control="input"
                          required
                        />
                      </div>
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <FormikControl
                          name="alFitrCompensationDays"
                          label={selectedContent.alFitr}
                          placeholder={selectedContent.alFitr}
                          control="input"
                          required
                        />
                      </div>
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <FormikControl
                          name="nationalDayCompensationDays"
                          label={selectedContent.nationalDay}
                          placeholder={selectedContent.nationalDay}
                          control="input"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5 mt-4"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <FormikControl
                          name="annualDaysLeft"
                          label={selectedContent.annual}
                          placeholder={selectedContent.annual}
                          control="input"
                          required
                        />
                      </div>
                    </div>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className="my-10 text-center">
              <Button
                type="submit"
                content={selectedContent.save}
                className="btn-primary"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EditUserVacations
