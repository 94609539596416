import {IoCheckmarkCircleSharp} from 'react-icons/io5'
import {useHistory} from 'react-router-dom'
import {Button} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import routes from '../../../routes'

const SuccessStep = () => {
  const [lang] = useLanguage()
  const approvalLang = content[lang]
  const history = useHistory()

  const handleBack = () => {
    history.push(routes.login)
  }
  return (
    <div className="p-10 text-center w-full">
      <IoCheckmarkCircleSharp size={100} className="text-primary m-auto" />
      <p className="text-5xl text-primary mb-5">{approvalLang.congrads}</p>
      <p className="text-lg ">{approvalLang.approvalContent}</p>

      <div className="my-6">
        <Button
          content={approvalLang.login}
          className="btn-primary"
          onClick={handleBack}
        />
      </div>
    </div>
  )
}

export default SuccessStep
