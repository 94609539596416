import {authAxios, axios} from '../config/axiosConfig'

// Get all departments
export const getAllDepartments = () => {
  return axios.get('/departments/departments')
}

// Get All employees
export const getAllEmployees = () => {
  return authAxios.get('/users/allUsers')
}

// Get employees by department
export const getEmployeesByDepartment = departments => {
  return authAxios.post('/users/usersByDepartment', departments)
}
