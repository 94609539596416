import {authAxios} from '../config/axiosConfig'

// Create an update request
export const createUpdateRequest = async updateData => {
  return authAxios.post('/requests/updates/', updateData)
}

// Get my update history
export const getMyUpdateHistory = async () => {
  return authAxios.get('/requests/updates/')
}

// Get all update requests
export const getAllUpdateRequests = async () => {
  return authAxios.get('/requests/updates/allUpdateRequests')
}

// Approve/Reject Update
export const setUpdateAction = async updateAction => {
  return authAxios.patch('/requests/updates/updateActions', updateAction)
}
