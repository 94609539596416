import React, {Fragment, useContext, useState} from 'react'
import {Dropdown, Icon, Table} from 'semantic-ui-react'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import usePagination from '../../hooks/use-pagination'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {formatDate} from '../../utils/date-format'
import PaginationWithLimit from '../pagination-with-limit'

const MyTickets = ({updateHistory}) => {
  const {run, isLoading, data} = useAsync()
  const {page, limit, onPageChange, onLimitChange} = usePagination()

  const {setShowModal} = useContext(StateContext)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  React.useEffect(() => {
    if (page)
      run(authAxios.get(`/tickets/myTickets?page=${page}&limit=${limit}`))
  }, [updateHistory, run, page, limit])

  return (
    <Fragment>
      <Table celled singleLine selectable>
        <Table.Header>
          <Table.Row textAlign="center" verticalAlign="middle">
            <Table.HeaderCell>{selectedContent.ticketNumber}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.date}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.createdBy}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.type}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.status}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.data?.length === 0 && isLoading ? (
            <Table.Row textAlign="center">
              <Table.Cell colSpan={8}>
                <Icon
                  name="asterisk"
                  size="large"
                  className="text-primary"
                  loading
                />
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data?.data?.map((t, i) => (
            <Table.Row key={t._id} textAlign="center" verticalAlign="middle">
              <Table.Cell>{t.ticketNumber}</Table.Cell>
              <Table.Cell>{formatDate(t.createdAt)}</Table.Cell>
              <Table.Cell>
                {t?.createdBy['firstName' + lang.toUpperCase()]}{' '}
                {t?.createdBy['lastName' + lang.toUpperCase()]}
              </Table.Cell>
              <Table.Cell>
                {t?.ticketType?.['name' + lang.toUpperCase()] || t?.ticketType}
              </Table.Cell>
              <Table.Cell
                warning={
                  t?.ticketStatus === 'pending' ||
                  t?.ticketStatus === 'in progress' ||
                  t?.ticketStatus === 'resent'
                    ? true
                    : false
                }
                positive={t?.ticketStatus === 'completed'}
                negative={t?.ticketStatus === 'rejected'}
              >
                {t?.ticketStatus === 'in progress'
                  ? selectedContent.inProgress
                  : selectedContent[t?.ticketStatus]}
              </Table.Cell>
              <Table.Cell>
                <div className="flex items-center">
                  <Dropdown
                    icon="settings"
                    loading={isLoading}
                    floating
                    button
                    className="icon mx-auto"
                  >
                    <Dropdown.Menu className="ltr:-left-24 rtl:-right-24">
                      <Dropdown.Item
                        icon={() => (
                          <Icon name="eye" className="text-goldPrimary" />
                        )}
                        text={selectedContent.view}
                        onClick={() =>
                          setShowModal({modalName: 'viewTicket', data: t})
                        }
                      />
                      {t?.ticketStatus === 'completed' && (
                        <Dropdown.Item
                          icon={() => (
                            <Icon name="send" className="text-primary" />
                          )}
                          text={selectedContent.resend}
                          onClick={() =>
                            setShowModal({
                              modalName: 'resendTicket',
                              data: t,
                            })
                          }
                        />
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <PaginationWithLimit
        page={page}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        totalPages={data?.totalPages}
      />
    </Fragment>
  )
}

export default MyTickets
