import moment from 'moment'
import {useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {DateInput, TimeInput} from 'semantic-ui-calendar-react'
import {Button, Form, Grid, Icon, Image, TextArea} from 'semantic-ui-react'
import borderIcon from '../../assets/images/ic_border.svg'
import Auth from '../../config/auth'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {editShift, deleteShift} from '../../services/shiftService'
import {formatDate, formatTime, stringToDate} from '../../utils/date-format'

const ViewEmployeeShifts = ({empData, onUpdate}) => {
  const [lang] = useLanguage()
  const empLang = content[lang]
  const {run, isLoading} = useAsync()
  const [edit, setEdit] = useState(false)
  const {addToast} = useToasts()
  const [state, setState] = useState({
    startDate: empData.startDate || '',
    endDate: empData.endDate || '',
    startTime: empData.startDate || '',
    endTime: empData.endDate || '',
    responsibilities: empData.responsibilities || '',
    dutyDays: empData.dutyDays,
  })
  // console.log(empData)
  const handleOnChangeTime = (value, type) => {
    setState({...state, [type + 'Time']: moment(value, 'hh:mm A').format()})
  }

  const handleOnChangeCheckbox = (value, checked) => {
    let arr = [...state.dutyDays]
    if (checked) {
      arr.push(value)
    } else {
      const i = arr.findIndex(o => o === value)
      arr.splice(i, 1)
    }
    setState({...state, dutyDays: arr})
  }

  const handleOnSubmit = type => {
    if (type === 'edit') {
      run(
        editShift({
          shiftId: empData._id,
          type: 'employeeShift',
          updatedData: state,
        }),
      )
        .then(({data}) => {
          // console.log(data)
          addToast(data.message[lang], {appearance: 'success'})
          onUpdate(true)
          setEdit(false)
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      setEdit(false)
    }
  }

  const handleOnDelete = id => {
    // console.log(empData)
    run(deleteShift({shiftId: id, type: 'employeeShifts'}))
      .then(({data}) => {
        // console.log(data)
        addToast(data.message[lang], {appearance: 'success'})
        onUpdate(true)
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <div className="mt-5">
      <div className="text-center">
        <p className="text-2xl font-bold text-primary">
          {empData.employees[0]['firstName' + lang.toUpperCase()]}{' '}
          {empData.employees[0]['lastName' + lang.toUpperCase()]}
        </p>
        <p className="text-gray-500 mb-2">
          {empData.employees[0]?.jobTitle?.['name' + lang.toUpperCase()]}
        </p>
        <div
          className="text-gray-500 flex justify-center items-center mt-2"
          dir={lang === 'en' ? 'ltr' : 'rtl'}
        >
          <p className="mb-0">{formatTime(empData.startDate)} </p>
          <Icon
            name={lang === 'en' ? 'arrow right' : 'arrow left'}
            className="text-lg -mt-2 mx-2"
          />{' '}
          <p className="mb-0">{formatTime(empData.endDate)}</p>
        </div>

        {Auth.isManagerOrHigher() && (
          <div className="mt-5 ">
            <Icon
              name="pencil alternate"
              size="large"
              className="mx-2 text-blue-500 cursor-pointer"
              onClick={() => setEdit(true)}
            />
            <Icon
              name="trash"
              size="large"
              className="mx-2 text-red-800 cursor-pointer"
              onClick={() => handleOnDelete(empData._id)}
            />
          </div>
        )}
      </div>

      <div className="my-10">
        <Grid doubling columns={2}>
          <Grid.Row>
            <Grid.Column>
              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {empLang.shiftDate}:
                </p>
                <div>
                  <p className="text-xl text-subTitleText">
                    {formatDate(empData.startDate)} ({' '}
                    {moment(empData.startDate).locale(lang).format('dddd')} )
                  </p>
                </div>
              </div>

              {/* <div className="mb-3">
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {empLang.duties}:
                  </p>
                </div>

                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                  {empData.dutyDays.map((d, i) => (
                    <p className="text-xl text-subTitleText" key={i}>
                      {d}
                    </p>
                  ))}
                </div>
              </div> */}

              <div className="mb-3">
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {empLang.responsibilities}:
                  </p>
                </div>

                <div className="flex flex-col ltr:ml-10 rtl:mr-10">
                  <p className="text-xl text-subTitleText">
                    {empData?.responsibilities}
                  </p>
                </div>
              </div>
            </Grid.Column>

            <Grid.Column>
              {edit && (
                <Form loading={isLoading}>
                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <Form.Group widths="equal">
                          <Form.Field>
                            <DateInput
                              placeholder={empLang.date}
                              label={empLang.date}
                              value={formatDate(state.startDate)}
                              onChange={(e, {value}) =>
                                setState({
                                  ...state,
                                  startDate: stringToDate(value),
                                  endDate: stringToDate(value),
                                })
                              }
                            />
                          </Form.Field>
                          {/* <Form.Field>
                            <DateInput
                              placeholder={empLang.endDate}
                              label={empLang.endDate}
                              value={formatDate(state.endDate)}
                              onChange={(e, {value}) =>
                                setState({
                                  ...state,
                                  endDate: stringToDate(value),
                                })
                              }
                            />
                          </Form.Field> */}
                        </Form.Group>
                      </div>
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <Form.Group widths="equal">
                          <Form.Field>
                            <TimeInput
                              timeFormat="AMPM"
                              placeholder={empLang.startTime}
                              label={empLang.startTime}
                              value={formatTime(state.startTime)}
                              onChange={(e, {value}) =>
                                handleOnChangeTime(value, 'start')
                              }
                            />
                          </Form.Field>
                          <Form.Field>
                            <TimeInput
                              timeFormat="AMPM"
                              placeholder={empLang.endTime}
                              label={empLang.endTime}
                              value={formatTime(state.endTime)}
                              onChange={(e, {value}) =>
                                handleOnChangeTime(value, 'end')
                              }
                            />
                          </Form.Field>
                        </Form.Group>
                      </div>
                    </div>
                  </Form.Field>
                  {/* 
                  <Form.Field>
                    <label className="text-lg">{empLang.duties}</label>
                    <Grid doubling columns={3} className="mt-1">
                      <Grid.Row>
                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Friday')}
                              label="Friday"
                              value="Friday"
                              // defaultChecked={state.days.includes('Friday')}
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Saturday')}
                              label="Saturday"
                              // defaultChecked={state.dutyDays.includes('Saturday')}
                              value="Saturday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Sunday')}
                              label="Sunday"
                              value="Sunday"
                              // defaultChecked={state.dutyDays.includes('Sunday')}
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Monday')}
                              // defaultChecked={state.dutyDays.includes('Monday')}

                              label="Monday"
                              value="Monday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Tuesday')}
                              // defaultChecked={state.dutyDays.includes('Tuesday')}

                              label="Tuesday"
                              value="Tuesday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Wednesday')}
                              // defaultChecked={state.dutyDays.includes('Wednesday')}
                              label="Wednesday"
                              value="Wednesday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Thursday')}
                              // defaultChecked={state.dutyDays.includes('Thursday')}
                              label="Thursday"
                              value="Thursday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field> */}

                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <label className="font-bold text-primary">
                          {empLang.responsibilities}
                        </label>
                        <TextArea
                          placeholder={empLang.responsibilities}
                          className="mt-2"
                          value={state.responsibilities}
                          onChange={(e, {value}) =>
                            setState({...state, responsibilities: value})
                          }
                        ></TextArea>
                      </div>
                    </div>
                  </Form.Field>

                  <div className="text-center mt-3">
                    <Button
                      content={empLang.save}
                      onClick={() => handleOnSubmit('edit')}
                      className="btn-primary"
                    />
                    <Button
                      content={empLang.discard}
                      onClick={() => handleOnSubmit('cancel')}
                      className="btn-danger"
                    />
                  </div>
                </Form>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

export default ViewEmployeeShifts
