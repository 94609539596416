import React from 'react'
import {
  Button,
  Form,
  Header,
  Message,
  Modal,
  Transition,
} from 'semantic-ui-react'
import {RiCloseFill} from 'react-icons/ri'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormikControl from '../formik/FormikControl'
import useAsync from '../../hooks/useAsync'
import {useToasts} from 'react-toast-notifications'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import {getSubDepartments} from '../../utils/handleDepartments'
import deepEqual from 'deep-equal'
import {getUserFullname} from '../../utils/user'
import AudioRecorder from '../AudioRecorder'
import FileAttachment from '../FileAttachment'

function NewTransaction({isOpen, setIsOpen}) {
  const [audioData, setAudioData] = React.useState({})
  const [attachmentData, setAttachmentData] = React.useState({})
  const [departmentOptions, setDepartmentOptions] = React.useState([])
  const [selectedMasterDepartment, setSelectedMasterDepartment] =
    React.useState('')
  const selectedSubDepartments = React.useRef([])
  const [employees, setEmployees] = React.useState([])
  const [error, setError] = React.useState(null)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, data, isLoading} = useAsync()
  const {run: getEmployees, isLoading: gettingEmployees} = useAsync()
  const {run: createTransaction, isLoading: creatingTransaction} = useAsync()
  const {addToast} = useToasts()

  const onSubDepartmentsChange = values => {
    if (!deepEqual(selectedSubDepartments.current, values)) {
      getEmployees(
        authAxios.post('/users/usersByDepartment', {
          departmentId: [...values, selectedMasterDepartment],
        }),
      ).then(({data}) => {
        let modifiedEmployees = data?.data?.map(e => ({
          key: e._id,
          text: getUserFullname(e, lang),
          value: e._id,
        }))

        selectedSubDepartments.current = values
        setEmployees(modifiedEmployees)
      })
    }
  }

  const newTransactionSchema = Yup.object({
    transactionNumber: Yup.string().required(selectedContent.required),
    transactionType: Yup.string()
      .equals(['Normal'])
      .required(selectedContent.required),
    transactionDate: Yup.string().required(selectedContent.required),
    delayDuration: Yup.string().required(selectedContent.required),
    department: Yup.string().required(selectedContent.required),
    subDepartments: Yup.array()
      .min(1, 'You have to select at least one department')
      .required(selectedContent.required),
    employees: Yup.array()
      .min(1, 'You have to select at least one user')
      .required(selectedContent.required),
    reviewers: Yup.array()
      .min(1, 'You have to select at least one user')
      .required(selectedContent.required),
    observers: Yup.array()
      .min(1, 'You have to select at least one user')
      .required(selectedContent.required),
    sahlDate: Yup.string().required(selectedContent.required),
    statement: Yup.string().optional(),
  })

  const handleCreateNewTransaction = values => {
    const {
      subDepartments,
      employees,
      reviewers,
      observers,
      ...withoutSubDepartments
    } = values
    const data = new FormData()

    if (audioData) data.append('audioFile', audioData.file)
    if (attachmentData) data.append('sahlFile', attachmentData)

    for (let k in withoutSubDepartments) {
      data.append(k, withoutSubDepartments[k])
    }

    employees.map(e => data.append('employeeId', e))
    reviewers.map(e => data.append('reviewerId', e))
    observers.map(e => data.append('observerId', e))

    createTransaction(authAxios.post('/sahl/createTransaction', data))
      .then(() => {
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
        setIsOpen(false)
      })
      .catch(e =>
        setError(
          Array.isArray(e?.errors)
            ? e?.errors?.map(e => e.message[lang])
            : null,
        ),
      )
  }

  React.useEffect(() => {
    run(authAxios.get('/departments/departments'))
      .then(({data}) => {
        let masterDepartmentsOptions = []

        data?.data?.masterDepartments?.map(d =>
          masterDepartmentsOptions.push({
            key: d._id,
            text: d['name' + lang.toUpperCase()],
            value: d._id,
          }),
        )
        setDepartmentOptions(masterDepartmentsOptions)
      })
      .catch(e =>
        e?.errors?.map(e => addToast(e.message[lang], {appearance: 'error'})),
      )
  }, [run, addToast])

  return (
    <Modal
      closeIcon={() => (
        <RiCloseFill
          size="24"
          className="absolute -top-8 -right-8 text-white cursor-pointer hidden md:block"
          onClick={() => setIsOpen(false)}
        />
      )}
      open={isOpen}
      closeOnEscape={false}
      onClose={() => setIsOpen(false)}
      size="tiny"
    >
      <Header className="rounded-t-xl" content="New transaction" />
      <Modal.Content scrolling>
        <Formik
          initialValues={{
            transactionNumber: '',
            transactionType: 'Normal',
            transactionDate: '',
            delayDuration: '',
            department: '',
            subDepartments: [],
            employees: [],
            reviewers: [],
            observers: [],
            sahlDate: '',
            statement: '',
          }}
          validationSchema={newTransactionSchema}
          onSubmit={handleCreateNewTransaction}
        >
          {formik => {
            let filteredEmployees = employees.filter(
              e => !formik.values.employees.includes(e.value),
            )

            setSelectedMasterDepartment(formik.values.department)

            return (
              <Form
                autocomplete="off"
                onSubmit={formik.submitForm}
                loading={isLoading || gettingEmployees || creatingTransaction}
              >
                <FormikControl
                  control="input"
                  label="Transaction number"
                  name="transactionNumber"
                />
                <FormikControl
                  control="input"
                  label="Transaction kind"
                  name="transactionType"
                  disabled
                />
                <FormikControl
                  control="date"
                  label="Transaction date inbox"
                  name="transactionDate"
                />
                <FormikControl
                  control="select"
                  label="Department"
                  name="department"
                  options={departmentOptions}
                />
                <Transition.Group duration={300} animation="fade up">
                  {formik.values.department ? (
                    <FormikControl
                      control="select"
                      label="Sub departments"
                      name="subDepartments"
                      options={getSubDepartments(
                        [formik.values.department],
                        data?.data,
                        lang,
                      )}
                      onChangeCallback={onSubDepartmentsChange}
                      multiple
                    />
                  ) : null}
                </Transition.Group>
                <Transition.Group duration={300} animation="fade up">
                  {formik.values.subDepartments?.length > 0 ? (
                    <>
                      <FormikControl
                        control="select"
                        label="Employee"
                        name="employees"
                        options={employees}
                        multiple
                      />
                      <FormikControl
                        control="select"
                        label="Reviewer"
                        name="reviewers"
                        options={filteredEmployees}
                        multiple
                      />
                      <FormikControl
                        control="select"
                        label="Observer"
                        name="observers"
                        options={filteredEmployees}
                        multiple
                      />
                    </>
                  ) : null}
                </Transition.Group>
                <FormikControl control="date" label="Date" name="sahlDate" />
                <FormikControl
                  control="input"
                  label="Delay duration (in days)"
                  name="delayDuration"
                />
                <FormikControl
                  control="textarea"
                  label="Statement"
                  name="statement"
                />
                <div className="mt-5">
                  <FileAttachment setAttachmentData={setAttachmentData} />
                </div>
                <div className="mt-5 text-primary">
                  <AudioRecorder setAudioData={setAudioData} />
                </div>

                {error ? (
                  <Message negative header="Error" content={error} />
                ) : null}
                <Button className="btn-primary w-full mt-4" type="submit">
                  {selectedContent.submit}
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default NewTransaction
