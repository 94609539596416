import {useEffect, useState} from 'react'
import {Grid, Image, Tab} from 'semantic-ui-react'
import borderIcon from '../../assets/images/ic_border.svg'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import {formatDate} from '../../utils/date-format'

const JobInfo = ({userData}) => {
  const [lang] = useLanguage()
  const profileLang = content[lang]
  const [jobData, setJobData] = useState('')

  useEffect(() => {
    if (!userData) return
    // console.log(userData)
    setJobData(userData)
    return () => {}
  }, [userData])

  console.log('users ingfooooo')

  return (
    <Tab.Pane
      attached={false}
      loading={jobData === '' ? true : false}
      className="border-none"
    >
      {jobData !== '' && (
        <Grid doubling columns="2">
          <Grid.Row>
            <Grid.Column>
              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.workPlace}:{' '}
                </p>
                <p className="text-xl text-subTitleText">{jobData.workPlace}</p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.region}:{' '}
                </p>
                <p className="text-xl text-subTitleText">{jobData.region}</p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.administration}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData.administration}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.city}:{' '}
                </p>
                <p className="text-xl text-subTitleText">{jobData.city}</p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.department}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData.user.department.isMaster
                    ? jobData.user.department['name' + lang.toUpperCase()]
                    : jobData.user.department.masterDepartment[
                        'name' + lang.toUpperCase()
                      ]}
                </p>
              </div>

              {!jobData.user.department.isMaster && (
                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {profileLang.subDepartment}:{' '}
                  </p>
                  <p className="text-xl text-subTitleText">
                    {jobData.user.department['name' + lang.toUpperCase()]}
                  </p>
                </div>
              )}

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.employee}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData?.employeeType?.['name' + lang.toUpperCase()]}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.degree}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData.employeeDegree}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.careerLevel}:{' '}
                </p>
                <p className="text-xl text-subTitleText">{jobData.rank}</p>
              </div>

              <div className="flex flex-col mb-3">
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {profileLang.systemRoles}:{' '}
                  </p>
                </div>

                <div className="ltr:ml-5 rtl:mr-5 my-3">
                  <Grid doubling columns={3}>
                    <Grid.Row>
                      {jobData.user?.responsibilities?.map((r, i) => (
                        <Grid.Column key={i} className="mb-2">
                          <div className="bg-goldPrimary rounded-full py-2 px-3 text-xl text-white text-center font-semibold">
                            {r['name' + lang.toUpperCase()]}
                          </div>
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.jobTitle}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData.jobTitle &&
                    jobData.jobTitle['name' + lang.toUpperCase()]}
                </p>
              </div>

              <div className="flex flex-col items-center mb-3">
                <div className="flex justify-start items-center w-full">
                  <Image
                    src={borderIcon}
                    width="3px"
                    height="3px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-xl font-bold mx-3 text-goldPrimary">
                    {profileLang.jobDescription}:{' '}
                  </p>
                </div>
                <div className="max-h-40 overflow-y-scroll ltr:ml-5 rtl:mr-5 my-3">
                  <p className="text-xl text-subTitleText">
                    {jobData.jobDescription}
                  </p>
                </div>
              </div>
              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.hiringDate + ' / ' + profileLang.workStartDate}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData.hiringDate ? formatDate(jobData.hiringDate) : ''}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.contractStartDate}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData.contractStartDate
                    ? formatDate(jobData.contractStartDate)
                    : ''}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.contractEndDate}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData.contractEndDate
                    ? formatDate(jobData.contractEndDate)
                    : ''}
                </p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.careerAngel}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData.careerAngel}
                </p>
              </div>

              {/* <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.workStartDate}:{' '}
                </p>
                <p className="text-xl text-subTitleText">
                  {jobData.workStartDate
                    ? formatDate(jobData.workStartDate)
                    : ''}
                </p>
              </div> */}

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.jobNumber}:{' '}
                </p>
                <p className="text-xl text-subTitleText">{jobData.jobNumber}</p>
              </div>

              <div className="flex items-center mb-3">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {profileLang.basicSalary}:{' '}
                </p>
                <p className="text-xl text-subTitleText">{jobData.salary}</p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Tab.Pane>
  )
}

export default JobInfo
