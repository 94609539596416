import {Fragment, useContext, useEffect, useState} from 'react'
import ReactCodeInput from 'react-code-input'
import {Button} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import StateContext from '../../../context/stateContext'
import {content} from '../../../localization/content'

const LoginVerifyCode = ({nextStep, errorContent}) => {
  const [lang] = useLanguage()
  const [state, setState] = useState({
    code: '',
  })
  const {setAuthTitle} = useContext(StateContext)
  const codeContent = content[lang]

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return
    setAuthTitle({
      title: codeContent.verificationCode,
      subTitle: codeContent.verifyAccount,
      message: codeContent.verifyMessage,
    })
    return () => {
      isMounted = false
    }
  }, [lang])

  const props = {
    inputStyle: {
      margin: '4px',
      MozAppearance: 'textfield',
      width: '35px',
      borderRadius: '3px',
      fontSize: '24px',
      fontWeight: 'bold',
      height: '45px',
      textAlign: 'center',
      paddingLeft: '7px',
      backgroundColor: 'white',
      color: '#148f64',
      padding: '4px',
      border: '1px solid #148f64',
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '15px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'black',
      color: '#148f64',
      border: '1px solid #148f64',
    },
    onChange: value => {
      setState({...state, code: value})
    },
  }

  const handleOnClickSubmit = () => {
    nextStep({step: 'phone', value: state})
  }
  return (
    <Fragment>
      <div dir="ltr" className="mb-6">
        <ReactCodeInput
          type="text"
          className="text-center w-100"
          fields={6}
          {...props}
        />
      </div>
      <div className="auth-action">
        <Button
          content={codeContent.login}
          className="main-font btn-primary"
          onClick={handleOnClickSubmit}
        />
      </div>
    </Fragment>
  )
}

export default LoginVerifyCode
