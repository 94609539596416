import {useContext, useEffect, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {Button, Form, Modal} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {approveTicket, getItUsers} from '../../services/ticketService'

const AssignTicketModal = ({updateTicket}) => {
  const [open, setOpen] = useState(false)
  const {showModal, setShowModal} = useContext(StateContext)
  const {addToast} = useToasts()
  const [modalData, setModalData] = useState('')
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const [employees, setEmployees] = useState([])
  const [state, setState] = useState({
    assignedTo: [],
  })
  const assignLang = content[lang]
  useEffect(() => {
    if (showModal.modalName === 'assignTicket') {
      setOpen(true)
      setModalData(showModal.data)
      run(getItUsers()).then(({data}) => {
        let emps = []
        data.data.map(e => {
          emps.push({
            key: e._id,
            text:
              e['firstName' + lang.toUpperCase()] +
              ' ' +
              e['lastName' + lang.toUpperCase()],
            value: e._id,
          })
        })
        setEmployees(emps)
      })
    } else {
      setOpen(false)
    }
  }, [showModal])

  const handleOnClickAssign = () => {
    run(approveTicket({ticketId: modalData._id, assignedTo: state.assignedTo}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        setShowModal({modalName: '', data: null})
        setState({...state, assignedTo: []})
        updateTicket(true)
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <Modal
      onClose={() => setShowModal({modalName: '', data: null})}
      open={open}
      size="small"
      className="main-font"
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>{assignLang.assignEmployee}</Modal.Header>
      <Modal.Content>
        <Form loading={isLoading}>
          <Form.Field>
            <Form.Dropdown
              label={assignLang.selectEmployees}
              placeholder={assignLang.select}
              fluid
              selection
              multiple
              search
              defaultValue={state.assignedTo}
              options={employees}
              onChange={(e, {value}) => setState({...state, assignedTo: value})}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="check"
          className="btn-primary"
          content={assignLang.save}
          onClick={handleOnClickAssign}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default AssignTicketModal
