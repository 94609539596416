import React from 'react'
import {Form, Tab, Button, Message} from 'semantic-ui-react'
import {Formik} from 'formik'

import Auth from '../../config/auth'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import useEmployeesDropDown from '../../hooks/use-employees-dropdown'
import {content} from '../../localization/content'
import FormikDropdown from '../../components/formik/FormikDropdown'
import formatError from '../../utils/error-format'

const DepartmentSettings = () => {
  const [showSaveButton, setShowSaveButton] = React.useState(false)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {
    run,
    data: department,
    error: errorGettingDepartment,
    isLoading,
  } = useAsync({data: {}})

  const {
    run: updateViceManager,
    error: errorUpdatingViceManager,
    isLoading: updatingViceManager,
  } = useAsync()

  const {employees, gettingEmployees, getEmployees} = useEmployeesDropDown({
    runImmediately: false,
  })

  const handleUpdateViceManager = values => {
    const userToAssign = values.viceManager
    updateViceManager(
      authAxios.post('departments/assign-vice-manager', {userToAssign}),
    ).then(() => getDepartment(false))
  }

  const getDepartment = React.useCallback(
    (updateEmplyees = true) => {
      const department = Auth.getDataFromToken().department
      if (!department) return

      run(authAxios.get(`/departments/${department._id}`)).then(
        ({data}) => updateEmplyees && getEmployees(data?.data?._id),
      )
    },
    [getEmployees, run],
  )

  React.useEffect(() => {
    getDepartment()
  }, [getDepartment])

  return (
    <Tab.Pane loading={isLoading}>
      <div class="max-w-2xl mx-auto my-8">
        <h4 className="text-gray-600">
          {selectedContent.departmentManagement}
        </h4>
        <div className="mt-4">
          {errorUpdatingViceManager ? (
            <Message error list={formatError(errorUpdatingViceManager)} />
          ) : null}

          {errorGettingDepartment ? (
            <Message error list={formatError(errorGettingDepartment)} />
          ) : null}

          <Formik
            initialValues={{viceManager: department?.data?.viceManager?._id}}
            onSubmit={handleUpdateViceManager}
            enableReinitialize
          >
            {formik => (
              <Form
                loading={updatingViceManager}
                onSubmit={formik.handleSubmit}
              >
                <FormikDropdown
                  name="viceManager"
                  options={employees}
                  label="Vice manager"
                  fluid
                  selection
                  loading={gettingEmployees}
                />

                {formik.values.viceManager !==
                department?.data?.viceManager?._id ? (
                  <Button size="small" className="btn-primary">
                    Save
                  </Button>
                ) : null}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Tab.Pane>
  )
}

export default DepartmentSettings
