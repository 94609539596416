import React from 'react'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router'

const useSearch = onPageChage => {
  const [searchQuery, setSearchQuery] = React.useState('')
  const {pathname, search} = useLocation()
  const history = useHistory()

  const onSearchChange = (newSearchQuery = '') => {
    const parsedQuery = queryString.parse(search)

    // fixes the issue of redirecting to page 1 when there is no search change
    if (parsedQuery.q === newSearchQuery) return

    if (!onPageChage)
      throw new Error(
        `useSearch can't be called without onPageChange handler passed to it.`,
      )

    const stringifiedQuery = queryString.stringify({
      ...parsedQuery,
      q: newSearchQuery,
    })

    setSearchQuery(newSearchQuery)
    onPageChage(1)

    history.push(`${pathname}?${stringifiedQuery}`)
  }

  React.useEffect(() => {
    const {q} = queryString.parse(search)

    if (q !== undefined) setSearchQuery(q)
  }, [])

  return {
    searchQuery,
    onSearchChange,
  }
}

export default useSearch
