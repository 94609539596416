import {useEffect, useState} from 'react'
import {Tab} from 'semantic-ui-react'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {useQuery} from '../../hooks/useQuery'
import {content} from '../../localization/content'
import {getUserFullname} from '../../utils/user'
import EditJobInfo from '../profileComponents/editProfile/editJobInfo'
import EditProfileInfo from '../profileComponents/editProfile/editProfileInfo'
import EditUserVacations from '../profileComponents/editProfile/editUserVacations'

const EditEmployeeProfile = () => {
  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const query = useQuery()
  const {run, data} = useAsync()
  const [empProfile, setEmpProfile] = useState('')

  const panes = [
    {
      menuItem: selectedContent.personalInfo,
      render: () => (
        <EditProfileInfo
          type="employee"
          userData={empProfile}
          storeProfile={v => setEmpProfile(v)}
        />
      ),
    },
    {
      menuItem: selectedContent.jobInfo,
      render: () => (
        <EditJobInfo
          userData={empProfile}
          storeProfile={v => setEmpProfile(v)}
          editType="employee"
        />
      ),
    },
    {
      menuItem: selectedContent.vacations,
      render: () => (
        <EditUserVacations
          userData={empProfile}
          storeProfile={v => setEmpProfile(v)}
        />
      ),
    },
  ]

  useEffect(() => {
    if (!query.get('id')) return
    run(authAxios.get(`/users/userById/${query.get('id')}`))
      .then(({data}) => {
        setEmpProfile(data.data)
      })
      .catch(e => {
        console.log(e)
      })
  }, [run])

  return (
    <section>
      <h2 className="text-primary">
        {getUserFullname(empProfile?.user, lang)}
      </h2>
      <div className="mt-8">
        <Tab menu={{secondary: true, pointing: true}} panes={panes} />
      </div>
    </section>
  )
}

export default EditEmployeeProfile
