import React from 'react'
import {useToasts} from 'react-toast-notifications'
import {Header, Image, Tab, Table} from 'semantic-ui-react'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import useAsync from '../../hooks/useAsync'
import {formatDate} from '../../utils/date-format'
import {getUserFullname} from '../../utils/user'
import {getVacationType} from '../../utils/vacation'
import {FaUserCircle} from 'react-icons/fa'

function EmployeesOnVacation() {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading, data} = useAsync()
  const {addToast} = useToasts()

  React.useEffect(() => {
    run(authAxios.get('/vacations/employees')).catch(e =>
      e?.errors.map(e => addToast(e.message[lang], {appearance: 'error'})),
    )
  }, [run, addToast, lang])

  return (
    <Tab.Pane className="p-0 m-0 border-0" loading={isLoading}>
      <Table celled singleLine selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{selectedContent.employee}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.from}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.to}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.vacationType}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.data?.map(vacation => {
            let user = vacation.user

            return (
              <Table.Row key={vacation._id}>
                <Table.Cell>
                  <Header as="h4" image>
                    {user?.avatar ? (
                      <Image src={user.avatar} rounded size="small" />
                    ) : (
                      <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-3 rtl:ml-3" />
                    )}
                    <Header.Content>
                      {getUserFullname(user, lang)}
                      <Header.Subheader>
                        {user.department['name' + lang.toUpperCase()]}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>{formatDate(vacation.start)}</Table.Cell>
                <Table.Cell>{formatDate(vacation.end)}</Table.Cell>
                <Table.Cell>
                  {getVacationType(vacation, selectedContent)}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </Tab.Pane>
  )
}

export default EmployeesOnVacation
