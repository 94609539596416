import {authAxios} from '../config/axiosConfig'

export const createContact = data => {
  return authAxios.post(`/contacts`, data)
}

export const getContacts = () => {
  return authAxios.get('/contacts')
}

export const updateContact = (id, data) => {
  return authAxios.put(`/contacts/${id}`, data)
}

export const deleteContact = id => {
  return authAxios.delete(`/contacts/${id}`)
}
