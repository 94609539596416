import React from 'react'
import {Form, Grid, Icon, Image, Transition} from 'semantic-ui-react'
import Fuse from 'fuse.js'
import moment from 'moment'

import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import useMediaQuery from '../../hooks/use-media-query'
import userIcon from '../../assets/images/user.svg'
import {authAxios} from '../../config/axiosConfig'
import Auth from '../../config/auth'
import {useSocket} from '../../context/socket-context'
import {getUserFullname} from '../../utils/user'
import FuseHighlight from '../FuseHighlight'

export default function Conversations({
  isCollapsed,
  setIsCollapsed,
  conversations,
  setConversations,
  activeConversation,
  setActiveConversation,
}) {
  const isMedium = useMediaQuery('(max-width: 992px)')
  const activeConversationRef = React.useRef(null)
  const [search, setSearch] = React.useState('')
  const [searchResult, setSearchResult] = React.useState([])
  const fuseRef = React.useRef(null)

  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const {run, isLoading} = useAsync()
  const socket = useSocket()

  // resets unread messages then sets the active conversation
  const onChangeActiveConversation = conv => {
    setConversations(prev =>
      prev.map(c => {
        if (c._id === conv._id) {
          if (c.members[0].user._id === Auth.getUserId()) {
            c.members[0].unReadMessages = 0
          } else if (c.members[1].user._id === Auth.getUserId()) {
            c.members[1].unReadMessages = 0
          }
          return conv
        }
        return c
      }),
    )

    setActiveConversation(conv)
  }

  const handleSearch = e => {
    const {value} = e.target
    setSearch(value)
    setSearchResult(fuseRef.current.search(value))
  }

  React.useEffect(() => {
    let keys = []
    if (lang === 'en') {
      keys = ['members.user.nameEN']
    } else {
      keys = ['members.user.nameAR']
    }

    fuseRef.current = new Fuse(conversations, {
      keys,
      includeMatches: true,
      threshold: 0.1,
    })

    // update searchResult when conversation updates
    // to reflect the last message in the chat
    setSearchResult(fuseRef.current.search(search))
  }, [conversations, lang])

  // ref to active conversations becuase I can't
  // access it in useEffect that can't update when activeConversation changes
  React.useEffect(() => {
    activeConversationRef.current = activeConversation
  }, [activeConversation])

  React.useEffect(() => {
    run(authAxios.get('/chats/conversation')).then(({data}) => {
      // make conversations searchable by fuse.js by combining users names
      let modifiedData = data.data.map(c => ({
        ...c,
        members: c.members.map(m => ({
          ...m,
          user: {
            ...m.user,
            nameEN: `${m.user.firstNameEN} ${m.user.lastNameEN}`,
            nameAR: `${m.user.firstNameAR} ${m.user.lastNameAR}`,
          },
        })),
      }))
      setConversations(modifiedData)
    })
  }, [])

  // register socket listeners
  React.useEffect(() => {
    const updateLastMessage = message =>
      setConversations(prev => {
        const updatedConv = prev.map(conv => {
          if (conv._id === message.conversationId) {
            conv.lastMessage = message

            // update the count of unread messages from that conversation
            // if it was not the active one
            if (conv._id !== activeConversationRef.current?._id) {
              if (conv.members[0].user._id === Auth.getUserId()) {
                conv.members[0].unReadMessages += 1
              } else if (conv.members[1].user._id === Auth.getUserId()) {
                conv.members[1].unReadMessages += 1
              }
            }
          }
          return conv
        })

        updatedConv.sort((a, b) => {
          const dateA = new Date(a.lastMessage?.createdAt || a.updatedAt)
          const dateB = new Date(b.lastMessage?.createdAt || a.updatedAt)

          return dateB - dateA
        })
        return updatedConv
      })

    const updateUserStatus = user =>
      setConversations(prev =>
        prev.map(conv => {
          if (conv.members[0].user._id === user._id) {
            conv.members[0].user = user
          } else if (conv.members[1].user._id === user._id) {
            conv.members[1].user = user
          }
          return conv
        }),
      )

    const addNewConversation = conversation => {
      // make the new conversation searchable by fuse.js
      let modifiedConverstaion = {
        ...conversation,
        members: conversation.members.map(m => ({
          ...m,
          user: {
            ...m.user,
            nameEN: `${m.user.firstNameEN} ${m.user.lastNameEN}`,
            nameAR: `${m.user.firstNameAR} ${m.user.lastNameAR}`,
          },
        })),
      }
      setConversations(prev => [modifiedConverstaion, ...prev])
    }

    socket?.on('receive conversation', addNewConversation)
    socket?.on('receive message', updateLastMessage)
    socket?.on('user status change', updateUserStatus)

    return () => {
      socket?.off('receive conversation', addNewConversation)
      socket?.off('receive message', updateLastMessage)
      socket?.off('user status change', updateUserStatus)
    }
  }, [socket])

  return (
    <Transition.Group animation="scale" duration={500}>
      <Grid.Column
        width={isMedium ? (isCollapsed ? 0 : 6) : 6}
        className={isMedium ? 'p-0 w-auto' : 'ltr:pl-0 rtl:pr-0'}
      >
        <div className={`${isMedium ? 'flex items-center w-full' : ''}`}>
          <Form
            className={`border-t border-gray-100 overflow-y-auto overflow-x-hidden  ${
              isMedium
                ? isCollapsed
                  ? 'hidden'
                  : 'shadow-md rounded-xl'
                : 'shadow-md rounded-xl'
            }`}
            style={{
              height: '100%',
              maxHeight: 'calc(100vh - 300px)',
              minHeight: '400px',
            }}
            loading={isLoading}
          >
            <Form.Group className="p-3 items-center">
              <Form.Field className="w-full">
                <Form.Input
                  placeholder={selectedContent.search}
                  value={search}
                  onChange={handleSearch}
                  icon="search"
                />
              </Form.Field>
            </Form.Group>

            <div
              className="mt-3"
              style={{height: '100vh', maxHeight: 'calc(100vh - 300px)'}}
            >
              {search ? (
                searchResult?.map(conv => (
                  <ConversationItem
                    key={conv.item._id}
                    conversation={conv}
                    isActive={conv._id === activeConversation._id}
                    setActiveConversation={onChangeActiveConversation}
                    searching
                  />
                ))
              ) : conversations?.length > 0 ? (
                <div>
                  {conversations.map(conv => (
                    <ConversationItem
                      key={conv._id}
                      conversation={conv}
                      isActive={conv._id === activeConversation._id}
                      setActiveConversation={onChangeActiveConversation}
                    />
                  ))}
                </div>
              ) : (
                <div
                  className="flex items-center justify-center"
                  style={{height: '100%'}}
                >
                  <p className="text-lg font-semibold text-primary text-center">
                    {selectedContent.noConversations}
                  </p>
                </div>
              )}
            </div>
          </Form>

          {isMedium && (
            <div
              className="bg-gray-100 flex items-center justify-center rtl:rounded-l-lg rounded-r-lg "
              style={{
                height: '360px',
              }}
              onClick={() => setIsCollapsed(prev => !prev)}
            >
              <Icon name="angle right" className="text-gray-500 m-0" />
            </div>
          )}
        </div>
      </Grid.Column>
    </Transition.Group>
  )
}

function ConversationItem({
  conversation,
  isActive,
  setActiveConversation,
  searching,
}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  let hit = conversation
  if (searching) conversation = conversation.item

  const receiver = conversation?.members?.filter(
    m => m.user._id !== Auth.getUserId(),
  )[0].user

  const unReadMessages = conversation?.members?.filter(
    m => m.user._id === Auth.getUserId(),
  )[0].unReadMessages

  const receiverName = getUserFullname(receiver, lang)
  const lastActive = moment(receiver?.lastActiveAt).format('hh:mm A')

  return (
    <div
      className="border-b-2 border-gray-100 cursor-pointer hover:bg-green-100 transition-colors duration-300"
      onClick={() => setActiveConversation(conversation)}
    >
      <div
        className={`${isActive ? 'bg-chatPrimary' : ''} p-2 flex items-center`}
      >
        <Image
          src={receiver?.avatar || userIcon}
          className="w-16 h-16 rounded-full"
        />
        <div className="ltr:ml-4 rtl:mr-4 w-full">
          <div className="flex items-center justify-between w-full">
            <p className="text-lg text-primary font-semibold">
              {searching ? (
                <FuseHighlight
                  attribute={'members.user.name' + lang.toUpperCase()}
                  hit={hit}
                />
              ) : (
                receiverName
              )}
            </p>
            <p className="text-sm text-gray-500">
              {receiver?.isActive
                ? selectedContent.online
                : receiver?.lastActiveAt
                ? `${lastActive}`
                : selectedContent.offline}
            </p>
          </div>
          <div className="flex items-center justify-between">
            <p
              className={`overflow-ellipsis flex-nowrap text-gray-600 ${
                unReadMessages ? 'font-bold' : ''
              }`}
              style={{
                width: '200px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {conversation.lastMessage?.message || selectedContent.startChat}
            </p>
            {unReadMessages > 0 ? (
              <span className="absolute right-2 bg-green-200 px-3 py-1 rounded-lg shadow-sm text-sm">
                {unReadMessages}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
