import React, {useContext, useEffect, useState} from 'react'
import {Form, Icon, Message} from 'semantic-ui-react'
import EmailForm from '../../components/auth/login/emailForm'
import LoginVerifyCode from '../../components/auth/login/loginVerifyCode'
import PhoneForm from '../../components/auth/login/phoneForm'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {loginUser} from '../../services/authService'
import {useToasts} from 'react-toast-notifications'
import Auth from '../../config/auth'
import useLocalStorage from '../../hooks/use-local-storage'
import {useHistory} from 'react-router'
import {useUser} from '../../context/UserContext'
import WaitingApproval from '../../components/auth/login/waitingApproval'

const LoginPage = () => {
  const {run, isLoading, isSuccess} = useAsync()
  // const [user, setUser] = useLocalStorage('user', '')
  const [user, setUser] = useUser()
  const {addToast} = useToasts()
  const [loginType, setLoginType] = useState('')
  const [state, setState] = useState('')
  const {authTitle} = useContext(StateContext)
  const [lang] = useLanguage()
  const authContent = content[lang]
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({
    isError: false,
    errorType: '',
  })
  const history = useHistory()

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return
    if (authTitle === null) {
      setTitle(authContent.greeting)
      setMessage(authContent.loginWith)
      return
    }
    if (authTitle.subTitle) {
      setTitle(authTitle.subTitle)
    } else {
      setTitle(authContent.greeting)
    }

    setMessage(authTitle.message)
    return () => {
      isMounted = false
    }
  }, [authTitle, lang])

  // Handle errors
  const handleOnError = () => {
    if (errors.errorType === 'all') {
      return (
        <Message
          error
          header={authContent.emptyField}
          content={authContent.emptyFieldContent}
        />
      )
    }
    if (errors.errorType === 'password') {
      return (
        <Message
          error
          header={authContent.invalidPassword}
          content={authContent.invalidPassContent}
        />
      )
    }
    if (errors.errorType === 'email') {
      return (
        <Message
          error
          header={authContent.invalidEmail}
          content={authContent.invalidEmailContent}
        />
      )
    }
  }

  const handleNextStep = value => {
    if (value.step === 'email') {
      run(
        loginUser({
          emailOrPhone: value.value.email,
          password: value.value.password,
          type: 'email',
        }),
      )
        .then(({data}) => {
          // console.log(data.data)
          if (data.data.isApproved) {
            Auth.setToken(data.data.token)
            setUser(
              JSON.stringify({
                _id: data.data._id,
                avatar: data.data.avatar,
                firstNameEN: data.data.firstNameEN,
                lastNameEN: data.data.lastNameEN,
                firstNameAR: data.data.firstNameAR,
                lastNameAR: data.data.lastNameAR,
                role: data.data.role,
                department: data.data.department,
                avgRate: data.data.avgRate,
                responsibilities: data.data.responsibilities,
              }),
            )

            history.push('/dashboard')
          } else {
            setLoginType('waiting')
          }
        })
        .catch(e => {
          console.log(e)
          e?.errors.map(error => {
            if (error.field === 'approved') {
              addToast(error.message[lang], {appearance: 'error'})
              setLoginType('waiting')
            } else {
              addToast(error.message[lang], {appearance: 'error'})
            }
          })
        })
      return
    }
    if (value.step === 'verify') {
      setState({...state, phone: value.value})
      // run(sendOTP({phone: value.value})).then(({data}) => {
      //   console.log(data)
      // })
      setLoginType('verify')
      return
    }
    if (value.step === 'phone') {
      run(loginUser({emailOrPhone: state.phone, type: 'phone'}))
        .then(({data}) => {
          if (data.data.isApproved) {
            Auth.setToken(data.data.token)
            setUser(
              JSON.stringify({
                _id: data.data._id,
                avatar: data.data.avatar,
                firstNameEN: data.data.firstNameEN,
                lastNameEN: data.data.lastNameEN,
                firstNameAR: data.data.firstNameAR,
                lastNameAR: data.data.lastNameAR,
                role: data.data.role,
                department: data.data.department,
                avgRate: data.data.avgRate,
                responsibilities: data.data.responsibilities,
              }),
            )

            history.push('/dashboard')
          } else {
            setLoginType('waiting')
          }
        })
        .catch(e => {
          e.errors.map(error => {
            // console.log(e.errors.field)
            if (e.errors.field === 'approved') {
              addToast(error.message[lang], {appearance: 'error'})
              setLoginType('waiting')
            } else {
              addToast(error.message[lang], {appearance: 'error'})
            }
          })
        })
    }
  }

  return (
    <div className="auth-content">
      {loginType !== 'waiting' && (
        <div className="auth-content-text flex">
          {!['', 'waiting'].includes(loginType) && (
            <Icon
              name="angle left"
              size="large"
              className="ltr:mr-4 rtl:ml-4 text-goldPrimary cursor-pointer"
              onClick={() => setLoginType('')}
            />
          )}
          <div>
            <h3 className="text-primary mb-4 main-font text-xl font-bold">
              {authContent.greeting}
            </h3>
            <p className="text-subTitleText main-font text-base">
              {authContent.loginWith}
            </p>
          </div>
        </div>
      )}

      <div
        className={`auth-content-types mt-10 ${
          loginType === '' ? 'visible' : 'hidden'
        }`}
      >
        <ul className="p-0">
          <li
            className="main-bgColor p-3 mb-3"
            onClick={() => setLoginType('email')}
          >
            <div className="login-type flex items-center">
              <Icon
                name="mail outline"
                className="ltr:mr-5 rtl:ml-5"
                size="large"
              />
              <p className="main-font">{authContent.loginByEmail}</p>
            </div>
          </li>
          <li
            className="p-3 hover:bg-gray-100 transition duration-300"
            onClick={() => setLoginType('phone')}
          >
            <div className="login-type flex items-center text-primary">
              <Icon name="phone" className="ltr:mr-5 rtl:ml-5" size="large" />
              <p className="main-font">{authContent.loginByPhone}</p>
            </div>
          </li>
        </ul>
      </div>
      <div
        className={`auth-content-form ${
          loginType !== '' ? 'visible' : 'hidden'
        }`}
      >
        <Form error={errors.isError} loading={isLoading}>
          <div className="my-6">{handleOnError()}</div>
          {loginType === 'email' && (
            <EmailForm
              errorContent={value => setErrors(value)}
              nextStep={value => handleNextStep(value)}
            />
          )}
          {loginType === 'phone' && (
            <PhoneForm nextStep={value => handleNextStep(value)} />
          )}
          {loginType === 'verify' && (
            <LoginVerifyCode nextStep={value => handleNextStep(value)} />
          )}
          {loginType === 'waiting' && (
            <WaitingApproval goBack={v => setLoginType(v)} />
          )}
        </Form>
      </div>
    </div>
  )
}

export default LoginPage
