import React from 'react'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router'

const DEFAULT_PAGE = '1'
const DEFAULT_LIMIT = '10'

/**
 * Sets the page & limit in the url,
 * You've to listen to location.search changes to update your data
 *
 */
function usePagination() {
  const [page, setPage] = React.useState(0)
  const [limit, setLimit] = React.useState(0)

  const history = useHistory()
  const {pathname, search} = useLocation()

  const onPageChange = activePage => {
    handleChange(activePage)
  }

  const onLimitChange = newLimit => {
    handleChange(undefined, newLimit)
  }

  const handleChange = (newPage = page, newLimit = limit) => {
    const parsedQuery = queryString.parse(search)
    // in case the change was not in page or limit
    if (parsedQuery.page === newPage && parsedQuery.limit === newLimit) return

    const stringifiedQuery = queryString.stringify({
      ...parsedQuery,
      page: newPage,
      limit: newLimit,
    })

    setPage(newPage)
    setLimit(newLimit)

    history.push(`${pathname}?${stringifiedQuery}`)
  }

  // When the component mounts, set the page & limit form the URL
  React.useEffect(() => {
    const {page = DEFAULT_PAGE, limit = DEFAULT_LIMIT} =
      queryString.parse(search)

    setPage(parseInt(page))
    setLimit(limit)
  }, [])

  return {
    page,
    limit,
    onPageChange,
    onLimitChange,
  }
}

export default usePagination
