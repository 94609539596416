import React from 'react'
import {Tab} from 'semantic-ui-react'
import AllPermissionRequests from '../../../components/permission-request/AllPermissionRequests'
import EmployeePermissionsHistory from '../../../components/permission-request/EmployeePermissionsHistory'
import NewPermissionRequest from '../../../components/permission-request/NewPermissionRequest'
import PermissionsHistory from '../../../components/permission-request/PermissionsHistory'
import Auth from '../../../config/auth'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import {useLocation, useHistory} from 'react-router-dom'
import routes from '../../../routes'

function PermissionRequest() {
  const [activeIndex, setActiveIndex] = React.useState(0)

  const {pathname} = useLocation()
  const history = useHistory()

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  function handleNewPermission() {
    setActiveIndex(panes.length - 1)
  }

  function onTabChange(e, {panes, activeIndex}) {
    history.push(`${routes.permissionRequest}/${panes[activeIndex].route}`)
  }

  const panes = React.useMemo(() => {
    if (Auth.isManagerOrHigher())
      return [
        {
          menuItem: selectedContent.incommingRequests,
          route: content.en.incommingRequests,
          render: () => <AllPermissionRequests />,
        },
        {
          menuItem: selectedContent.submitRequest,
          route: content.en.submitRequest,
          render: () => (
            <NewPermissionRequest handleNewPermission={handleNewPermission} />
          ),
        },
        {
          menuItem: selectedContent.employeesRequests,
          route: content.en.employeesRequests,
          render: () => <EmployeePermissionsHistory />,
        },
        {
          menuItem: selectedContent.history,
          route: content.en.history,
          render: () => <PermissionsHistory />,
        },
      ]
    else
      return [
        {
          menuItem: selectedContent.submitRequest,
          route: content.en.submitRequest,
          render: () => (
            <NewPermissionRequest handleNewPermission={handleNewPermission} />
          ),
        },
        {
          menuItem: selectedContent.history,
          route: content.en.history,
          render: () => <PermissionsHistory />,
        },
      ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  React.useEffect(() => {
    for (let i = 0; i < panes.length; i++)
      if (pathname.split('?')[0].endsWith(panes[i].route)) setActiveIndex(i)
  }, [panes, pathname])

  return (
    <section>
      <h2 className="text-primary">{selectedContent.permission}</h2>
      <div className="mt-8">
        <Tab
          menu={{
            secondary: true,
            pointing: true,
            className: 'flex flex-wrap',
          }}
          panes={panes}
          onTabChange={onTabChange}
          activeIndex={activeIndex}
        />
      </div>
    </section>
  )
}

export default PermissionRequest
