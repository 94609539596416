import React from 'react'
import {useLocation} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import usePagination from '../../hooks/use-pagination'
import useSearch from '../../hooks/use-search'
import useAsync from '../../hooks/useAsync'
import {toastErrors} from '../../utils/request'
import PaginationWithLimit from '../pagination-with-limit'
import TransactionsTable from './TransactionsTable'

function TransactionsHistory() {
  const {run, isLoading, data} = useAsync()
  const {page, limit, onPageChange, onLimitChange} = usePagination()
  const {searchQuery, onSearchChange} = useSearch(onPageChange)

  const {addToast} = useToasts()
  const [lang] = useLanguage()

  React.useEffect(() => {
    console.log({page, limit, searchQuery})
    if (page && limit && searchQuery !== undefined) {
      console.log({page, limit, searchQuery})
      run(
        authAxios.get(
          `/sahl/history?page=${page}&limit=${limit}&q=${searchQuery}`,
        ),
      ).catch(toastErrors(addToast, lang))
    }
  }, [run, addToast, lang, page, limit, searchQuery])

  return (
    <div>
      <TransactionsTable
        isLoading={isLoading}
        data={data?.data}
        onSearchChange={onSearchChange}
      />

      <PaginationWithLimit
        totalPages={data?.totalPages}
        page={page}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  )
}

export default TransactionsHistory
