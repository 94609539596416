import {authAxios} from '../config/axiosConfig'

export const createNewShift = shiftData => {
  return authAxios.post('/shifts/createShift', shiftData)
}

// Get all shifts
export const getAllShifts = () => {
  return authAxios.get('/shifts/allShifts')
}

// Get employees by ShiftID
export const getEmployeesByShiftId = shiftId => {
  return authAxios.get('/shifts/getEmployeesByShiftId/' + shiftId)
}

// Add employees for normal shifts
export const addEmployeesForShifts = employees => {
  return authAxios.post('/shifts/addEmployees/', employees)
}

// Edit Shift
export const editShift = shiftData => {
  return authAxios.patch('/shifts/editShift', shiftData)
}

// Delete Shift
export const deleteShift = shiftData => {
  return authAxios.patch('/shifts/deleteShift', shiftData)
}
