import {authAxios} from '../config/axiosConfig'

// Get all user notifications
export const myNotifications = () => {
  return authAxios.get('/notifications/myNotifications')
}

// Set Read Message
export const setReadMessage = messageId => {
  return authAxios.patch('/notifications/setReadMessage', messageId)
}
