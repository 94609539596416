export const content = {
  en: {
    greeting: 'Welcome to ASAS',
    loginWith: 'Please login to your account by phone number or email address',
    loginByEmail: 'Login with Email address',
    loginByPhone: 'Login with Phone Number',
    phone: 'Phone Number',
    email: 'Email address',
    password: 'Password',
    confirmPassword: 'Confirm password',
    phoneLoginMessage: 'Please enter your account phone number.',
    forgetPassword: 'Forget your password ?',
    resetPassword: 'Reset Password',
    login: 'Login',
    signup: 'Register',
    next: 'Next',
    editShift: 'Edit Shift',
    isMaster: 'Managerial responsibilities',
    careerLevel: 'Career level',
    startMeeting: 'Start Meeting',
    department: 'Department',
    subDepartment: 'Sub-Department',
    haveAccount: 'Have account?',
    noAccount: "Don't have account?",
    jobRole: 'Job Role',
    salary: 'Basic Salary',
    vacations: 'Vacations',
    performance: 'Performance',
    meetings: 'Meetings',
    employeeDegree: 'Degree',
    passwordLink:
      'Please enter your account email address to send reset password link',
    send: 'Send',
    resetP: 'Reset Password',
    resetPassText: 'Please enter your password to reset',
    addPerformance: 'Add performance',
    monthlyPerformance: 'Monthly Performance',
    annuallyPerformance: 'Annually Performance',
    chooseEmployee: 'Choose Employee',
    approve: 'Approve',
    reject: 'Reject',
    addResponsibility: 'Add Responsibility',
    exUser: 'External User',
    user: 'User',
    congrads: 'Congratulations!',
    approvalContent:
      'You have been registered successfully, Waiting approval from admin',
    waitinaApproval: 'Waiting Approval',
    waitinaApprovalContent:
      'Your Account is under review, please contact administrative section for any inquiry.',

    // Verify phone code
    verificationCode: 'Verification Code',
    verifyAccount: 'Verify your account',
    verifyMessage:
      'Verify your account phone number by entering the received verification code on your phone number provided',

    // Register page
    createAccount: 'Create new account',
    registrationDetails:
      'Please enter your account details for registraton process',
    firstName: 'First name',
    lastName: 'Last name',
    firstNameAR: 'First name in Arabic',
    lastNameAR: 'Last name in Arabic',
    avgRate: 'Average Rate',

    // Job Roles
    it: 'Information Technology',
    hr: 'Human Resources',
    manager: 'Manager',
    secretary: 'Secretary',
    noAccess: 'You have no permission to perform this request',
    // Validation error messages

    invalidPassword: "Password doesn't match",
    invalidPassContent: 'Please enter password and confirm it correctly',
    invalidEmail: 'Invalid Email address',
    invalidEmailContent: 'Please enter a valid email address',
    emptyField: 'Please fill the form',
    emptyFieldContent: 'The form is empty please enter the valid information',
    invalidPhone: 'Invalid Phone number',
    invalidPhoneContent: 'Please enter a valid phone number',
    invalidVerify: 'Invalid verification code',
    invalidVerifyContent: 'Please enter the correct verification code',
    invalidFirstName: 'First name is empty',
    invalidFirstNameContent: 'Please enter the first name',
    invalidLastName: 'Last name is empty',
    invalidLastNameContent: 'Please enter the last name',
    invalidAvatar: 'Image is empty',
    invalidAvatarContent: 'Please add your image',
    invalidIDNumber: 'ID Number is empty',
    invalidIDNumberContent: 'Please enter your ID Number',
    invalidRole: 'Role is not exist',
    invalidRoleContent: 'Please choose the account type and your role',
    invalidDepartment: 'Department is empty',
    invalidDepartmentContent:
      'Please choose your assigned department procedures',
    noUpddateRequests: 'No Update requests',
    rateEmployee: 'Rate Employee',
    monthlyRating: 'Monthly Rating',
    ratings: 'Ratings',
    ratingTitle: 'Rating Title',
    ratingTitleEN: 'Rating title in English',
    ratingTitleAR: 'Rating title in Arabic',
    ratingContent: 'Rating Content',
    ratingContentEN: 'Rating Content in English',
    ratingContentAR: 'Rating Content in Arabic',
    emptyRatings: 'There is no ratings',
    resend: 'Resend',
    resendTicket: 'Resend Ticket',
    writeReason: 'Write reason',
    reason: 'Reason',
    rejectTicket: 'Reject Ticket',
    departments: 'Departments',
    deleteMeeting: 'Delete Meeting',
    warehouse: 'Warehouse',
    medicalGases: 'Medical Gases',
    masterBuilding: 'Master Building',
    sweetener: 'Sweetener',

    shifts: 'Shifts',
    createShift: 'Create Shift',
    male: 'Male',
    female: 'Female',

    dashboard: 'Dashboard',
    employees: 'Employees',
    employee: 'Employee',
    jobTitle: 'Job title',
    idNumber: 'Identification number',
    computerNumber: 'Computer Number',
    Nationality: 'Nationality',
    search: 'Search',
    addEmployee: 'Add employee',
    requests: 'Requests',
    request: 'Request',
    permission: 'Permission request',
    permissionRequest: 'Permission request',
    updateRequest: 'Update Requests',
    type: 'Type',
    incommingRequests: 'Incomming requests',
    submitRequest: 'Submit a request',
    history: 'History',
    from: 'From',
    to: 'To',
    date: 'Date',
    time: 'Time',
    reason: 'Reason',
    moreDetails: 'More details',
    reject: 'Reject',
    accept: 'Accept',
    rejectionReason: 'Rejection Reason',
    createdAt: 'Created at',
    status: 'Status',
    approved: 'Approved',
    rejected: 'Rejected',
    inProgress: 'In Progress',
    searchEmployees:
      'Search by name, department, job title, identification number, or computer number',
    exportToPdf: 'Export to PDF',
    required: 'Required',
    tooLong: 'Too long',
    tooShort: 'Too short',
    save: 'Save',
    goBack: 'Go Back',
    add: 'Add',
    requestUpdate: 'Request Update',
    meetingTopic: 'Meeting Topic',
    meetingTopicAR: 'Meeting Topic in Arabic',
    meetingDate: 'Meeting Date',
    meetingTime: 'Meeting Time',
    meetingStatus: 'Meeting Status',
    selectRole: 'Select Role',
    meetingAgenda: 'Meeting Agenda',
    meetingLocation: 'Meeting Location',
    meetingTime: 'Meeting Time',
    print: 'Print',

    // Profile
    profile: 'Profile',
    editProfile: 'Edit Profile',
    personalInfo: 'Personal Information',
    jobInfo: 'Job Information',
    trainings: 'Qualifications / Training Courses',
    attachments: 'Attachments',
    settings: 'Settings',
    fullNameAR: 'Full name in Arabic',
    qualification: 'Qualification',
    qualifications: 'Qualifications',
    courses: 'Courses',
    qualificationName: 'Qualification Name',
    universityName: 'University Name',
    graduationYear: 'Graduation Year',
    attachedFile: 'Attached File',
    courseName: 'Course Name',
    destination: 'Destination',
    duration: 'Duration',
    rate: 'Rate',
    cvFile: 'CV File',
    residenceCard: 'ID / Residence Card',
    businessCard: 'Business Card',
    PACertificate: 'Professional Accreditation Certificate',
    CPRCertificate: 'CPR Certificate',
    add: 'Add',
    emptyQualifications: 'There is no qualification certificates',
    emptyMeetings: 'There is no meetings',
    emptyEmployees: 'There is no employees',
    started: 'Started',
    ended: 'Ended',
    excuseMeeting: 'Excuse Meeting',
    excused: 'Excused',
    decisions: 'Decisions & Advices',
    assignedEmployee: 'Assigned Employee',
    durationTime: 'Duration Time',
    attendance: 'Attendance',
    empName: 'Employee Name',
    noJobTitle: 'No Job Title',

    emptyCourses: 'There are no course certificates',
    emptyAttachments: 'Attachments tab is empty',
    addQualification: 'Add Qualification',
    addCourse: 'Add Course',
    attachFile: 'Attach File',
    updateHistory: 'Update Requests History',
    addAttachment: 'Add Attachment',
    expiryDate: 'Expiry Date',
    employeeType: 'Employee',
    pending: 'Pending',
    'in progress': 'In progress',
    completed: 'Completed',
    createdBy: 'Created By',
    startDate: 'Start Date',
    endDate: 'End Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    responsibilities: 'Responsibilities',
    createShiftsToEmployee: 'Create Shifts To Employee',

    download: 'Download',
    view: 'View',
    description: 'Description',
    meetingDescription: 'Meeting Description',

    specialization: 'Specialization',
    birthDate: 'Birth Date',
    religion: 'Religion',
    nationality: 'Nationality',
    gender: 'Gender',
    address: 'Address',
    bankAccount: 'Bank Account',
    SCFHSNumber: 'SCFHS Number',
    workPlace: 'Work Place',
    region: 'Region',
    administration: 'Administration',
    city: 'City',
    degree: 'Degree',
    rank: 'Rank',
    jobDescription: 'Job Description',
    jobType: 'Job Type',
    careerAngel: 'Career Angel',
    hiringDate: 'Hiring Date',
    contractStartDate: 'Contract Start Date',
    contractEndDate: 'Contract End Date',
    workStartDate: 'Work Start Date',
    jobNumber: 'Job Number',
    basicSalary: 'Basic Salary',
    systemRoles: 'System Responsibities',
    vacations: 'Vacations',
    employeesOnVacation: 'Employees on vacation',
    vacationType: 'Vacation type',
    details: 'Details',
    forgetP: 'Forget your password',
    FixedEnd: 'Fixed end vacation',
    VariableEnd: 'Variable end vacation',
    certainVacation: 'Certain vacation',
    Planned: 'Planned vacation',
    Compensatory: 'Compensatory vacation',
    suggestions: 'Suggestions',
    suggestion: 'Suggestion',
    selectSuggestionsToApprove: 'Select suggestions to approve',
    successfulOperation: 'Successful operation',
    firstSuggestion: 'First suggestion',
    secondSuggestion: 'Second suggestion',
    thirdSuggestion: 'Third suggestion',
    submit: 'Submit',
    leaveStatus: 'Leave status',
    remainingAnnualVacationDays: 'Remaining annual vacation days',
    alAdha: 'Al Adha',
    alFitr: 'Al Fitr',
    nationalDay: 'National Day',
    annual: 'Annual',
    remainingCompensatoryVacationDays: 'Remaining compensatory days from',
    noDaysLeftMessage: "You don't have any days left",
    noCompensatoryDaysError:
      "You can't request a compensatory vacation since you have no days left.",
    noCompensatoryDaysInstruction:
      'Please ask the secretary to add the days for you if you think you should have compensatory days from one of these vacations:  Al-Adha, Al-Fitr, or National Day.',
    variableEndVacationTypes: {
      aDeathCase: 'A death case',
      leaveDialysisAndPeriodicTreatmentSessions:
        'Leave dialysis and periodic treatment sessions',
      outdoorSportsLeave: 'Outdoor sports leave',
      indoorSportsLeave: 'Indoor sports leave',
      externalCulturalOrSocialLeave: 'External cultural or social leave',
      internalCulturalOrSocialLeave: 'Internal cultural or social leave',
      participateInReliefWork: 'Participate in relief work',
      childcareLeave: 'Childcare leave',
      specialLeave: 'Special leave',
      sickLeave: 'Sick leave',
      exceptionalLeave: 'Exceptional leave',
      emission: 'Emission',
      leaveToTakeExams: 'Leave to take exams',
      internalLeave: 'Internal leave',
      other: 'Other',
    },
    newborn: 'New born vacation',
    maternity: 'Maternity vacation',
    actions: 'Actions',
    cancel: 'Cancel',
    awaiting: 'Awaiting',
    role: 'Role',
    central: 'DPCC Director',
    errors: {
      toIsBeforeOrEqualToFrom: `'To' time can't be before or equal to 'From' time`,
      youCanOnlySelectOneSuggestion: 'You can only select one suggestion',
    },
    acceptSelectedSuggestions: 'Accept selected suggestions',
    overlappingVacations: 'Overlapping vacations',
    nOfAnnualVacation: 'Number of annual vacation days',
    nOfCanBeMigrated: 'Number of days that can be migrated',
    nOfNewbornLeave: 'Number of days for new born leave',
    nOfMaternityLeave: 'Number of days for maternity leave',
    nOfEidAlAdhaStation: 'The number of compensatory days for Al-Adha',
    nOfEidAlFitrStation: 'The number of compensatory days for Al-Fitr',
    nOfNationalDayStation: 'The number of compensatory days for National day',
    areYouSureYouWantToDelete: 'Are you sure you want to delete?',
    sahl: 'Sahl system',
    activeTransactions: 'Active transactions',
    observation: 'Observation',
    review: 'Review',
    transactionNumber: 'Transaction number',
    statement: 'Statement',
    deliveryDate: 'Delivery date',
    newConversation: 'New Conversation',
    noConversations: 'No conversations available',
    assignees: 'Assignees',
    searchTransactions: 'Search by transaction number or statement',
    newTransaction: 'New transaction',
    voiceNote: 'Voice note',
    transactionDetails: 'Transaction details',
    observers: 'Observeres',
    reviewers: 'Reviewers',
    replies: 'Replies',
    edit: 'Edit',
    note: 'Note',
    update: 'Update',
    noTransactionsAvailable: 'No active transactions',
    clickToOpen: 'Click to open',
    noAttachments: 'No attachments',
    noVoiceNote: 'No voice note',
    open: 'Open',
    stop: 'Stop',
    clear: 'Clear',
    fileName: 'File name',
    upload: 'Upload Photo',
    removalConfirm: 'Are you sure you want to remove',
    awaitingApprovalFrom: 'Awaiting approval',

    tickets: 'Technical support',
    myTickets: 'My Tickets',
    assignedTickets: 'Assigned Tickets',
    newTicket: 'New ticket',
    ticketNumber: 'Ticket number',
    ticketType: 'Ticket Type',
    requestType: 'Request Type',
    building: 'Building',
    floor: 'Floor',
    assignEmployee: 'Assign Employee',
    addComment: 'Add Comment',
    markAsCompleted: 'Mark As Completed',
    viewTicket: 'View Ticket',
    ticketCategory: 'Ticket Category',
    ticketFile: 'Ticket File',
    selectEmployees: 'Select Employees',
    select: 'Select',
    createMeeting: 'Create Meeting',
    translated: 'Translated',
    name: 'Name',
    ratingBy: 'Rating By',
    ratingFilterContent:
      "If you'll not choose the rating date, it will be submitted as the current date.",
    shiftDate: 'Shift Date',
    duties: 'Duties',
    discard: 'Discard Changes',
    shiftName: 'Shift Name',
    shiftNameAR: 'Shift Name In Arabic',
    defaultVacation: 'Default Vacation',
    assignShift: 'Assign Shift',
    resent: 'Resent',
    markAsInProgress: 'Mark as In Progress',
    resendReason: 'Resend Reason',
    rejectedReason: 'Rejected Reason',
    successRegister:
      'User has been created successfully, Waiting Approval from admin',
    master: 'Manager',
    endMeeting: 'End Meeting',
    delete: 'Delete',
    logout: 'Logout',
    noNotifications: 'No Notifications',
    permissionDenied: 'Permission Denied',
    resetLinkExpired: 'Reset Link is Expired',
    createdResetLink: 'Reset password has been generated successfully',
    resetDesc: 'Please go to the reset password link to reset your password',
    newEmployee: 'New Employee',
    error: 'Error',
    chat: 'Chat',
    transferUser: 'Transfer User',
    tasks: 'Tasks',
    contacts: 'Contacts',
    startChat: 'Start Chat',
    noMessages: 'No Messages',

    newContact: 'New contact',
    editContact: 'Edit contact',
    title: 'Title',
    company: 'Company',
    addToSystem: 'Add to system',
    notes: 'Notes',
    shiftTime: 'Shift Time',
    addEmployees: 'Add Employees',
    useDragFeature: 'Use drag and drop the selected employee to assign',
    deleteConfirmText: 'Are you sure you want to delete',
    printMeeting: 'Print Meeting',
    employeesRequests: 'Employees requests',
    online: 'Online',
    offline: 'Offline',
    activeFrom: 'Active from',
    create: 'Create',
    done: 'Done',
    deleteTaskConfirmation: 'Are you sure you want to delete this task',
    confirm: 'Confirm',
    invalidDate: 'Please enter a valid date',
    dateCannotBeBeforeToday: `Request date can't be before today`,

    // appointments
    appointments: 'Appointments',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    agenda: 'Agenda',
    today: 'Today',
    back: 'Back',
    showMore: 'Show more',
    yesterday: 'Yesterday',
    allDay: 'All day',

    departmentManagement: 'Department management',

    // internal memos
    internalMemos: 'Internal memos',
    newMemo: 'New memo',
    content: 'Content',
    referenceNumber: 'Reference number',
    ministryOfHealth: 'Ministry of health',
    departmentManager: 'Department manager',
    centerManager: 'DPCC Directorate',

    // tasks
    deadline: 'Deadline',
    comments: 'Comments',
    comment: 'Comment',
    newTask: 'New task',
    archived: 'Archived',
    hide: 'Hide',

    // permission request
    requestReason: 'Request reason',
  },

  ar: {
    greeting: 'مرحبا بكم في اساس',
    login: 'تسجيل دخول',
    signup: 'انشاء حساب',
    loginWith:
      'من فضلك اختار نوع تسجيل الدخول بالبريد الالكتروني او برقم الجوال للحساب الخاص بكم',
    loginByEmail: 'تسجيل الدخول بالبريد الالكتروني',
    loginByPhone: 'تسجيل الدخول برقم الجوال',
    phone: 'رقم الجوال',
    email: 'البريد الالكتروني',
    password: 'كلمة المرور',
    confirmPassword: 'تأكيد كلمة المرور',
    forgetPassword: 'نسيت كلمة المرور ؟',
    resetPassword: 'تغيير كلمة المرور',
    phoneLoginMessage: 'من فضلك يجب ادخال رقم الجوال المسجل في حسابك',
    next: 'التالي',
    isMaster: 'هل الحساب لمدير ادارة؟',
    department: 'قسم',
    subDepartment: 'الادارة الفرعية',
    haveAccount: 'لديك حساب؟',
    noAccount: 'ليس لديك حساب؟',
    jobRole: 'مهام الوظيفة',
    performance: 'الأداء',
    monthlyRating: 'التقييم الشهري',
    ratings: 'التقييمات',
    ratingTitle: 'عنوان التقييم',
    ratingTitleEN: 'عنوان التقييم بالإنجليزي',
    ratingTitleAR: 'عنوان التقييم بالعربي',
    ratingContent: 'محتوي التقييم',
    ratingContentEN: 'محتوي التقييم بالإنجليزي',
    ratingContentAR: 'محتوي التقييم بالعربي',
    type: 'النوع',
    ticketType: 'نوع التذكرة',
    central: 'مدير مركز',

    shifts: 'المناوبات',
    createShift: 'انشاء مناوبة',
    male: 'ذكر',
    female: 'أنثي',

    // Verify phone code
    verificationCode: 'تأكيد رقم الجوال',
    verifyAccount: 'تأكيد الحساب',
    verifyMessage:
      'لتسجيل الدجول يجب اتأكد من تفعيل رقم الجوال بإدخال ارقام التأكيد المرسلة للرقم الذي ادخلته',

    // Job Roles
    it: 'تكنولوجيا المعلومات و الصيانات',
    hr: 'تنمية بشرية',
    manager: 'مدير قسم',
    master: 'مدير اداره',
    secretary: 'سكرتير',
    noAccess: 'ليس لديك الصلاحية للعمل علي هذه المهام',
    employeeDegree: 'الدرجة',
    excuseMeeting: 'اعتذار الإجتماع',
    excused: 'اعتذرت',
    chooseEmployee: 'اختر الموظف',
    rateEmployee: 'تقييم الموظف',
    startDate: 'تاريخ البدء',
    endDate: 'تاريخ الإنتهاء',
    startTime: 'وقت البدء',
    endTime: 'وقت الإنتهاء',
    responsibilities: 'المهام',
    createShiftsToEmployee: 'انشاء مناوبة لموظف',
    approve: 'قبول',
    reject: 'رفض',
    addResponsibility: 'إضافة مهام',
    startMeeting: 'إبدأ الإجتماع',
    endMeeting: 'إنهاء الإجتماع',
    careerLevel: 'درجة الوظيفة',

    // Register page
    createAccount: 'انشاء حساب جديد',
    registrationDetails: 'من فضلك ادخل البيانات المطلوبة لإنشاء حسابك',
    firstName: 'الاسم الاول',
    lastName: 'الاسم الاخير',
    firstNameAR: 'الاسم الاول بالعربي',
    lastNameAR: 'الاسم الاخير بالعربي',

    // Validation error messages
    invalidPassword: 'كلمة المرور غير متطابقة',
    invalidPassContent: 'يجب ادخال كلمة المرور و تكون متطابقة مع التكرار',
    invalidEmail: ' البريد الالكتروني غير صحيح',
    invalidEmailContent: 'يجب ادخال البريد الالكتروني صحيحا',
    emptyField: 'لا توجد بيانات',
    emptyFieldContent: 'من فضلك ادخل البيانات المطلوبة',
    invalidPhone: 'رقم الجوال غير صحيح',
    invalidPhoneContent: 'من فضلك ادخل رقم الجوال صحيحا',
    invalidVerify: 'كود التأكيد عير صحيح',
    invalidVerifyContent: 'من فضلك ادخل كود التأكيد صحيحا',
    invalidFirstName: 'الاسم الاول غير موجود',
    invalidFirstNameContent: 'من فضلك ادخل الاسم الاول',
    invalidLastName: 'اسم اللقب غير موجود',
    invalidLastNameContent: 'من فضلك ادخل اسم اللقب',
    invalidAvatar: 'الصورة غير موجود',
    invalidAvatarContent: 'من فضلك اضف صورة للحساب لديك',
    invalidIDNumber: 'الرقم الوظيفي غير موجود',
    invalidIDNumberContent: 'من فضلك ادخل الرقم الوظيفي',
    invalidRole: 'نوع الحساب غير موجود',
    invalidRoleContent: 'من فضلك اختر نوع الحساب',
    invalidDepartment: 'الادارة غير مختارة',
    invalidDepartmentContent: 'من فضلك اختار الجهة التي تعمل معها',
    salary: 'الراتب الأساسي',
    vacations: 'الأجازات',
    meetings: 'الإجتماعات',
    noUpddateRequests: 'لا يوجد طلب تعديلات',
    forgetP: 'نسيت كلمة المرور',
    passwordLink:
      'من فضلك ادخل البريد الإلكتروني الخاص بحسابك لإرسال لينك تغيير كلمة المرور',
    resetP: 'تغيير كلمة المرور',
    resetPassText: 'من فضلك ادخل كلمة المرور لتغييرها',
    send: 'ارسال',
    dashboard: 'المنصة',
    employees: 'الموظفين',
    employee: 'الموظف',
    employeeType: 'الموظف',
    jobTitle: 'الوظيفة',
    idNumber: 'رقم الهوية',
    computerNumber: 'رقم الحاسب',
    Nationality: 'الجنسية',
    addEmployee: 'إضافة موظف',
    search: 'بحث',
    requests: 'الطلبات',
    request: 'طلب',
    permission: 'طلب إذن',
    permissionRequest: 'طلب إذن',
    updateRequest: 'طلب التعديل',
    addPerformance: 'إضافة تقييم',
    monthlyPerformance: 'التقييم الشهري',
    annuallyPerformance: 'التقييم السنوي',
    print: 'طباعة',
    delete: 'حذف',

    incommingRequests: 'الطلبات القادمة',
    submitRequest: 'تقديم طلب',
    history: 'سجل',
    from: 'من',
    to: 'إلى',
    date: 'تاريخ',
    time: 'وقت',
    reason: 'السبب',
    moreDetails: 'تفاصيل',
    reject: 'رفض',
    accept: 'قبول',
    rejectionReason: 'سبب الرفض',
    createdAt: 'تاريخ الإنشاء',
    status: 'الحالة',
    pending: 'معلق',
    'in progress': 'قيد التنفيذ',
    completed: 'مكتمل',
    approved: 'مقبول',
    rejected: 'مرفوض',
    searchEmployees:
      'إبحث بالإسم أو القسم أو المسمى الوظيفي أو رقم الهوية أو رقم الجهاز',
    exportToPdf: 'تصدير إلى PDF',
    required: 'مطلوب',
    tooLong: 'طويل للغاية',
    tooShort: 'قصير',
    save: 'حفظ',
    goBack: 'رجوع',
    add: 'إضافه',
    requestUpdate: 'طلب تعديل',
    selectRole: 'إختار مهام الحساب',
    addAttachment: 'إضافة الملف',
    expiryDate: 'تاريخ الإنتهاء',
    meetingTopic: 'اسم الإجتماع',
    meetingTopicAR: 'اسم الإجتماع بالعربي',
    meetingDate: 'تاريخ الإجتماع',
    meetingTime: 'وقت الإجتماع',
    meetingStatus: 'حالة الإجتماع',
    createdBy: 'انشاء بواسطة',
    decisions: 'القرارات و النصائح',
    assignedEmployee: 'الشخص المسؤول',
    durationTime: 'المدة الزمنية',
    attendance: 'الحضور',
    empName: 'اسم الموظف',
    noJobTitle: 'لا يوجد اسم وظيفة',
    nextMeeting: 'الإجتماع القادم',

    // Profile
    profile: 'الملف الشخصي',
    editProfile: 'تعديل الملف الشخصي',
    personalInfo: 'البيانات الشخصية',
    jobInfo: 'البيانات الوظيفية',
    trainings: 'المؤهلات / الدورات التدريبية',
    attachments: 'المرفقات',
    settings: 'الإعدادات',
    fullNameAR: 'الاسم الكامل بالعربي',
    qualification: 'المؤهل',
    qualifications: 'المؤهلات',
    qualificationName: 'اسم المؤهل',
    universityName: 'اسم الجامعة',
    graduationYear: 'سنة التخرج',
    attachedFile: 'الملف',
    rate: 'التقدير',
    courseName: 'اسم الدورة',
    destination: 'الجهة',
    duration: 'المدة',
    cvFile: 'السيرة الذاتية',
    residenceCard: 'بطاقة الأحوال / الإقامة',
    download: 'تحميل',
    view: 'مشاهدة',
    businessCard: 'بطاقة العمل',
    PACertificate: 'شهادة التصنيف المهني',
    CPRCertificate: 'شهادة الإنعاش القلبي الرئوي / الإسعافات الأولية',
    add: 'إضافة',
    description: 'تفاصيل',
    meetingDescription: 'تفاصيل الإجتماع',
    meetingAgenda: 'اجندة الإجتماع',
    meetingLocation: 'مكان الإجتماع',
    meetingTime: 'وقت الإجتماع',
    addComment: 'اضافة تعليق',
    exUser: 'موظف خارجي',
    removalConfirm: 'هل أنت متأكد من حذف',
    awaitingApprovalFrom: 'في إنتظار الموافقة',

    emptyQualifications: 'لا توجد شهادات للمؤهل',
    emptyMeetings: 'لا توجد اجتماعات',
    emptyEmployees: 'لا يوجد موظفين',
    started: 'بدأ',
    ended: 'انتهي',
    congrads: 'تم التسجيل!',
    approvalContent:
      'لقد تم تسجيل حسابك بنجاح في المنصة، بإنتظار الموافقة من القسم المختص',
    waitinaApproval: 'قيد الموافقة',
    waitinaApprovalContent:
      'هذا الحساب قيد الملاحظة ، برجاء المتابعة مع القسم المختص',

    emptyCourses: 'لا توجد شهادات للتدريبات',
    emptyAttachments: 'لا توجد مرفقات',
    addQualification: 'إضافة الشهادة',
    addCourse: 'إضافة التدريب',
    updateHistory: 'متابعة طلب التعديلات',

    attachFile: 'ارفاق ملف',

    courses: 'الدورات التدريبية',
    specialization: 'التخصص',
    birthDate: 'تاريخ الميلاد',
    religion: 'الديانة',
    nationality: 'الجنسية',
    gender: 'الجنس / النوع',
    address: 'العنوان',
    bankAccount: 'حساب البنك',
    SCFHSNumber: 'رقم الهيئة',
    workPlace: 'مكان العمل',
    region: 'المنطقة',
    administration: 'الإدارة',
    city: 'المدينة',
    degree: 'الدرجة',
    rank: 'المرتبة',
    jobDescription: 'المهام الوظيفية',
    jobType: 'نوع الوظيفة',
    careerAngel: 'الملاك الوظيفي',
    hiringDate: 'تاريخ التعيين',
    contractStartDate: 'تاريخ بداية العقد',
    contractEndDate: 'تاريخ نهاية العقد',
    workStartDate: 'تاريخ المباشرة',
    jobNumber: 'الرقم الوظيفي',
    basicSalary: 'الراتب الاساسي',
    systemRoles: 'المهام علي المنصة',
    vacations: 'الأجازات',
    employeesOnVacation: 'موظفين في إجازة',
    vacationType: 'نوع الأجازة',
    details: 'تفاصيل',
    FixedEnd: 'إجازة محددة المدة',
    VariableEnd: 'إجازة غير محددة المدة',
    certainVacation: 'إجازة معينة',
    Planned: 'إجازة مخططة',
    Compensatory: 'إجازة تعويضية',
    suggestions: 'الإقتراحات',
    suggestion: 'الإقتراح',
    successfulOperation: 'عملية ناجحة',
    firstSuggestion: 'الاقتراح الاول',
    secondSuggestion: 'الاقتراح الثاني',
    selectSuggestionsToApprove: 'حدد الاقتراحات للموافقة عليها',
    thirdSuggestion: 'الاقتراح الثالث',
    submit: 'إرسال',
    leaveStatus: 'سبب الإجازة',
    remainingAnnualVacationDays: 'أيام الإجازة السنوية المتبقية',
    alAdha: 'الأضحى',
    alFitr: 'الفطر',
    nationalDay: 'اليوم الوطني',
    annual: 'سنوية',
    remainingCompensatoryVacationDays: 'الأيام التعويضية المتبقية من',
    noDaysLeftMessage: 'ليس لديك أي أيام متبقية',
    noCompensatoryDaysError:
      'لا يمكنك طلب إجازة تعويضية لأنه لم يتبق لديك أيام.',
    noCompensatoryDaysInstruction:
      'اطلب من السكرتير أن يضيف لك الأيام إذا كنت تعتقد أنه يجب أن يكون لديك أيام تعويضية من إحدى هذه الإجازات: الأضحى أو الفطر أو العيد الوطني.',
    variableEndVacationTypes: {
      aDeathCase: 'حاله وفاة',
      leaveDialysisAndPeriodicTreatmentSessions:
        'ترك الغسيل الكلوي وجلسات العلاج الدورية',
      outdoorSportsLeave: 'إجازة الرياضة في الهواء الطلق',
      indoorSportsLeave: 'إجازة رياضية داخلية',
      externalCulturalOrSocialLeave: 'إجازة خارجية ثقافية أو اجتماعية',
      internalCulturalOrSocialLeave: 'إجازة ثقافية أو اجتماعية داخلية',
      participateInReliefWork: 'المشاركة في أعمال الإغاثة',
      childcareLeave: 'إجازة رعاية الطفل',
      specialLeave: 'اجازة خاصة',
      sickLeave: 'أجازة مرضية',
      exceptionalLeave: 'إجازة استثنائية',
      emission: 'انبعاث',
      leaveToTakeExams: 'اتركه لإجراء الامتحانات',
      internalLeave: 'إجازة داخلية',
      other: 'آخر',
    },
    newEmployee: 'إضافة موظف جديد',
    maternity: 'إجازة الأمومة',
    newborn: 'إجازة حديثي الولادة',
    actions: 'العمليات',
    cancel: 'إلغاء',
    awaiting: 'في إنتظار',
    role: 'وظيفة',
    errors: {
      toIsBeforeOrEqualToFrom: `لا يمكن أن يكون الوقت "إلى" قبل أو يساوي الوقت "من"`,
      youCanOnlySelectOneSuggestion: 'يمكنك اختيار اقتراح واحد فقط',
    },
    error: 'خطأ',
    acceptSelectedSuggestions: 'قبول الاقتراحات المختارة',
    overlappingVacations: 'الإجازات المتداخلة',
    nOfAnnualVacation: 'عدد أيام الإجازة السنوية',
    nOfCanBeMigrated: 'عدد الأيام التي يمكن ترحيلها',
    nOfNewbornLeave: 'عدد أيام إجازة المواليد الجدد',
    nOfMaternityLeave: 'عدد أيام إجازة الولادة',
    nOfEidAlAdhaStation: 'عدد الأيام التعويضية للأضحى',
    nOfEidAlFitrStation: 'عدد الايام التعويضية عن الفطر',
    nOfNationalDayStation: 'عدد الأيام التعويضية لليوم الوطني',
    areYouSureYouWantToDelete: 'هل أنت متأكد أنك تريد حذف',
    sahl: 'نظام سهل',
    activeTransactions: 'المعاملات النشطة',
    observation: 'المراقبة',
    review: 'المراجعة',
    transactionNumber: 'رقم المعاملة',
    statement: 'الموضوع',
    deliveryDate: 'تاريخ التسليم',
    assignees: 'الموظف الحالي',
    searchTransactions: 'ابحث برقم المعاملة أو الموضوع',
    newTransaction: 'معاملة جديدة',
    voiceNote: 'ملاحظة صوتية',
    transactionDetails: 'تفاصيل المعاملة',
    observers: 'الملاحظون',
    reviewers: 'المراجعون',
    warehouse: 'المخازن',
    medicalGases: 'الغازات الطبيه',
    masterBuilding: 'المبني الرئيسي',
    sweetener: 'Sweetener',

    replies: 'الردود',
    edit: 'تعديل',
    note: 'ملاحظة',
    update: 'تحديث',
    noTransactionsAvailable: 'لا توجد معاملات',
    clickToOpen: 'انقر لفتح المرفق',
    noAttachments: 'لا توجد مرفقات',
    noVoiceNote: 'لا توجد ملاحظة صوتية',
    open: 'مفتوح',
    stop: 'ايقاف',
    clear: 'مسح',
    fileName: 'اسم الملف',
    tickets: 'الدعم الفني',
    myTickets: 'تذاكري',
    assignedTickets: 'التذاكر المرفقة بي',
    ticketNumber: 'رقم التذكرة',
    newTicket: 'تذكرة جديدة',
    requestType: 'نوع الطلب',
    building: 'المبني',
    floor: 'الدور',
    successRegister: 'تم انشاء الحساب بنجاح، بإنتظار الموافقة من القسم المختص',
    assignEmployee: 'تعيين موظف',
    markAsCompleted: 'اكتمال التذكرة',
    inProgress: 'قيد التنفيذ',
    viewTicket: 'تفاصيل التذكرة',
    ticketCategory: 'تصنيف التذكرة',
    ticketFile: 'ملف التذكرة',
    selectEmployees: 'اختر موظفين',
    select: 'اختر',
    createMeeting: 'اضافة اجتماع',
    translated: 'مترجم',
    name: 'الإسم',
    ratingBy: 'التقييم بالتاريخ',
    ratingFilterContent:
      'اذا لم تقم بإختيار تاريخ التقييم، فسوف يتم اختيار التاريخ الحالي',
    shiftDate: 'تاريخ المناوبة',
    duties: 'ايام المناوبة',
    discard: 'انهاء',
    shiftName: 'اسم المناوبة',
    shiftNameAR: 'اسم المناوبة بالعربي',
    defaultVacation: 'الأجازات الرسمية',
    assignShift: 'تعيين المناوبة',
    avgRate: 'متوسط التقييم',
    emptyRatings: 'لا يوجد تقييمات',
    resend: 'إعادة ارسال',
    resendTicket: 'إعادة ارسال التذكرة',
    writeReason: 'اكتب السبب',
    reason: 'السبب',
    rejectTicket: 'رفض التذكرة',
    resent: 'تم اعادة ارسالها',
    markAsInProgress: 'مراجعة التذكرة',
    resendReason: 'سبب اعادة الإرسال',
    rejectedReason: 'سبب الرفض',
    logout: 'خروج',
    noNotifications: 'لا توجد إشعارات',
    permissionDenied: 'غير مسموح بالدخول',
    resetLinkExpired: 'انتهت صلاحية رابط إعادة تعيين كلمة المرور',
    createdResetLink: 'تم إنشاء إعادة تعيين كلمة المرور بنجاح',
    resetDesc:
      'يرجى الانتقال إلى رابط إعادة تعيين كلمة المرور لإعادة تعيين كلمة المرور الخاصة بك',
    chat: 'المحادثات',
    transferUser: 'تحويل الموظف',
    user: 'موظف',
    editShift: 'تعديل المناوبة',
    tasks: 'المهمات',
    contacts: 'جهات الاتصال',
    newContact: 'اضافة جهة اتصال جديدة',
    startChat: 'بدأ المحادثة',
    editContact: 'تعديل جهة الاتصال',
    title: 'المسمى',
    company: 'المنشأه',
    upload: 'إرفاق صورة',
    addToSystem: 'اضف الى النظام',
    notes: 'ملاحظات',
    shiftTime: 'وقت المناوبة',
    addEmployees: 'إضافة موظفين',
    useDragFeature:
      'قم بتحريك المربع لتعيين الموظف المراد تعيينه في هذه المناوبة',
    departments: 'الأقسام',
    deleteMeeting: 'حذف الإجتماع',
    deleteConfirmText: 'هل أنت متأكد من حذف',
    printMeeting: 'طباعة الإجتماع',
    tasks: 'المهمات',
    employeesRequests: 'طلبات الموظفين',
    online: 'متصل',
    offline: 'غير متصل',
    activeFrom: 'نشط من',
    newConversation: 'محادثة جديدة',
    noConversations: 'لا توجد محادثات',
    noMessages: 'لا توجد رسائل',
    create: 'انشاء',
    done: 'منتهي',
    deleteTaskConfirmation: 'هل أنت متأكد من حذف هذه المهمة؟',
    confirm: 'تأكيد',
    invalidDate: 'برجاء ادخال تاريخ صحيح',
    dateCannotBeBeforeToday: 'لا يمكن أن يكون تاريخ الطلب قبل اليوم',

    // appointments
    appointments: 'المواعيد',
    month: 'شهر',
    week: 'أسبوع',
    day: 'يوم',
    agenda: 'جدول',
    today: 'اليوم',
    back: 'السابق',
    showMore: 'المزيد',
    yesterday: 'الأمس',
    allDay: 'اليوم بالكامل',

    departmentManagement: 'إدارة القسم',

    internalMemos: 'المذكرات الداخلية',
    newMemo: 'مذكرة جديدة',
    content: 'المحتوى',
    referenceNumber: 'رقم المرجع',
    ministryOfHealth: 'وزارة الصحة',
    departmentManager: 'مدير الإدارة',
    centerManager: 'مدير المركز',

    // tasks
    deadline: 'الموعد النهائي',
    comments: 'التعليقات',
    comment: 'التعليق',
    newTask: 'مهمة جديدة',
    archived: 'مؤرشف',
    hide: 'إخفاء',

    // permission request
    requestReason: 'سبب الطلب',
  },
}
