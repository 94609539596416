import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router'
import {
  Button,
  Dimmer,
  Dropdown,
  Image,
  Loader,
  Menu,
  Popup,
  Sidebar,
  Transition,
} from 'semantic-ui-react'
import Employees from '../pages/dashboard/Employees'
import {BsPeopleCircle} from 'react-icons/bs'
import {Link, NavLink} from 'react-router-dom'
import {useLanguage} from '../context/languageContext'
import Breadcrumb from '../components/Breadcrumb'
import {content} from '../localization/content'
import React, {useContext, useEffect, useState} from 'react'
import Requests from '../pages/dashboard/Requests/Requests'
import routes from '../routes'
import PermissionRequest from '../pages/dashboard/Requests/PermissionRequest'
import ProfilePage from '../pages/dashboard/profile'
import StateContext from '../context/stateContext'
import {
  RiCloseCircleLine,
  RiDashboard3Line,
  RiFileList2Line,
  RiMenu2Line,
  RiMiniProgramLine,
  RiSettings3Line,
  RiSunFoggyLine,
  RiTeamLine,
  RiUserSettingsLine,
  RiFileUserLine,
  RiNotification3Line,
  RiContactsBookLine,
  RiCalendarCheckLine,
  RiMessageLine,
} from 'react-icons/ri'
import {BiLogOut, BiTask} from 'react-icons/bi'

import {SiClockify} from 'react-icons/si'
import {IoTicketOutline, IoChatbubblesOutline} from 'react-icons/io5'
import Dashboard from '../pages/dashboard/dashboard'
import UpdateRequest from '../pages/dashboard/Requests/updateRequests'
import Vacation from '../pages/dashboard/Vacation'
import {FiUsers} from 'react-icons/fi'
import MeetingPage from '../pages/dashboard/Meetings/meetings'
import useAsync from '../hooks/useAsync'
import {logoutUser} from '../services/authService'
import {useToasts} from 'react-toast-notifications'
import Auth from '../config/auth'
import EmployeeProfile from '../components/employee/EmployeeProfile'
import Settings from '../pages/dashboard/Settings'
import Sahl from '../pages/dashboard/Sahl'
import PrintMeeting from '../pages/dashboard/Meetings/printMeeting'
import PerformancePage from '../pages/dashboard/Performances/performance'
import RatingSection from '../pages/dashboard/Performances/ratings'
import useMediaQuery from '../hooks/use-media-query'
import TransactionDetails from '../components/sahl/TransactionDetails'
import ShiftsPage from '../pages/dashboard/Shifts/Shifts'
import CreateShiftsEmployees from '../pages/dashboard/Shifts/CreateShiftsEmployees'
import AddEmpShifts from '../components/ShiftsComponents/addEmpShifts'
import {useUser} from '../context/UserContext'
import Tickets from '../pages/dashboard/Tickets'

import {myNotifications, setReadMessage} from '../services/notificationService'
import {timeFromNow} from '../utils/date-format'
import {myProfile} from '../services/profileService'
import ChatPage from '../pages/dashboard/Chat'
import TasksPage from '../pages/dashboard/Tasks'
import {useSocket} from '../context/socket-context'
import Contacts from '../pages/dashboard/Contacts'
import EditEmployeeProfile from '../components/employee/EditEmployeeProfile'
import AppointmentsPage from '../pages/dashboard/Appointments'
import DepartmentSettings from '../components/settings/department-settings'
import Memos from '../pages/dashboard/memos'

const notificationIcons = {
  employees: RiTeamLine,
  performance: RiUserSettingsLine,
  tasks: '',
  tickets: IoTicketOutline,
  vacations: RiSunFoggyLine,
  meetings: FiUsers,
  requests: RiFileList2Line,
  sahl: RiMiniProgramLine,
  shifts: SiClockify,
  updateRequest: RiFileUserLine,
  profile: RiFileUserLine,
}

function DashboardLayout() {
  const {pathname} = useLocation()
  const [lang, setLang] = useLanguage()
  const [showSidebar, setShowSidebar] = useState(false)
  const dasbhoardLayoutContent = content[lang]
  const history = useHistory()
  const [userData, setUserData] = useState('')
  const [isCollapsed, setIsCollapsed] = React.useState(true)
  const {showModal} = useContext(StateContext)
  const {run, isLoading} = useAsync()
  const [user, setUser] = useUser()
  const parsedUser = JSON.parse(user)
  const {addToast} = useToasts()
  const [showNotification, setShowNotification] = useState(false)
  const [notificationData, setNotificationData] = useState([])
  const [updateNotification, setUpdateNotification] = useState(false)
  const [readMessagesCounts, setReadmessagesCounts] = useState(0)
  const [loading, setLoading] = useState(false)
  const [sidebarNav, setSidebarNav] = useState([])
  const [hasNewMessage, setHasNewMessage] = useState(false)

  const isSmall = useMediaQuery('(max-width: 992px)')
  const socket = useSocket()

  React.useLayoutEffect(() => {
    isSmall ? setIsCollapsed(true) : setIsCollapsed(false)
  }, [isSmall])

  useEffect(() => {
    let sidebarItems = []

    if (!Auth.isExUser()) {
      sidebarItems.push(
        {
          icon: RiDashboard3Line,
          label: 'dashboard',
          link: routes.dashboard,
        },
        {
          icon: RiTeamLine,
          label: 'employees',
          link: routes.employees,
        },
        {
          icon: RiFileList2Line,
          label: 'requests',
          link: routes.requests,
        },
        {
          icon: RiSunFoggyLine,
          label: 'vacations',
          link: routes.vacations,
        },
        {
          icon: RiUserSettingsLine,
          label: 'performance',
          link: routes.performance,
        },
        {
          icon: SiClockify,
          label: 'shifts',
          link: routes.shifts,
        },
        {
          icon: RiMiniProgramLine,
          label: 'sahl',
          link: routes.sahl,
        },
        {
          icon: IoTicketOutline,
          label: 'tickets',
          link: routes.tickets,
        },
        {
          icon: RiMessageLine,
          label: 'internalMemos',
          link: routes.internalMemos,
        },
        {
          icon: RiContactsBookLine,
          label: 'contacts',
          link: routes.contacts,
        },
      )
    }

    sidebarItems.push(
      {
        icon: FiUsers,
        label: 'meetings',
        link: routes.meetings,
      },
      {
        icon: BiTask,
        label: 'tasks',
        link: routes.tasks,
      },
    )

    if (Auth.isMaster() || Auth.isSecretary() || Auth.isAdmin())
      sidebarItems.push({
        icon: RiCalendarCheckLine,
        label: 'appointments',
        link: routes.appointments,
      })

    // just putting settings as last item in sidebar
    if ((Auth.isManagerOrHigher() || Auth.isHR()) && !Auth.isViceManager())
      sidebarItems.push({
        icon: RiSettings3Line,
        label: 'settings',
        link: routes.settings,
      })

    sidebarItems = sidebarItems.filter(function (el) {
      return (
        typeof el != 'object' || Array.isArray(el) || Object.keys(el).length > 0
      )
    })

    setSidebarNav(sidebarItems)

    let avgRate = 0
  }, [])

  useEffect(() => {
    // socket is not defined at first run :/
    socket?.on('notification', msg => {
      if (!msg.message.users.includes(Auth.getUserId())) return

      addToast(msg.message['title' + lang.toUpperCase()], {
        appearance: 'info',
      })
      if (
        msg.message.users.includes(Auth.getUserId()) &&
        msg.type === 'performance'
      ) {
        run(myProfile()).then(({data}) => {
          setUser(JSON.stringify({...user, avgRate: data.data.user.avgRate}))
        })
      }
      setUpdateNotification(prev => !prev)
    })

    socket?.on('receive message', () => {
      // only set true if window was not active
      if (!window.location.href.endsWith(`/chat`)) setHasNewMessage(true)
    })
  }, [socket])

  useEffect(() => {
    if (!user) {
      history.push('/auth/login')
      return
    }
    setUserData(parsedUser)
    document.body.classList.add('bg-gray-100')

    return () => {
      document.body.classList.remove('bg-gray-100')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    run(myNotifications())
      .then(({data}) => {
        setNotificationData(data.data)
        let counts = 0
        data.data?.messages.map(m => {
          if (!m.isRead) {
            counts += 1
          }
        })
        setReadmessagesCounts(counts)
      })
      .catch(e => {
        console.log(e)
      })
  }, [updateNotification])

  const handleNotifications = () => {
    return notificationData?.messages?.map((n, i) => {
      const NotifyIcon = notificationIcons?.[n.messageType]
      return (
        <li
          className={`p-3 ${
            n.isRead ? 'bg-gray-50' : 'bg-green-100'
          } hover:bg-gray-100 transition-colors duration-300 rounded-xl my-1 cursor-pointer`}
          onClick={() => handleOnClickNotify(n.messageType, n._id)}
          key={i}
        >
          <div
            className={`flex items-center ${
              isSmall ? 'flex-col' : 'justify-between'
            } mb-1`}
          >
            <div className={`flex items-center ${isSmall ? 'mb-2' : ''}`}>
              {NotifyIcon && (
                <NotifyIcon
                  size="25"
                  className="text-primary ltr:mr-4 rtl:ml-4"
                />
              )}

              <p
                className="text-gray-600 text-lg font-bold"
                style={{
                  width: '300px',
                }}
              >
                {n['title' + lang.toUpperCase()]}
              </p>
            </div>
            <p
              className={`text-gray-400 text-sm ${
                isSmall ? 'w-full ltr:text-right rtl:text-left' : ''
              }`}
            >
              {timeFromNow(n.createdAt, lang)}
            </p>
          </div>
          <div>
            <p
              className="text-gray-500 ltr:ml-10 rtl:mr-10"
              style={{
                width: '400px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {n['message' + lang.toUpperCase()]}
            </p>
          </div>
        </li>
      )
    })
  }

  const handleOnClickLogout = () => {
    run(logoutUser())
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        Auth.logout()
        history.push('/auth/login')
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          console.log(e)
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              history.push('/auth/login')
              return null
            }
            if (error.field === 'permissionDenied') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/requests')
              return null
            }
            return null
          })
        }
      })
  }

  const handleOnClickNotify = (type, id) => {
    const index = notificationData.messages.findIndex(obj => obj._id === id)
    if (notificationData.messages[index].isRead) {
      history.push(routes[type])
    } else {
      setLoading(true)
      run(setReadMessage({messageId: id}))
        .then(({data}) => {
          setLoading(false)

          setUpdateNotification(prev => !prev)
          history.push(routes[type])
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  return (
    <div>
      <Dimmer active={loading}>
        <Loader inverted />
      </Dimmer>
      {showSidebar && isCollapsed ? (
        <div
          className="absolute top-0 left-0 w-screen h-screen bg-black opacity-10 z-50"
          onClick={() => setShowSidebar(false)}
        ></div>
      ) : null}
      <Sidebar
        as={Menu}
        direction="left"
        icon="labeled"
        animation="overlay"
        inverted
        vertical
        visible={showSidebar || !isCollapsed}
        className={`bg-primary w-28 lg:w-24`}
        width="thin"
      >
        <div
          className="lg:hidden text-white mt-6 mb-10"
          onClick={() => setShowSidebar(prev => !prev)}
        >
          <RiCloseCircleLine size="24" className="mx-auto" />
        </div>
        <Link
          to={routes.dashboard}
          className="mx-auto mt-5 mb-10 hidden lg:block"
        >
          <img
            src={require('../assets/images/asas_logo_white.svg').default}
            alt="Asas Logo"
            width="38"
            height="38"
            className="mx-auto"
          />
        </Link>
        <div className="mt-1">
          {sidebarNav.map((route, i) => (
            <NavLink to={route.link} key={i}>
              {isCollapsed ? (
                <Menu.Item
                  key={route.link}
                  active={Array.isArray(pathname.match(route.label, 'i'))}
                >
                  <route.icon size="24" className="mx-auto mb-3 lg:mb-0" />
                  <span className="text-white text-sm">
                    {dasbhoardLayoutContent[route.label]}
                  </span>
                </Menu.Item>
              ) : (
                <Popup
                  content={dasbhoardLayoutContent[route.label]}
                  className="bg-primary text-white border-none rtl:mr-3"
                  position={'left center'}
                  on="hover"
                  trigger={
                    <Menu.Item
                      key={route.link}
                      active={Array.isArray(pathname.match(route.label, 'i'))}
                    >
                      <route.icon size="24" className="mx-auto mb-3 lg:mb-0" />
                      <span className="text-white text-sm lg:hidden">
                        {dasbhoardLayoutContent[route.label]}
                      </span>
                    </Menu.Item>
                  }
                />
              )}
            </NavLink>
          ))}
          {isSmall && (
            <Popup
              content={dasbhoardLayoutContent.logout}
              className="bg-primary text-white border-none rtl:mr-3"
              position={'left center'}
              on="hover"
              trigger={
                <Menu.Item onClick={handleOnClickLogout}>
                  <BiLogOut size="24" className="mx-auto mb-3 lg:mb-0" />
                  <span className="text-white text-sm lg:hidden">
                    {dasbhoardLayoutContent.logout}
                  </span>
                </Menu.Item>
              }
            />
          )}
        </div>
      </Sidebar>
      <nav className="text-white bg-primary px-8 py-4 relative">
        <ul className="flex items-center justify-items-center">
          <li
            className="lg:hidden"
            onClick={() => setShowSidebar(prev => !prev)}
          >
            <RiMenu2Line size="24" />
          </li>
          <li className="ltr:ml-auto rtl:mr-auto">
            <Dropdown
              className="flex items-center bg-primary border-white text-white"
              selection
              options={[
                {key: 'ar', value: 'ar', text: 'عربي'},
                {key: 'en', value: 'en', text: 'English'},
              ]}
              value={lang}
              onChange={(e, {value}) => setLang(value)}
            />
          </li>
          <li
            className="ltr:ml-8 rtl:mr-8 cursor-pointer relative"
            onBlur={() => {
              setShowNotification(false)
            }}
            onFocus={() => {
              setShowNotification(false)
            }}
            onClick={() => setShowNotification(prev => !prev)}
            tabIndex="0"
          >
            <RiNotification3Line size="20" />
            <div className="absolute -top-2 ltr:-right-2 rtl:-left-2 bg-green-500 p-2 rounded-full w-5 h-5 flex justify-center items-center">
              <p className="text-sm mb-0">{readMessagesCounts}</p>
            </div>
          </li>

          <li className="ltr:ml-8 rtl:mr-8 lg:block hidden">
            <Dropdown
              direction="left"
              trigger={
                <div className="flex flex-row items-center">
                  {userData.avatar ? (
                    <Image
                      src={userData.avatar}
                      alt="avatar"
                      className={`${
                        userData.avatar ? 'visible' : 'hidden'
                      } w-12 h-12 rounded-full mx-auto`}
                    />
                  ) : (
                    <BsPeopleCircle
                      size="32"
                      // className={`mx-auto`}
                    />
                  )}

                  <div className="flex flex-col justify-center items-center">
                    <p className="ltr:mx-3 rtl:mx-5 text-lg font-semibold">
                      {userData['firstName' + lang.toUpperCase()]}{' '}
                      {userData['lastName' + lang.toUpperCase()]}
                    </p>
                    <div
                      className="stars"
                      style={{'--rating': userData.avgRate}}
                      aria-label={
                        'Rating of this product is ' +
                        userData.avgRate +
                        ' out of 5.'
                      }
                    ></div>
                    {/* <p className="text-gray-100 mt-1 mb-0 text-xs">
                      {userData.avgRate}
                    </p> */}
                  </div>
                </div>
              }
              className="flex items-center"
            >
              <Dropdown.Menu className="mt-2 w-40 ">
                <Link to={routes.profile}>
                  <Dropdown.Item className="text-primary hover:text-black hover:bg-gray-100 transition-colors duration-300 text-center py-2">
                    {dasbhoardLayoutContent.profile}
                  </Dropdown.Item>
                </Link>
                <Dropdown.Item
                  className="text-primary hover:text-black transition-colors duration-300 text-center"
                  onClick={handleOnClickLogout}
                >
                  {dasbhoardLayoutContent.logout}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>

        {/* Notificaitons popup */}
        <Transition.Group duration={300} animation="scale">
          {showNotification && (
            <div
              className={`absolute bg-white z-10 border-2 border-gray-300 shadow-sm top-24  rounded-xl ${
                isSmall
                  ? 'ltr:right-0 rtl:left-0 w-full'
                  : 'ltr:right-5 rtl:left-5'
              }`}
              style={
                !isSmall && {
                  width: '500px',
                }
              }
            >
              <p className="text-black">{showNotification}</p>

              {notificationData?.messages?.length > 0 && (
                <ul
                  className="p-3"
                  style={{
                    height: '500px',
                    overflowY: 'auto',
                    maxHeight: '500px',
                  }}
                >
                  {handleNotifications()}
                </ul>
              )}
              {notificationData?.messages?.length === 0 ||
              notificationData?.length === 0 ? (
                <div
                  className="flex items-center justify-center"
                  style={{height: '500px'}}
                >
                  <div className="flex flex-col justify-center items-center">
                    <RiNotification3Line
                      size={30}
                      className="text-primary mb-2"
                    />
                    <p className="text-primary font-bold text-xl">
                      {dasbhoardLayoutContent.noNotifications}
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </Transition.Group>
      </nav>

      <div
        className={`lg:ltr:ml-40 lg:ltr:mr-14 lg:rtl:mr-40 lg:rtl:ml-14 ${
          showModal.modalName !== '' ? '' : 'mt-5'
        } mx-5`}
        style={{height: '100%'}}
      >
        <Breadcrumb />
        <div className="pb-10">
          <div className="bg-white shadow-md rounded-lg mt-5 px-3 py-4 sm:px-7 sm:py-8">
            <Switch>
              <Route exact path={routes.dashboard} component={Dashboard} />
              <Route
                exact
                path={routes.updateRequest}
                component={UpdateRequest}
              />
              <Route exact path={routes.employees} component={Employees} />
              <Route
                exact
                path={routes.printMeeting}
                component={PrintMeeting}
              />
              <Route
                path={routes.employeeProfile}
                component={EmployeeProfile}
              />
              <Route
                path={routes.editEmployeeProfile}
                component={EditEmployeeProfile}
              />

              {/* Requests */}
              <Route exact path={routes.requests} component={Requests} />
              <Route
                path={routes.permissionRequest}
                component={PermissionRequest}
              />
              <Route path={routes.vacations} component={Vacation} />

              <Route
                exact
                path={routes.performance}
                component={PerformancePage}
              />
              <Route path={routes.ratings} component={RatingSection} />
              <Route exact path={routes.shifts} component={ShiftsPage} />
              <Route
                path={routes.createShiftEmployees}
                component={CreateShiftsEmployees}
              />
              <Route path={routes.addEmpShifts} component={AddEmpShifts} />
              <Route exact path={routes.profile} component={ProfilePage} />
              <Route exact path={routes.meetings} component={MeetingPage} />

              {/* Sahl */}
              <Route
                exact
                path={routes.sahlTransactionDetails}
                component={TransactionDetails}
              />
              <Route path={routes.sahl} component={Sahl} />

              <Route path={routes.tasks} component={TasksPage} />
              <Route exact path={routes.tickets} component={Tickets} />
              <Route exact path={routes.contacts} component={Contacts} />
              <Route
                exact
                path={routes.appointments}
                component={AppointmentsPage}
              />
              <Route exact path={routes.settings} component={Settings} />
              <Route
                exact
                path={routes.departmentManagement}
                component={DepartmentSettings}
              />
              <Route exact path={routes.internalMemos} component={Memos} />
              <Route exact path={routes.chat} component={ChatPage} />

              <Redirect to={routes.dashboard} />
            </Switch>
          </div>
        </div>

        {!pathname.match('chat', 'i') && (
          <div
            className="bg-primary fixed bottom-10 ltr:right-10 rtl:left-10 z-50 p-4 cursor-pointer rounded-full flex justify-center items-center shadow-lg"
            onClick={() => {
              setHasNewMessage(false)
              history.push(routes.chat)
            }}
          >
            <div className="relative">
              {hasNewMessage ? (
                <span class="flex h-5 w-5 absolute -top-4 -left-4">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-5 w-5 bg-yellow-400"></span>
                </span>
              ) : null}
              <IoChatbubblesOutline size={30} className="text-white" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DashboardLayout
