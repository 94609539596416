import {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import {
  Button,
  Dimmer,
  Grid,
  Icon,
  Image,
  Loader,
  Segment,
} from 'semantic-ui-react'
import Auth from '../../../config/auth'
import {useLanguage} from '../../../context/languageContext'
import useAsync from '../../../hooks/useAsync'
import {content} from '../../../localization/content'
import {
  getAllUpdateRequests,
  setUpdateAction,
} from '../../../services/requestService'
import defaultAvatar from '../../../assets/images/icons/default_avatar.svg'
import {formatDate} from '../../../utils/date-format'
import {useSocket} from '../../../context/socket-context'

const UpdateRequest = () => {
  const [lang] = useLanguage()
  const updateRequestLang = content[lang]
  const {run, isLoading} = useAsync()
  const history = useHistory()
  const {addToast} = useToasts()
  const [updates, setUpdates] = useState([])
  const [updateRequests, setUpdateRequests] = useState(false)

  const socket = useSocket()

  useEffect(() => {
    socket?.on('notification', msg => {
      if (
        !['updateRequest'].includes(msg.type) &&
        !msg.message.users.includes(Auth.getUserId())
      )
        return
      setUpdateRequests(prev => !prev)
    })
  }, [socket])

  useEffect(() => {
    run(getAllUpdateRequests())
      .then(({data}) => {
        setUpdates(data.data)
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              history.push('/auth/login')
              return
            }
            if (error.field === 'permissionDenied') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/requests')
              return
            }
          })
        }
      })
  }, [updateRequests])

  const handleOnClick = (type, requestId) => {
    const updateAction = {
      requestStatus: type,
      requestId: requestId,
    }
    run(setUpdateAction(updateAction))
      .then(({data}) => {
        // console.log(data)
        addToast(data.message[lang], {appearance: 'success'})
        const arr = [...updates]
        const index = arr.findIndex(obj => obj._id === requestId)
        arr.splice(index, 1)
        setUpdates(arr)
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              history.push('/auth/login')
              return
            }
            if (error.field === 'permissionDenied') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/requests')
              return
            }
          })
        }
      })
  }

  return (
    <section>
      <Dimmer active={isLoading} className="fixed w-full h-full">
        <Loader inverted />
      </Dimmer>
      <h2 className="text-primary">
        {updateRequestLang.updateRequest} ({updates.length})
      </h2>

      <div className="mt-8">
        {updates.length === 0 && (
          <div className="text-center">
            <p className="text-primary font-bold text-xl">
              {updateRequestLang.noUpddateRequests}
            </p>
          </div>
        )}
        <Grid doubling columns={3}>
          <Grid.Row className="flex justify-center">
            {updates.length > 0 &&
              updates.map((ur, i) => (
                <Grid.Column key={i} className="mb-10">
                  <Segment className="border-gray-100 overflow-y-auto max-h-96">
                    <div className="flex items-center mb-6">
                      <Image
                        src={ur.createdBy?.avatar || defaultAvatar}
                        className="rounded-full w-20 h-20"
                        alt="Avatar"
                      />
                      <div className="ltr:ml-5 rtl:mr-5">
                        <p className="text-primary font-bold text-lg">
                          {ur.createdBy['firstName' + lang.toUpperCase()]}{' '}
                          {ur.createdBy['lastName' + lang.toUpperCase()]}
                        </p>
                        <p className="text-gray-400 text-center">
                          {ur.createdBy.department['name' + lang.toUpperCase()]}
                        </p>
                      </div>
                    </div>
                    <hr />

                    <div className="my-3">
                      <p className="font-semibold mt-4 text-primary text-center italic">
                        This user wants to update these fields
                      </p>
                    </div>

                    <div className="my-5">
                      <ul>
                        {ur.department && (
                          <li className="p-3 mb-2 flex flex-col">
                            <div className="flex items-center">
                              <Icon
                                name="circle"
                                className="text-primary ltr:mr-3 rtl:ml-3"
                                size="small"
                              />
                              <p className="font-semibold text-lg">
                                {updateRequestLang.department}:{' '}
                              </p>
                            </div>
                            <div className="text-lg ltr:ml-4 rtl:mr-4">
                              <p className="ltr:ml-2 rtl:mr-2 text-gray-500">
                                {ur.department['name' + lang.toUpperCase()]}
                              </p>
                            </div>
                          </li>
                        )}

                        {Object.keys(ur.updateRequestData).map(
                          (requestsData, j) => {
                            return (
                              <li className="p-3 mb-2 flex flex-col" key={j}>
                                <div className="flex items-center">
                                  <Icon
                                    name="circle"
                                    className="text-primary ltr:mr-3 rtl:ml-3"
                                    size="small"
                                  />
                                  <p className="font-semibold text-lg">
                                    {updateRequestLang[requestsData]}:{' '}
                                  </p>
                                </div>
                                <div className="text-lg ltr:ml-4 rtl:mr-4">
                                  <p className="ltr:ml-2 rtl:mr-2 text-gray-500">
                                    {requestsData === 'jobTitle'
                                      ? ur.updateRequestData.jobTitle[
                                          'name' + lang.toUpperCase()
                                        ]
                                      : requestsData === 'employeeType'
                                      ? ur.updateRequestData.employeeType[
                                          'name' + lang.toUpperCase()
                                        ]
                                      : requestsData === 'hiringDate' ||
                                        requestsData === 'contractStartDate' ||
                                        requestsData === 'contractEndDate' ||
                                        requestsData === 'workStartDate'
                                      ? formatDate(
                                          ur.updateRequestData[requestsData],
                                        )
                                      : ur.updateRequestData[requestsData]}
                                  </p>
                                </div>
                              </li>
                            )
                          },
                        )}
                      </ul>
                    </div>

                    <div className="mt-3 text-center">
                      <Button
                        content="Accept"
                        className="btn-primary"
                        onClick={() => handleOnClick('Approved', ur._id)}
                      />
                      <Button
                        content="Reject"
                        className="btn-danger"
                        onClick={() => handleOnClick('Rejected', ur._id)}
                      />
                    </div>
                  </Segment>
                </Grid.Column>
              ))}
          </Grid.Row>
        </Grid>
      </div>
    </section>
  )
}

export default UpdateRequest
