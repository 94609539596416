import {
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Inject,
  Day,
  Month,
  Week,
  WorkWeek,
  Agenda,
} from '@syncfusion/ej2-react-schedule'
import moment from 'moment'
import React, {useState} from 'react'

const AsasScheduleComponent = React.memo(
  ({isUpdated, onClickEvent, scheduleData}) => {
    const [currentEventDate, setCurrentEventDate] = useState(new Date())
    return (
      <ScheduleComponent
        width="100%"
        height="550px"
        selectedDate={currentEventDate}
        currentView="Month"
        showQuickInfo={false}
        showTimeIndicator={false}
        cellDoubleClick={false}
        delayUpdate={true}
        eventRendered={args => {
          args.element.style.backgroundColor = args.data.Color
        }}
        eventClick={e => {
          setCurrentEventDate(moment.utc(e.event.StartTime).toDate().toString())
          onClickEvent(e)
        }}
        eventSettings={{dataSource: scheduleData}}
      >
        <ViewsDirective>
          <ViewDirective option="Month"></ViewDirective>
          {/* <ViewDirective option="Agenda"></ViewDirective> */}
        </ViewsDirective>
        <Inject services={[Day, Month, Week, WorkWeek, Agenda]} />
      </ScheduleComponent>
    )
  },
)

export default AsasScheduleComponent
