module.exports = {
  main: '/',
  login: '/auth/login',
  dashboard: '/dashboard',
  employees: '/employees',
  employeeProfile: '/employees/details',
  editEmployeeProfile: '/employees/edit',
  requests: '/requests',
  vacations: '/vacations',
  permissionRequest: '/requests/permission',
  updateRequest: '/requests/updateRequest',
  profile: '/profile',
  editProfile: '/profile/editProfile',
  performance: '/performance',
  ratings: '/performance/ratings',
  meetings: '/meetings',
  sahl: '/sahl',
  sahlTransactionDetails: '/sahl/transaction/:id',
  settings: '/settings',
  printMeeting: '/meetings/printMeeting',
  shifts: '/shifts',
  createShiftEmployees: '/shifts/createShiftToEmployees',
  addEmpShifts: '/shifts/addEmployees',
  tickets: '/tickets',
  tasks: '/tasks',
  chat: '/chat',
  contacts: '/contacts',
  appointments: '/appointments',
  departmentManagement: '/settings/department-management',
  internalMemos: '/memos',
}
