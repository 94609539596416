import React from 'react'
import {authAxios} from '../config/axiosConfig'
import {useLanguage} from '../context/languageContext'
import {getUserFullname} from '../utils/user'
import useAsync from './useAsync'

/**
 * Get the employees in a form that semantic ui dropdown will understand
 *
 * @example const {employees} = useEmployeesDropDown('department id')
 * @param {ObjectId} departmentId the department to get employees for
 * @returns { employees, gettingEmployees, errorGettingEmployees, getEmployees }
 */
function useEmployeesDropDown({
  departmentId = '',
  runImmediately = true,
  includeSubDepartments = false,
  includeManager = false,
}) {
  const [employees, setEmployees] = React.useState()
  const [lang] = useLanguage()

  const {run, error, isLoading} = useAsync()

  const getEmployees = React.useCallback(
    async (id = departmentId) => {
      run(
        authAxios.get(
          `/departments/employees/${id}?includeSubDepartments=${
            includeSubDepartments || ''
          }&includeManager=${includeManager || ''}`,
        ),
      ).then(({data}) => {
        let employees = []

        data?.data?.forEach(e =>
          employees.push({
            key: e._id,
            value: e._id,
            text: getUserFullname(e, lang),
          }),
        )
        setEmployees(employees)
      })
    },
    [departmentId, lang, run],
  )

  React.useEffect(() => {
    if (runImmediately) getEmployees(departmentId)
  }, [run, departmentId, getEmployees, runImmediately])

  return {
    employees,
    gettingEmployees: isLoading,
    errorGettingEmployees: error,
    getEmployees,
  }
}

export default useEmployeesDropDown
