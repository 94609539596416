import {Button, Form, Grid, Icon, Image, TextArea} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import userIcon from '../../../assets/images/user.svg'
import {DateInput, TimeInput} from 'semantic-ui-calendar-react'
import {Fragment, useEffect, useState} from 'react'
import {formatDate, formatTime, stringToDate} from '../../../utils/date-format'
import borderIcon from '../../../assets/images/ic_border.svg'
import moment from 'moment'
import useAsync from '../../../hooks/useAsync'
import {getAllEmployees} from '../../../services/dashboardService'
import {createNewShift} from '../../../services/shiftService'
import {useToasts} from 'react-toast-notifications'
import Auth from '../../../config/auth'
import {useHistory} from 'react-router'
import routes from '../../../routes'

const CreateShiftsEmployees = () => {
  const [lang] = useLanguage()
  const createLang = content[lang]
  const {run, isLoading} = useAsync()
  const [allEmployees, setAllEmployees] = useState([])
  const {addToast} = useToasts()
  const history = useHistory()
  const [state, setState] = useState({
    employeeId: '',
    dateFrom: '',
    dateTo: '',
    dutyDays: [],
    timeFrom: '',
    timeTo: '',
    responsibilities: '',
  })

  useEffect(() => {
    if (!Auth.isManagerOrHigher()) {
      history.push(routes.shifts)
      return
    }
    run(getAllEmployees())
      .then(({data}) => {
        // console.log(data)
        setAllEmployees(data.data)
      })
      .catch(e => {
        console.log(e)
      })
  }, [])

  const handleOnChangeTime = (value, type) => {
    setState({...state, ['time' + type]: moment(value, 'hh:mm A').format()})
  }

  const handleOnChangeCheckbox = (value, checked) => {
    let arr = [...state.dutyDays]
    if (checked) {
      arr.push(value)
    } else {
      const i = arr.findIndex(o => o === value)
      arr.splice(i, 1)
    }
    setState({...state, dutyDays: arr})
  }

  const handleOnSubmit = () => {
    const newEmpShift = {
      employees: [state.employeeId],
      startDate: state.dateFrom,
      endDate: state.dateTo,
      dutyDays: state.dutyDays,
      startTime: state.timeFrom,
      endTime: state.timeTo,
      responsibilities: state.responsibilities,
      shiftType: 'employeeShift',
    }
    run(createNewShift(newEmpShift))
      .then(({data}) => {
        // console.log(data.data)
        addToast(data.message[lang], {appearance: 'success'})
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <section>
      <div className="flex items-center justify-between">
        <h2 className="text-primary m-0">
          {createLang.createShiftsToEmployee}
        </h2>
      </div>
      <div className="mt-5">
        <Grid doubling columns={2}>
          <Grid.Row>
            <Grid.Column>
              <div
                className="border-2 overflow-y-auto border-gray-200 rounded-lg p-3"
                style={{height: '500px', maxHeight: '500px'}}
              >
                {isLoading && (
                  <div className="bg-white h-full flex justify-center items-center">
                    <Icon
                      name="asterisk"
                      size="large"
                      className="text-primary"
                      loading
                    />
                  </div>
                )}

                {!isLoading && (
                  <Fragment>
                    {allEmployees.length > 0 &&
                      allEmployees.map((emp, i) => (
                        <div
                          key={i}
                          className={`${
                            emp.isVacation !== '' ? 'bg-red-100' : 'bg-gray-100'
                          } rounded-lg mb-2 p-2 flex items-center hover:bg-gray-200 transition-colors duration-300 cursor-pointer ${
                            emp.user._id === state.employeeId
                              ? 'bg-gray-200'
                              : ''
                          }`}
                          onClick={() =>
                            setState({
                              ...state,
                              dutyDays: [],
                              responsibilities: '',
                              dateFrom: '',
                              dateTo: '',
                              timeFrom: '',
                              timeTo: '',
                              employeeId: emp?.user?._id,
                            })
                          }
                        >
                          <div className="ltr:mr-4 rtl:ml-4 w-12">
                            <Image
                              src={emp?.user?.avatar || userIcon}
                              className="rounded-full w-12 h-12"
                              alt="user image"
                            />
                          </div>
                          <div>
                            <p className="text-gray-800 font-bold">
                              {emp?.user?.['firstName' + lang.toUpperCase()]}{' '}
                              {emp?.user?.['lastName' + lang.toUpperCase()]}
                            </p>
                            <p className="text-gray-500">
                              {emp?.jobTitle?.['name' + lang.toUpperCase()]}
                            </p>
                            {emp?.isVacation !== '' && (
                              <p>
                                Requested vacation from{' '}
                                {formatDate(emp?.isVacation?.start)} to{' '}
                                {formatDate(emp?.isVacation?.end)}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                  </Fragment>
                )}
              </div>
            </Grid.Column>
            {state.employeeId !== '' && (
              <Grid.Column>
                <Form loading={isLoading}>
                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <Form.Group widths="equal">
                          <Form.Field>
                            <DateInput
                              placeholder={createLang.date}
                              label={createLang.date}
                              value={formatDate(state.dateFrom)}
                              onChange={(e, {value}) =>
                                setState({
                                  ...state,
                                  dateFrom: stringToDate(value),
                                  dateTo: stringToDate(value),
                                })
                              }
                            />
                          </Form.Field>
                          {/* <Form.Field>
                            <DateInput
                              placeholder={createLang.endDate}
                              label={createLang.endDate}
                              value={formatDate(state.dateTo)}
                              onChange={(e, {value}) =>
                                setState({
                                  ...state,
                                  dateTo: stringToDate(value),
                                })
                              }
                            />
                          </Form.Field> */}
                        </Form.Group>
                      </div>
                    </div>
                  </Form.Field>

                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <Form.Group widths="equal">
                          <Form.Field>
                            <TimeInput
                              timeFormat="AMPM"
                              placeholder={createLang.startTime}
                              label={createLang.startTime}
                              value={formatTime(state.timeFrom)}
                              onChange={(e, {value}) =>
                                handleOnChangeTime(value, 'From')
                              }
                            />
                          </Form.Field>
                          <Form.Field>
                            <TimeInput
                              timeFormat="AMPM"
                              placeholder={createLang.endTime}
                              label={createLang.endTime}
                              value={formatTime(state.timeTo)}
                              onChange={(e, {value}) =>
                                handleOnChangeTime(value, 'To')
                              }
                            />
                          </Form.Field>
                        </Form.Group>
                      </div>
                    </div>
                  </Form.Field>

                  {/* <Form.Field>
                    <label className="text-lg">{createLang.duties}</label>
                    <Grid doubling columns={3} className="mt-1">
                      <Grid.Row>
                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Friday')}
                              label="Friday"
                              value="Friday"
                              // defaultChecked={state.days.includes('Friday')}
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Saturday')}
                              label="Saturday"
                              // defaultChecked={state.days.includes('Saturday')}
                              value="Saturday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Sunday')}
                              label="Sunday"
                              value="Sunday"
                              // defaultChecked={state.dutyDays.includes('Sunday')}
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Monday')}
                              // defaultChecked={state.dutyDays.includes('Monday')}

                              label="Monday"
                              value="Monday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Tuesday')}
                              // defaultChecked={state.dutyDays.includes('Tuesday')}

                              label="Tuesday"
                              value="Tuesday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Wednesday')}
                              // defaultChecked={state.dutyDays.includes('Wednesday')}
                              label="Wednesday"
                              value="Wednesday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column className="mb-2">
                          <Form.Field>
                            <Form.Checkbox
                              checked={state.dutyDays.includes('Thursday')}
                              // defaultChecked={state.dutyDays.includes('Thursday')}
                              label="Thursday"
                              value="Thursday"
                              onChange={(e, {value, checked}) =>
                                handleOnChangeCheckbox(value, checked)
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field> */}

                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <label className="font-bold text-primary">
                          {createLang.responsibilities}
                        </label>
                        <TextArea
                          placeholder={createLang.responsibilities}
                          className="mt-2"
                          value={state.responsibilities}
                          onChange={(e, {value}) =>
                            setState({...state, responsibilities: value})
                          }
                        ></TextArea>
                      </div>
                    </div>
                  </Form.Field>

                  <div className="text-center mt-3">
                    <Button
                      content={createLang.assignShift}
                      onClick={handleOnSubmit}
                      className="btn-primary"
                    />
                  </div>
                </Form>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    </section>
  )
}

export default CreateShiftsEmployees
