import React from 'react'
import {Button, Form} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import useAsync from '../../hooks/useAsync'
import {authAxios} from '../../config/axiosConfig'
import moment from 'moment'
import {useToasts} from 'react-toast-notifications'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormikControl from '../formik/FormikControl'
import {formatDate} from '../../utils/date-format'
import FileAttachment from '../FileAttachment'

function NewPermissionRequest({handleNewPermission}) {
  const [attachmentData, setAttachmentData] = React.useState(null)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const permissionSchema = Yup.object({
    from: Yup.string().required(selectedContent.required),
    to: Yup.string().required(selectedContent.required),
    date: Yup.date()
      .typeError(selectedContent.invalidDate)
      .min(new Date().toDateString())
      .required(selectedContent.required),
    reason: Yup.string()
      .required(selectedContent.required)
      .min(3, selectedContent.tooShort)
      .max(1000, selectedContent.tooLong),
  })

  const createPermisison = (values, {resetForm}) => {
    const {date, from, to, reason} = values
    let modifiedFrom = moment(
      `${formatDate(date)} ${from}`,
      'DD-MM-YYYY hh:mm A',
    ).format()
    let modifiedTo = moment(
      `${formatDate(date)} ${to}`,
      'DD-MM-YYYY hh:mm A',
    ).format()

    if (Date.parse(modifiedTo) < Date.parse(modifiedFrom)) {
      return addToast(selectedContent.errors.toIsBeforeOrEqualToFrom, {
        appearance: 'error',
      })
    }

    const data = new FormData()

    data.append('start', modifiedFrom)
    data.append('end', modifiedTo)
    data.append('date', date)
    data.append('reason', reason)

    if (attachmentData) data.append('attachment', attachmentData)

    run(authAxios.post('/requests/permissions', data))
      .then(() => {
        handleNewPermission()
        resetForm({})
        setAttachmentData(null)
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
      })
      .catch(e => {
        e?.errors.map(error =>
          addToast(error.message[lang], {appearance: 'error'}),
        )
      })
  }

  return (
    <section>
      <Formik
        initialValues={{from: '', to: '', date: '', reason: ''}}
        validationSchema={permissionSchema}
        onSubmit={createPermisison}
      >
        {formik => (
          <Form
            className="max-w-xl mx-auto mt-8"
            onSubmit={formik.submitForm}
            loading={isLoading}
            autocomplete="off"
          >
            <FormikControl
              name="from"
              label={selectedContent.from}
              control="time"
              className="my-2"
            />
            <FormikControl
              name="to"
              label={selectedContent.to}
              control="time"
              className="my-2"
            />
            <FormikControl
              name="date"
              label={selectedContent.date}
              control="date"
              className="my-2"
            />
            <FormikControl
              name="reason"
              label={selectedContent.reason}
              control="textarea"
              className="mt-2"
            />
            <FileAttachment setAttachmentData={setAttachmentData} />
            <Button type="submit" className="btn-primary mt-5 block w-100">
              {selectedContent.submit}
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  )
}

export default NewPermissionRequest
