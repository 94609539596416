import {Fragment, useState} from 'react'
import {Button, Form, Icon} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const EmailForm = ({errorContent, nextStep}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [lang] = useLanguage()
  const emailContent = content[lang]
  const [state, setState] = useState({
    email: '',
    password: '',
    errorType: '',
  })

  const handleOnClickSubmit = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (state.email === '' || state.password === '') {
      errorContent({
        isError: true,
        errorType: 'all',
      })
      setState({...state, errorType: 'all'})
      return
    }
    nextStep({
      step: 'email',
      value: {email: state.email, password: state.password, type: 'email'},
    })
  }
  return (
    <Fragment>
      <Form.Field>
        <label className="auth-form-label">{emailContent.email}</label>
        <div className="rtl-input">
          <Form.Input
            placeholder={emailContent.email}
            type="email"
            onChange={(e, {value}) => setState({...state, email: value})}
            error={
              state.errorType === 'email' || state.errorType === 'all'
                ? true
                : false
            }
          />
        </div>
      </Form.Field>

      <Form.Field className="auth-password-input">
        <label className="auth-form-label">{emailContent.password}</label>
        <Form.Input
          placeholder="*******"
          type={showPassword ? 'text' : 'password'}
          icon={
            <Icon
              name={showPassword ? 'eye slash' : 'eye'}
              onClick={() => setShowPassword(prev => !prev)}
              circular
              link
            />
          }
          onChange={(e, {value}) => setState({...state, password: value})}
          error={
            state.errorType === 'password' || state.errorType === 'all'
              ? true
              : false
          }
        />
      </Form.Field>

      <div className="auth-action">
        <Button
          content={emailContent.login}
          className="btn-primary"
          onClick={handleOnClickSubmit}
        />
      </div>
    </Fragment>
  )
}

export default EmailForm
