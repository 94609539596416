import {Icon, Rating} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'

const MonthlyPerformance = ({ratings}) => {
  const [lang] = useLanguage()
  const segmentLang = content[lang]

  console.log({ratings})

  return (
    <div className="my-3">
      <div className="ltr:ml-10 rtl:mr-10">
        <ul>
          <li className="flex items-center mb-3">
            <Icon name="circle" size="large" className="text-primary" />
            <p className="text-lg ltr:ml-3 rtl:mr-3">
              <strong className="text-goldPrimary">{segmentLang.name}:</strong>{' '}
              <span className="ltr:ml-2 rtl:mr-2 text-gray-500">
                {ratings.ratings.user['firstName' + lang.toUpperCase()]}{' '}
                {ratings.ratings.user['lastName' + lang.toUpperCase()]}
              </span>
            </p>
          </li>
          <li className="flex items-center mb-3">
            <Icon name="circle" size="large" className="text-primary" />
            <p className="text-lg ltr:ml-3 rtl:mr-3">
              <strong className="text-goldPrimary">
                {segmentLang.createdBy}:
              </strong>{' '}
              <span className="ltr:ml-2 rtl:mr-2 text-gray-500">
                {ratings.ratings.createdBy['firstName' + lang.toUpperCase()]}{' '}
                {ratings.ratings.createdBy['lastName' + lang.toUpperCase()]}
              </span>
            </p>
          </li>

          <li className="">
            <div className="flex items-center">
              <Icon name="circle" size="large" className="text-primary" />
              <p className="text-lg ltr:ml-3 rtl:mr-3">
                <strong className="text-goldPrimary">
                  {segmentLang.ratings}:
                </strong>
              </p>
            </div>
            <div className="ltr:ml-10 rtl:mr-10 my-3">
              {ratings.ratings.ratingData.map((r, i) => (
                <div key={i}>
                  <div className="flex items-center mb-2">
                    <p className="text-lg ltr:ml-3 rtl:mr-3">
                      <strong className="text-goldPrimary">
                        {r['name' + lang.toUpperCase()]}:
                      </strong>{' '}
                      {/* <span className="ltr:ml-2 rtl:mr-2 text-gray-500">
                      {r.totalRate}
                    </span> */}
                    </p>
                  </div>
                  <div>
                    {r.fields.map(f => (
                      <div key={f.nameEN} className="flex items-center">
                        <Icon
                          name="circle notch"
                          className="text-lg -mt-2 text-primary"
                        />
                        <p className="text-lg ltr:ml-3 rtl:mr-3">
                          <strong className="text-goldPrimary">
                            {f['name' + lang.toUpperCase()]}:
                          </strong>{' '}
                          <Rating
                            icon="star"
                            rating={f.rating}
                            maxRating={5}
                            disabled
                          />
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MonthlyPerformance
