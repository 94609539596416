import {Fragment, useState} from 'react'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import {Button, Form} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import useAsync from '../../../hooks/useAsync'
import {content} from '../../../localization/content'

const PhoneStep = ({nextStep, errorContent, values}) => {
  const [lang] = useLanguage()
  // console.log(values.phoneNumber)
  const [state, setState] = useState({
    phoneNumber: values.phoneNumber || '',
    countryCode: '',
    error: false,
  })
  const phoneContent = content[lang]

  const handleOnChangePhone = (isValid, phone, country) => {
    // console.log(phone, state.countryCode)
    setState({
      ...state,
      phoneNumber: phone.replace(' ', ''),
      countryCode: country.dialCode,
    })
    // '+' + country.dialCode + phone?.replace(`+${country.dialCode}`, '')
  }

  const handleOnClickSubmit = () => {
    if (state.phoneNumber === '') {
      errorContent({
        isError: true,
        errorType: 'phone',
      })
      return setState({...state, error: true})
    }
    errorContent({isError: false, errorType: ''})
    // console.log(state.phoneNumber, state.countryCode)
    nextStep({
      step: 3,
      type: 'step',
      data: {
        phoneNumber: state.phoneNumber.replace(' ', ''),
      },
    })
  }

  return (
    <Fragment>
      <Form.Field>
        <label className="text-primary">{phoneContent.phone}</label>
        <IntlTelInput
          containerClassName="intl-tel-input w-100 main-font"
          inputClassName="ltr:pl-14 rtl:pl-0 rtl:pr-14 main-font"
          nationalMode={false}
          autoFocus={true}
          value={state.phoneNumber}
          onPhoneNumberChange={(isValid, phone, country) =>
            handleOnChangePhone(isValid, phone, country)
          }
        />
      </Form.Field>

      <div className="my-5 text-center">
        <Button
          content={phoneContent.next}
          className="btn-primary"
          onClick={handleOnClickSubmit}
        />
      </div>
    </Fragment>
  )
}

export default PhoneStep
