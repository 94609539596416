import {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import {Button, Form, Grid, Icon, Image} from 'semantic-ui-react'
import Auth from '../../../config/auth'
import {useLanguage} from '../../../context/languageContext'
import useAsync from '../../../hooks/useAsync'
import {useQuery} from '../../../hooks/useQuery'
import {content} from '../../../localization/content'
import {
  getJobTitlesByUserId,
  getMeetingById,
  printMeetingReport,
} from '../../../services/meetingService'
import borderIcon from '../../../assets/images/ic_border.svg'
import {DateInput, TimeInput} from 'semantic-ui-calendar-react'
import {formatDate, formatTime} from '../../../utils/date-format'
import fileDownload from 'js-file-download'

const PrintMeeting = () => {
  const query = useQuery()
  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()
  const history = useHistory()
  const [lang] = useLanguage()
  const [employees, setEmployees] = useState([])
  const [addAgenda, setAddAgenda] = useState(1)
  const [addDecision, setAddDecision] = useState(1)
  const [addAttendance, setAddAttendance] = useState(1)
  const [nextMeeting, setNextMeeting] = useState(1)
  const [jobNames, setJobNames] = useState([])
  const printLang = content[lang]
  const [state, setState] = useState({
    meetingTopic: '',
    meetingDate: '',
    meetingTime: '',
    meetingLocation: '',
    createdByEN: '',
    createdByAR: '',
    meetingAgenda: {},
    decisions: [],
    departments: [],
    attendance: [],
    nextMeeting: [],
  })

  useEffect(() => {
    if (!query.get('id')) {
      history.push('/meetings')
      addToast('Permission denied', {appearance: 'error'})
      return
    }

    run(getMeetingById(query.get('id')))
      .then(({data}) => {
        // console.log(data)
        let empData = []
        let departmentData = []
        data.data.employees.map(emp => {
          if (!emp.isExcuse) {
            empData.push({
              key: emp.empId._id,
              text:
                emp.empId['firstName' + lang.toUpperCase()] +
                ' ' +
                emp.empId['lastName' + lang.toUpperCase()],
              value: emp.empId['firstNameAR'] + ' ' + emp.empId['lastNameAR'],
            })
          }
        })
        data.data.department.map(department => {
          departmentData.push(department['nameAR'])
        })

        setState({
          ...state,
          meetingAgenda: data.data.meetingAgenda,
          departments: departmentData,
          meetingTopic:
            data.data.meetingTopicAR === ''
              ? data.data.meetingTopic
              : data.data.meetingTopicAR,
          meetingDate: formatDate(data.data.meetingDate),
          meetingTime: formatTime(data.data.meetingTime),
          meetingLocation: data.data.meetingLocation,
          createdByEN:
            data.data.createdBy.firstNameEN +
            ' ' +
            data.data.createdBy.lastNameEN,
          createdByAR:
            data.data.createdBy.firstNameAR +
            ' ' +
            data.data.createdBy.lastNameAR,
        })
        setAddAgenda(Object.keys(data.data.meetingAgenda).length)
        setEmployees(empData)
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/auth/login')
              return
            }
            if (error.field === 'permissionDenied') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/requests')
              return
            }
            if (error.field === 'meeting') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/meetings')
              return
            }
          })
        }
      })
  }, [])

  useEffect(() => {
    if (employees.length === 0) return
    const empId = employees.reduce((a, o) => (o.key && a.push(o.key), a), [])
    run(getJobTitlesByUserId({userId: empId}))
      .then(({data}) => {
        setJobNames(data.data)
        setAddAttendance(data?.data?.length)

        let employee = []
        data?.data?.map((emp, i) => {
          employee.push({
            employeeNameEN:
              emp.user['firstNameEN'] + ' ' + emp.user['lastNameEN'],
            employeeNameAR:
              emp.user['firstNameAR'] + ' ' + emp.user['lastNameAR'],
            jobTitleEN: emp?.jobTitle ? emp.jobTitle['nameEN'] : '',
            jobTitleAR: emp?.jobTitle ? emp.jobTitle['nameAR'] : '',
          })
        })
        setState({...state, attendance: employee})
      })
      .catch(e => {
        console.error(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, lang, run])

  const handleOnChangeDecision = (i, value, name) => {
    let arr = [...state.decisions]
    if (arr[i]) {
      arr[i][name] = value
      setState({...state, decisions: arr})
    } else {
      arr.push({
        id: i + 1,
        [name]: value,
      })
      setState({...state, decisions: arr})
    }
  }

  const handleOnChangeAttendance = (i, value, name) => {
    let arr = [...state.attendance]
    if (arr[i]) {
      if (name === 'jobTitle') {
        arr[i]['jobTitleEN'] = value
        arr[i]['jobTitleAR'] = value
      } else {
        arr[i]['employeeNameEN'] = value
        arr[i]['employeeNameAR'] = value
      }

      setState({...state, attendance: arr})
    } else {
      if (name === 'jobTitle') {
        arr.push({
          jobTitleEN: value,
          jobTitleAR: value,
        })
      } else {
        arr.push({
          employeeNameEN: value,
          employeeNameAR: value,
        })
      }

      setState({...state, attendance: arr})
    }
  }

  const handleOnChangeNextMeeting = (i, value, name) => {
    let arr = [...state.nextMeeting]
    if (arr[i]) {
      arr[i][name] = value
      setState({...state, nextMeeting: arr})
    } else {
      arr.push({
        [name]: value,
      })
      setState({...state, nextMeeting: arr})
    }
  }

  const handleOnSubmit = () => {
    // console.log(state)

    run(printMeetingReport(state))
      .then(({data}) => {
        // console.log(data)
        fileDownload(data, `Meeting report-${formatDate(Date.now())}.pdf`)
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/auth/login')
              return
            }
            if (error.field === 'permissionDenied') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/requests')
              return
            }
            if (error.field === 'meeting') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/meetings')
              return
            }
          })
        }
      })
  }

  return (
    <section>
      <Grid doubling columns={1}>
        <Grid.Row>
          <Grid.Column>
            <p className="text-center font-bold text-primary text-xl">
              {printLang.meetingAgenda}
            </p>
            <Form loading={isLoading}>
              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-2">
                      <label className="font-bold text-base text-primary">
                        {printLang.meetingAgenda}
                      </label>
                      <Icon
                        name="plus"
                        className="text-primary cursor-pointer"
                        onClick={() => setAddAgenda(prev => prev + 1)}
                      />
                    </div>

                    {new Array(addAgenda).fill(1).map((k, i) => (
                      <div className="flex items-center w-full mb-3" key={i}>
                        <Form.Input
                          className="w-full mb-0"
                          placeholder={printLang.meetingAgenda}
                          defaultValue={
                            state.meetingAgenda && state.meetingAgenda[i + 1]
                          }
                          onChange={(e, {value}) =>
                            setState({
                              ...state,
                              meetingAgenda: {
                                ...state.meetingAgenda,
                                [i + 1]: value,
                              },
                            })
                          }
                        />
                        {/* <Icon
                          name="times"
                          className="text-red-700 ml-4"
                          onClick={handleDeleteAgenda}
                        /> */}
                      </div>
                    ))}
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-2">
                      <label className="font-bold text-base text-primary">
                        {printLang.decisions}
                      </label>
                      <Icon
                        name="plus"
                        className="text-primary cursor-pointer"
                        onClick={() => setAddDecision(prev => prev + 1)}
                      />
                    </div>

                    {new Array(addDecision).fill(1).map((k, i) => (
                      <Form.Group widths="equal" key={i}>
                        <Form.Field>
                          <Form.Input
                            placeholder={printLang.decisions}
                            onChange={(e, {value}) =>
                              handleOnChangeDecision(i, value, 'decisionName')
                            }
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Dropdown
                            placeholder={printLang.assignedEmployee}
                            fluid
                            search
                            clearable
                            disabled={isLoading}
                            selection
                            options={employees}
                            onChange={(e, {value}) =>
                              handleOnChangeDecision(i, value, 'employee')
                            }
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            placeholder={printLang.durationTime}
                            onChange={(e, {value}) =>
                              handleOnChangeDecision(i, value, 'duration')
                            }
                          />
                        </Form.Field>
                      </Form.Group>
                    ))}
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-2">
                      <label className="font-bold text-base text-primary">
                        {printLang.attendance}
                      </label>
                      <Icon
                        name="plus"
                        className="text-primary cursor-pointer"
                        onClick={() => setAddAttendance(prev => prev + 1)}
                      />
                    </div>

                    {new Array(addAttendance).fill(1).map((k, i) => (
                      <Form.Group widths="equal" key={i}>
                        <Form.Field>
                          <Form.Input
                            placeholder={printLang.empName}
                            defaultValue={
                              state.attendance.length > 0 &&
                              i + 1 <= state.attendance.length
                                ? state.attendance[i][
                                    'employeeName' + lang.toUpperCase()
                                  ]
                                : ''
                            }
                            onChange={(e, {value}) =>
                              handleOnChangeAttendance(i, value, 'employeeName')
                            }
                          />
                        </Form.Field>

                        <Form.Field>
                          <Form.Input
                            placeholder={printLang.jobTitle}
                            defaultValue={
                              state.attendance.length > 0 &&
                              i + 1 <= state.attendance.length
                                ? state.attendance[i][
                                    'jobTitle' + lang.toUpperCase()
                                  ]
                                : printLang.noJobTitle
                            }
                            onChange={(e, {value}) =>
                              handleOnChangeAttendance(i, value, 'jobTitle')
                            }
                          />
                        </Form.Field>
                      </Form.Group>
                    ))}
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-2">
                      <label className="font-bold text-base text-primary">
                        {printLang.nextMeeting}
                      </label>
                      <Icon
                        name="plus"
                        className="text-primary cursor-pointer"
                        onClick={() => setNextMeeting(prev => prev + 1)}
                      />
                    </div>

                    {new Array(nextMeeting).fill(1).map((k, i) => (
                      <Form.Group widths="equal" key={i}>
                        <Form.Field>
                          <DateInput
                            closable
                            iconPosition="left"
                            placeholder={printLang.meetingDate}
                            value={
                              state.nextMeeting[i]
                                ? state.nextMeeting[i].nextDate
                                : ''
                            }
                            onChange={(e, {value}) =>
                              handleOnChangeNextMeeting(i, value, 'nextDate')
                            }
                          />
                        </Form.Field>

                        <Form.Field>
                          <TimeInput
                            placeholder={printLang.meetingTime}
                            iconPosition="left"
                            timeFormat="AMPM"
                            value={
                              state.nextMeeting[i]
                                ? state.nextMeeting[i].nextTime
                                : ''
                            }
                            onChange={(e, {value}) =>
                              handleOnChangeNextMeeting(i, value, 'nextTime')
                            }
                          />
                        </Form.Field>

                        <Form.Field>
                          <Form.Input
                            placeholder={printLang.meetingLocation}
                            defaultValue={
                              state.nextMeeting[i]
                                ? state.nextMeeting[i].nextLocation
                                : ''
                            }
                            onChange={(e, {value}) =>
                              handleOnChangeNextMeeting(
                                i,
                                value,
                                'nextLocation',
                              )
                            }
                          />
                        </Form.Field>
                      </Form.Group>
                    ))}
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-2">
                      <label className="font-bold text-base text-primary">
                        {printLang.createdBy}
                      </label>
                    </div>

                    <Form.Input
                      placeholder={'Created By'}
                      defaultValue={state['createdBy' + lang.toUpperCase()]}
                      onChange={(e, {value}) =>
                        setState({
                          ...state,
                          createdByEN: value,
                          createdByAR: value,
                        })
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <div className="text-center">
                <Button
                  content={printLang.print}
                  className="btn-primary"
                  icon="print"
                  onClick={handleOnSubmit}
                />
              </div>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </section>
  )
}

export default PrintMeeting
