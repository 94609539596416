import {Formik} from 'formik'
import React from 'react'
import {IoMdContact} from 'react-icons/io'
import {
  Button,
  Confirm,
  Form,
  Image,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react'
import * as Yup from 'yup'

import FormikDate from '../../components/formik/FormikDate'
import FormikDropdown from '../../components/formik/FormikDropdown'
import FormikInput from '../../components/formik/FormikInput'
import Auth from '../../config/auth'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import formatError from '../../utils/error-format'
import useEmployeesDropDown from '../../hooks/use-employees-dropdown'
import FileAttachment from '../../components/FileAttachment'
import {getUserFullname} from '../../utils/user'
import {formatDate} from '../../utils/date-format'
import FormikTextArea from '../../components/formik/FormikTextArea'
import {Link} from 'react-router-dom'
import {RiDeleteBin2Line} from 'react-icons/ri'

const Memos = () => {
  const [creating, setCreating] = React.useState(false)
  const [forceReload, setForceReload] = React.useState(false)
  const [confirmDeletion, setConfirmDeletion] = React.useState(null)

  const {run, data, error, isLoading} = useAsync()
  const {
    run: deleteMemo,
    error: errorDeletingMemo,
    isLoading: isDeletingMemo,
  } = useAsync()

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const handleDelete = () => {
    deleteMemo(authAxios.delete(`/memos/${confirmDeletion}`)).then(() => {
      setConfirmDeletion(null)
      setForceReload(p => !p)
    })
  }

  React.useEffect(() => {
    run(authAxios.get('/memos'))
  }, [run, forceReload])

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="text-primary">{selectedContent.internalMemos}</h2>
        {Auth.isManagerOrHigher() ? (
          <Button
            size="small"
            className="btn-primary"
            onClick={() => setCreating(true)}
          >
            {selectedContent.create}
          </Button>
        ) : null}
      </div>

      <div className="flex items-start flex-wrap mt-8">
        <Loader active={isLoading} />

        {data?.data?.map(d => (
          <div className="flex flex-col w-96 bg-white shadow-md rounded-lg p-4">
            <div>
              <div className="flex items-start justify-between">
                <div class="flex">
                  {d?.createdBy?.avatar ? (
                    <Image
                      src={d?.createdBy?.avatar}
                      className="rounded-full w-14 h-14"
                    />
                  ) : (
                    <IoMdContact size={52} className="text-primary" />
                  )}
                  <div className="ltr:ml-4 rtl:mr-4 mt-1 text-lg">
                    <small>{selectedContent.createdBy}</small>
                    <p>{getUserFullname(d?.createdBy, lang)}</p>
                  </div>
                </div>
                {Auth.getUserId() === d?.createdBy?._id ? (
                  <Button
                    size="tiny"
                    basic
                    className="px-4 py-2"
                    negative
                    onClick={() => setConfirmDeletion(d?._id)}
                  >
                    <RiDeleteBin2Line size="16" />
                  </Button>
                ) : null}
              </div>

              <div className="bg-green-100 rounded-md mt-2 px-4 py-2">
                <div class="flex">
                  <p className="font-bold ltr:mr-4 rtl:ml-4">From</p>
                  <p>{selectedContent[d?.from]}</p>
                </div>

                <div class="flex">
                  <p className="font-bold ltr:mr-4 rtl:ml-4">
                    {selectedContent.referenceNumber}
                  </p>
                  <p>{d?.referenceNumber}</p>
                </div>

                <div class="flex">
                  <p className="font-bold ltr:mr-4 rtl:ml-4">
                    {selectedContent.date}
                  </p>
                  <p>{formatDate(d?.date)}</p>
                </div>
              </div>

              <div className="flex bg-green-100 rounded-md mt-2 px-4 py-2">
                <p className="font-bold ltr:mr-4 rtl:ml-4">
                  {selectedContent.title}
                </p>
                <p>{d?.title}</p>
              </div>

              <div className="flex bg-green-100 rounded-md mt-2 px-4 py-2">
                <p className="font-bold ltr:mr-4 rtl:ml-4">
                  {selectedContent.content}
                </p>
                <p>{d?.content}</p>
              </div>

              <div className="flex mt-2 px-4 py-2">
                <p className="font-bold ltr:mr-4 rtl:ml-4">
                  {selectedContent.attachments}
                </p>
                {d?.attachment ? (
                  <a
                    href={d?.attachment?.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {selectedContent.clickToOpen}
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>

      <CreateMemo
        open={creating}
        onClose={() => setCreating(false)}
        reload={() => setForceReload(p => !p)}
      />
      <Confirm
        open={confirmDeletion}
        content="Are you sure want to delete the memo?"
        onCancel={() => setConfirmDeletion(false)}
        confirmButton={() => (
          <Button
            onClick={handleDelete}
            size="small"
            negative
            loading={isDeletingMemo}
          >
            Delete
          </Button>
        )}
        size="tiny"
      />
    </div>
  )
}

export default Memos

function CreateMemo({open, onClose, reload}) {
  const [from, setFrom] = React.useState([])
  const [attachment, setAttachment] = React.useState(null)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const memoSchema = Yup.object({
    referenceNumber: Yup.number().required(selectedContent.required),
    title: Yup.string().required(selectedContent.required),
    content: Yup.string().required(selectedContent.required),
    from: Yup.string().required(selectedContent.required),
    to: Yup.array()
      .min(1, selectedContent.required)
      .required(selectedContent.required),
    date: Yup.date()
      .typeError(selectedContent.invalidDate)
      .required(selectedContent.required),
  })

  const {employees, gettingEmployees, errorGettingEmployees} =
    useEmployeesDropDown({
      departmentId: Auth.getDataFromToken().department._id,
      includeSubDepartments: true,
      includeManagers: true,
    })

  const {run, error, isLoading} = useAsync()

  React.useEffect(() => {
    setFrom([
      {
        text: selectedContent.ministryOfHealth,
        key: 'ministryOfHealth',
        value: 'ministryOfHealth',
      },
      {
        text: selectedContent.centerManager,
        key: 'centerManager',
        value: 'centerManager',
      },
      {
        text: selectedContent.departmentManager,
        key: 'departmentManager',
        value: 'departmentManager',
      },
    ])
  }, [selectedContent])

  const handleCreateMemo = values => {
    const data = new FormData()
    for (let k in values) {
      data.append(k, values[k])
    }
    if (attachment) data.append('memoFile', attachment)

    run(authAxios.post('/memos', data)).then(() => {
      onClose()
      reload()
    })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="small"
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>{selectedContent.newMemo}</Modal.Header>
      <Modal.Content>
        {errorGettingEmployees ? (
          <Message error list={formatError(errorGettingEmployees)} />
        ) : null}
        {error ? <Message error list={formatError(error)} /> : null}

        <Formik
          initialValues={{
            title: '',
            referenceNumber: '',
            date: '',
            from: '',
            content: '',
            to: [],
          }}
          validationSchema={memoSchema}
          onSubmit={handleCreateMemo}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit} loading={isLoading}>
              <FormikInput label={selectedContent.title} name="title" />
              <FormikInput
                label={selectedContent.referenceNumber}
                name="referenceNumber"
              />
              <FormikDate label={selectedContent.date} name="date" />
              <FormikDropdown
                label={selectedContent.from}
                name="from"
                options={from}
              />
              <FormikDropdown
                label={selectedContent.to}
                name="to"
                loading={gettingEmployees}
                multiple
                options={employees}
              />
              <FormikTextArea label={selectedContent.content} name="content" />
              <FileAttachment setAttachmentData={setAttachment} />
              <Button
                type="submit"
                className="btn-primary mt-4 block w-full"
                size="small"
              >
                {selectedContent.create}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
