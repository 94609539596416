import {Button, Form, Grid, Image} from 'semantic-ui-react'
import {Fragment, useContext, useEffect, useState} from 'react'
import {Modal} from 'semantic-ui-react'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import borderIcon from '../../assets/images/ic_border.svg'
import {useLanguage} from '../../context/languageContext'
import {
  approveTicket,
  getItUsers,
  ticketActions,
} from '../../services/ticketService'
import {useToasts} from 'react-toast-notifications'
import moment from 'moment'
import Auth from '../../config/auth'
import {content} from '../../localization/content'

const ViewTicketModal = ({updateTicket}) => {
  const [open, setOpen] = useState(false)
  const [openSecond, setOpenSecond] = useState(false)
  const {showModal, setShowModal} = useContext(StateContext)
  const {addToast} = useToasts()
  const [modalData, setModalData] = useState('')
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const [employees, setEmployees] = useState([])
  const [state, setState] = useState({
    assignedTo: [],
  })
  const viewLang = content[lang]

  useEffect(() => {
    if (showModal.modalName === 'viewTicket') {
      setOpen(true)
      setModalData(showModal.data)
    } else {
      setOpen(false)
    }
  }, [showModal])

  useEffect(() => {
    if (!openSecond) return
    run(getItUsers()).then(({data}) => {
      let emps = []
      data.data.map(e => {
        emps.push({
          key: e._id,
          text:
            e['firstName' + lang.toUpperCase()] +
            ' ' +
            e['lastName' + lang.toUpperCase()],
          value: e._id,
        })
      })
      setEmployees(emps)
    })
  }, [openSecond])

  const handleOnClickAssign = () => {
    run(approveTicket({ticketId: modalData._id, assignedTo: state.assignedTo}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        setOpenSecond(false)
        setShowModal({modalName: '', data: null})
        updateTicket(true)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleNotes = () => {
    const sortedUpdates = modalData.updateNotes.sort((a, b) => {
      const dateA = new Date(a.updatedAt),
        dateB = new Date(b.updatedAt)
      return dateB - dateA
    })
    return sortedUpdates.map((n, i) => (
      <li className="bg-gray-100 mb-2 rounded-lg p-2">
        <div className="flex items-center justify-between">
          <p className="font-medium text-lg">
            {n?.updatedBy['firstName' + lang.toUpperCase()]}{' '}
            {n?.updatedBy['lastName' + lang.toUpperCase()]}
          </p>
          <small className="">{moment(n.updatedAt).format('LLL')}</small>
        </div>
        <p className="text-gray-500 ltr:ml-3 rtl:mr-3">{n.note}</p>
      </li>
    ))
  }

  const handleOnClickComplete = type => {
    run(ticketActions({ticketId: modalData._id, type: type}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        updateTicket(true)
        setShowModal({modalName: '', data: null})
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <Modal
      onClose={() => setShowModal({modalName: '', data: null})}
      open={open}
      closeIcon
      size="large"
      className="main-font"
    >
      <Modal.Header>{viewLang.viewTicket}</Modal.Header>
      <Modal.Content>
        <Form loading={isLoading}>
          <Grid doubling columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="2px"
                    height="2px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-lg font-bold mx-3 text-goldPrimary">
                    {viewLang.ticketType}:
                  </p>
                  <p className="text-lg text-subTitleText">
                    {modalData?.ticketType?.['name' + lang.toUpperCase()] ||
                      modalData?.ticketType}
                  </p>
                </div>

                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="2px"
                    height="2px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-lg font-bold mx-3 text-goldPrimary">
                    {viewLang.ticketCategory}:
                  </p>
                  <p className="text-lg text-subTitleText">
                    {modalData?.ticketCategory?.['name' + lang.toUpperCase()] ||
                      modalData?.ticketCategory}
                  </p>
                </div>

                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="2px"
                    height="2px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-lg font-bold mx-3 text-goldPrimary">
                    {viewLang.building}:
                  </p>
                  <p className="text-lg text-subTitleText">
                    {viewLang[modalData?.building]}
                  </p>
                </div>

                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="2px"
                    height="2px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-lg font-bold mx-3 text-goldPrimary">
                    {viewLang.department}:
                  </p>
                  <p className="text-lg text-subTitleText">
                    {modalData?.department?.['name' + lang.toUpperCase()]}
                  </p>
                </div>

                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="2px"
                    height="2px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <div className="flex flex-col">
                    <p className="text-lg font-bold mx-3 text-goldPrimary">
                      {viewLang.assignedEmployee}:
                    </p>
                    {modalData.assignedTo?.length > 0 &&
                      modalData.assignedTo.map((assignEmp, i) => (
                        <p
                          className="text-lg text-subTitleText ltr:ml-5 rtl:mr-5"
                          key={i}
                        >
                          {assignEmp['firstName' + lang.toUpperCase()]}{' '}
                          {assignEmp['lastName' + lang.toUpperCase()]}
                        </p>
                      ))}
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="2px"
                    height="2px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <div className="flex flex-col">
                    <p className="text-lg font-bold mx-3 text-goldPrimary">
                      {viewLang.description}:
                    </p>
                    <p className="text-lg text-subTitleText ltr:ml-5 rtl:mr-5">
                      {modalData?.ticketDescription}
                    </p>
                  </div>
                </div>

                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="2px"
                    height="2px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-lg font-bold mx-3 mb-0 text-goldPrimary">
                    {viewLang.ticketFile}:
                  </p>
                  {modalData?.ticketFile !== '' && (
                    <a
                      className="text-lg text-blue-600"
                      href={modalData?.ticketFile}
                      target="_blank"
                    >
                      View File
                    </a>
                  )}
                </div>

                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="2px"
                    height="2px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-lg font-bold mx-3 mb-0 text-goldPrimary">
                    {viewLang.createdBy}:
                  </p>
                  <p className="text-lg text-subTitleText">
                    {modalData?.createdBy?.['firstName' + lang.toUpperCase()]}{' '}
                    {modalData?.createdBy?.['lastName' + lang.toUpperCase()]}
                  </p>
                </div>

                <div className="flex items-center mb-3">
                  <Image
                    src={borderIcon}
                    width="2px"
                    height="2px"
                    className="ltr:mr-2 rtl:ml-2"
                    alt="border icon"
                  />
                  <p className="text-lg font-bold mx-3 mb-0 text-goldPrimary">
                    {viewLang.status}:
                  </p>
                  <p className="text-lg text-subTitleText">
                    {modalData.ticketStatus === 'in progress'
                      ? viewLang.inProgress
                      : viewLang[modalData.ticketStatus]}
                  </p>
                </div>

                {modalData?.ticketStatus === 'resent' && (
                  <div className="flex items-center mb-3">
                    <Image
                      src={borderIcon}
                      width="2px"
                      height="2px"
                      className="ltr:mr-2 rtl:ml-2"
                      alt="border icon"
                    />
                    <p className="text-lg font-bold mx-3 mb-0 text-goldPrimary">
                      {viewLang.resendReason}:
                    </p>
                    <p className="text-lg text-subTitleText">
                      {modalData.resendReason}
                    </p>
                  </div>
                )}

                {modalData?.ticketStatus === 'rejected' && (
                  <div className="flex items-center mb-3">
                    <Image
                      src={borderIcon}
                      width="2px"
                      height="2px"
                      className="ltr:mr-2 rtl:ml-2"
                      alt="border icon"
                    />
                    <p className="text-lg font-bold mx-3 mb-0 text-goldPrimary">
                      {viewLang.rejectedReason}:
                    </p>
                    <p className="text-lg text-subTitleText">
                      {modalData.rejectedReason}
                    </p>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>

        {modalData?.updateNotes?.length > 0 && (
          <Fragment>
            <hr />

            <div className="mt-5">
              <ul>{handleNotes()}</ul>
            </div>
          </Fragment>
        )}
      </Modal.Content>
      {modalData.ticketStatus !== 'completed' &&
      modalData.ticketStatus !== 'rejected' ? (
        Auth.isManagerOrIT() ? (
          <Modal.Actions>
            {modalData.assignedTo?.length === 0 ? (
              <Button
                content={viewLang.assignEmployee}
                labelPosition="right"
                icon="checkmark"
                onClick={() => setOpenSecond(true)}
                className="btn-primary"
              />
            ) : (
              <>
                {modalData?.assignedTo?.map((a, i) => {
                  if (a._id === Auth.getUserId()) {
                    return (
                      <Button
                        content={viewLang.markAsCompleted}
                        labelPosition="right"
                        icon="checkmark"
                        onClick={() => handleOnClickComplete('completed')}
                        className="btn-primary"
                      />
                    )
                  }
                })}
              </>
            )}

            <Button
              content={viewLang.reject}
              labelPosition="right"
              icon="times"
              onClick={() => {
                setOpenSecond(false)
                setShowModal({modalName: 'rejectTicket', data: modalData})
              }}
              negative
            />
          </Modal.Actions>
        ) : null
      ) : null}

      <Modal
        onClose={() => setOpenSecond(false)}
        open={openSecond}
        size="small"
        className="main-font"
        closeIcon
      >
        <Modal.Header>{viewLang.assignEmployee}</Modal.Header>
        <Modal.Content>
          <Form loading={isLoading}>
            <Form.Field>
              <Form.Dropdown
                label={viewLang.selectEmployees}
                placeholder="Select"
                fluid
                selection
                multiple
                search
                defaultValue={state.assignedTo}
                options={employees}
                onChange={(e, {value}) =>
                  setState({...state, assignedTo: value})
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="check"
            className="btn-primary"
            content={viewLang.save}
            onClick={handleOnClickAssign}
          />
        </Modal.Actions>
      </Modal>
    </Modal>
  )
}

export default ViewTicketModal
