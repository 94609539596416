import moment from 'moment'
import React from 'react'
import {FaUserCircle} from 'react-icons/fa'
import {Header, Icon, Image, Tab, Table, Grid} from 'semantic-ui-react'

import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {formatDate, formatTime, nOfHoursBetween} from '../../utils/date-format'
import {getUserFullname} from '../../utils/user'
import usePagination from '../../hooks/use-pagination'
import PaginationWithLimit from '../pagination-with-limit'

function EmployeePermissionsHistory() {
  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const [data, setData] = React.useState([])
  const [viewing, setViewing] = React.useState('')

  const {page, limit, onPageChange, onLimitChange} = usePagination()

  const {run, isLoading} = useAsync()

  React.useEffect(() => {
    if (page)
      run(
        authAxios.get(
          `/requests/permissions/employees-history?page=${page}&limit=${limit}`,
        ),
      ).then(({data}) => {
        setData(data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run, page, limit])

  return (
    <Tab.Pane loading={isLoading}>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {selectedContent.employee}
            </Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.from}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.to}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.duration}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.date}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.awaiting}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.status}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.attachments}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.data?.map((d, i) => (
            <>
              <Table.Row
                key={d?.createdAt}
                negative={d.status === 'Rejected'}
                positive={d.status === 'Approved'}
              >
                <Table.Cell
                  selectable
                  width="1"
                  className="cursor-pointer"
                  textAlign="center"
                  onClick={() =>
                    setViewing(prev => (prev === d._id ? '' : d._id))
                  }
                >
                  <Icon
                    name={viewing === d._id ? 'angle up' : 'angle down'}
                    className="text-xl text-primary m-0"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4" image>
                    {d.creator?.avatar ? (
                      <Image
                        src={d.creator?.avatar}
                        rounded
                        size="small"
                        className="mb-2"
                      />
                    ) : (
                      <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-2 rtl:ml-2 mb-2" />
                    )}
                    <Header.Content>
                      {getUserFullname(d.creator, lang)}
                      <Header.Subheader>
                        {d.creator?.department?.['name' + lang.toUpperCase()]}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>

                {/* <Table.Cell>{d.for?.reason}</Table.Cell> */}
                <Table.Cell>{formatTime(d.for?.start)}</Table.Cell>
                <Table.Cell>{formatTime(d.for?.end)}</Table.Cell>
                <Table.Cell>
                  {nOfHoursBetween(d.for?.start, d.for?.end)} H
                </Table.Cell>
                <Table.Cell>
                  {formatDate(d.for?.date)} (
                  {moment(d.for?.date).locale(lang).format('dddd')})
                </Table.Cell>
                <Table.Cell>
                  {getUserFullname(d.awaitingApprovalFrom, lang)}
                </Table.Cell>
                <Table.Cell>
                  {selectedContent[d?.status?.toLowerCase()]}
                </Table.Cell>
                <Table.Cell>
                  {d.for?.attachment?.link ? (
                    <a
                      rel="noreferrer"
                      href={d.for?.attachment?.link}
                      target="_blank"
                      className="underline"
                    >
                      {selectedContent.clickToOpen}
                    </a>
                  ) : (
                    selectedContent.noAttachments
                  )}
                </Table.Cell>
              </Table.Row>
              {viewing === d._id ? (
                <Table.Row>
                  <Table.Cell colSpan={8} selectable={false}>
                    <Grid doubling columns={3} relaxed className="py-5">
                      <Grid.Row>
                        <Grid.Column>
                          <Feild
                            title={selectedContent.date}
                            description={formatDate(d?.for?.date)}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Feild
                            title={selectedContent.from}
                            description={formatTime(d?.for?.start)}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Feild
                            title={selectedContent.to}
                            description={formatTime(d?.for?.end)}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Feild
                            title={selectedContent.createdAt}
                            description={formatDate(d?.for?.createdAt)}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Feild
                            title={selectedContent.status}
                            description={
                              selectedContent[d?.status.toLowerCase()]
                            }
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Feild
                            title={`${selectedContent.requestReason}`}
                            description={d?.for?.reason}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        {d?.status === 'Rejected' ? (
                          <Grid.Column>
                            <Feild
                              title={selectedContent.rejectionReason}
                              description={d?.rejectionReason}
                            />
                          </Grid.Column>
                        ) : null}
                      </Grid.Row>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </>
          ))}
        </Table.Body>
      </Table>

      <PaginationWithLimit
        page={page}
        limit={limit}
        totalPages={data?.totalPages}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Tab.Pane>
  )
}

export default EmployeePermissionsHistory

const Feild = ({title, description}) => (
  <div className="flex mt-2">
    <span className="border border-primary ltr:mr-2 rtl:ml-2"></span>
    <p className="font-bold ltr:mr-2 rtl:ml-2">{title}:</p>
    <p>{description}</p>
  </div>
)
