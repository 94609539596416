import Auth from '../config/auth'
import {authAxios, axios} from '../config/axiosConfig'

// login

// Login user
export const loginUser = data => {
  return axios.post('/auth/login', data, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      mode: 'no-cors',
    },
  })
}

// Verify phone number
export const verifyPhoneNumber = phone => {
  return axios.post('/auth/verifyPhoneNumber', phone)
}

// Logout user
export const logoutUser = () => {
  return authAxios.get('/auth/logout')
}

// user forget password
export const sendEmailForgetPassword = email => {
  return axios.post('/auth/forgetPassword', email)
}

// user reset password
export const resetPassword = userId => {
  return axios.post('/auth/resetPassword', userId)
}

// Verify code
export const sendOTP = phone => {
  return axios.post('/auth/sendOTP', phone)
}
// signup
export const registerUser = data => {
  return axios.post('/auth/signup', data, {
    headers: {'Content-Type': 'multipart/form-data'},
  })
}
