import {useContext, useEffect, useState} from 'react'
import StateContext from '../../context/stateContext'
import {Modal, Form, Transition, Button} from 'semantic-ui-react'
import {Formik} from 'formik'
import FormikControl from '../formik/FormikControl'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import useAsync from '../../hooks/useAsync'
import {authAxios} from '../../config/axiosConfig'
import {useToasts} from 'react-toast-notifications'
import {getSubdepartmentsFromMasterDepartment} from '../../utils/handleDepartments'
import FormikInput from '../formik/FormikInput'
import FormikDropdown from '../formik/FormikDropdown'

const TransferUserModal = ({updateEmployees}) => {
  const [open, setOpen] = useState(false)
  const {showModal, setShowModal} = useContext(StateContext)
  const [lang] = useLanguage()
  const [departments, setDepartments] = useState([])
  const [roles, setRoles] = useState([])
  const [masterDepartmentsOptions, setMasterDepartmentsOptions] = useState([])
  const [departmentsOptions, setDepartmentsOptions] = useState([])
  const transferLang = content[lang]
  const {run, isLoading, data} = useAsync()
  const [errors, setErrors] = useState([])

  const {addToast} = useToasts()

  useEffect(() => {
    if (showModal.modalName === 'transferUser') {
      setOpen(true)
      run(authAxios.get('/departments/departments')).then(({data}) => {
        let departmentsOptions = []
        let masterDepartmentsOptions = []

        data?.data?.departments?.map(d =>
          departmentsOptions.push({
            key: d._id,
            text: d['name' + lang.toUpperCase()],
            value: d._id,
          }),
        )
        data?.data?.masterDepartments?.map(d =>
          masterDepartmentsOptions.push({
            key: d._id,
            text: d['name' + lang.toUpperCase()],
            value: d._id,
          }),
        )

        setDepartments(data?.data?.departments)
        setDepartmentsOptions(departmentsOptions)
        setMasterDepartmentsOptions(masterDepartmentsOptions)
      })

      run(authAxios.get('/users/roles')).then(({data}) => {
        const rolesOptions = data?.data.map(r => ({
          text: r.toUpperCase(),
          value: r,
          key: r,
        }))
        setRoles(rolesOptions)
      })
    } else {
      setOpen(false)
    }
  }, [showModal])

  const handleOnSubmit = values => {
    let body = {...values}

    if (values.isMaster) {
      delete body.subDepartment
    } else {
      body.department = values.subDepartment
    }

    run(
      authAxios.post('/users/transferUser', {
        userId: showModal.data.user?._id,
        departmentId: body.department,
        role: body.role,
      }),
    )
      .then(() => {
        setShowModal({modalName: '', data: null})
        addToast(transferLang.successfulOperation, {appearance: 'success'})
        updateEmployees(true)
      })
      .catch(e => {
        console.log(e)
        if (Array.isArray(e.errors))
          setErrors(e.errors.map(e => e.message[lang]))
      })
  }
  console.log(showModal?.data)
  return (
    <Modal
      open={open}
      closeIcon
      className="main-font"
      closeOnDimmerClick={false}
      onClose={() => setShowModal({modalName: '', data: null})}
    >
      <Modal.Header>{transferLang.transferUser}</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            isMaster: showModal?.data?.department?.isMaster ? true : false,
            department:
              showModal?.data?.department?.masterDepartment?._id ||
              showModal?.data?.department?._id,
            subDepartment: showModal?.data?.department?._id,
            role: showModal?.data?.user?.role,
          }}
          onSubmit={handleOnSubmit}
        >
          {formik => (
            <Form loading={isLoading} onSubmit={formik.submitForm}>
              {/* <p>{formik.values.department}</p> */}
              <FormikControl
                name="isMaster"
                label={transferLang.isMaster}
                checked={formik.values.isMaster}
                control="checkbox"
              />
              <FormikControl
                name="department"
                label={transferLang.department}
                control="select"
                selection
                fluid
                clearable
                value={formik.values.department || ''}
                options={masterDepartmentsOptions}
              />
              <Transition.Group duration={300} animation="fade up">
                {!formik.values.isMaster ? (
                  <FormikControl
                    name="subDepartment"
                    label={transferLang.subDepartment}
                    control="dropdown"
                    fluid
                    selection
                    clearable
                    value={formik.values.subDepartment || ''}
                    options={getSubdepartmentsFromMasterDepartment(
                      formik.values.department,
                      departments || departmentsOptions,
                      lang,
                    )}
                  />
                ) : null}
              </Transition.Group>
              <FormikDropdown
                name="role"
                label={transferLang.role}
                options={roles}
                selection
                fluid
                clearable
              />

              <div className="mt-5 text-center">
                <Button
                  content={transferLang.transferUser}
                  className="btn-primary"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default TransferUserModal
