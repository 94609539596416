import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, Form} from 'semantic-ui-react'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import StateContext from '../../../context/stateContext'

const PhoneForm = ({nextStep}) => {
  const [lang] = useLanguage()
  const {setAuthTitle} = useContext(StateContext)
  const phoneContent = content[lang]
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState('')

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return
    const title = {
      title: phoneContent.loginByPhone,
      subTitle: phoneContent.greeting,
      message: phoneContent.phoneLoginMessage,
    }

    setAuthTitle(title)
    return () => {
      isMounted = false
    }
  }, [lang])

  const handleOnChangePhone = (isValid, phone, country) => {
    setPhone(phone.replace(' ', ''))
  }

  const handleOnClickSubmit = () => {
    nextStep({step: 'verify', value: phone.replace(' ', '')})
  }
  return (
    <Fragment>
      <Form.Field width="16">
        <label className="auth-form-label main-font">
          {phoneContent.phone}
        </label>
        <IntlTelInput
          containerClassName="intl-tel-input w-100 main-font"
          inputClassName="ltr:pl-14 rtl:pl-0 rtl:pr-14 main-font"
          nationalMode={false}
          autoFocus={true}
          value={phone}
          onPhoneNumberChange={(isValid, phone, country) =>
            handleOnChangePhone(isValid, phone, country)
          }
        />
      </Form.Field>

      <div className="auth-action">
        <Button
          content={phoneContent.login}
          className="main-font btn-primary"
          onClick={handleOnClickSubmit}
        />
      </div>
    </Fragment>
  )
}

export default PhoneForm
