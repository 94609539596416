import {Formik} from 'formik'
import {Button, Form, Image} from 'semantic-ui-react'
import * as Yup from 'yup'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import FormikControl from '../../formik/FormikControl'
import borderIcon from '../../../assets/images/ic_border.svg'

const ShiftInfoForm = props => {
  const [lang] = useLanguage()
  const modalLang = content[lang]
  const shiftSchema = Yup.object({
    nameEN: Yup.string().required(modalLang.required),
    nameAR: Yup.string().required(modalLang.required),
    startDate: Yup.date().required(modalLang.required),
    endDate: Yup.date().required(modalLang.required),
    startTime: Yup.string().required(modalLang.required),
    endTime: Yup.string().required(modalLang.required),
  })

  const handleSubmit = values => {
    props.next({next: true, values: values})
  }
  return (
    <Formik
      initialValues={{
        nameEN: '',
        nameAR: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
      }}
      validationSchema={shiftSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form onSubmit={formik.submitForm} loading={props.isLoading}>
          <Form.Field>
            <div className="flex items-center">
              <Image
                src={borderIcon}
                width="5px"
                height="5px"
                className="ltr:mr-5 rtl:ml-5"
                alt="border icon"
              />
              <div className="w-full">
                <FormikControl
                  name="nameEN"
                  label={modalLang.shiftName}
                  control="input"
                />
              </div>
            </div>
          </Form.Field>

          <Form.Field>
            <div className="flex items-center">
              <Image
                src={borderIcon}
                width="5px"
                height="5px"
                className="ltr:mr-5 rtl:ml-5"
                alt="border icon"
              />
              <div className="w-full">
                <FormikControl
                  name="nameAR"
                  control="input"
                  label={modalLang.shiftNameAR}
                />
                {/* <Form.Input
                          label={
                            <label className="text-primary">
                              {modalLang.shiftNameAR}
                            </label>
                          }
                          defaultValue={state.nameAR}
                          placeholder={modalLang.shiftNameAR}
                          onChange={(e, {value}) =>
                            setState({...state, nameAR: value})
                          }
                        /> */}
              </div>
            </div>
          </Form.Field>

          <hr />

          <div className="my-3">
            <label className="font-bold text-primary">
              {modalLang.shiftDate}
            </label>

            <Form.Field className="mt-3">
              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="5px"
                  height="5px"
                  className="ltr:mr-5 rtl:ml-5"
                  alt="border icon"
                />
                <div className="w-full">
                  <Form.Group widths="equal">
                    <Form.Field>
                      <FormikControl
                        name="startDate"
                        label={modalLang.from}
                        control="date"
                      />
                      {/* <DateInput
                                value={formatDate(state.dateFrom)}
                                label={
                                  <label className="text-primary">
                                    {modalLang.from}
                                  </label>
                                }
                                onChange={(e, {value}) =>
                                  setState({
                                    ...state,
                                    dateFrom: stringToDate(value),
                                  })
                                }
                              /> */}
                    </Form.Field>

                    <Form.Field>
                      <FormikControl
                        name="endDate"
                        control="date"
                        label={modalLang.to}
                      />
                      {/* <DateInput
                                value={formatDate(state.dateTo)}
                                label={
                                  <label className="text-primary">
                                    {modalLang.to}
                                  </label>
                                }
                                onChange={(e, {value}) =>
                                  setState({
                                    ...state,
                                    dateTo: stringToDate(value),
                                  })
                                }
                              /> */}
                    </Form.Field>
                  </Form.Group>
                </div>
              </div>
            </Form.Field>

            <Form.Field className="mt-3">
              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="5px"
                  height="5px"
                  className="ltr:mr-5 rtl:ml-5"
                  alt="border icon"
                />
                <div className="w-full">
                  <Form.Group widths="equal">
                    <Form.Field>
                      <FormikControl
                        name="startTime"
                        label={modalLang.from}
                        control="time"
                      />
                      {/* <TimeInput
                                name="from"
                                value={formatTime(state.timeFrom)}
                                label={
                                  <label className="text-primary">
                                    {modalLang.from}
                                  </label>
                                }
                                timeFormat="AMPM"
                                onChange={(e, {value}) =>
                                  handleOnChangeTime(value, 'From')
                                }
                              /> */}
                    </Form.Field>
                    <Form.Field>
                      <FormikControl
                        name="endTime"
                        label={modalLang.to}
                        control="time"
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              </div>
            </Form.Field>
          </div>

          {props.children}

          <div className="my-3 text-center">
            <Button
              content={modalLang.next}
              className="btn-primary"
              type="submit"
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ShiftInfoForm
