import DraggableEmployeeList from './DraggableEmployeeList'

const DraggableBoxList = ({data}) => {
  return (
    <div
      className="border-2 border-gray-100 overflow-y-auto rounded-lg"
      style={{height: '400px'}}
    >
      <ul className="p-2">
        {data?.length > 0 &&
          data?.map((emp, i) => (
            <DraggableEmployeeList empData={emp} type="list" key={i} />
          ))}
      </ul>
    </div>
  )
}

export default DraggableBoxList
