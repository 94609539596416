function getVacationType(vacation, content) {
  return (
    content[vacation.vacationType.toLowerCase()] ||
    content.variableEndVacationTypes[vacation.vacationType]
  )
}

module.exports = {
  getVacationType,
}
