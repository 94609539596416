import {Fragment, useEffect, useState} from 'react'
import {Icon, Image} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import emptyAttachment from '../../../assets/images/attachments_empty.svg'
import borderIcon from '../../../assets/images/ic_border.svg'
import {keys} from '../../../config/keys'
import {formatDate} from '../../../utils/date-format'

const ViewAttachments = ({userData}) => {
  const [lang] = useLanguage()
  const attachmentsLang = content[lang]
  const [attachments, setAttachments] = useState('')

  useEffect(() => {
    if (!userData) return
    setAttachments(userData)
  }, [userData])
  return (
    <Fragment>
      {attachments === '' && (
        <div
          className={`${attachments === '' ? 'visible' : 'hidden'} h-68 p-7`}
        >
          <Image
            src={emptyAttachment}
            width="150"
            height="150"
            className="mx-auto"
          />
          <p className="text-3xl font-bold text-primary text-center my-5">
            {attachmentsLang.emptyAttachments}
          </p>
        </div>
      )}

      {attachments !== '' && (
        <Fragment>
          {attachments.cv && (
            <div className={`flex items-center justify-between mb-3`}>
              <div className="flex items-center w-1/2">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <p className="text-xl font-bold mx-3 text-goldPrimary">
                  {attachmentsLang.cvFile}
                </p>
              </div>

              <div className="w-1/2 flex items-center justify-center">
                <a
                  href={attachments.cv.file}
                  download
                  className="bg-transparent text-primary font-bold ltr:mr-10 rtl:ml-10 hover:text-green-500 transition-colors duration-300"
                >
                  <Icon
                    name="download"
                    className="text-primary rtl:ml-3 ltr:mr-3"
                  />
                  {attachmentsLang.download}
                </a>

                <a
                  href={attachments.cv.file}
                  target="_blank"
                  className="bg-transparent text-primary hover:text-green-500 transition-colors font-bold duration-300"
                >
                  <Icon name="eye" className="text-primary rtl:ml-3 ltr:mr-3" />
                  {attachmentsLang.view}
                </a>
              </div>
            </div>
          )}
          {attachments.residenceCard && (
            <div className={`flex items-center justify-between mb-3`}>
              <div className="flex items-center w-1/2">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <div className="flex flex-col mx-3 items-center ">
                  <p className="text-xl font-bold mb-1 w-full text-goldPrimary">
                    {attachmentsLang.residenceCard}
                  </p>
                  <small className="text-red-800 w-full">
                    {attachmentsLang.expiryDate}:{'  '}
                    {formatDate(attachments.residenceCard.expiryDate)}
                  </small>
                </div>
              </div>

              <div className="w-1/2 flex items-center justify-center">
                <a
                  href={attachments.residenceCard.file}
                  download
                  className="bg-transparent text-primary font-bold ltr:mr-10 rtl:ml-10 hover:text-green-500 transition-colors duration-300"
                >
                  <Icon
                    name="download"
                    className="text-primary rtl:ml-3 ltr:mr-3"
                  />
                  {attachmentsLang.download}
                </a>

                <a
                  href={attachments.residenceCard.file}
                  target="_blank"
                  className="bg-transparent text-primary hover:text-green-500 transition-colors font-bold duration-300"
                >
                  <Icon name="eye" className="text-primary rtl:ml-3 ltr:mr-3" />
                  {attachmentsLang.view}
                </a>
              </div>
            </div>
          )}

          {attachments.businessCard && (
            <div className={`flex items-center justify-between mb-3`}>
              <div className="flex items-center w-1/2">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <div className="flex flex-col items-center mx-3">
                  <p className="text-xl font-bold mb-1 w-full text-goldPrimary">
                    {attachmentsLang.businessCard}
                  </p>
                  <small className="text-red-800 w-full">
                    {attachmentsLang.expiryDate}:{'  '}
                    {formatDate(attachments.businessCard.expiryDate)}
                  </small>
                </div>
              </div>

              <div className="w-1/2 flex items-center justify-center">
                <a
                  href={attachments.businessCard.file}
                  download
                  className="bg-transparent text-primary font-bold ltr:mr-10 rtl:ml-10 hover:text-green-500 transition-colors duration-300"
                >
                  <Icon
                    name="download"
                    className="text-primary rtl:ml-3 ltr:mr-3"
                  />
                  {attachmentsLang.download}
                </a>

                <a
                  href={attachments.businessCard.file}
                  target="_blank"
                  className="bg-transparent text-primary hover:text-green-500 transition-colors font-bold duration-300"
                >
                  <Icon name="eye" className="text-primary rtl:ml-3 ltr:mr-3" />
                  {attachmentsLang.view}
                </a>
              </div>
            </div>
          )}
          {attachments.PACertificate && (
            <div className={`flex items-center justify-between mb-3`}>
              <div className="flex items-center w-1/2">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <div className="flex flex-col items-center mx-3 justify-start">
                  <p className="text-xl font-bold w-full mb-1 text-goldPrimary">
                    {attachmentsLang.PACertificate}
                  </p>
                  <small className="text-red-800 w-full">
                    {attachmentsLang.expiryDate}:{'  '}
                    {formatDate(attachments.PACertificate.expiryDate)}
                  </small>
                </div>
              </div>

              <div className="w-1/2 flex items-center justify-center">
                <a
                  href={attachments.PACertificate.file}
                  download
                  className="bg-transparent text-primary font-bold ltr:mr-10 rtl:ml-10 hover:text-green-500 transition-colors duration-300"
                >
                  <Icon
                    name="download"
                    className="text-primary rtl:ml-3 ltr:mr-3"
                  />
                  {attachmentsLang.download}
                </a>

                <a
                  href={attachments.PACertificate.file}
                  target="_blank"
                  className="bg-transparent text-primary hover:text-green-500 transition-colors font-bold duration-300"
                >
                  <Icon name="eye" className="text-primary rtl:ml-3 ltr:mr-3" />
                  {attachmentsLang.view}
                </a>
              </div>
            </div>
          )}

          {attachments.CPRCerificate && (
            <div className={`flex items-center justify-between mb-3`}>
              <div className="flex items-center w-1/2">
                <Image
                  src={borderIcon}
                  width="3px"
                  height="3px"
                  className="ltr:mr-2 rtl:ml-2"
                  alt="border icon"
                />
                <div className="flex flex-col mx-3 items-center">
                  <p className="text-xl font-bold w-full mb-1 text-goldPrimary">
                    {attachmentsLang.CPRCerificate}
                  </p>
                  <small className="text-red-800 w-full">
                    {attachmentsLang.expiryDate}:{'  '}
                    {formatDate(attachments.CPRCerificate.expiryDate)}
                  </small>
                </div>
              </div>

              <div className="w-1/2 flex items-center justify-center">
                <a
                  href={attachments.CPRCerificate.file}
                  download
                  className="bg-transparent text-primary font-bold ltr:mr-10 rtl:ml-10 hover:text-green-500 transition-colors duration-300"
                >
                  <Icon
                    name="download"
                    className="text-primary rtl:ml-3 ltr:mr-3"
                  />
                  {attachmentsLang.download}
                </a>

                <a
                  href={attachments.CPRCerificate.file}
                  target="_blank"
                  className="bg-transparent text-primary hover:text-green-500 transition-colors font-bold duration-300"
                >
                  <Icon name="eye" className="text-primary rtl:ml-3 ltr:mr-3" />
                  {attachmentsLang.view}
                </a>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default ViewAttachments
