import React, {useEffect} from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'
import PrivateRoute from '../components/protectedRoutes'
import {useLanguage} from '../context/languageContext'
import {SocketProvider} from '../context/socket-context'
import authLayout from '../layout/authLayout'
import DashboardLayout from '../layout/DashboardLayout'

const Routes = () => {
  const [lang] = useLanguage()

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return
    const directionTag = document
      .getElementsByTagName('html')[0]
      .getAttribute('dir')
    const style = document.getElementById('semanticStyle')

    if (directionTag === 'ltr') {
      style.href = '/assets/css/semantic.min.css'
      document.title = 'ASAS Dashboard'
    } else {
      style.href = '/assets/css/semantic.rtl.min.css'
      document.title = 'منصة أساس'
    }
    return () => {
      isMounted = false
    }
  }, [lang])

  return (
    <Router>
      <Switch>
        <Route path="/auth" component={authLayout} />
        <SocketProvider>
          <PrivateRoute path="/" component={DashboardLayout} />
          {/* <Route
          path="/auth"
          render={({ match: { url } }) => (
            <Route path={url + "/login"} exact component={LoginPage} />
          )}
        /> */}
        </SocketProvider>
      </Switch>
    </Router>
  )
}

export default Routes
