import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button, Form} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import StateContext from '../../../context/stateContext'
import CreateShiftModal from '../../../components/ShiftsComponents/createShiftModal'
import useAsync from '../../../hooks/useAsync'
import {getAllShifts} from '../../../services/shiftService'
import moment from 'moment'
import AsasScheduleComponent from '../../../components/AsasScheduleComponent'
import routes from '../../../routes'
import {useHistory} from 'react-router'
import ViewEmployeeShifts from '../../../components/ShiftsComponents/ViewEmployeeShifts'
import ViewNormalShifts from '../../../components/ShiftsComponents/ViewNormalShifts'
import EditShiftModal from '../../../components/ShiftsComponents/editShiftModal'
import Auth from '../../../config/auth'
import {useSocket} from '../../../context/socket-context'

const ShiftsPage = () => {
  const [lang] = useLanguage()
  const shiftLang = content[lang]
  const {setShowModal} = useContext(StateContext)
  const {run, isLoading} = useAsync()
  const [isUpdated, setIsUpdated] = useState(false)
  const [selected, setSelected] = useState({
    type: '',
    data: {},
  })
  const history = useHistory()
  const [state, setState] = useState({
    selectedShift: {},
    shiftsData: [],
    allShifts: [],
  })

  const socket = useSocket()

  useEffect(() => {
    socket?.on('notification', msg => {
      if (msg.type !== 'shifts' && msg.message.users.includes(Auth.getUserId()))
        return
      setIsUpdated(prev => !prev)
    })
  }, [socket])

  useEffect(() => {
    run(getAllShifts())
      .then(({data}) => {
        let displayData = []
        data.data.map((sh, i) => {
          console.log(sh)
          displayData.push({
            Id: sh._id,
            Subject:
              sh['name' + lang.toUpperCase()] ||
              sh.employees[0]['firstName' + lang.toUpperCase()] +
                ' ' +
                sh.employees[0]['lastName' + lang.toUpperCase()],
            Type: sh.shiftType,
            StartTime: moment.utc(sh.startDate).toDate().toString(),
            EndTime: moment.utc(sh.endDate).toDate().toString(),
            Color: sh.shiftType === 'normalShift' ? '#148f64' : '#958a5f',
          })
        })
        if (selected.data) {
          if (selected.type === 'normal') {
            const i = data.data.findIndex(o => o._id === selected.data._id)
            if (i !== -1) {
              setSelected({...selected, data: data.data[i]})
            } else {
              setSelected({...selected, data: {}, type: ''})
            }
          } else {
            const i = data.data.findIndex(o => o._id === selected.data._id)
            if (i !== -1) {
              setSelected({...selected, data: data.data[i]})
            } else {
              setSelected({...selected, data: {}, type: ''})
            }
          }
        }
        setState({...state, shiftsData: displayData, allShifts: data.data})
      })
      .catch(e => {
        console.log(e)
      })
  }, [isUpdated, lang])

  const handleOnClickEvent = e => {
    console.log(e)
    e.originalEvent.preventDefault()
    let shiftId = e.event.Id
    // console.log(e.event)

    const i = state.allShifts.findIndex(o => o._id === shiftId)
    console.log(i)
    setSelected({type: e.event.Type, data: state.allShifts[i]})
  }

  return (
    <section>
      {Auth.isAdmin() || Auth.isManagerOrHigher() ? (
        <Fragment>
          <CreateShiftModal onUpdate={value => setIsUpdated(prev => !prev)} />
          <EditShiftModal onUpdate={value => setIsUpdated(prev => !prev)} />
        </Fragment>
      ) : null}

      <div className="flex items-center justify-between">
        <h2 className="text-primary m-0">{shiftLang.shifts}</h2>

        {Auth.isAdmin() || Auth.isManagerOrHigher() ? (
          <div>
            <Button
              content={shiftLang.createShiftsToEmployee}
              className="btn-goldPrimary"
              onClick={() => history.push(routes.createShiftEmployees)}
            />
            <Button
              content={shiftLang.createShift}
              className="btn-primary"
              onClick={() =>
                setShowModal({modalName: 'createShift', data: null})
              }
            />
          </div>
        ) : null}
      </div>

      <div className="mt-5">
        <Form loading={isLoading}>
          <AsasScheduleComponent
            scheduleData={state.shiftsData}
            isUpdated={isUpdated}
            onClickEvent={e => handleOnClickEvent(e)}
          />
        </Form>
      </div>
      {selected.type === 'employeeShift' && (
        <Fragment>
          <hr />
          <ViewEmployeeShifts
            empData={selected.data}
            onUpdate={value => setIsUpdated(prev => !prev)}
          />
        </Fragment>
      )}

      {selected.type === 'normalShift' && (
        <Fragment>
          <hr />
          <ViewNormalShifts
            normalShifts={selected.data}
            onUpdate={value => setIsUpdated(prev => !prev)}
          />
        </Fragment>
      )}
    </section>
  )
}

export default ShiftsPage
