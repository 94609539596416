import {Formik} from 'formik'
import React from 'react'
import {useDrag, useDrop} from 'react-dnd'
import * as Yup from 'yup'
import {
  Button,
  Form,
  FormCheckbox,
  FormInput,
  Image,
  Message,
  Modal,
  Tab,
} from 'semantic-ui-react'

import FormikControl from '../../components/formik/FormikControl'
import {authAxios} from '../../config/axiosConfig'
import useAsync from '../../hooks/useAsync'
import {itemTypes} from '../../utils/draggableItems'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import {getUserFullname} from '../../utils/user'
import AudioRecorder from '../../components/AudioRecorder'
import FileAttachment from '../../components/FileAttachment'
import {FaUserCircle} from 'react-icons/fa'
import Auth from '../../config/auth'
import {timeFromNow} from '../../utils/date-format'
import TASK_STATUS from '../../constants/task-status'
import {useHistory, useLocation} from 'react-router'
import routes from '../../routes'
import OpenTasks from '../../components/tasks/open-tasks'
import InProgressTasks from '../../components/tasks/inprogress-tasks'
import DoneTasks from '../../components/tasks/done-tasks'
import ArchivedTasks from '../../components/tasks/archived-tasks'

const TasksPage = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)

  const {pathname} = useLocation()
  const history = useHistory()

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const panes = React.useMemo(
    () => [
      {
        menuItem: selectedContent.open,
        route: content.en.open,
        render: () => <OpenTasks reload={reload} />,
      },
      {
        menuItem: selectedContent.inProgress,
        route: content.en.inProgress,
        render: () => <InProgressTasks />,
      },
      {
        menuItem: selectedContent.done,
        route: content.en.done,
        render: () => <DoneTasks />,
      },
      {
        menuItem: selectedContent.archived,
        route: content.en.archived,
        render: () => <ArchivedTasks />,
      },
    ],
    [],
  )

  function onTabChange(e, {panes, activeIndex}) {
    history.push(`${routes.tasks}/${panes[activeIndex].route}`)
  }

  React.useEffect(() => {
    for (let i = 0; i < panes.length; i++)
      if (pathname.split('?')[0].endsWith(panes[i].route)) setActiveIndex(i)
  }, [panes, pathname])

  return (
    <section>
      <div className="flex justify-between">
        <h2 className="text-primary">{selectedContent.tasks}</h2>
        {Auth.isManagerOrHigher() && !Auth.isViceManager() ? (
          <Button className="btn-primary" onClick={() => setIsOpen(true)}>
            {selectedContent.create}
          </Button>
        ) : null}
      </div>

      <Tab
        menu={{
          secondary: true,
          pointing: true,
          className: 'flex flex-wrap',
        }}
        panes={panes}
        activeIndex={activeIndex}
        onTabChange={onTabChange}
      />
      <NewTaskModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        forceReload={() => setReload(prev => !prev)}
      />
    </section>
  )
}
// const TasksPage = () => {
//   const [tasks, setTasks] = React.useState([])
//   const [isOpen, setIsOpen] = React.useState(false)
//   const [reload, setReload] = React.useState(false)

//   const [lang] = useLanguage()
//   const selectedContent = content[lang]

//   const {run, isLoading} = useAsync()
//   const {run: updateStatus, isLoading: updatingStatus} = useAsync()

//   const updateTaskStatus = (item, newStatus) => {
//     if (item.status !== newStatus) {
//       if (
//         newStatus === TASK_STATUS.open &&
//         (item.status === TASK_STATUS.inProgress ||
//           item.status === TASK_STATUS.done ||
//           item.status === TASK_STATUS.archived)
//       )
//         return
//       else if (
//         newStatus === TASK_STATUS.inProgress &&
//         (item.status === TASK_STATUS.done ||
//           item.status === TASK_STATUS.archived)
//       )
//         return
//       else if (
//         newStatus === TASK_STATUS.done &&
//         item.status === TASK_STATUS.archived
//       )
//         return
//       // perform update
//       updateStatus(
//         authAxios.patch(`/tasks/status/${item._id}`, {status: newStatus}),
//       ).then(() => {
//         let modifiedTasks = tasks?.data.map(t => {
//           if (t._id === item._id) {
//             t.status = newStatus
//           }
//           return t
//         })

//         setTasks({...tasks, data: modifiedTasks})
//       })
//     }
//   }

//   React.useEffect(() => {
//     run(authAxios.get('/tasks')).then(({data}) => setTasks(data))
//   }, [reload, run])

//   return (
//     <section>
//       <div className="flex justify-between">
//         <h2 className="text-primary">{selectedContent.tasks}</h2>
//         {Auth.isManagerOrHigher() && !Auth.isViceManager() ? (
//           <Button className="btn-primary" onClick={() => setIsOpen(true)}>
//             {selectedContent.create}
//           </Button>
//         ) : null}
//       </div>

//       {isLoading ? (
//         <div className="flex items-center justify-center">
//           <Loader active />
//         </div>
//       ) : (
//         <>
//           <Dimmer active={updatingStatus} className="opacity-30">
//             <Loader active={updatingStatus} />
//           </Dimmer>
//           <div className="flex items-stretch mt-4">
//             {Object.values(TASK_STATUS).map(status => (
//               <TaskStatus
//                 key={status}
//                 status={status}
//                 onDrop={updateTaskStatus}
//                 tasks={tasks?.data?.filter(t => t.status === status)}
//                 forceReload={() => setReload(prev => !prev)}
//               />
//             ))}
//           </div>
//         </>
//       )}
//       <NewTaskModal
//         isOpen={isOpen}
//         setIsOpen={setIsOpen}
//         forceReload={() => setReload(prev => !prev)}
//       />
//     </section>
//   )
// }

const TaskCard = ({task, forceReload}) => {
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const [{isDragging}, drag] = useDrag({
    type: itemTypes.CARD,
    item: {
      type: itemTypes.CARD,
      _id: task._id,
      status: task.status,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  return (
    <div
      className="bg-gray-300 rounded-md"
      onClick={() => setIsDetailsOpen(true)}
    >
      <div
        className={`bg-white cursor-pointer hover:bg-gray-50 p-4 my-4 shadow-md rounded-md ${
          isDragging ? 'transform rotate-3 opacity-0' : ''
        }`}
        ref={drag}
      >
        <h4 className="text-lg mb-1">{task.title}</h4>

        <div>
          <p className="text-gray-600 my-1 text-sm">
            {selectedContent.description}
          </p>
          <p>{task.description}</p>
        </div>

        <div>
          <p className="text-gray-600 my-1 text-sm">
            {selectedContent.deadline}
          </p>
          <p>{new Date(task.deadline).toLocaleDateString()}</p>
        </div>

        {task.attachments?.file?.link ? (
          <div className="flex my-3">
            <p>
              <span className="text-gray-600">
                {selectedContent.attachments}:
              </span>{' '}
              <a
                rel="noreferrer"
                href={task.attachments?.file?.link}
                target="_blank"
                className="underline"
              >
                {selectedContent.clickToOpen}
              </a>
            </p>
          </div>
        ) : null}

        {task.attachments?.voice?.link ? (
          <div className="flex mb-3">
            <div>
              <span className="text-gray-600">
                {selectedContent.voiceNote}:
              </span>{' '}
              <audio controls className="my-4 w-80">
                <source src={task.attachments?.voice?.link} type="audio/ogg" />
              </audio>
            </div>
          </div>
        ) : null}

        <div className="mt-2">
          <p className="text-sm text-gray-600 mb-2">
            {selectedContent.assignedEmployee}
          </p>
          <div clsasName="flex items-center">
            {task?.assignedTo?.avatar ? (
              <Image
                src={task.assignedTo.avatar}
                circular
                size="small"
                className="w-8 h-8 rtl:ml-3 ltr:mr-3 inline-block"
              />
            ) : (
              <FaUserCircle className="inline-block text-primary w-8 h-8 ltr:mr-3 rtl:ml-3" />
            )}
            {getUserFullname(task.assignedTo, lang)}
          </div>
        </div>
      </div>
      <TaskDetailsModal
        task={task}
        isOpen={isDetailsOpen}
        setIsOpen={setIsDetailsOpen}
        forceReload={forceReload}
      />
    </div>
  )
}

const TaskDetailsModal = ({task, isOpen, setIsOpen, forceReload}) => {
  const [commentMessage, setCommentMessage] = React.useState('')
  const [comments, setComments] = React.useState(task.comments || [])
  const [confirmDelete, setConfirmDelete] = React.useState(false)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading: isDeleting} = useAsync()
  const {run: sendComment, isLoading: isSending} = useAsync()

  const onNewCommment = () => {
    if (commentMessage)
      sendComment(
        authAxios
          .patch(`/tasks/${task._id}`, {
            comment: {message: commentMessage},
          })
          .then(({data /* updated task */}) => {
            task.comments = data.data.comments
            setComments(data.data.comments)
            setCommentMessage('')
          }),
      )
  }

  const onDelete = () => {
    run(authAxios.delete(`/tasks/${task._id}`)).then(() => {
      console.log(forceReload)
      forceReload()
    })
  }

  React.useEffect(() => {
    setComments(task.comments)
  }, [task])

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} size="tiny">
      <Modal.Content>
        <div class="flex justify-between">
          <h3 className="text-xl mb-1">{task.title}</h3>
          {Auth.isManagerOrHigher() ? (
            <Button
              className="btn-danger"
              size="small"
              onClick={() => setConfirmDelete(true)}
            >
              {selectedContent.delete}
            </Button>
          ) : null}
        </div>

        {confirmDelete ? (
          <Message
            error
            className="flex items-center justify-between mt-4"
            size="small"
          >
            {selectedContent.deleteTaskConfirmation}
            <Button
              className="btn-danger"
              size="small"
              onClick={onDelete}
              loading={isDeleting}
            >
              {selectedContent.confirm}
            </Button>
          </Message>
        ) : null}

        <div className="mb-4">
          <p className="text-gray-600 my-1 text-sm">
            {selectedContent.description}
          </p>
          <p>{task.description}</p>
        </div>

        <div className="mb-4">
          <p className="text-gray-600 my-1 text-sm">
            {selectedContent.deadline}
          </p>
          <p>{new Date(task.deadline).toLocaleDateString()}</p>
        </div>

        {task.attachments?.file?.link ? (
          <div className="flex my-3">
            <p>
              <span className="text-gray-600">
                {selectedContent.attachments}:
              </span>{' '}
              <a
                rel="noreferrer"
                href={task.attachments?.file?.link}
                target="_blank"
                className="underline"
              >
                {selectedContent.clickToOpen}
              </a>
            </p>
          </div>
        ) : null}

        {task.attachments?.voice?.link ? (
          <div className="flex mb-3">
            <div>
              <span className="text-gray-600">
                {selectedContent.voiceNote}:
              </span>{' '}
              <audio controls className="my-4 w-80">
                <source src={task.attachments?.voice?.link} type="audio/ogg" />
              </audio>
            </div>
          </div>
        ) : null}

        <div className="mt-2">
          <p className="text-sm text-gray-600 mb-2">
            {selectedContent.assignedEmployee}
          </p>
          <div clsasName="flex items-center">
            {task?.assignedTo?.avatar ? (
              <Image
                src={task.assignedTo.avatar}
                circular
                size="small"
                className="w-8 h-8 rtl:ml-3 ltr:mr-3 inline-block"
              />
            ) : (
              <FaUserCircle className="inline-block text-primary w-8 h-8 ltr:mr-3 rtl:ml-3" />
            )}
            {getUserFullname(task.assignedTo, lang)}
          </div>
        </div>

        <div>
          <h4 className="mt-4 text-sm text-gray-600">
            {selectedContent.comments}
          </h4>
          <div className="my-4 flex flex-col items-start">
            {comments.map(c => (
              <div className="my-2 px-3 py-2 bg-gray-100 rounded-b-lg rounded-tr-lg">
                <div className="flex justify-between gap-4 text-sm mb-1 text-gray-600 ">
                  <p clsasName="">{timeFromNow(c.createdAt, lang)}</p>
                  <p>{getUserFullname(c.createdBy, lang)}</p>
                </div>
                <p>{c.message}</p>
              </div>
            ))}
          </div>
          <div className="mt-2">
            <div class="flex items-center">
              <FormInput
                placeholder={selectedContent.comment}
                size="small"
                value={commentMessage}
                onChange={e => setCommentMessage(e.target.value)}
              />
              <Button
                size="small"
                className="ltr:ml-2 rtl:mr-2"
                loading={isSending}
                onClick={onNewCommment}
              >
                {selectedContent.send}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const TaskStatus = ({status, onDrop, tasks, forceReload}) => {
  const [hidden, setHidden] = React.useState(true)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const [{isOver}, drop] = useDrop({
    accept: itemTypes.CARD,
    drop: (item, monitor) => onDrop(item, status),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  console.log({hidden})
  return (
    <aside
      ref={drop}
      className={`${
        isOver ? 'bg-gray-200' : 'bg-gray-100'
      } p-4 ltr:mr-8 rtl:ml-8 w-96 rounded-md`}
    >
      {status === TASK_STATUS.archived ? (
        <div className="flex items-center justify-between">
          <h4 className="text-gray-600 text-lg font-bold">
            {selectedContent[status]}
          </h4>
          <FormCheckbox
            label="Hide"
            checked={hidden}
            onChange={(_, e) => setHidden(e?.checked)}
          />
        </div>
      ) : (
        <h4 className="text-gray-600 text-lg font-bold">
          {status === TASK_STATUS.inProgress
            ? selectedContent.inProgress
            : selectedContent[status]}
        </h4>
      )}
      {status === TASK_STATUS.archived
        ? !hidden
          ? tasks.map(t => <TaskCard task={t} forceReload={forceReload} />)
          : null
        : tasks?.map(t => <TaskCard task={t} forceReload={forceReload} />)}
    </aside>
  )
}

const NewTaskModal = ({isOpen, setIsOpen, forceReload}) => {
  const [audioData, setAudioData] = React.useState(null)
  const [attachmentData, setAttachmentData] = React.useState(null)
  const [employees, setEmployees] = React.useState([])

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {run: createTask, isLoading: creatingTask} = useAsync()

  const newTaskSchema = Yup.object({
    title: Yup.string().min(3).max(50).required(selectedContent.required),
    description: Yup.string()
      .min(3)
      .max(255)
      .required(selectedContent.required),
    deadline: Yup.date('Please provide a valid date').required(
      selectedContent.required,
    ),
    assignedTo: Yup.string().required(selectedContent.required),
  })

  const createNewTask = (values, {resetForm}) => {
    const data = new FormData()

    for (let k in values) {
      data.append(k, values[k])
    }

    if (audioData) data.append('audioFile', audioData.file)
    if (attachmentData) data.append('taskFile', attachmentData)

    createTask(authAxios.post('/tasks', data)).then(() => {
      resetForm()
      forceReload()
      setAudioData(null)
      setAttachmentData(null)
      setIsOpen(false)
    })
  }

  React.useEffect(() => {
    run(authAxios.get('/users/employees')).then(({data}) => {
      let modifiedEmployees = data?.data?.map(e => ({
        key: e.user._id,
        text: getUserFullname(e.user, lang),
        value: e.user._id,
      }))

      setEmployees(modifiedEmployees)
    })
  }, [])

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} size="tiny">
      <Modal.Content>
        <h3>{selectedContent.newTask}</h3>
        <div className="mt-8">
          <Formik
            initialValues={{
              title: '',
              description: '',
              deadline: '',
              assignedTo: '',
            }}
            validationSchema={newTaskSchema}
            onSubmit={createNewTask}
          >
            {formik => (
              <Form
                onSubmit={formik.submitForm}
                loading={isLoading || creatingTask}
              >
                <FormikControl
                  name="title"
                  control="input"
                  placeholder="Title"
                  label={selectedContent.title}
                />
                <FormikControl
                  control="textarea"
                  name="description"
                  placeholder="Description"
                  label={selectedContent.description}
                />
                <FormikControl
                  name="deadline"
                  control="date"
                  label={selectedContent.deadline}
                />
                <FormikControl
                  name="assignedTo"
                  control="select"
                  label={selectedContent.assignedEmployee}
                  options={employees}
                />
                <div className="text-primary my-4">
                  <AudioRecorder setAudioData={setAudioData} />
                </div>
                <FileAttachment setAttachmentData={setAttachmentData} />
                <Button className="btn-primary w-full mt-4" type="submit">
                  {selectedContent.submit}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default TasksPage
