import {Fragment, useEffect, useState} from 'react'
import {Button, Form, Icon, Image, Tab} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import borderIcon from '../../assets/images/ic_border.svg'
import {updateRatingSettings} from '../../services/settingsService'
import {useToasts} from 'react-toast-notifications'

const RatingSettings = ({ratings, setRatings, isLoading}) => {
  const [lang] = useLanguage()
  const ratingLang = content[lang]
  const {run, isLoading: isUpdating} = useAsync()
  const {addToast} = useToasts()

  const [state, setState] = useState({
    ratingCount: 1,
    ratingSettings: [],
  })

  useEffect(() => {
    if (ratings) {
      setState({
        ...state,
        ratingSettings:
          ratings.ratings.length > 0
            ? ratings.ratings
            : [{nameEN: '', nameAR: '', fields: []}],
        ratingCount: ratings.ratings.length || 1,
      })
    }
  }, [ratings])

  const handleOnAddRate = i => {
    // console.log(i)
    const arr = [...state.ratingSettings]
    arr[i].fields.push({nameEN: '', nameAR: ''})

    setState({...state, ratingSettings: arr})
  }

  const handleOnDeleteRate = (i, j) => {
    const arr = [...state.ratingSettings]
    arr[i].fields.splice(j, 1)
    setState({...state, ratingSettings: arr})
  }

  const handleOnChangeRateTitle = (value, i, l) => {
    const arr = [...state.ratingSettings]
    arr[i]['name' + l] = value

    setState({...state, ratingSettings: arr})
  }

  const handleOnChangeRateContent = (value, i, j, l) => {
    const arr = [...state.ratingSettings]
    arr[i].fields[j]['name' + l] = value

    setState({...state, ratingSettings: arr})
  }

  const handleOnSubmit = () => {
    // console.log(state)
    run(
      updateRatingSettings({
        settingId: ratings.settingId,
        ratings: state.ratingSettings,
      }),
    )
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <Tab.Pane className="m-0 p-0 border-0">
      <Form className="max-w-xl mx-auto" loading={isLoading || isUpdating}>
        <div className="my-5 ltr:text-right rtl:text-left">
          <Button
            content={ratingLang.add}
            className="btn-primary"
            onClick={() =>
              setState({
                ...state,
                ratingSettings: [
                  ...state.ratingSettings,
                  {nameEN: '', nameAR: '', fields: []},
                ],
                ratingCount: state.ratingCount + 1,
              })
            }
          />
        </div>
        {new Array(state.ratingCount).fill(1).map((k, i) => (
          <Fragment key={i}>
            <div className="flex items-center mt-5">
              <Image
                src={borderIcon}
                width="5px"
                height="5px"
                className="ltr:mr-5 rtl:ml-5"
                alt="border icon"
              />
              <div className="w-full">
                <label className="font-bold text-base text-primary">
                  {ratingLang.ratingTitle}
                </label>
                <Form.Input
                  placeholder={ratingLang.ratingTitleEN}
                  defaultValue={state.ratingSettings[i]?.nameEN}
                  className="mt-2"
                  onChange={(e, {value}) =>
                    handleOnChangeRateTitle(value, i, 'EN')
                  }
                />

                <Form.Input
                  placeholder={ratingLang.ratingTitleAR}
                  className="mt-2"
                  defaultValue={state.ratingSettings[i]?.nameAR}
                  onChange={(e, {value}) =>
                    handleOnChangeRateTitle(value, i, 'AR')
                  }
                />
              </div>
            </div>

            <div className="mt-4">
              <div className="flex justify-end mb-4">
                <Icon
                  name="plus circle"
                  size="large"
                  className="text-primary ml-auto cursor-pointer"
                  onClick={() => handleOnAddRate(i)}
                />
              </div>

              {state.ratingSettings[i]?.fields.map((f, j) => (
                <Form.Field className="w-full" key={j}>
                  <label className="text-primary">
                    {ratingLang.ratingContent}
                  </label>
                  <div className="flex items-center mb-10">
                    <div className="w-full">
                      <Form.Input
                        placeholder={ratingLang.ratingContentEN}
                        className="w-full"
                        defaultValue={state.ratingSettings[i].fields[j]?.nameEN}
                        onChange={(e, {value}) =>
                          handleOnChangeRateContent(value, i, j, 'EN')
                        }
                      />
                      <Form.Input
                        placeholder={ratingLang.ratingContentAR}
                        className="w-full"
                        defaultValue={state.ratingSettings[i].fields[j]?.nameAR}
                        onChange={(e, {value}) =>
                          handleOnChangeRateContent(value, i, j, 'AR')
                        }
                      />
                    </div>
                    <Icon
                      name="times circle"
                      size="large"
                      className="text-red-700 ltr:ml-4 rtl:mr-4 cursor-pointer"
                      onClick={() => handleOnDeleteRate(i, j)}
                    />
                  </div>
                  <hr />
                </Form.Field>
              ))}
            </div>
          </Fragment>
        ))}

        <div className="text-center my-5">
          <Button
            content={ratingLang.submit}
            className="btn-primary"
            onClick={handleOnSubmit}
          />
        </div>
      </Form>
    </Tab.Pane>
  )
}

export default RatingSettings
