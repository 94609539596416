import {BsFileEarmarkText} from 'react-icons/bs'
import {useHistory} from 'react-router-dom'
import {Button} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import routes from '../../../routes'

const WaitingApproval = ({goBack}) => {
  const [lang] = useLanguage()
  const approvalLang = content[lang]
  const history = useHistory()

  const handleBack = () => {
    goBack('')
  }
  return (
    <div className="p-10 text-center w-full">
      <BsFileEarmarkText size={100} className="text-primary m-auto" />
      <p className="text-5xl text-primary my-5">
        {approvalLang.waitinaApproval}
      </p>
      <p className="text-lg ">{approvalLang.waitinaApprovalContent}</p>

      <div className="my-6">
        <Button
          content={approvalLang.login}
          className="btn-primary"
          onClick={handleBack}
        />
      </div>
    </div>
  )
}

export default WaitingApproval
