import {Fragment} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {Breadcrumb as SBreadcrumb} from 'semantic-ui-react'
import {useLanguage} from '../context/languageContext'
import {content} from '../localization/content'
import routes from '../routes'
import isMongoId from 'validator/lib/isMongoId'

function Breadcrumb() {
  const [lang] = useLanguage()
  const {pathname} = useLocation()
  const pathnames = pathname.split('/').filter(x => x)
  const breadcrumbContent = content[lang]

  return (
    <SBreadcrumb>
      {Array.isArray(pathname.match(routes.dashboard, 'i')) ? null : (
        <>
          <SBreadcrumb.Section link>
            <Link to={routes.dashboard}>{breadcrumbContent.dashboard}</Link>
          </SBreadcrumb.Section>
          <SBreadcrumb.Divider>/</SBreadcrumb.Divider>
        </>
      )}

      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
        if (isMongoId(name)) return null
        return index === pathnames.length - 1 ? (
          <SBreadcrumb.Section active key={index}>
            {breadcrumbContent[name] || name}
          </SBreadcrumb.Section>
        ) : (
          <Fragment key={index}>
            <SBreadcrumb.Section link>
              <Link to={routeTo}>{breadcrumbContent[name] || name}</Link>
            </SBreadcrumb.Section>
            <SBreadcrumb.Divider>/</SBreadcrumb.Divider>
          </Fragment>
        )
      })}
    </SBreadcrumb>
  )
}

export default Breadcrumb
