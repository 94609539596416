import {Formik} from 'formik'
import {Fragment, useContext, useEffect, useState} from 'react'
import {Modal, Form, Icon, Button} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import StateContext from '../../../context/stateContext'
import useAsync from '../../../hooks/useAsync'
import {content} from '../../../localization/content'
import * as Yup from 'yup'
import FormikControl from '../../formik/FormikControl'
import moment from 'moment'
import {uploadAttachFile} from '../../../services/profileService'
import {useHistory} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import Auth from '../../../config/auth'

const EditModal = ({storeValues}) => {
  const {showModal, setShowModal} = useContext(StateContext)
  const [open, setOpen] = useState(false)
  const {run, isLoading} = useAsync()
  const [type, setType] = useState('')
  const history = useHistory()
  const {addToast} = useToasts()
  const [lang] = useLanguage()
  const modalLang = content[lang]
  const [attachFile, setAttachFile] = useState('')
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    if (
      showModal.modalName === 'qualification' ||
      showModal.modalName === 'course'
    ) {
      setOpen(true)
      setType(showModal.modalName)
    } else {
      setOpen(false)
      setType('')
    }
  }, [showModal])

  const qualificationSchema = Yup.object({
    qualificationName: Yup.string().required(modalLang.required),
    universityName: Yup.string().required(modalLang.required),
    graduationYear: Yup.string().required(modalLang.required),
  })

  const courseSchema = Yup.object({
    courseName: Yup.string().required(modalLang.required),
    courseDate: Yup.string().required(modalLang.required),
  })

  const handleOnChangeFile = e => {
    setAttachFile(e.target.files[0])
    setFileName(e.target.files[0].name)
  }

  const handleOnSubmit = values => {
    const userId = JSON.parse(localStorage.getItem('user') || '[]')

    if (userId.length === 0) {
      history.push('/auth/login')
      addToast(modalLang.noAccess, {appearance: 'error'})
      return
    }

    let newData = new FormData()
    if (type === 'qualification') {
      newData.append('qualificationName', values.qualificationName)
      newData.append('universityName', values.universityName)
      newData.append('attachmentType', 'qualification')
      newData.append('graduationYear', values.graduationYear)
    } else {
      newData.append('courseName', values.courseName)
      newData.append('duration', values.duration)
      newData.append('attachmentType', 'course')
      newData.append('destination', values.destination)
      newData.append('courseDate', values.courseDate)
    }
    newData.append('userId', userId._id)
    newData.append('rate', values.rate)
    newData.append('degree', values.degree)
    newData.append('attachmentFile', attachFile)

    run(uploadAttachFile(newData))
      .then(({data}) => {
        storeValues(data.data)
        addToast(data.message[lang], {appearance: 'success'})
        setShowModal({modalName: '', data: null})
      })
      .catch(e => {
        console.log(e)
        e.errors.map(error => {
          if (
            error.field === 'notAuthorized' ||
            error.field === 'authToken' ||
            error.field === 'token'
          ) {
            Auth.logout()
            // localStorage.removeItem('user')
            setShowModal({modalName: '', data: null})
            history.push('/auth/login')
            addToast(error.message[lang], {appearance: 'error'})
            return
          }
        })
      })
  }

  return (
    <Modal
      open={open}
      className="main-font"
      size="tiny"
      closeIcon
      onClose={() => setShowModal({modalName: '', data: null})}
    >
      <Modal.Header>
        {modalLang.add} {modalLang[type]}
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={
            type === 'qualification'
              ? {
                  qualificationName: '',
                  universityName: '',
                  graduationYear: moment.now(),
                  rate: '',
                  degree: '',
                }
              : {
                  courseName: '',
                  duration: '',
                  destination: '',
                  courseDate: moment.now(),
                  degree: '',
                  rate: '',
                }
          }
          validationSchema={
            type === 'qualification' ? qualificationSchema : courseSchema
          }
          onSubmit={handleOnSubmit}
        >
          {formik => (
            <Form loading={isLoading} onSubmit={formik.submitForm}>
              {type === 'qualification' && (
                <Fragment>
                  <Form.Field required>
                    <FormikControl
                      name="qualificationName"
                      label={modalLang.qualificationName}
                      placeholder={modalLang.qualificationName}
                      control="input"
                    />
                  </Form.Field>

                  <Form.Field required>
                    <FormikControl
                      name="universityName"
                      label={modalLang.universityName}
                      placeholder={modalLang.universityName}
                      control="input"
                    />
                  </Form.Field>

                  <Form.Field required>
                    <FormikControl
                      name="graduationYear"
                      label={modalLang.graduationYear}
                      placeholder={modalLang.graduationYear}
                      control="date"
                    />
                  </Form.Field>
                </Fragment>
              )}

              {type === 'course' && (
                <Fragment>
                  <Form.Field required>
                    <FormikControl
                      name="courseName"
                      label={modalLang.courseName}
                      placeholder={modalLang.courseName}
                      control="input"
                    />
                  </Form.Field>

                  <Form.Field>
                    <FormikControl
                      name="destination"
                      label={modalLang.destination}
                      placeholder={modalLang.destination}
                      control="input"
                    />
                  </Form.Field>

                  <Form.Field>
                    <FormikControl
                      name="duration"
                      label={modalLang.duration}
                      placeholder={modalLang.duration}
                      control="input"
                    />
                  </Form.Field>

                  <Form.Field>
                    <FormikControl
                      name="courseDate"
                      label={modalLang.date}
                      placeholder={modalLang.date}
                      control="date"
                    />
                  </Form.Field>
                </Fragment>
              )}

              <Form.Field>
                <FormikControl
                  name="degree"
                  label={modalLang.degree}
                  placeholder={modalLang.degree}
                  control="input"
                />
              </Form.Field>

              <Form.Field>
                <FormikControl
                  name="rate"
                  label={modalLang.rate}
                  placeholder={modalLang.rate}
                  control="input"
                />
              </Form.Field>

              <Form.Field className="my-10">
                <input
                  type="file"
                  id="upload-qualification"
                  accept="image/*,application/pdf"
                  className="hidden"
                  onChange={handleOnChangeFile}
                />
                <label
                  htmlFor="upload-qualification"
                  className="cursor-pointer"
                >
                  <div className="flex items-center">
                    <Icon name="attach" className="text-primary" size="large" />
                    <p className="text-primary text-lg">
                      {modalLang.attachFile}
                    </p>
                  </div>
                </label>
                <small className="ltr:ml-7 rtl:mr-7 text-gray-400">
                  {fileName}
                </small>
              </Form.Field>

              <div className="my-10 text-center">
                <Button
                  content={
                    type === 'qualification'
                      ? modalLang.addQualification
                      : modalLang.addCourse
                  }
                  className="btn-primary"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default EditModal
