import {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import {Button, Form, Modal} from 'semantic-ui-react'
import Auth from '../../config/auth'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {excuseMeeting} from '../../services/meetingService'

const ExcuseModal = ({storeValues}) => {
  const {setShowModal, showModal} = useContext(StateContext)
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const {addToast} = useToasts()
  const modalLang = content[lang]
  const [open, setOpen] = useState(false)
  const [reason, setReason] = useState('')
  const [meetingId, setMeetingId] = useState('')
  const history = useHistory()

  useEffect(() => {
    if (showModal.modalName === 'excuseMeeting') {
      setOpen(true)

      setMeetingId(showModal.data.meetingId)
    } else {
      setOpen(false)
    }
  }, [showModal])

  const handleOnClickSubmit = () => {
    run(excuseMeeting({meetingId: meetingId, reason: reason}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        setShowModal({modalName: '', data: null})
        setReason('')
        storeValues(true)
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/auth/login')
              return
            }
            if (error.field === 'permissionDenied') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/requests')
              return
            }
          })
        }
      })
  }

  return (
    <Modal
      open={open}
      className="main-font"
      size="tiny"
      closeIcon
      closeOnDimmerClick={false}
      onClose={() => {
        setShowModal({modalName: '', data: null})
      }}
    >
      <Modal.Header>{modalLang.excuseMeeting}</Modal.Header>
      <Modal.Content>
        <Form loading={isLoading}>
          <Form.Field>
            <Form.Input
              label={<label className="text-primary">{modalLang.reason}</label>}
              placeholder={modalLang.reason}
              onChange={(e, {value}) => setReason(value)}
            />
          </Form.Field>

          <div className="text-center">
            <Button
              content={modalLang.excuseMeeting}
              className="btn-primary"
              onClick={handleOnClickSubmit}
            />
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default ExcuseModal
