import {Fragment, useContext, useEffect, useState} from 'react'
import {Button, Form, Icon, Image, Modal} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {DateInput, TimeInput} from 'semantic-ui-calendar-react'
import {formatDate, formatTime, stringToDate} from '../../utils/date-format'
import moment from 'moment'
import {
  getAllDepartments,
  getEmployeesByDepartment,
} from '../../services/dashboardService'
import borderIcon from '../../assets/images/ic_border.svg'
import {getSubDepartments} from '../../utils/handleDepartments'
import {createMeeting} from '../../services/meetingService'
import {useToasts} from 'react-toast-notifications'
import {useHistory} from 'react-router'
import Auth from '../../config/auth'

const CreateMeetingModal = ({storeValue}) => {
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const {addToast} = useToasts()
  const [next, setNext] = useState(false)
  const modalLang = content[lang]
  const [open, setOpen] = useState(false)
  const {showModal, setShowModal} = useContext(StateContext)
  const [type, setType] = useState('')
  const [allDepartments, setAllDepartments] = useState([])
  const [departments, setDepartments] = useState([])
  const [subDepartments, setSubDepartments] = useState([])
  const history = useHistory()
  const [addAgenda, setAddAgenda] = useState(1)
  const [disabled, setDisabled] = useState(false)
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [state, setState] = useState({
    translated: false,
    topic: '',
    topicAR: '',
    date: '',
    from: '',
    to: '',
    duration: '',
    location: '',
    master: false,
    department: [],
    subDepartments: [],
    employees: [],
    description: '',
    agenda: [],
  })

  useEffect(() => {
    if (showModal.modalName === 'Meeting') {
      setOpen(true)
      setType('meetings')

      run(getAllDepartments())
        .then(({data}) => {
          let departmentArr = []
          setAllDepartments(data.data)
          if (data.data.masterDepartments.length > 0) {
            data.data.masterDepartments.forEach((department, i) => {
              departmentArr.push({
                key: department._id,
                text: department['name' + lang.toUpperCase()],
                value: department._id,
              })
            })
            setDepartments(departmentArr)
            if (Auth.isMaster()) {
              const filtered = getSubDepartments(
                [Auth.isMaster().department],
                data.data,
                lang,
              )

              setSubDepartments(filtered)
            }
          }
        })
        .catch(e => {
          console.log(e)
          if (e === undefined) return
          if (e.errors || e.errors.length > 0) {
            e.errors.map(error => {
              if (
                error.field === 'notAuthorized' ||
                error.field === 'authToken' ||
                error.field === 'token'
              ) {
                Auth.logout()
                // localStorage.removeItem('user')
                addToast(error.message[lang], {appearance: 'error'})
                history.push('/auth/login')
                return
              }
              if (error.field === 'permissionDenied') {
                addToast(error.message[lang], {appearance: 'error'})
                history.push('/requests')
                return
              }
            })
          }
        })

      if (Auth.isManager()) {
        setState({
          ...state,
          master: false,
          subDepartments: [Auth.isManager().department],
        })

        getEmployees([Auth.isManager().department])
      }
    } else {
      setOpen(false)
      setNext(false)
    }
  }, [showModal, lang])

  useEffect(() => {
    if (state.from !== '' && state.to !== '') {
      const duration = moment
        .utc(
          moment.duration(moment(state.to).diff(state.from)).asMilliseconds(),
        )
        .format('h:mm')
      setState({...state, duration: duration})
    }
  }, [state.to, state.from])

  const handleOnChangeTime = (value, type) => {
    const modifiedTime = moment(
      `${state.date} ${value}`,
      'DD-MM-YYYY hh:mm A',
    ).format()
    setState({...state, [type]: modifiedTime})
  }

  const handleOnChangeMasterCheckbox = (e, {value, checked}) => {
    setSelectedEmployees([])
    setState({
      ...state,
      employees: [],
    })
    if (checked) {
      setState({...state, master: checked})
      if (state.department.length > 0) {
        setState({...state, subDepartments: [], master: checked})
        getEmployees(state.department)
      }
    }

    if (!checked) {
      setState({...state, master: checked})
      if (state.department.length > 0) {
        const filtered = getSubDepartments(
          state.department,
          allDepartments,
          lang,
        )
        setSubDepartments(filtered)
      }
    }
  }

  const handleOnChangeDepartment = (e, {value}) => {
    setState({...state, department: value})
    if (state.master) {
      getEmployees(value)
      return
    }
    const filtered = getSubDepartments(value, allDepartments, lang)
    setSubDepartments(filtered)
  }

  const handleOnChangeSubDepartment = (e, {value}) => {
    setState({...state, subDepartments: value})
    getEmployees(value)
  }

  const getEmployees = departmentId => {
    run(getEmployeesByDepartment({departmentId: departmentId}))
      .then(({data}) => {
        let employee = []
        data.data.forEach(emp => {
          employee.push({
            key: emp._id,
            text:
              emp['firstName' + lang.toUpperCase()] +
              ' ' +
              emp['lastName' + lang.toUpperCase()],
            value: emp._id,
          })
        })

        setSelectedEmployees(employee)
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          e.errors.forEach(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/auth/login')
              return
            }
            if (error.field === 'permissionDenied') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/requests')
              return
            }
          })
        }
      })
  }

  const handleOnSubmit = () => {
    let newMeeting
    if (Auth.isManager()) {
      newMeeting = {
        department: state.subDepartments,
      }
    } else if (Auth.isMaster()) {
      newMeeting = {
        department: state.department,
      }
    } else {
      newMeeting = {
        department: state.master ? state.department : state.subDepartments,
      }
    }

    newMeeting = {
      ...newMeeting,
      employees: [
        ...state.employees.map(newEmp => {
          return {
            empId: newEmp,
          }
        }),
      ],
      isTranslated: state.translated,
      meetingTopic: state.topic,
      meetingTopicAR: state.topicAR,
      meetingDate: state.date,
      meetingStartTime: state.from,
      meetingEndTime: state.to,
      duration: state.duration,
      meetingAgenda: state.agenda,
      meetingDescription: state.description,
      meetingLocation: state.location,
    }
    run(createMeeting(newMeeting))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        setShowModal({modalName: '', data: null})
        storeValue(true)
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        // if (e.errors || e.errors.length > 0) {
        //   e.errors.map(error => {
        //     if (
        //       error.field === 'notAuthorized' ||
        //       error.field === 'authToken' ||
        //       error.field === 'token'
        //     ) {
        //       Auth.logout()
        //       // localStorage.removeItem('user')
        //       addToast(error.message[lang], {appearance: 'error'})
        //       history.push('/auth/login')
        //       return
        //     }
        //     if (error.field === 'permissionDenied') {
        //       addToast(error.message[lang], {appearance: 'error'})
        //       history.push('/requests')
        //       return
        //     }
        //   })
        // }
      })
  }

  return (
    <Modal
      open={open}
      className="main-font"
      size="tiny"
      closeIcon
      closeOnDimmerClick={false}
      onClose={() => {
        setNext(false)
        setShowModal({modalName: '', data: null})
      }}
    >
      <Modal.Header>
        {next && (
          <Icon
            name="angle left"
            size="small"
            className="ltr:mr-3 rtl:ml-3 cursor-pointer"
            onClick={() => setNext(prev => !prev)}
          />
        )}
        {modalLang.add} {modalLang[type]}
      </Modal.Header>
      <Modal.Content>
        <Form loading={isLoading}>
          {!next && (
            <Fragment>
              <Form.Field>
                <Form.Checkbox
                  checked={state.translated}
                  toggle
                  label={modalLang.translated}
                  onChange={(e, {value}) =>
                    setState({...state, translated: !state.translated})
                  }
                />
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.Input
                      label={
                        <label className="text-primary">
                          {modalLang.meetingTopic}
                        </label>
                      }
                      placeholder={modalLang.meetingTopic}
                      onChange={(e, {value}) =>
                        setState({...state, topic: value})
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              {state.translated && (
                <Form.Field>
                  <div className="flex items-center">
                    <Image
                      src={borderIcon}
                      width="5px"
                      height="5px"
                      className="ltr:mr-5 rtl:ml-5"
                      alt="border icon"
                    />
                    <div className="w-full">
                      <Form.Input
                        label={
                          <label className="text-primary">
                            {modalLang.meetingTopicAR}
                          </label>
                        }
                        placeholder={modalLang.meetingTopicAR}
                        onChange={(e, {value}) =>
                          setState({...state, topicAR: value})
                        }
                      />
                    </div>
                  </div>
                </Form.Field>
              )}

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <DateInput
                      value={formatDate(state.date)}
                      label={
                        <label className="text-primary">{modalLang.date}</label>
                      }
                      onChange={(e, {value}) =>
                        setState({...state, date: stringToDate(value)})
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="5px"
                  height="5px"
                  className="ltr:mr-5 rtl:ml-5"
                  alt="border icon"
                />
                <div className="w-full">
                  <label className="font-bold text-goldPrimary">
                    {modalLang.meetingTime}
                  </label>
                  <Form.Group widths="equal" className="mt-3">
                    <Form.Field>
                      <TimeInput
                        name="from"
                        value={formatTime(state.from)}
                        label={
                          <label className="text-primary">
                            {modalLang.from}
                          </label>
                        }
                        timeFormat="AMPM"
                        onChange={(e, {value}) =>
                          handleOnChangeTime(value, 'from')
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <TimeInput
                        name="to"
                        label={
                          <label className="text-primary">{modalLang.to}</label>
                        }
                        value={formatTime(state.to)}
                        timeFormat="AMPM"
                        onChange={(e, {value}) =>
                          handleOnChangeTime(value, 'to')
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              </div>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <p className="font-bold text-goldPrimary">
                      {modalLang.duration}:{' '}
                      <span className="font-normal text-gray-600">
                        {/invalid/.test(state.duration) ? '' : state.duration}
                      </span>
                    </p>
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.Input
                      label={
                        <label className="text-primary">
                          {modalLang.meetingLocation}
                        </label>
                      }
                      placeholder={modalLang.meetingLocation}
                      onChange={(e, {value}) =>
                        setState({...state, location: value})
                      }
                    />
                  </div>
                </div>
              </Form.Field>
            </Fragment>
          )}

          {next && (
            <Fragment>
              {Auth.isManager() && (
                <Fragment>
                  <Form.Field>
                    <Form.Checkbox
                      checked={state.master}
                      disabled={disabled}
                      label={<label>{modalLang.isMaster}</label>}
                      onChange={handleOnChangeMasterCheckbox}
                    />
                  </Form.Field>

                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <label className="font-bold text-base mt-4 text-primary">
                          {modalLang.department}
                        </label>
                        <Form.Dropdown
                          placeholder={modalLang.department}
                          fluid
                          search
                          clearable
                          disabled={disabled}
                          multiple
                          selection
                          className="mt-2"
                          options={departments}
                          onChange={handleOnChangeDepartment}
                        />
                      </div>
                    </div>
                  </Form.Field>

                  {!state.master && (
                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <label className="font-bold text-base mt-4 text-primary">
                            {modalLang.subDepartment}
                          </label>
                          <Form.Dropdown
                            placeholder={modalLang.subDepartment}
                            fluid
                            search
                            clearable
                            multiple
                            selection
                            className="mt-2"
                            options={subDepartments}
                            onChange={handleOnChangeSubDepartment}
                          />
                        </div>
                      </div>
                    </Form.Field>
                  )}
                </Fragment>
              )}

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <label className="font-bold text-base mt-4 text-primary">
                      {modalLang.employees}
                    </label>
                    <Form.Dropdown
                      placeholder={modalLang.employees}
                      fluid
                      search
                      clearable
                      multiple
                      selection
                      className="mt-2"
                      options={selectedEmployees}
                      onChange={(e, {value}) =>
                        setState({
                          ...state,
                          employees: value,
                        })
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <label className="font-bold text-base mt-4 text-primary">
                      {modalLang.meetingDescription}
                    </label>
                    <Form.TextArea
                      placeholder={modalLang.description}
                      rows={3}
                      onChange={(e, {value}) =>
                        setState({
                          ...state,
                          description: value,
                        })
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-2">
                      <label className="font-bold text-base text-primary">
                        {modalLang.meetingAgenda}
                      </label>
                      <Icon
                        name="plus"
                        className="text-primary cursor-pointer"
                        onClick={() => setAddAgenda(prev => prev + 1)}
                      />
                    </div>

                    {new Array(addAgenda).fill(1).map((k, i) => (
                      <div className="flex items-center w-full mb-3" key={i}>
                        <Form.Input
                          className="w-full mb-0"
                          placeholder={modalLang.meetingAgenda}
                          onChange={(e, {value}) =>
                            setState({
                              ...state,
                              agenda: [
                                ...state.agenda.slice(0, i),
                                value,
                                ...state.agenda.slice(i + 1),
                              ],
                            })
                          }
                        />
                        {/* <Icon
                          name="times"
                          className="text-red-700 ml-4"
                          onClick={handleDeleteAgenda}
                        /> */}
                      </div>
                    ))}
                  </div>
                </div>
              </Form.Field>
            </Fragment>
          )}

          <div className="text-center">
            <Button
              type={`${next ? 'submit' : 'button'}`}
              className="btn-primary"
              content={
                !next
                  ? modalLang.next
                  : modalLang.add + ' ' + modalLang.meetings
              }
              onClick={() => {
                if (next) {
                  handleOnSubmit()
                } else {
                  setNext(true)
                }
              }}
            />
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default CreateMeetingModal
