import {Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {Button, Form, Header, Message, Modal} from 'semantic-ui-react'
import * as Yup from 'yup'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {getTicketTypes} from '../../services/ticketService'
import {getTicketCategory} from '../../utils/handleDepartments'
import FileAttachment from '../FileAttachment'
import FormikControl from '../formik/FormikControl'

function NewTicket({isOpen, setIsOpen, updateTickets}) {
  const [departments, setDepartments] = React.useState('')
  const [attachmentData, setAttachmentData] = React.useState(null)
  const [error, setError] = React.useState('')
  const [allTypes, setAllTypes] = useState([])
  const [typeOptions, setTypeOptions] = useState([])
  const [categoryOptions, setCategotyOptions] = useState([])
  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()

  const buildingOptions = [
    {key: '0', text: selectedContent.warehouse, value: 'warehouse'},
    {key: '1', text: selectedContent.medicalGases, value: 'medicalGases'},
    {key: '2', text: selectedContent.masterBuilding, value: 'masterBuilding'},
    {key: '3', text: selectedContent.sweetener, value: 'sweetener'},
  ]

  useEffect(() => {
    run(getTicketTypes())
      .then(({data}) => {
        setAllTypes(data.data)
        let typesArr = []
        data.data.ticketTypes.map((t, i) => {
          typesArr.push({
            key: i,
            text: t['name' + lang.toUpperCase()],
            value: t.id,
          })
        })
        setTypeOptions(typesArr)
      })
      .catch(e => {
        console.log(e)
      })
  }, [lang])

  const newTicketSchema = Yup.object({
    ticketType: Yup.number().required(selectedContent.required),
    ticketCategory: Yup.string().required(selectedContent.required),
    building: Yup.string().required(selectedContent.required),
    floor: Yup.string().required(selectedContent.required),
    department: Yup.string().required(selectedContent.required),
    ticketDescription: Yup.string().required(selectedContent.required),
  })

  const createNewTicket = values => {
    const {ticketType, ...withoutTicketType} = values
    const data = new FormData()

    for (let k in withoutTicketType) {
      data.append(k, withoutTicketType[k])
    }

    data.append('ticketType', values.ticketType)
    if (attachmentData) data.append('ticketFile', attachmentData)

    run(authAxios.post('/tickets/createTicket', data))
      .then(() => {
        addToast(selectedContent.successfulOperation, {appearance: 'success'})
        updateTickets(true)
        setIsOpen(false)
      })
      .catch(e => {
        console.log(e)
        // setError(e?.errors?.map(e => e.message))
      })
  }

  React.useEffect(() => {
    run(authAxios.get('/departments/departments'))
      .then(({data}) => {
        let masterDepartmentsOptions = []

        data?.data?.masterDepartments?.map(d =>
          masterDepartmentsOptions.push({
            key: d._id,
            text: d['name' + lang.toUpperCase()],
            value: d._id,
          }),
        )
        setDepartments(masterDepartmentsOptions)
      })
      .catch(e =>
        e?.errors?.map(e => addToast(e.message[lang], {appearance: 'error'})),
      )
  }, [run, addToast, lang])

  return (
    <Modal
      className="main-font"
      closeIcon
      open={isOpen}
      onClose={() => setIsOpen(false)}
      size="tiny"
    >
      <Header className="rounded-t-xl" content={selectedContent.newTicket} />
      <Modal.Content scrolling>
        <Formik
          initialValues={{
            ticketType: 0,
            ticketCategory: '',
            building: '',
            floor: '',
            department: '',
            ticketDescription: '',
          }}
          validationSchema={newTicketSchema}
          onSubmit={createNewTicket}
        >
          {formik => (
            <Form
              autoComplete="off"
              onSubmit={formik.submitForm}
              loading={isLoading}
              error={error}
            >
              {error ? <Message negative header="Error" list={error} /> : null}
              <FormikControl
                name="ticketType"
                control="select"
                options={typeOptions}
                label={selectedContent.ticketType}
              />
              {formik.values.ticketType < 4 ? (
                <FormikControl
                  name="ticketCategory"
                  control="select"
                  options={getTicketCategory(
                    formik.values.ticketType,
                    allTypes?.ticketCategory,
                    lang,
                  )}
                  label={selectedContent.requestType}
                />
              ) : (
                <FormikControl
                  name="ticketCategory"
                  control="input"
                  label={selectedContent.requestType}
                />
              )}
              <FormikControl
                name="building"
                control="dropdown"
                fluid
                selection
                options={buildingOptions}
                label={selectedContent.building}
              />
              <FormikControl
                name="floor"
                control="input"
                label={selectedContent.floor}
              />
              <FormikControl
                name="department"
                control="select"
                options={departments}
                label={selectedContent.department}
              />
              <FormikControl
                name="ticketDescription"
                control="textarea"
                label={selectedContent.description}
              />
              <FileAttachment setAttachmentData={setAttachmentData} />
              <Button className="btn-primary w-full mt-4" type="submit">
                {selectedContent.submit}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default NewTicket
