import {Button, Form, Grid, Image} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import useAsync from '../../../hooks/useAsync'
import {content} from '../../../localization/content'
import * as Yup from 'yup'
import {Fragment, useEffect, useState} from 'react'
import {Formik} from 'formik'
import FormikControl from '../../formik/FormikControl'
import borderIcon from '../../../assets/images/ic_border.svg'
import {countries} from '../../../config/keys'
import {useToasts} from 'react-toast-notifications'
import {stringToDate} from '../../../utils/date-format'
import {updateProfile} from '../../../services/profileService'
import {useHistory} from 'react-router'
import Auth from '../../../config/auth'
import {useQuery} from '../../../hooks/useQuery'

const EditProfileInfo = ({userData, storeProfile, type = 'profile'}) => {
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const [personalData, setPersonalData] = useState('')
  const history = useHistory()
  const personalLang = content[lang]
  const {addToast} = useToasts()
  const query = useQuery()

  useEffect(() => {
    if (!userData) return
    setPersonalData(userData)
  }, [userData])

  const personalSchema = Yup.object({
    firstNameEN: Yup.string().required(personalLang.required),
    lastNameEN: Yup.string().required(personalLang.required),
    firstNameAR: Yup.string().required(personalLang.required),
    lastNameAR: Yup.string().required(personalLang.required),
    idNumber: Yup.string().required(personalLang.required),
    birthDate: Yup.date()
      .typeError('Invalid date')
      .required(personalLang.required),
    computerNumber: Yup.string().required(personalLang.required),
    phoneNumber: Yup.string().required(personalLang.required),
    nationality: Yup.string().required(personalLang.required),
    gender: Yup.string().required(personalLang.required),
    religion: Yup.string().required(personalData.required),
  })

  const editPersonal = values => {
    const {
      firstNameEN,
      lastNameEN,
      firstNameAR,
      lastNameAR,
      idNumber,
      computerNumber,
      phoneNumber,
      qualification,
      specialization,
      birthDate,
      religion,
      nationality,
      gender,
      address,
      bankAccount,
      SCFHSNumber,
    } = values

    const userId =
      type === 'profile'
        ? JSON.parse(localStorage.getItem('user'))._id
        : query.get('id')

    const dateFormated = stringToDate(birthDate)
    run(
      updateProfile({
        userId: userId,
        updateType: 'profile',
        firstNameEN,
        lastNameEN,
        firstNameAR,
        lastNameAR,
        idNumber,
        computerNumber,
        phoneNumber,
        religion,
        qualification,
        specialization,
        birthDate: dateFormated,
        nationality,
        gender,
        address,
        bankAccount,
        SCFHSNumber,
      }),
    )
      .then(({data}) => {
        storeProfile(data.data)
        addToast(data.message[lang], {appearance: 'success'})
      })
      .catch(e => {
        console.log(e)
        e.errors.map(error => {
          if (
            error.field === 'notAuthorized' ||
            error.field === 'authToken' ||
            error.field === 'token'
          ) {
            Auth.logout()
            // localStorage.removeItem('user')
            history.push('/auth/login')
            addToast(error.message[lang], {appearance: 'error'})
            return
          }
        })
      })
  }

  return (
    <Fragment>
      {personalData !== '' && (
        <Formik
          initialValues={{
            firstNameEN: personalData.user['firstNameEN'],
            lastNameEN: personalData.user['lastNameEN'],
            firstNameAR: personalData.user['firstNameAR'],
            lastNameAR: personalData.user['lastNameAR'],
            idNumber: personalData.user.idNumber,
            computerNumber: personalData.computerNumber,
            phoneNumber: personalData.user.phoneNumber,
            qualification: personalData.qualification,
            specialization: personalData.specialization,
            birthDate: personalData.birthDate || '',
            religion: personalData.religion || '',
            nationality: personalData.nationality,
            gender: personalData.gender,
            address: personalData.address,
            bankAccount: personalData.bankAccount,
            SCFHSNumber: personalData.SCFHSNumber,
          }}
          enableReinitialize
          validationSchema={personalSchema}
          onSubmit={editPersonal}
        >
          {formik => (
            <Form
              loading={isLoading}
              onSubmit={formik.submitForm}
              className="lg:overflow-hidden mt-10"
            >
              <Grid doubling columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="firstNameEN"
                            label={personalLang.firstName}
                            placeholder={personalLang.firstName}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="lastNameEN"
                            label={personalLang.lastName}
                            placeholder={personalLang.lastName}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="firstNameAR"
                            label={personalLang.firstNameAR}
                            placeholder={personalLang.firstNameAR}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="lastNameAR"
                            label={personalLang.lastNameAR}
                            placeholder={personalLang.lastNameAR}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="idNumber"
                            label={personalLang.idNumber}
                            placeholder={personalLang.idNumber}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="computerNumber"
                            label={personalLang.computerNumber}
                            placeholder={personalLang.computerNumber}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="phoneNumber"
                            label={personalLang.phone}
                            placeholder={personalLang.phone}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="qualification"
                            label={personalLang.qualification}
                            placeholder={personalLang.qualification}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="specialization"
                            label={personalLang.specialization}
                            placeholder={personalLang.specialization}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column>
                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="birthDate"
                            label={personalLang.birthDate}
                            control="date"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            id="nationality"
                            name="nationality"
                            label={personalLang.nationality}
                            placeholder={personalLang.nationality}
                            control="dropdown"
                            fluid
                            search
                            required
                            clearable
                            selection
                            options={countries}
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="religion"
                            label={personalLang.religion}
                            placeholder={personalLang.religion}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="gender"
                            label={personalLang.gender}
                            placeholder={personalLang.gender}
                            control="dropdown"
                            fluid
                            search
                            required
                            clearable
                            selection
                            options={[
                              {key: '0', text: 'Male', value: 'Male'},
                              {key: '1', text: 'Female', value: 'Female'},
                            ]}
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="address"
                            label={personalLang.address}
                            placeholder={personalLang.address}
                            control="input"
                            required
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="bankAccount"
                            label={personalLang.bankAccount}
                            placeholder={personalLang.bankAccount}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field>

                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <FormikControl
                            name="SCFHSNumber"
                            label={personalLang.SCFHSNumber}
                            placeholder={personalLang.SCFHSNumber}
                            control="input"
                          />
                        </div>
                      </div>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="my-10 text-center">
                <Button
                  type="submit"
                  content={personalLang.save}
                  className="btn-primary"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  )
}

export default EditProfileInfo
