import React from 'react'
import {RiDeleteBin7Fill, RiRecordCircleLine, RiStopFill} from 'react-icons/ri'
import {BsFillMicFill} from 'react-icons/bs'
import {Message} from 'semantic-ui-react'
import {useLanguage} from '../context/languageContext'
import {content} from '../localization/content'

function AudioRecorder({setAudioData}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const audioType = 'audio/ogg'
  const chunks = React.useRef([])
  const mediaRecorder = React.useRef()

  const [isRecording, setIsRecording] = React.useState(false)
  const [audioURL, setAudioURL] = React.useState('')
  const [error, setError] = React.useState('')

  const handleRecording = async () => {
    getUserMedia()

    if (navigator.mediaDevices) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true})
        mediaRecorder.current = new MediaRecorder(stream)
        chunks.current = []

        mediaRecorder.current.ondataavailable = e => {
          if (e.data && e.data.size > 0) {
            chunks.current.push(e.data)
          }
        }

        mediaRecorder.current.start(10)

        setError('')
        setIsRecording(true)
      } catch {
        setError(
          'No media device was found, please connect/enable microphone access',
        )
      }
    }
  }

  const handleStop = () => {
    // reset the event handler
    mediaRecorder.current.ondataavailable = e => null
    mediaRecorder.current.stop()
    setIsRecording(false)

    saveAudio()
  }

  const saveAudio = () => {
    let blob = new Blob(chunks.current, {type: audioType})
    const audioURL = window.URL.createObjectURL(blob)
    const file = new File([blob], 'recording.ogg')

    setAudioURL([audioURL])
    setAudioData({
      audioURL,
      file,
    })
  }

  const clearAudio = () => {
    setAudioURL(null)
    setAudioData({
      audioURL: null,
      file: null,
    })
  }

  const getUserMedia = () => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
  }

  return (
    <>
      {error ? <Message negative header="Error" content={error} /> : null}
      {!isRecording ? (
        audioURL ? (
          <div className="flex justify-between items-center">
            <audio controls>
              <source src={audioURL} type="audio/ogg" />
            </audio>
            <div
              className="text-red-500 flex items-center justify-center cursor-pointer"
              onClick={clearAudio}
            >
              <RiDeleteBin7Fill size="16" className="ltr:mr-3 rtl:ml-3" />
              {selectedContent.clear}
            </div>
          </div>
        ) : (
          <div className="flex">
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={handleRecording}
            >
              <BsFillMicFill size="24" className="ltr:mr-4 rtl:ml-4" />
              <span>{selectedContent.voiceNote}</span>
            </div>
          </div>
        )
      ) : (
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <RiRecordCircleLine
              size="24"
              className="ltr:mr-4 rtl:ml-4 text-red-500 animate-pulse"
            />
            <span className="text-black">Recording..</span>
          </div>

          <div className="flex items-center">
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={handleStop}
            >
              <RiStopFill size="24" className="ltr:mr-3 rtl:ml-3" />
              <span>{selectedContent.stop}</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AudioRecorder
