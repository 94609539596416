import {Fragment, useEffect, useState} from 'react'
import {FaUserCircle} from 'react-icons/fa'
import {Accordion, Header, Icon, Image, Table} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {getRatingHistory} from '../../services/ratingService'
import {getUserFullname} from '../../utils/user'
import AnuallyPerformance from './annuallyPerformance'
import usePagination from '../../hooks/use-pagination'
import PaginationWithLimit from '../../components/pagination-with-limit'

const PerformanceHistory = () => {
  const [view, setView] = useState('')
  const [annualData, setAnnualData] = useState([])
  const [lang] = useLanguage()
  const historyLang = content[lang]
  const {run, data, isLoading} = useAsync()
  const [historyData, setHistoryData] = useState([])
  const {page, limit, onPageChange, onLimitChange} = usePagination()

  useEffect(() => {
    if (view) {
      const annualData = []
      let filteredData = historyData.filter(h => h._id._id === view)[0]
      if (filteredData) {
        filteredData.data.forEach((f, i) => {
          annualData.push({
            key: `year-${i}`,
            title: {
              content: (
                <label className="text-primary font-bold text-lg">
                  {f.ratingYear[0]}
                </label>
              ),
            },
            content: {
              content: <AnuallyPerformance months={f.months} />,
            },
          })
        })
        setAnnualData(annualData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  useEffect(() => {
    if (page)
      run(getRatingHistory({page, limit}))
        .then(({data}) => {
          setHistoryData(data.data)
        })
        .catch(e => {
          console.log(e)
        })
  }, [run, page, limit])
  return (
    <Fragment>
      <Table celled color="green" stackable verticalAlign="middle">
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>#</Table.HeaderCell>

            <Table.HeaderCell>{historyLang.name}</Table.HeaderCell>
            <Table.HeaderCell>{historyLang.avgRate}</Table.HeaderCell>
            <Table.HeaderCell>{historyLang.department}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!isLoading && historyData.length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan={8} textAlign="center" warning>
                {historyLang.emptyRatings}
              </Table.Cell>
            </Table.Row>
          ) : null}
          {historyData?.map((h, i) => (
            <Fragment key={i}>
              <Table.Row>
                <Table.Cell
                  selectable
                  width="1"
                  className="cursor-pointer"
                  textAlign="center"
                  onClick={() =>
                    setView(prev => (prev === h._id._id ? '' : h._id._id))
                  }
                >
                  <Icon
                    name={view === h._id._id ? 'angle up' : 'angle down'}
                    className="text-xl text-primary m-0"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Header as="h4" image>
                    {h?._id?.avatar ? (
                      <Image src={h._id.avatar} rounded size="small" />
                    ) : (
                      <FaUserCircle classname="inline-block text-primary w-10 h-10 ltr:mr-3 rtl:ml-3" />
                    )}
                    <Header.Content>
                      {getUserFullname(h?._id, lang)}
                      <Header.Subheader>
                        {h?._id?.jobTitle?.['name' + lang.toUpperCase()]}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>{h?._id?.avgRate}</Table.Cell>
                <Table.Cell>
                  {h?._id?.department['name' + lang.toUpperCase()]}
                </Table.Cell>
              </Table.Row>
              {view === h._id._id ? (
                <Table.Row>
                  <Table.Cell colSpan={8} selectable={false}>
                    <Accordion panels={annualData} exclusive={false} fluid />
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </Fragment>
          ))}
          {isLoading && historyData.length === 0 ? (
            <Table.Row textAlign="center">
              <Table.Cell colSpan={8}>
                <Icon
                  name="asterisk"
                  size="large"
                  className="text-primary"
                  loading
                />
              </Table.Cell>
            </Table.Row>
          ) : null}
        </Table.Body>
      </Table>

      <PaginationWithLimit
        page={page}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        totalPages={data?.totalPages}
      />
    </Fragment>
  )
}

export default PerformanceHistory
