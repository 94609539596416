import moment from 'moment'
import React from 'react'
import {useToasts} from 'react-toast-notifications'
import {Loader, Tab, Table, Message, Button, Confirm} from 'semantic-ui-react'
import Auth from '../../config/auth'
import {authAxios} from '../../config/axiosConfig'
import {useLanguage} from '../../context/languageContext'
import {useSocket} from '../../context/socket-context'
import usePagination from '../../hooks/use-pagination'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {formatDate, formatTime} from '../../utils/date-format'
import formatError from '../../utils/error-format'
import {getUserFullname} from '../../utils/user'
import PaginationWithLimit from '../pagination-with-limit'

function PermissionsHistory() {
  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const [updatePermissions, setUpdatePermissions] = React.useState(false)
  const [cancelling, setCancelling] = React.useState(null)
  const [data, setData] = React.useState({})

  const {page, limit, onPageChange, onLimitChange} = usePagination()

  const {run, isLoading} = useAsync()
  const {
    run: cancelPermission,
    error: errorCancelling,
    isLoading: isCancelling,
  } = useAsync()
  const {addToast} = useToasts()

  const socket = useSocket()

  const onCancelPermission = permissionToCancel => {
    cancelPermission(
      authAxios.delete(`/requests/permissions/${permissionToCancel}`),
    )
      .then(() => setUpdatePermissions(p => !p))
      .finally(() => setCancelling(null))
  }

  React.useEffect(() => {
    socket?.on('notification', msg => {
      if (msg.type !== 'requests') return
      if (!msg.message.users.includes(Auth.getUserId())) return
      if ([msg.message.from].includes(Auth.getUserId())) return
      setUpdatePermissions(prev => !prev)
    })
  }, [socket])

  React.useEffect(() => {
    if (page)
      run(authAxios.get(`/requests/permissions?page=${page}&limit=${limit}`))
        .then(({data}) => setData(data))
        .catch(e =>
          e?.errors?.map(e => addToast(e.message[lang], {appearance: 'error'})),
        )
  }, [run, addToast, updatePermissions, lang, page, limit])

  return (
    <Tab.Pane loading={isLoading} className="p-0 m-0 border-0">
      {errorCancelling ? (
        <Message list={formatError(errorCancelling)} error />
      ) : null}

      <Table celled selectable className="w-full">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>
              {selectedContent.reason}
            </Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.from}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.to}</Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {selectedContent.date}
            </Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.awaiting}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.attachments}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.status}</Table.HeaderCell>
            <Table.HeaderCell width={5}>
              {selectedContent.rejectionReason}
            </Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.actions}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {isLoading ? (
          <Loader active />
        ) : (
          <Table.Body>
            {data?.data?.map(h => {
              return (
                <Table.Row
                  key={h?.createdAt}
                  negative={h?.status === 'Rejected'}
                  positive={h?.status === 'Approved'}
                >
                  <Table.Cell width={1} title={h?.for?.reason}>
                    {h?.for?.reason}
                  </Table.Cell>
                  <Table.Cell width={1}>{formatTime(h?.for?.start)}</Table.Cell>
                  <Table.Cell width={1}>{formatTime(h?.for?.end)}</Table.Cell>
                  <Table.Cell width={1}>
                    {formatDate(h?.for?.date)} (
                    {moment(h?.for?.date).locale(lang).format('dddd')})
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {getUserFullname(h?.awaitingApprovalFrom, lang)}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {h.for.attachment?.link ? (
                      <a
                        rel="noreferrer"
                        href={h.for.attachment?.link}
                        target="_blank"
                        className="underline"
                      >
                        {selectedContent.clickToOpen}
                      </a>
                    ) : (
                      selectedContent.noAttachments
                    )}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {selectedContent[h?.status.toLowerCase()]}
                  </Table.Cell>
                  <Table.Cell width={3}>{h?.rejectionReason}</Table.Cell>
                  <Table.Cell>
                    <Button
                      disabled={h?.status !== 'Pending'}
                      negative
                      className="btn-danger"
                      size="small"
                      loading={cancelling === h?._id}
                      onClick={() => setCancelling(h?._id)}
                    >
                      {selectedContent.cancel}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table>

      <PaginationWithLimit
        page={page}
        limit={limit}
        totalPages={data?.totalPages}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />

      <Confirm
        content={selectedContent.areYouSureYouWantToDelete}
        open={cancelling}
        onCancel={() => setCancelling(null)}
        size="small"
        confirmButton={() => (
          <Button
            onClick={() => onCancelPermission(cancelling)}
            size="small"
            negative
            loading={isCancelling}
          >
            Delete
          </Button>
        )}
      />
    </Tab.Pane>
  )
}

export default PermissionsHistory
