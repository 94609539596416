import {Fragment, useEffect, useState} from 'react'
import {useQuery} from '../../hooks/useQuery'
import EditAttachments from './editProfile/editAttachments'
import ViewAttachments from './viewProfile/viewAttachments'

const Attachments = ({userData}) => {
  const query = useQuery()
  const [attachments, setAttachments] = useState('')
  useEffect(() => {
    if (!userData) return
    setAttachments(userData.attachments)
    return () => {}
  }, [userData])
  return (
    <Fragment>
      {query.get('edit') && <EditAttachments />}
      {!query.get('edit') && <ViewAttachments userData={attachments} />}
    </Fragment>
  )
}

export default Attachments
