import {useContext, useEffect, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {Button, Form, Modal} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {rejectTicket, resendTicket} from '../../services/ticketService'

const ResendTicketModal = ({updateTicket}) => {
  const [open, setOpen] = useState(false)
  const {showModal, setShowModal} = useContext(StateContext)
  const [state, setState] = useState({
    reason: '',
  })
  const {run, isLoading} = useAsync()
  const {addToast} = useToasts()
  const [modalData, setModalData] = useState('')
  const [lang] = useLanguage()
  const modalLang = content[lang]

  useEffect(() => {
    if (showModal.modalName === 'resendTicket') {
      setOpen(true)
      setModalData(showModal.data)
    } else {
      setOpen(false)
    }
  }, [showModal])

  const handleOnClickResend = () => {
    run(resendTicket({ticketId: modalData._id, resendReason: state.reason}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        setState({...state, reason: ''})
        updateTicket(true)
        setShowModal({modalName: '', data: null})
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <Modal
      onClose={() => setShowModal({modalName: '', data: null})}
      open={open}
      size="small"
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>{modalLang.resendTicket}</Modal.Header>
      <Modal.Content>
        <Form loading={isLoading}>
          <Form.Field>
            <Form.TextArea
              rows={4}
              label={modalLang.reason}
              placeholder={modalLang.writeReason}
              onChange={(e, {value}) => setState({...state, reason: value})}
            ></Form.TextArea>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="send"
          className="btn-primary"
          content={modalLang.resend}
          onClick={handleOnClickResend}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default ResendTicketModal
