import React from 'react'
import {CgAttachment} from 'react-icons/cg'
import {RiDeleteBin7Fill} from 'react-icons/ri'
import {useLanguage} from '../context/languageContext'
import {content} from '../localization/content'

function FileAttachment({setAttachmentData}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const [file, setFile] = React.useState(null)

  const handleOnSelectedFile = e => {
    setFile(e.target.files[0])
    setAttachmentData(e.target.files[0])
  }

  const clearAttachment = () => {
    setFile(null)
    setAttachmentData(null)
  }

  return (
    <>
      {file ? (
        <div className="flex justify-between">
          <span>
            <strong>{selectedContent.fileName}</strong>: {file.name}
          </span>
          <div
            className="text-red-500 flex items-center justify-center cursor-pointer"
            onClick={clearAttachment}
          >
            <RiDeleteBin7Fill size="16" className="ltr:mr-3 rtl:ml-3" />
            {selectedContent.clear}
          </div>
        </div>
      ) : (
        <>
          <input
            type="file"
            id="file-input"
            accept="*/*"
            className="hidden"
            onChange={handleOnSelectedFile}
          />
          <label htmlFor="file-input" className="text-primary flex">
            <div className="flex items-center justify-center cursor-pointer">
              <CgAttachment size="24" className="ltr:mr-3 rtl:ml-3" />
              <span>{selectedContent.attachFile}</span>
            </div>
          </label>
        </>
      )}
    </>
  )
}

export default FileAttachment
