import {Button, Grid, Icon} from 'semantic-ui-react'
import {ImUserTie} from 'react-icons/im'
import {Fragment, useState} from 'react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const AccountTypeStep = ({nextStep, errorContent}) => {
  const [lang] = useLanguage()
  const accountContent = content[lang]
  const [role, setRole] = useState('')

  const handleOnClickRole = type => {
    setRole(type)
  }

  const handleOnCLickSubmit = () => {
    if (role === '') {
      return errorContent({isError: true, errorType: 'role'})
    }
    errorContent({isError: false, errorType: ''})

    nextStep({
      step: 6,
      type: 'step',
      data: {role: role},
    })
  }
  return (
    <Fragment>
      <Grid stackable>
        <Grid.Row columns="2">
          <Grid.Column className="flex flex-col justify-center">
            <div
              className={`cursor-pointer ${
                role === 'user'
                  ? 'py-3 bg-gray-200 transition-colors duration-500 rounded-md'
                  : ''
              }`}
              onClick={() => handleOnClickRole('user')}
            >
              <div className="flex items-center justify-center w-20 h-20 bg-gray-100 rounded-full mx-auto">
                <Icon
                  name="user"
                  className="text-3xl text-primary -mt-2 mx-0"
                />
              </div>
              <p className="text-primary text-center mt-5 font-semibold">
                {accountContent.user}
              </p>
            </div>
          </Grid.Column>

          <Grid.Column className="flex flex-col justify-center">
            <div
              className={`cursor-pointer ${
                role === 'manager'
                  ? 'py-3 bg-gray-200 transition-colors duration-500 rounded-md'
                  : ''
              }`}
              onClick={() => handleOnClickRole('manager')}
            >
              <div className="flex items-center justify-center w-20 h-20 bg-gray-100 rounded-full mx-auto">
                <ImUserTie size="30" className="text-primary" />
              </div>
              <p className="text-primary text-center mt-5 font-semibold">
                {accountContent.master}
              </p>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div className="mt-10 mb-6 text-center">
        <Button
          content={accountContent.next}
          className="btn-primary"
          onClick={handleOnCLickSubmit}
        />
      </div>
    </Fragment>
  )
}

export default AccountTypeStep
