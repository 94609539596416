import React, {Fragment, useContext} from 'react'
import {useToasts} from 'react-toast-notifications'
import {Dropdown, Icon, Tab, Table} from 'semantic-ui-react'
import Auth from '../../config/auth'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {ticketActions} from '../../services/ticketService'
import {formatDate} from '../../utils/date-format'
import {content} from '../../localization/content'
import {authAxios} from '../../config/axiosConfig'
import usePagination from '../../hooks/use-pagination'
import PaginationWithLimit from '../pagination-with-limit'

function AssignedTicketsTable({updateHistory, setUpdateHistory}) {
  const {run, isLoading, data} = useAsync()
  const {page, limit, onPageChange, onLimitChange} = usePagination()

  const {setShowModal} = useContext(StateContext)
  const {addToast} = useToasts()

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  React.useEffect(() => {
    if (page)
      run(authAxios.get(`/tickets/assignedTickets?page=${page}&limit=${limit}`))
  }, [updateHistory, page, limit, run])

  const handleOnClickReject = ticketItem => {
    setShowModal({modalName: 'rejectTicket', data: ticketItem})
  }

  const handleOnClickCompleted = (ticketId, type) => {
    run(ticketActions({ticketId: ticketId, type: type}))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        setUpdateHistory(true)
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <Tab.Pane loading={isLoading}>
      <Table
        celled
        singleLine
        selectable
        verticalAlign="middle"
        textAlign="center"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{selectedContent.ticketNumber}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.date}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.createdBy}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.type}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.status}</Table.HeaderCell>
            <Table.HeaderCell>{selectedContent.actions}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.data?.map(t => (
            <Table.Row key={t?._id} textAlign="center" verticalAlign="middle">
              <Table.Cell>{t?.ticketNumber}</Table.Cell>
              <Table.Cell>{formatDate(t?.createdAt)}</Table.Cell>
              <Table.Cell>
                {t?.createdBy?.['firstName' + lang.toUpperCase()]}{' '}
                {t?.createdBy?.['lastName' + lang.toUpperCase()]}
              </Table.Cell>
              <Table.Cell>
                {t?.ticketType?.['name' + lang.toUpperCase()] || t?.ticketType}
              </Table.Cell>
              <Table.Cell
                warning={
                  t?.ticketStatus === 'pending' ||
                  t?.ticketStatus === 'in progress' ||
                  t?.ticketStatus === 'resent'
                    ? true
                    : false
                }
                positive={t?.ticketStatus === 'completed'}
                negative={t?.ticketStatus === 'rejected'}
              >
                {t?.ticketStatus === 'in progress'
                  ? selectedContent.inProgress
                  : selectedContent[t?.ticketStatus]}
              </Table.Cell>
              <Table.Cell>
                <div className="flex items-center">
                  <Dropdown
                    icon="settings"
                    loading={isLoading}
                    floating
                    button
                    className="icon mx-auto"
                  >
                    <Dropdown.Menu className="ltr:-left-24 rtl:-right-24">
                      {t?.ticketStatus === 'in progress' ||
                      t?.ticketStatus === 'resent'
                        ? t?.assignedTo?.map(a => {
                            if (a._id === Auth.getUserId()) {
                              return (
                                <Fragment>
                                  {t?.ticketStatus === 'in progress' ? (
                                    <Fragment>
                                      <Dropdown.Item
                                        icon={() => (
                                          <Icon
                                            name="check"
                                            className="text-primary"
                                          />
                                        )}
                                        text={selectedContent.markAsCompleted}
                                        onClick={() =>
                                          handleOnClickCompleted(
                                            t?._id,
                                            'completed',
                                          )
                                        }
                                      />
                                      <Dropdown.Item
                                        icon={() => (
                                          <Icon
                                            name="edit"
                                            className="text-primary"
                                          />
                                        )}
                                        text={selectedContent.addComment}
                                        onClick={() =>
                                          setShowModal({
                                            modalName: 'addComment',
                                            data: t,
                                          })
                                        }
                                      />
                                    </Fragment>
                                  ) : (
                                    <Dropdown.Item
                                      icon={() => (
                                        <Icon
                                          name="file alternate outline"
                                          className="text-goldPrimary"
                                        />
                                      )}
                                      text={selectedContent.markAsInProgress}
                                      onClick={() =>
                                        handleOnClickCompleted(
                                          t?._id,
                                          'in progress',
                                        )
                                      }
                                    />
                                  )}
                                </Fragment>
                              )
                            }
                          })
                        : null}

                      {t?.ticketStatus !== 'completed' &&
                      t?.ticketStatus !== 'rejected' ? (
                        <Dropdown.Item
                          label={{
                            color: 'red',
                            empty: true,
                            circular: true,
                          }}
                          text={selectedContent.reject}
                          onClick={() => handleOnClickReject(t)}
                        />
                      ) : null}

                      <Dropdown.Item
                        icon={() => (
                          <Icon name="eye" className="text-goldPrimary" />
                        )}
                        text={selectedContent.view}
                        onClick={() =>
                          setShowModal({modalName: 'viewTicket', data: t})
                        }
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <PaginationWithLimit
        page={page}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        totalPages={data?.totalPages}
      />
    </Tab.Pane>
  )
}

export default AssignedTicketsTable
