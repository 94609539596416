import {authAxios} from '../config/axiosConfig'

// Create meeting
export const createMeeting = meetingData => {
  return authAxios.post('/meetings/createMeeting', meetingData)
}

// Get my meetings
export const getMyMeetings = ({page, limit}) => {
  return authAxios.get(`/meetings/myMeetings?page=${page}&limit=${limit}`)
}

// Print meeting report
export const printMeetingReport = reportData => {
  return authAxios.post('/meetings/printMeetingReport', reportData, {
    responseType: 'arraybuffer',
  })
}

// Get job titles by user id
export const getJobTitlesByUserId = userId => {
  return authAxios.post('/users/getJobTitleByUserId', userId)
}

// Get meeting by id
export const getMeetingById = id => {
  return authAxios.get('/meetings/getMeetingsById/' + id)
}

// Update meetings
export const updateMeetings = meetingData => {
  return authAxios.patch('/meetings/updateMeetings', meetingData)
}

// Delete meetings
export const deleteMeetings = meetingData => {
  return authAxios.patch('/meetings/deleteMeetings', meetingData)
}

// Excuse meeting
export const excuseMeeting = meetingData => {
  return authAxios.patch('/meetings/excuseMeeting', meetingData)
}
