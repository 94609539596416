import {useContext, useEffect, useState} from 'react'
import {Form, Icon, Message} from 'semantic-ui-react'
import PersonalInfoStep from '../../components/auth/register/personalInfoStep'
import VerifyPhone from '../../components/auth/register/verifyPhone'
import EmailStep from '../../components/auth/register/emailStep'
import PhoneStep from '../../components/auth/register/phoneStep'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import {content} from '../../localization/content'
import AccountTypeStep from '../../components/auth/register/accountTypeStep'
import ManagerListStep from '../../components/auth/register/managerListStep'
import {registerUser, verifyPhoneNumber} from '../../services/authService'
import {useToasts} from 'react-toast-notifications'
import useAsync from '../../hooks/useAsync'
import {useHistory} from 'react-router'
import SuccessStep from '../../components/auth/register/SuccessStep'

const RegisterPage = () => {
  const {run, isLoading, isSuccess} = useAsync()
  const {addToast} = useToasts()
  const [lang] = useLanguage()
  const {setAuthTitle} = useContext(StateContext)
  const authContent = content[lang]
  const [title, setTitle] = useState('')
  const [step, setStep] = useState(1)
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState({
    isError: false,
    errorType: '',
  })
  const [accountType, setAccountType] = useState('')
  const [state, setState] = useState({})
  const history = useHistory()

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return

    setAuthTitle({
      title: authContent.createAccount,
      subTitle: authContent.greeting,
      message: authContent.registrationDetails,
    })
    setTitle(authContent.greeting)
    setMessage(authContent.registrationDetails)
    return () => {
      isMounted = false
    }
  }, [lang])

  // Handle errors
  const handleOnError = () => {
    if (errors.errorType === 'empty') {
      return (
        <Message
          error
          header={authContent.emptyField}
          content={authContent.emptyFieldContent}
        />
      )
    }
    // For email form
    if (errors.errorType === 'password') {
      return (
        <Message
          error
          header={authContent.invalidPassword}
          content={authContent.invalidPassContent}
        />
      )
    }
    if (errors.errorType === 'email') {
      return (
        <Message
          error
          header={authContent.invalidEmail}
          content={authContent.invalidEmailContent}
        />
      )
    }

    // For phone form
    if (errors.errorType === 'phone') {
      return (
        <Message
          error
          header={authContent.invalidPhone}
          content={authContent.invalidPhoneContent}
        />
      )
    }
    // For phone form
    if (errors.errorType === 'verify') {
      return (
        <Message
          error
          header={authContent.invalidVerify}
          content={authContent.invalidVerifyContent}
        />
      )
    }
    if (errors.errorType === 'firstName') {
      return (
        <Message
          error
          header={authContent.invalidFirstName}
          content={authContent.invalidFirstNameContent}
        />
      )
    }
    if (errors.errorType === 'lastName') {
      return (
        <Message
          error
          header={authContent.invalidLastName}
          content={authContent.invalidLastNameContent}
        />
      )
    }
    if (errors.errorType === 'avatar') {
      return (
        <Message
          error
          header={authContent.invalidAvatar}
          content={authContent.invalidAvatarContent}
        />
      )
    }
    if (errors.errorType === 'idNumber') {
      return (
        <Message
          error
          header={authContent.invalidIDNumber}
          content={authContent.invalidIDNumberContent}
        />
      )
    }
    if (errors.errorType === 'role') {
      return (
        <Message
          error
          header={authContent.invalidRole}
          content={authContent.invalidRoleContent}
        />
      )
    }
    if (errors.errorType === 'department') {
      return (
        <Message
          error
          header={authContent.invalidDepartment}
          content={authContent.invalidDepartmentContent}
        />
      )
    }
  }

  const handleNextStep = value => {
    if (value.type == 'step') {
      if (value.step === 3) {
        run(verifyPhoneNumber(value.data))
          .then(({data}) => {
            setStep(value.step)
            setState({...state, ...value.data})
          })
          .catch(e => {
            console.log(e)
            e.errors.map(error => {
              addToast(error.message[lang], {appearance: 'error'})
            })
          })
        return
      }
      setStep(value.step)
      setState({...state, ...value.data})
      return
    } else {
      // console.log(state)
      const newUser = new FormData()
      newUser.append('firstNameEN', state.firstNameEN)
      newUser.append('lastNameEN', state.lastNameEN)
      newUser.append('firstNameAR', state.firstNameAR)
      newUser.append('lastNameAR', state.lastNameAR)
      newUser.append('email', state.email)
      newUser.append('password', state.password)
      newUser.append('role', value.data.role)
      newUser.append('phoneNumber', state.phoneNumber)
      newUser.append('avatar', state.avatar)
      newUser.append('idNumber', state.idNumber)
      newUser.append('isMaster', value.data.isMaster)
      newUser.append(
        'department',
        value.data.isMaster ? value.data.department : value.data.subDepartment,
      )
      run(registerUser(newUser))
        .then(({data}) => {
          addToast(authContent.successRegister, {
            appearance: 'success',
          })
          setStep(step + 1)
          // history.push('/auth/login')
        })
        .catch(e => {
          console.log(e)
          e.errors.map(error => {
            addToast(error.message[lang], {appearance: 'error'})
          })
        })
    }
  }

  return (
    <div className="auth-content">
      {[4].includes(step) && (
        <Icon
          name="angle left"
          size="large"
          className="ltr:mr-4 rtl:ml-4 text-goldPrimary cursor-pointer"
          onClick={() => setStep(prev => prev - 1)}
        />
      )}
      {![4, 7].includes(step) && (
        <div className="auth-content-text">
          <div className="flex items-center mb-4">
            {step > 1 && (
              <Icon
                name="angle left"
                size="large"
                className="ltr:mr-4 rtl:ml-4 text-goldPrimary cursor-pointer"
                onClick={() => setStep(prev => prev - 1)}
              />
            )}

            <h3 className="text-primary main-font text-xl font-bold">
              {title}
            </h3>
          </div>
          <p className="text-subTitleText main-font text-base">{message}</p>
        </div>
      )}

      <div className="mt-6">
        <Form error={errors.isError} loading={isLoading}>
          <div className="my-6">{handleOnError()}</div>
          {step === 1 && (
            <EmailStep
              nextStep={value => handleNextStep(value)}
              values={state}
              errorContent={value =>
                setErrors({
                  ...errors,
                  errorType: value.errorType,
                  isError: value.isError,
                })
              }
            />
          )}
          {step === 2 && (
            <PhoneStep
              nextStep={value => handleNextStep(value)}
              values={state}
              errorContent={value =>
                setErrors({
                  ...errors,
                  errorType: value.errorType,
                  isError: value.isError,
                })
              }
            />
          )}
          {step === 3 && (
            <VerifyPhone
              errorContent={value =>
                setErrors({
                  ...errors,
                  errorType: value.errorType,
                  isError: value.isError,
                })
              }
              values={state}
              nextStep={value => handleNextStep(value)}
            />
          )}
          {step === 4 && (
            <PersonalInfoStep
              errorContent={value =>
                setErrors({
                  ...errors,
                  errorType: value.errorType,
                  isError: value.isError,
                })
              }
              values={state}
              nextStep={value => handleNextStep(value)}
            />
          )}
          {step === 5 && (
            <AccountTypeStep
              errorContent={value =>
                setErrors({
                  ...errors,
                  errorType: value.errorType,
                  isError: value.isError,
                })
              }
              values={state}
              nextStep={value => {
                setAccountType(value.data.role)
                setStep(value.step)
              }}
            />
          )}
          {step === 6 && (
            <ManagerListStep
              errorContent={value =>
                setErrors({
                  ...errors,
                  errorType: value.errorType,
                  isError: value.isError,
                })
              }
              values={state}
              role={accountType}
              nextStep={value => handleNextStep(value)}
            />
          )}

          {step === 7 && <SuccessStep />}
        </Form>
      </div>
    </div>
  )
}

export default RegisterPage
