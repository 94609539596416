import {useContext, useEffect, useState, Fragment} from 'react'
import {Modal, Image, Form, Button} from 'semantic-ui-react'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import userIcon from '../../assets/images/user.svg'

import {getAllChatUsers, startNewChat} from '../../services/chatService'
import {useLanguage} from '../../context/languageContext'
import {getCharaterList} from '../../utils/characterList'
import {content} from '../../localization/content'
import {useSocket} from '../../context/socket-context'
import Auth from '../../config/auth'

const NewChatModal = ({newConversation}) => {
  const [open, setOpen] = useState(false)
  const [lang] = useLanguage()
  const {showModal, setShowModal} = useContext(StateContext)
  const {run, isLoading} = useAsync()
  const [users, setUsers] = useState([])
  const chatLang = content[lang]

  const socket = useSocket()

  useEffect(() => {
    if (showModal.modalName === 'newChat') {
      setOpen(true)
      run(getAllChatUsers())
        .then(({data}) => {
          let userArr = []
          data.data?.map((d, i) => {
            const prefixChar = getCharaterList(
              d['firstName' + lang.toUpperCase()],
              d,
              lang,
            )
            const isExistPrefix = userArr.findIndex(
              o => o.prefix === prefixChar.prefix,
            )

            if (isExistPrefix !== -1) {
              userArr[isExistPrefix].users.push(prefixChar.users)
            } else {
              userArr.push({
                prefix: prefixChar.prefix,
                users: [prefixChar.users],
              })
            }
          })
          userArr.sort(function (a, b) {
            return (
              a.prefix - b.prefix || a.prefix.localeCompare(b.prefix, [lang])
            )
          })
          setUsers(userArr)
        })
        .catch(e => {
          console.error(e)
        })
    } else {
      setOpen(false)
    }
  }, [showModal])

  const handleStartChat = userId => {
    run(startNewChat({members: [userId]}))
      .then(({data}) => {
        // removve current user from members array
        // const index = data.data.members.findIndex(
        //   o => o._id === Auth.getUserId(),
        // )
        // data.data.members.splice(index, 1)
        socket.emit('new conversation', {
          from: Auth.getUserId(),
          to: userId,
          conversationId: data.data._id,
        })

        // make the new conversation searchable by fuse.js
        let modifiedConversation = {
          ...data.data,
          members: data.data.members.map(m => ({
            ...m,
            user: {
              ...m.user,
              nameEN: `${m.user.firstNameEN} ${m.user.lastNameEN}`,
              nameAR: `${m.user.firstNameAR} ${m.user.lastNameAR}`,
            },
          })),
        }
        newConversation(modifiedConversation)
        setShowModal({modalName: '', data: null})
      })
      .catch(e => {
        console.error(e)
      })
  }
  return (
    <Modal
      className="main-font"
      open={open}
      closeIcon
      closeOnDimmerClick={false}
      onClose={() => setShowModal({modalName: '', data: null})}
      size={'small'}
    >
      <Modal.Header className="main-font">
        {chatLang.newConversation}
      </Modal.Header>
      <Modal.Content
        className={`${isLoading ? 'p-10' : 'p-0'} min-h-full`}
        scrolling
      >
        <Form loading={isLoading}>
          {users.length > 0 &&
            users.map((u, i) => (
              <Fragment key={i}>
                <div className="bg-gray-50 p-3 flex items-center justify-between">
                  <p className="text-lg text-primary font-medium">
                    {u.prefix.toUpperCase()}
                  </p>
                  <p className="text-primary text-lg font-medium">
                    ( {u.users?.length} {chatLang.contacts} )
                  </p>
                </div>
                <ul>
                  {u.users.map((user, j) => (
                    <li
                      className="flex items-center justify-between p-3 bg-white hover:bg-gray-100 transition-colors duration-300"
                      key={j}
                    >
                      <div className="flex items-center ">
                        <Image
                          src={user.avatar || userIcon}
                          className="w-16 h-16 rounded-full"
                        />
                        <div className="ltr:ml-4 rtl:mr-4 ">
                          <p className="font-semibold text-lg">
                            {user['firstName' + lang.toUpperCase()]}{' '}
                            {user['lastName' + lang.toUpperCase()]}
                          </p>
                          <p className="text-primary">
                            {user.jobTitle?.['name' + lang.toUpperCase()]}
                          </p>
                        </div>
                      </div>

                      <div>
                        <Button
                          content={chatLang.startChat}
                          className="btn-primary"
                          onClick={() => handleStartChat(user._id)}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </Fragment>
            ))}
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default NewChatModal
