import {Formik} from 'formik'
import moment from 'moment'
import {Fragment, useContext, useEffect, useState} from 'react'
import {Form, Button, Icon, Modal, Image} from 'semantic-ui-react'
import StateContext from '../../../context/stateContext'
import * as Yup from 'yup'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import useAsync from '../../../hooks/useAsync'
import FormikControl from '../../formik/FormikControl'
import {uploadAttachFile} from '../../../services/profileService'
import {useHistory} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import Auth from '../../../config/auth'
import addAttachmentIcon from '../../../assets/images/attachments_empty.svg'

const AttachmentModal = ({storeValues}) => {
  const {showModal, setShowModal} = useContext(StateContext)
  const [lang] = useLanguage()
  const {run, isLoading} = useAsync()
  const modalLang = content[lang]
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('')
  const [attachFile, setAttachFile] = useState('')
  const [fileName, setFileName] = useState('')
  const history = useHistory()
  const {addToast} = useToasts()

  useEffect(() => {
    if (
      showModal.modalName === 'businessCard' ||
      showModal.modalName === 'residenceCard' ||
      showModal.modalName === 'PACertificate' ||
      showModal.modalName === 'CPRCerificate' ||
      showModal.modalName === 'cv'
    ) {
      setOpen(true)
      setType(showModal.modalName)
    } else {
      setOpen(false)
      setType('')
    }
  }, [showModal])

  const attachmentSchema = Yup.object({
    expiryDate: Yup.string().required(modalLang.required),
  })

  const handleOnChangeFile = e => {
    setAttachFile(e.target.files[0])
    setFileName(e.target.files[0].name)
  }

  const handleOnSubmit = values => {
    const userId = JSON.parse(localStorage.getItem('user') || '[]')

    if (userId.length === 0) {
      history.push('/auth/login')
      addToast(modalLang.noAccess, {appearance: 'error'})
      return
    }
    const newAttachment = new FormData()
    newAttachment.append('userId', userId._id)
    newAttachment.append('attachmentType', 'attachments')
    newAttachment.append('fileType', type)
    newAttachment.append('attachmentFile', attachFile)
    if (type !== 'cv') {
      newAttachment.append('expiryDate', values.expiryDate)
    }

    run(uploadAttachFile(newAttachment))
      .then(({data}) => {
        storeValues(data.data)
        setShowModal({modalName: '', data: null})
        addToast(data.message[lang], {appearance: 'success'})
      })
      .catch(e => {
        console.log(e)
        Array.isArray(e.errors) &&
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              setShowModal({modalName: '', data: null})
              history.push('/auth/login')
              addToast(error.message[lang], {appearance: 'error'})
              return
            }
          })
      })
  }

  return (
    <Modal
      open={open}
      className="main-font"
      size="tiny"
      closeIcon
      onClose={() => setShowModal({modalName: '', data: null})}
    >
      <Modal.Header>
        {modalLang['add']} {modalLang[type]}
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            expiryDate: moment.now(),
          }}
          validationSchema={attachmentSchema}
          onSubmit={handleOnSubmit}
        >
          {formik => (
            <Form loading={isLoading} onSubmit={formik.submitForm}>
              {type !== 'cv' && (
                <Fragment>
                  <Form.Field required>
                    <FormikControl
                      name="expiryDate"
                      label={modalLang.expiryDate}
                      placeholder={modalLang.expiryDate}
                      control="date"
                    />
                  </Form.Field>
                  <Form.Field className="my-10">
                    <input
                      type="file"
                      id="upload-qualification"
                      accept="image/*,application/pdf"
                      className="hidden"
                      onChange={handleOnChangeFile}
                    />
                    <label
                      htmlFor="upload-qualification"
                      className="cursor-pointer"
                    >
                      <div className="flex items-center">
                        <Icon
                          name="attach"
                          className="text-primary"
                          size="large"
                        />
                        <p className="text-primary text-lg">
                          {modalLang.attachFile}
                        </p>
                      </div>
                    </label>
                    <small className="ltr:ml-7 rtl:mr-7 text-gray-400">
                      {fileName}
                    </small>
                  </Form.Field>
                </Fragment>
              )}

              {type === 'cv' && (
                <Fragment>
                  <Form.Field className="my-10">
                    <input
                      type="file"
                      id="upload-qualification"
                      accept="image/*,application/pdf"
                      className="hidden"
                      onChange={handleOnChangeFile}
                    />
                    <label
                      htmlFor="upload-qualification"
                      className="cursor-pointer"
                    >
                      <div className={`p-7 text-center`}>
                        <Image
                          src={addAttachmentIcon}
                          width="150"
                          height="150"
                          className="mx-auto"
                        />
                        <p className="text-3xl font-bold text-primary text-center my-5">
                          {modalLang.addAttachment}
                        </p>
                      </div>
                    </label>
                  </Form.Field>
                  <div className="mt-5 text-center">
                    <small className="text-gray-400">{fileName}</small>
                  </div>
                </Fragment>
              )}

              <div className="my-10 text-center">
                <Button
                  content={modalLang.addAttachment}
                  className="btn-primary"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default AttachmentModal
