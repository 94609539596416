import React from 'react'
import {Button, Loader} from 'semantic-ui-react'

import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'

const Calendar = React.lazy(() =>
  import('../../components/appointment/calendar'),
)

const AppointmentsPage = () => {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  return (
    <div className>
      <h2 className="text-primary">{selectedContent.appointments}</h2>
      <div className="mt-8">
        <React.Suspense fallback={() => <Loader active={true} />}>
          <Calendar />
        </React.Suspense>
      </div>
    </div>
  )
}

export default AppointmentsPage
