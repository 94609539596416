const {myProfile} = require('../services/profileService')

function getUserFullname(user, lang) {
  let upperCaseLang = lang.toUpperCase()

  if (!user) return '-- --'

  return `${user['firstName' + upperCaseLang]} ${
    user['lastName' + upperCaseLang]
  }`
}

const getAvgRating = () => {
  return myProfile().then(data => {
    // console.log(data)
    return data.data.user.avgRate
  })
}

module.exports = {
  getUserFullname,
  getAvgRating,
}
