export const getSubDepartments = (departmentID, allDepartments, lang) => {
  let filtered = []
  departmentID.map(department => {
    const filteredSubDerpartments = allDepartments.departments.filter(
      obj => obj.masterDepartment._id === department,
    )

    if (filteredSubDerpartments.length > 0) {
      filteredSubDerpartments.map((subDepartment, i) => {
        const isEmptyObject = filtered.findIndex(obj => obj.key === '0')
        if (isEmptyObject !== -1) {
          filtered.splice(isEmptyObject, 1)
        }
        filtered.push({
          key: subDepartment._id,
          text: subDepartment['name' + lang.toUpperCase()],
          value: subDepartment._id,
        })
      })
    } else {
      if (filtered.length === 0) {
        filtered.splice(0, filtered.length)
        filtered.push({
          key: '0',
          text: 'No results',
          value: 0,
          disabled: true,
        })
      }
    }
  })

  return filtered
}

export const getJobTile = (typeId, allJobTitles, lang) => {
  let filtered = []
  typeId?.map(type => {
    const filteredJobTile = allJobTitles.filter(obj => obj.typeRef === type)

    if (filteredJobTile.length > 0) {
      filteredJobTile.map((jobTitle, i) => {
        const isEmptyObject = filtered.findIndex(obj => obj.key === '0')
        if (isEmptyObject !== -1) {
          filtered.splice(isEmptyObject, 1)
        }
        filtered.push({
          key: jobTitle.id,
          text: jobTitle['name' + lang.toUpperCase()],
          value: jobTitle.id,
        })
      })
    } else {
      if (filtered.length === 0) {
        filtered.splice(0, filtered.length)
        filtered.push({
          key: '0',
          text: 'No results',
          value: 0,
          disabled: true,
        })
      }
    }
  })

  return filtered
}

export const getSubdepartmentsFromMasterDepartment = (
  masterDepartmentId,
  departments,
  lang,
) => {
  let relatedToMasterdepartment = departments.filter(
    d => d.masterDepartment._id === masterDepartmentId,
  )

  let options = []
  options = relatedToMasterdepartment.map(d => ({
    key: d._id,
    text: d['name' + lang.toUpperCase()],
    value: d._id,
  }))
  return options
}

export const getTicketCategory = (typeId, ticketCategory, lang) => {
  const filtered = ticketCategory.filter(o => o.typeId === typeId)
  console.log(filtered)

  let categoriesArr = []
  if (filtered.length > 0) {
    filtered.map((f, i) => {
      categoriesArr.push({
        key: i,
        text: f['name' + lang.toUpperCase()],
        value: f.id,
      })
    })
  }
  return categoriesArr
}
