import moment from 'moment'
import {useContext, useEffect, useState} from 'react'
import {DateInput, TimeInput} from 'semantic-ui-calendar-react'
import {Form, Modal, Grid, Image, Button, Icon} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import {content} from '../../localization/content'
import {formatDate, formatTime, stringToDate} from '../../utils/date-format'
import borderIcon from '../../assets/images/ic_border.svg'
import {Fragment} from 'react'
import useAsync from '../../hooks/useAsync'
import {getAllDepartments} from '../../services/dashboardService'
import {getSubDepartments} from '../../utils/handleDepartments'
import {useToasts} from 'react-toast-notifications'
import {useHistory} from 'react-router'
import {createNewShift} from '../../services/shiftService'
import {Formik} from 'formik'
import FormikControl from '../formik/FormikControl'
import * as Yup from 'yup'
import ShiftInfoForm from './shiftInfoModal/shiftInfoForm'

const CreateShiftModal = ({onUpdate}) => {
  const {showModal, setShowModal} = useContext(StateContext)
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const modalLang = content[lang]
  const history = useHistory()
  const {addToast} = useToasts()
  const [open, setOpen] = useState(false)
  const [next, setNext] = useState(false)
  const [allDepartments, setAllDepartments] = useState([])
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [departments, setDepartments] = useState([])
  const [subDepartments, setSubDepartments] = useState([])

  const [state, setState] = useState({
    nameEN: '',
    nameAR: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    responsibilities: '',
    departments: [],
    subDepartments: [],
    defaultVacation: [],
    gender: [],
  })

  useEffect(() => {
    if (showModal.modalName === 'createShift') {
      setOpen(true)
      run(getAllDepartments())
        .then(({data}) => {
          // console.log(data)
          setAllDepartments(data.data)
          let departmentData = []
          data.data.masterDepartments.map((d, i) => {
            departmentData.push({
              key: d._id,
              text: d['name' + lang.toUpperCase()],
              value: d._id,
            })
          })
          setDepartments(departmentData)
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      setOpen(false)
    }
  }, [showModal])

  const handleOnChangeDepartment = (e, {value}) => {
    setState({...state, departments: value})
    const filtered = getSubDepartments(value, allDepartments, lang)

    setSubDepartments(filtered)
  }

  const handleOnChangeSubDepartment = (e, {value}) => {
    setState({...state, subDepartments: value})
  }

  const handleOnChangeTime = (value, type) => {
    setState({...state, ['time' + type]: moment(value, 'hh:mm A').format()})
  }

  const handleOnChangeCheckbox = (value, checked) => {
    let arr = [...state.defaultVacation]
    if (checked) {
      arr.push(value)
    } else {
      const i = arr.findIndex(o => o === value)
      arr.splice(i, 1)
    }
    setState({...state, defaultVacation: arr})
  }

  const handleNext = values => {
    setState({...state, ...values.values})
    setNext(values.next)
  }

  const handleSubmit = () => {
    console.log({departments: state.departments})
    const newShift = {
      nameEN: state.nameEN,
      nameAR: state.nameAR,
      startDate: state.startDate,
      endDate: state.endDate,
      startTime: moment(state.startTime, 'hh:mm A').format(),
      endTime: moment(state.endTime, 'hh:mm A').format(),
      defaultVacation: state.defaultVacation,
      department: state.subDepartments,
      masterDepartment: state.departments,
      gender: state.gender,
      responsibilities: state.responsibilities,
      shiftType: 'normalShift',
    }
    run(createNewShift(newShift))
      .then(({data}) => {
        // console.log(data)
        addToast(data.message[lang], {appearance: 'success'})
        setShowModal({modalName: '', data: null})
        setState({
          nameEN: '',
          nameAR: '',
          dateFrom: '',
          dateTo: '',
          timeFrom: '',
          timeTo: '',
          resposibilities: '',
          departments: [],
          subDepartments: [],
          defaultVacation: [],
          gender: [],
        })
        setNext(false)
        onUpdate(true)
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <Modal
      open={open}
      className="main-font"
      size="tiny"
      closeOnDimmerClick={false}
      closeIcon
      onClose={() => setShowModal({modalName: '', data: null})}
    >
      <Modal.Header>
        {next && (
          <Icon
            name="angle left"
            size="large"
            className="ltr:mr-3 rtl:ml-3 cursor-pointer"
            onClick={() => setNext(false)}
          />
        )}
        {modalLang.createShift}
      </Modal.Header>
      <Modal.Content>
        {!next && (
          <ShiftInfoForm next={handleNext} isLoading={isLoading}>
            <hr />

            <div className="my-3">
              <label className="font-bold text-primary">
                {modalLang.defaultVacation}
              </label>
              <Grid doubling columns={3} className="mt-2">
                <Grid.Row>
                  <Grid.Column className="mb-2">
                    <Form.Field>
                      <Form.Checkbox
                        checked={state.defaultVacation.includes('Friday')}
                        label="Friday"
                        value="Friday"
                        // defaultChecked={state.defaultVacation.includes('Friday')}
                        onChange={(e, {value, checked}) =>
                          handleOnChangeCheckbox(value, checked)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column className="mb-2">
                    <Form.Field>
                      <Form.Checkbox
                        checked={state.defaultVacation.includes('Saturday')}
                        label="Saturday"
                        // defaultChecked={state.defaultVacation.includes('Saturday')}
                        value="Saturday"
                        onChange={(e, {value, checked}) =>
                          handleOnChangeCheckbox(value, checked)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column className="mb-2">
                    <Form.Field>
                      <Form.Checkbox
                        checked={state.defaultVacation.includes('Sunday')}
                        label="Sunday"
                        value="Sunday"
                        // defaultChecked={state.defaultVacation.includes('Sunday')}
                        onChange={(e, {value, checked}) =>
                          handleOnChangeCheckbox(value, checked)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column className="mb-2">
                    <Form.Field>
                      <Form.Checkbox
                        checked={state.defaultVacation.includes('Monday')}
                        // defaultChecked={state.defaultVacation.includes('Monday')}

                        label="Monday"
                        value="Monday"
                        onChange={(e, {value, checked}) =>
                          handleOnChangeCheckbox(value, checked)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column className="mb-2">
                    <Form.Field>
                      <Form.Checkbox
                        checked={state.defaultVacation.includes('Tuesday')}
                        // defaultChecked={state.defaultVacation.includes('Tuesday')}

                        label="Tuesday"
                        value="Tuesday"
                        onChange={(e, {value, checked}) =>
                          handleOnChangeCheckbox(value, checked)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column className="mb-2">
                    <Form.Field>
                      <Form.Checkbox
                        checked={state.defaultVacation.includes('Wednesday')}
                        // defaultChecked={state.defaultVacation.includes('Wednesday')}
                        label="Wednesday"
                        value="Wednesday"
                        onChange={(e, {value, checked}) =>
                          handleOnChangeCheckbox(value, checked)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column className="mb-2">
                    <Form.Field>
                      <Form.Checkbox
                        checked={state.defaultVacation.includes('Thursday')}
                        // defaultChecked={state.defaultVacation.includes('Thursday')}
                        label="Thursday"
                        value="Thursday"
                        onChange={(e, {value, checked}) =>
                          handleOnChangeCheckbox(value, checked)
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </ShiftInfoForm>
        )}
        {next && (
          <Form loading={isLoading}>
            <Form.Field>
              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="5px"
                  height="5px"
                  className="ltr:mr-5 rtl:ml-5"
                  alt="border icon"
                />
                <div className="w-full">
                  <Form.Dropdown
                    placeholder={modalLang.department}
                    fluid
                    search
                    clearable
                    label={modalLang.department}
                    defaultValue={state.departments}
                    multiple
                    selection
                    className="mt-2"
                    options={departments}
                    onChange={handleOnChangeDepartment}
                  />
                </div>
              </div>
            </Form.Field>

            <Form.Field>
              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="5px"
                  height="5px"
                  className="ltr:mr-5 rtl:ml-5"
                  alt="border icon"
                />
                <div className="w-full">
                  <Form.Dropdown
                    placeholder={modalLang.department}
                    fluid
                    search
                    clearable
                    label={modalLang.subDepartment}
                    multiple
                    defaultValue={state.subDepartments}
                    selection
                    className="mt-2"
                    options={subDepartments}
                    onChange={handleOnChangeSubDepartment}
                  />
                </div>
              </div>
            </Form.Field>

            <Form.Field>
              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="5px"
                  height="5px"
                  className="ltr:mr-5 rtl:ml-5"
                  alt="border icon"
                />
                <div className="w-full">
                  <Form.Dropdown
                    placeholder={modalLang.gender}
                    fluid
                    search
                    clearable
                    label={modalLang.gender}
                    multiple
                    selection
                    defaultValue={state.gender}
                    className="mt-2"
                    options={[
                      {key: 0, text: modalLang.male, value: 'Male'},
                      {key: 1, text: modalLang.female, value: 'Female'},
                    ]}
                    onChange={(e, {value}) =>
                      setState({...state, gender: value})
                    }
                  />
                </div>
              </div>
            </Form.Field>

            <Form.Field>
              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="5px"
                  height="5px"
                  className="ltr:mr-5 rtl:ml-5"
                  alt="border icon"
                />
                <div className="w-full">
                  <Form.TextArea
                    placeholder={modalLang.responsibilities}
                    label={modalLang.responsibilities}
                    defaultValue={state.responsibilities}
                    onChange={(e, {value}) =>
                      setState({...state, responsibilities: value})
                    }
                  ></Form.TextArea>
                </div>
              </div>
            </Form.Field>

            <div className="my-3 text-center">
              <Button
                type="submit"
                content={modalLang.submit}
                className="btn-primary"
                onClick={handleSubmit}
              />
            </div>
          </Form>
        )}
      </Modal.Content>
    </Modal>
  )
}

export default CreateShiftModal
