import {Pagination} from 'semantic-ui-react'

const PaginationTable = ({page, data, pageChange, itemsPerPage}) => {
  const handleOnPageChange = (e, {activePage}) => {
    const currPage = page > activePage ? activePage - 1 : page
    pageChange({
      page: activePage,
      begin: currPage * itemsPerPage + 1,
    })
  }
  return (
    <Pagination
      pointing
      secondary
      firstItem={null}
      lastItem={null}
      onPageChange={handleOnPageChange}
      defaultActivePage={page}
      totalPages={Math.ceil(data.length / itemsPerPage)}
    />
  )
}

export default PaginationTable
