import React from 'react'
import {Image} from 'semantic-ui-react'
import borderIcon from '../../assets/images/ic_border.svg'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'

function UserVacation({userData}) {
  const vacations = userData.vacations
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  return (
    <div>
      <p className="font-bold text-xl mb-4">
        {selectedContent.remainingCompensatoryVacationDays}
      </p>

      <div className="flex items-center mb-3">
        <Image
          src={borderIcon}
          width="3px"
          height="3px"
          className="ltr:mr-2 rtl:ml-2"
          alt="border icon"
        />
        <p className="text-xl font-bold mx-3 text-goldPrimary">
          {selectedContent.alAdha}
        </p>
        <p className="text-xl text-subTitleText">
          {vacations.alAdhaCompensationDays}
        </p>
      </div>

      <div className="flex items-center mb-3">
        <Image
          src={borderIcon}
          width="3px"
          height="3px"
          className="ltr:mr-2 rtl:ml-2"
          alt="border icon"
        />
        <p className="text-xl font-bold mx-3 text-goldPrimary">
          {selectedContent.alFitr}
        </p>
        <p className="text-xl text-subTitleText">
          {vacations.alFitrCompensationDays}
        </p>
      </div>

      <div className="flex items-center mb-3">
        <Image
          src={borderIcon}
          width="3px"
          height="3px"
          className="ltr:mr-2 rtl:ml-2"
          alt="border icon"
        />
        <p className="text-xl font-bold mx-3 text-goldPrimary">
          {selectedContent.nationalDay}
        </p>
        <p className="text-xl text-subTitleText">
          {vacations.nationalDayCompensationDays}
        </p>
      </div>
      <hr />
      <div className="flex items-center mt-8">
        <Image
          src={borderIcon}
          width="3px"
          height="3px"
          className="ltr:mr-2 rtl:ml-2"
          alt="border icon"
        />
        <p className="text-xl font-bold mx-3 text-goldPrimary">
          {selectedContent.remainingAnnualVacationDays}
        </p>
        <p className="text-xl text-subTitleText">{vacations.annualDaysLeft}</p>
      </div>
    </div>
  )
}

export default UserVacation
