import {authAxios} from '../config/axiosConfig'

// Get responsibilities
export const getResponsibilities = () => {
  return authAxios.get('/users/responsibilities')
}

// Add Responsibilities
export const addResponsibilities = resData => {
  return authAxios.post('/users/addResponsibility', resData)
}

// Approve employee
export const approveEmployee = userId => {
  return authAxios.post('/users/approveUser', userId)
}
