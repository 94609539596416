import React from 'react'
import {FaUserCircle} from 'react-icons/fa'
import {RiSettings3Line, RiEyeLine} from 'react-icons/ri'
import {
  Button,
  Dropdown,
  FormInput,
  Icon,
  Image,
  Message,
  Modal,
  Table,
} from 'semantic-ui-react'
import Auth from '../../config/auth'
import {authAxios} from '../../config/axiosConfig'
import TASK_STATUS from '../../constants/task-status'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {tasks} from '../../routes'
import {formatDate, timeFromNow} from '../../utils/date-format'
import formatError from '../../utils/error-format'
import {getUserFullname} from '../../utils/user'

const TasksTable = ({tasks: incommingTasks = []}) => {
  const [tasks, setTasks] = React.useState(incommingTasks)
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedTask, setSelectedTask] = React.useState(null)

  const [lang] = useLanguage()
  const selectedContent = content[lang]
  const {
    run: updateStatus,
    error: errorUpdatingStatus,
    isLoading: updatingStatus,
  } = useAsync()

  const dropdownOptions = React.useMemo(() => {
    let options = []
    tasks?.forEach(({status}) => {
      let taskOptions = []
      switch (status) {
        case TASK_STATUS.open:
          taskOptions = [
            TASK_STATUS.inProgress,
            TASK_STATUS.done,
            TASK_STATUS.archived,
          ]
          break
        case TASK_STATUS.inProgress:
          taskOptions = [TASK_STATUS.done, TASK_STATUS.archived]
          break
        case TASK_STATUS.done:
          taskOptions = [TASK_STATUS.archived]
          break
        default:
          break
      }

      options.push(
        taskOptions.map((t, i) => ({
          key: i,
          value: t,
          label: selectedContent[t],
        })),
      )
    })

    return options
  }, [selectedContent, tasks])

  const updateTaskStatus = (item, newStatus) => {
    updateStatus(
      authAxios.patch(`/tasks/status/${item._id}`, {status: newStatus}),
    ).then(() => {
      setTasks(tasks.filter(t => t._id !== item._id))
    })
  }

  const handleDelete = id => {
    setTasks(tasks.filter(task => task._id !== id))
    handleModalClose()
  }

  const handleModalClose = () => {
    setIsOpen(false)
    setSelectedTask(null)
  }

  React.useEffect(() => {
    setTasks(incommingTasks)
  }, [incommingTasks])

  return (
    <>
      {errorUpdatingStatus ? (
        <Message error list={formatError(errorUpdatingStatus)} />
      ) : null}

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Deadline</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Attachments</Table.HeaderCell>
            <Table.HeaderCell>Assigned to</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {tasks?.map((task, i) => (
          <Table.Row positive={task?.status === TASK_STATUS.done}>
            <Table.Cell width="5">{task.title}</Table.Cell>
            <Table.Cell width="2">{formatDate(task.deadline)}</Table.Cell>
            <Table.Cell width="2">
              {task?.status === TASK_STATUS.inProgress
                ? selectedContent.inProgress
                : selectedContent[task?.status]}
            </Table.Cell>
            <Table.Cell width="2">
              {task.attachments?.file?.link ? (
                <a
                  rel="noreferrer"
                  href={task.attachments?.file?.link}
                  target="_blank"
                  className="underline"
                >
                  {selectedContent.clickToOpen}
                </a>
              ) : null}
            </Table.Cell>
            <Table.Cell width="2">
              {getUserFullname(task?.assignedTo, lang)}
            </Table.Cell>
            <Table.Cell
              textAlign="center"
              disabled={task.status === TASK_STATUS.archived}
              width="2"
            >
              <div className="flex w-14 gap-3">
                <Dropdown
                  icon={RiSettings3Line}
                  floating
                  button
                  basic
                  className="icon mx-auto px-4"
                >
                  <Dropdown.Menu>
                    {dropdownOptions[i].map(o => (
                      <Dropdown.Item
                        key={o.key}
                        text={o?.label}
                        onClick={() => updateTaskStatus(task, o.value)}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Button
                  basic
                  size="small"
                  className="px-4"
                  onClick={() => {
                    setIsOpen(true)
                    setSelectedTask(task)
                  }}
                >
                  <RiEyeLine />
                </Button>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>

      <TaskDetailsModal
        task={selectedTask}
        isOpen={isOpen}
        onClose={handleModalClose}
        handleDelete={handleDelete}
      />
    </>
  )
}

export default TasksTable

const TaskDetailsModal = ({task, isOpen, onClose, handleDelete}) => {
  const [commentMessage, setCommentMessage] = React.useState('')
  const [comments, setComments] = React.useState(task?.comments || [])
  const [confirmDelete, setConfirmDelete] = React.useState(false)

  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading: isDeleting} = useAsync()
  const {run: sendComment, isLoading: isSending} = useAsync()

  const onNewCommment = () => {
    if (commentMessage)
      sendComment(
        authAxios
          .patch(`/tasks/${task._id}`, {
            comment: {message: commentMessage},
          })
          .then(({data /* updated task */}) => {
            task.comments = data.data.comments
            setComments(data.data.comments)
            setCommentMessage('')
          }),
      )
  }

  const onDelete = () => {
    run(authAxios.delete(`/tasks/${task._id}`)).then(() => {
      handleDelete(task._id)
    })
  }

  React.useEffect(() => {
    setComments(task?.comments)
  }, [task])

  return (
    <Modal open={isOpen} onClose={onClose} size="tiny">
      <Modal.Content>
        <div class="flex justify-between">
          <h3 className="text-xl mb-1">{task?.title}</h3>
          {Auth.isManagerOrHigher() ? (
            <Button
              className="btn-danger"
              size="small"
              onClick={() => setConfirmDelete(true)}
            >
              {selectedContent.delete}
            </Button>
          ) : null}
        </div>

        {confirmDelete ? (
          <Message
            error
            className="flex items-center justify-between mt-4"
            size="small"
          >
            {selectedContent.deleteTaskConfirmation}
            <Button
              className="btn-danger"
              size="small"
              onClick={onDelete}
              loading={isDeleting}
            >
              {selectedContent.confirm}
            </Button>
          </Message>
        ) : null}

        <div className="mb-4">
          <p className="text-gray-600 my-1 text-sm">
            {selectedContent.description}
          </p>
          <p>{task?.description}</p>
        </div>

        <div className="mb-4">
          <p className="text-gray-600 my-1 text-sm">
            {selectedContent.deadline}
          </p>
          <p>{new Date(task?.deadline).toLocaleDateString()}</p>
        </div>

        {task?.attachments?.file?.link ? (
          <div className="flex my-3">
            <p>
              <span className="text-gray-600">
                {selectedContent.attachments}:
              </span>{' '}
              <a
                rel="noreferrer"
                href={task?.attachments?.file?.link}
                target="_blank"
                className="underline"
              >
                {selectedContent.clickToOpen}
              </a>
            </p>
          </div>
        ) : null}

        {task?.attachments?.voice?.link ? (
          <div className="flex mb-3">
            <div>
              <span className="text-gray-600">
                {selectedContent.voiceNote}:
              </span>{' '}
              <audio controls className="my-4 w-80">
                <source src={task?.attachments?.voice?.link} type="audio/ogg" />
              </audio>
            </div>
          </div>
        ) : null}

        <div className="mt-2">
          <p className="text-sm text-gray-600 mb-2">
            {selectedContent.assignedEmployee}
          </p>
          <div clsasName="flex items-center">
            {task?.assignedTo?.avatar ? (
              <Image
                src={task?.assignedTo.avatar}
                circular
                size="small"
                className="w-8 h-8 rtl:ml-3 ltr:mr-3 inline-block"
              />
            ) : (
              <FaUserCircle className="inline-block text-primary w-8 h-8 ltr:mr-3 rtl:ml-3" />
            )}
            {getUserFullname(task?.assignedTo, lang)}
          </div>
        </div>

        <div>
          <h4 className="mt-4 text-sm text-gray-600">
            {selectedContent.comments}
          </h4>
          <div className="my-4 flex flex-col items-start">
            {comments?.map(c => (
              <div className="my-2 px-3 py-2 bg-gray-100 rounded-b-lg rounded-tr-lg">
                <div className="flex justify-between gap-4 text-sm mb-1 text-gray-600 ">
                  <p clsasName="">{timeFromNow(c.createdAt, lang)}</p>
                  <p>{getUserFullname(c.createdBy, lang)}</p>
                </div>
                <p>{c.message}</p>
              </div>
            ))}
          </div>
          <div className="mt-2">
            <div class="flex items-center">
              <FormInput
                placeholder={selectedContent.comment}
                size="small"
                value={commentMessage}
                onChange={e => setCommentMessage(e.target.value)}
              />
              <Button
                size="small"
                className="ltr:ml-2 rtl:mr-2"
                loading={isSending}
                onClick={onNewCommment}
              >
                {selectedContent.send}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}
