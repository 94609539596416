import {Formik} from 'formik'
import React, {Fragment, useContext} from 'react'
import {RiEditFill} from 'react-icons/ri'
import {useParams} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import {Button, Dimmer, Form, Header, Image, Loader} from 'semantic-ui-react'
import {authAxios} from '../../config/axiosConfig'
import {keys} from '../../config/keys'
import {useLanguage} from '../../context/languageContext'
import useLocalStorage from '../../hooks/use-local-storage'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {toastErrors} from '../../utils/request'
import AudioRecorder from '../AudioRecorder'
import FileAttachment from '../FileAttachment'
import FormikControl from '../formik/FormikControl'
import {getUserFullname} from '../../utils/user'
import StateContext from '../../context/stateContext'
import {FaUserCircle} from 'react-icons/fa'
import * as Yup from 'yup'

function TransactionDetails() {
  const [transaction, setTransaction] = React.useState(null)
  const [isEditing, setIsEditing] = React.useState(false)
  const [audioData, setAudioData] = React.useState(null)
  const [attachmentData, setAttachmentData] = React.useState(null)
  const {showModal, setShowModal} = useContext(StateContext)
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const {run, isLoading} = useAsync()
  const {run: updateTransaction, isLoading: updatingTransaction} = useAsync()
  const {addToast} = useToasts()

  const params = useParams()

  const [unParsedUser] = useLocalStorage('user')
  const user = JSON.parse(unParsedUser)

  const userCanUpdateTransaction =
    transaction?.data?.employees?.some(e => e._id === user._id) &&
    transaction?.data?.canBeModified

  const replySchema = Yup.object({
    notesText: Yup.string().required(selectedContent.required),
  })

  const onFormSubmit = (values, {resetForm}) => {
    const data = new FormData()

    data.append('transactionId', params.id)
    data.append('note', values.notesText)
    data.append('transactionStatus', values.transactionStatus)

    if (audioData) data.append('replyVoiceNote', audioData.file)
    if (attachmentData) data.append('replyFile', attachmentData)

    updateTransaction(authAxios.patch('/sahl/reply', data))
      .then(({data}) => {
        resetForm()
        setAttachmentData(null)
        setAudioData(null)
        setIsEditing(false)
        setTransaction(data)
      })
      .catch(toastErrors(addToast, lang))
  }

  console.log({userCanUpdateTransaction})
  React.useEffect(() => {
    run(authAxios.get(`/sahl/${params.id}`))
      .then(({data}) => setTransaction(data))
      .catch(toastErrors(addToast, lang))
  }, [run, addToast, params.id, lang])

  return (
    <section>
      <Dimmer active={isLoading} inverted>
        <Loader inverted active={isLoading} />
      </Dimmer>

      <h4 className="text-xl">{selectedContent.transactionDetails}</h4>
      {transaction?.data ? (
        <div className="mt-5">
          <section className="my-4 flex justify-between flex-wrap max-w-4xl">
            <div className="flex flex-col">
              <h5>
                <strong>{selectedContent.assignees}</strong>
              </h5>
              {transaction.data.employees.map((e, i) => (
                <Header
                  as="h4"
                  image
                  className="my-2 flex items-center"
                  key={i}
                >
                  {e.avatar ? (
                    <Image src={e.avatar} rounded size="small" />
                  ) : (
                    <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-2 rtl:ml-2" />
                  )}
                  <Header.Content>{getUserFullname(e, lang)}</Header.Content>
                </Header>
              ))}
            </div>

            <div className="flex flex-col">
              <h5>
                <strong>{selectedContent.observers}</strong>
              </h5>

              {transaction.data.observer.map((e, i) => (
                <Header
                  as="h4"
                  image
                  className="my-2 flex items-center"
                  key={i}
                >
                  {e.avatar ? (
                    <Image src={e.avatar} rounded size="small" />
                  ) : (
                    <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-2 rtl:ml-2" />
                  )}
                  <Header.Content>{getUserFullname(e, lang)}</Header.Content>
                </Header>
              ))}
            </div>

            <div className="flex flex-col">
              <h5>
                <strong>{selectedContent.reviewers}</strong>
              </h5>
              {transaction.data.reviewer.map((e, i) => (
                <Header
                  as="h4"
                  image
                  className="my-2 flex items-center"
                  key={i}
                >
                  {e.avatar ? (
                    <Image src={e.avatar} rounded size="small" />
                  ) : (
                    <FaUserCircle className="inline-block text-primary w-10 h-10 ltr:mr-2 rtl:ml-2" />
                  )}
                  <Header.Content>{getUserFullname(e, lang)}</Header.Content>
                </Header>
              ))}
            </div>
          </section>
          <div className="flex mb-3">
            <p>
              <span className="text-gray-600">
                {selectedContent.transactionNumber}:
              </span>{' '}
              {transaction.data.transactionNumber}
            </p>
          </div>

          <div className="flex mb-3">
            <p>
              <span className="text-gray-600">{selectedContent.status}:</span>{' '}
              {selectedContent[transaction.data.transactionStatus]}
            </p>
          </div>

          <div className="flex mb-3">
            <p>
              <span className="text-gray-600">
                {selectedContent.statement}:
              </span>{' '}
              {transaction.data.statement}
            </p>
          </div>

          <div className="flex mb-3">
            <p>
              <span className="text-gray-600">
                {selectedContent.attachments}:
              </span>{' '}
              {transaction.data.attachments?.sahlFile?.link ? (
                <a
                  rel="noreferrer"
                  href={transaction.data.attachments?.sahlFile?.link}
                  target="_blank"
                  className="underline"
                >
                  {selectedContent.clickToOpen}
                </a>
              ) : (
                selectedContent.noAttachments
              )}
            </p>
          </div>

          <div className="flex mb-3">
            <div>
              <span className="text-gray-600">
                {selectedContent.voiceNote}:
              </span>{' '}
              {transaction.data.attachments?.voiceNote?.link ? (
                <audio controls className="my-4">
                  <source
                    src={transaction.data.attachments?.voiceNote?.link}
                    type="audio/ogg"
                  />
                </audio>
              ) : (
                selectedContent.noVoiceNote
              )}
            </div>
          </div>

          <h4 className="text-lg mb-2">
            <strong>{selectedContent.replies}</strong>
          </h4>
          {transaction.data.replies?.map((r, i) =>
            r.createdBy ? (
              <Fragment key={i}>
                {i > 0 ? <hr /> : null}
                <Header as="h4" image className="flex items-center">
                  {r.createdBy?.avatar ? (
                    <Image src={r.createdBy?.avatar} rounded size="small" />
                  ) : (
                    <FaUserCircle className="w-10 h-10 text-primary ltr:mr-2 rtl:ml-2" />
                  )}
                  <Header.Content>
                    {getUserFullname(r.createdBy, lang)}
                  </Header.Content>
                </Header>
                <div className="flex mb-3">
                  <p>
                    <span className="text-gray-600">
                      {selectedContent.note}:
                    </span>{' '}
                    {r.note}
                  </p>
                </div>

                {r.attachments?.noteFile?.link ? (
                  <div className="flex mb-3">
                    <p>
                      <span className="text-gray-600">
                        {selectedContent.attachments}:
                      </span>{' '}
                      <a
                        rel="noreferrer"
                        href={r.attachments?.noteFile?.link}
                        target="_blank"
                        className="underline"
                      >
                        {selectedContent.clickToOpen}
                      </a>
                    </p>
                  </div>
                ) : null}

                {r.attachments?.voiceNote?.link ? (
                  <div className="flex mb-3">
                    <div>
                      <span className="text-gray-600">
                        {selectedContent.voiceNote}:
                      </span>{' '}
                      <audio controls className="my-4">
                        <source
                          src={r.attachments.voiceNote?.link}
                          type="audio/ogg"
                        />
                      </audio>
                    </div>
                  </div>
                ) : null}
              </Fragment>
            ) : null,
          )}
        </div>
      ) : null}

      {userCanUpdateTransaction ? (
        <>
          <hr className="mt-5" />
          <section className="mt-5">
            {isEditing ? (
              <div>
                <Formik
                  initialValues={{
                    transactionStatus: transaction?.data?.transactionStatus,
                    notesText: '',
                  }}
                  enableReinitialize
                  onSubmit={onFormSubmit}
                  validationSchema={replySchema}
                >
                  {formik => (
                    <Form
                      className="w-96 text-primary"
                      loading={updatingTransaction}
                      onSubmit={formik.handleSubmit}
                    >
                      <FormikControl
                        control="select"
                        name="transactionStatus"
                        label={selectedContent.status}
                        options={[
                          {
                            key: 'Pending',
                            text: selectedContent.pending,
                            value: 'pending',
                          },
                          {
                            key: 'In progress',
                            text: selectedContent['in progress'],
                            value: 'in progress',
                          },
                          {
                            key: 'Completed',
                            text: selectedContent.completed,
                            value: 'completed',
                          },
                          {
                            key: 'Rejected',
                            text: selectedContent.rejected,
                            value: 'rejected',
                          },
                        ]}
                      />
                      <FormikControl
                        control="textarea"
                        name="notesText"
                        label={selectedContent.note}
                      />
                      <div className="mb-4">
                        <AudioRecorder setAudioData={setAudioData} />
                      </div>
                      <div className="mb-4">
                        <FileAttachment setAttachmentData={setAttachmentData} />
                      </div>
                      <Button className="btn-primary w-100" type="submit">
                        {selectedContent.update}
                      </Button>
                      <Button
                        className="w-100 mt-3"
                        onClick={() => setIsEditing(false)}
                      >
                        {selectedContent.cancel}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            ) : (
              <Button
                icon
                labelPosition="left"
                onClick={() => setIsEditing(true)}
              >
                <RiEditFill className="icon p-2" />
                {selectedContent.edit}
              </Button>
            )}
          </section>
        </>
      ) : null}
    </section>
  )
}

export default TransactionDetails
