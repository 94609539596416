import {useCallback, useContext, useEffect, useState} from 'react'
import {Button, Form, Image, Modal} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {
  getAllDepartments,
  getEmployeesByDepartment,
} from '../../services/dashboardService'
import borderIcon from '../../assets/images/ic_border.svg'
import {getSubDepartments} from '../../utils/handleDepartments'
import {useHistory} from 'react-router'
import useLocalStorage from '../../hooks/use-local-storage'
import Auth from '../../config/auth'

const ChooseEmployeeModal = () => {
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [allDepartments, setAllDepartments] = useState([])
  const [departments, setDepartments] = useState([])
  const [subDepartments, setSubDepartments] = useState([])
  const [employees, setEmployees] = useState([])
  const {showModal, setShowModal} = useContext(StateContext)
  const [lang] = useLanguage()
  const modalLang = content[lang]
  const {run, isLoading} = useAsync()
  const [state, setState] = useState({
    departments: '',
    subDepartments: '',
    employee: '',
  })
  const [user, setUser] = useLocalStorage('user', [], JSON.parse)
  const history = useHistory()

  const properlySetDepartments = useCallback(
    departments => {
      if (user.department.isAdmin) {
        setDepartments(departments)
      } else if (!user.department.isAdmin && user.department.isMaster) {
        setDepartments(departments.filter(v => v.value === user.department._id))
      } else {
        setDepartments(
          departments.filter(
            v => v.value === user.department.masterDepartment._id,
          ),
        )
      }
    },
    [user],
  )

  useEffect(() => {
    if (showModal.modalName === 'chooseEmployee') {
      setOpen(true)
      run(getAllDepartments())
        .then(({data}) => {
          setAllDepartments(data.data)
          let departmentData = []
          data.data.masterDepartments.map(d => {
            departmentData.push({
              key: d._id,
              text: d['name' + lang.toUpperCase()],
              value: d._id,
            })
          })
          // if (Auth.isManager()) {
          // }
          properlySetDepartments(departmentData)
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      setOpen(false)
    }
  }, [showModal])

  const handleOnChangeDepartment = (e, {value}) => {
    setState({...state, departments: value, subDepartments: '', employee: ''})
    const filtered = getSubDepartments([value], allDepartments, lang)
    console.log(filtered)

    // if this is not a mster department, then only set the department
    // that the manager has control over & nothing else, otherwise display all sub departments
    if (!user.department.isMaster) {
      setSubDepartments(filtered.filter(v => v.value === user.department._id))
    } else {
      setSubDepartments(filtered)
    }
  }

  const handleOnChangeSubDepartment = (e, {value}) => {
    setState({...state, subDepartments: value, employee: ''})
    run(getEmployeesByDepartment({departmentId: [value]}))
      .then(({data}) => {
        // console.log(data);
        let empData = []
        data.data.map(emp => {
          empData.push({
            key: emp._id,
            text:
              emp['firstName' + lang.toUpperCase()] +
              ' ' +
              emp['lastName' + lang.toUpperCase()],
            value: emp._id,
          })
        })
        setEmployees(empData)
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <Modal
      open={open}
      className="main-font"
      size="tiny"
      closeIcon
      closeOnDimmerClick={false}
      closeOnDocumentClick={open}
      onClose={() => setShowModal({modalName: '', data: null})}
    >
      <Modal.Header>{modalLang.chooseEmployee}</Modal.Header>
      <Modal.Content>
        <Form loading={isLoading}>
          <Form.Field>
            <div className="flex items-center">
              <Image
                src={borderIcon}
                width="5px"
                height="5px"
                className="ltr:mr-5 rtl:ml-5"
                alt="border icon"
              />
              <div className="w-full">
                <label className="font-bold text-base mt-4 text-primary">
                  {modalLang.department}
                </label>
                <Form.Dropdown
                  placeholder={modalLang.department}
                  fluid
                  search
                  clearable
                  disabled={disabled}
                  selection
                  className="mt-2"
                  options={departments}
                  onChange={handleOnChangeDepartment}
                />
              </div>
            </div>
          </Form.Field>

          <Form.Field>
            <div className="flex items-center">
              <Image
                src={borderIcon}
                width="5px"
                height="5px"
                className="ltr:mr-5 rtl:ml-5"
                alt="border icon"
              />
              <div className="w-full">
                <label className="font-bold text-base mt-4 text-primary">
                  {modalLang.subDepartment}
                </label>
                <Form.Dropdown
                  placeholder={modalLang.subDepartment}
                  fluid
                  search
                  clearable
                  disabled={disabled}
                  selection
                  className="mt-2"
                  options={subDepartments}
                  onChange={handleOnChangeSubDepartment}
                />
              </div>
            </div>
          </Form.Field>

          <Form.Field>
            <div className="flex items-center">
              <Image
                src={borderIcon}
                width="5px"
                height="5px"
                className="ltr:mr-5 rtl:ml-5"
                alt="border icon"
              />
              <div className="w-full">
                <label className="font-bold text-base mt-4 text-primary">
                  {modalLang.employee}
                </label>
                <Form.Dropdown
                  placeholder={modalLang.employee}
                  fluid
                  search
                  clearable
                  disabled={disabled}
                  value={state.employee}
                  selection
                  className="mt-2"
                  options={employees}
                  onChange={(e, {value}) =>
                    setState({...state, employee: value})
                  }
                />
              </div>
            </div>
          </Form.Field>

          <div className="text-center mt-3">
            <Button
              content={modalLang.rateEmployee}
              disabled={state.employee !== '' ? false : true}
              className="btn-primary"
              onClick={() => {
                setShowModal({modalName: '', data: null})
                history.push('/performance/ratings?id=' + state.employee)
              }}
            />
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default ChooseEmployeeModal
