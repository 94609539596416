import moment from 'moment'
import 'moment/locale/ar'
import 'moment/locale/en-ca'

export function formatTime(time) {
  if (time === '') {
    return ''
  }
  return moment(time).format('hh:mm A')
}

export function formatDate(date) {
  if (!date) {
    return ''
  }
  return moment(date).format('DD-MM-YYYY')
}

export function stringToDate(date) {
  return moment(date, 'DD-MM-YYYY').format()
}

export function timeFromNow(time, lang) {
  // console.log(moment(time).fromNow())
  const timeData = moment(time)
  return timeData.locale(lang).fromNow()
}

export function timeToDate(date, time) {
  // console.log(date, time)
  if (date !== '' && time === '') {
    return date
  } else if (time !== '' && date === '') {
    return time
  }
  return moment(`${date} ${time}`, 'DD-MM-YYYY hh:mm A').format()
}

export function fromatDateTime(datetime) {
  return moment(datetime).format('DD-MM-YYYY hh:mm A')
}

export function nOfDaysBetween(date1, date2) {
  const a = moment(date1).startOf('day')
  const b = moment(date2).startOf('day')

  return Math.abs(a.diff(b, 'day'))
}

export function nOfHoursBetween(start, end) {
  let startDate = new Date(start)
  let endDate = new Date(end)

  const diffInSeconds = Math.abs(endDate - startDate) / 1000
  let nOfHours = Math.floor((diffInSeconds / 60 / 60) % 24)
  let nOfMinutes = Math.floor((diffInSeconds / 60) % 60)

  nOfHours = nOfHours < 10 ? '0' + nOfHours : nOfHours
  nOfMinutes = nOfMinutes < 10 ? '0' + nOfMinutes : nOfMinutes

  // let diff = moment.duration(endDate - startDate)
  // return moment.utc(diff.asMilliseconds()).format('hh:mm')
  return `${nOfHours}:${nOfMinutes}`
}

export function getDateFromTime(date, time) {
  const [hours, min] = time.split(':')
  const [minutes, AM] = min.split(' ')
  const d = new Date(date)

  if (hours.trim() === '12')
    if (AM.trim() === 'AM') d.setHours(0)
    else d.setHours(12)
  else d.setHours(AM.trim() === 'AM' ? hours : Number(hours) + 12)

  d.setMinutes(minutes)
  return d
}
