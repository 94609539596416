import {Fragment, useContext, useEffect, useState} from 'react'
import {Button, Form, Icon, Image, Modal} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import StateContext from '../../context/stateContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import {DateInput, TimeInput} from 'semantic-ui-calendar-react'
import {formatDate, formatTime, stringToDate} from '../../utils/date-format'
import moment from 'moment'
import {
  getAllDepartments,
  getEmployeesByDepartment,
} from '../../services/dashboardService'
import borderIcon from '../../assets/images/ic_border.svg'
import {getSubDepartments} from '../../utils/handleDepartments'
import {updateMeetings} from '../../services/meetingService'
import {useToasts} from 'react-toast-notifications'
import {useHistory} from 'react-router'
import Auth from '../../config/auth'

const UpdateMeetingModal = ({storeValue}) => {
  const {run, isLoading} = useAsync()
  const [lang] = useLanguage()
  const {addToast} = useToasts()
  const [next, setNext] = useState(false)
  const modalLang = content[lang]
  const [open, setOpen] = useState(false)
  const {showModal, setShowModal} = useContext(StateContext)
  const [type, setType] = useState('')
  const [allDepartments, setAllDepartments] = useState([])
  const [departments, setDepartments] = useState([])
  const [subDepartments, setSubDepartments] = useState([])
  const history = useHistory()
  const [addAgenda, setAddAgenda] = useState(1)
  const [disabled, setDisabled] = useState(false)
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [state, setState] = useState({
    translated: false,
    topic: '',
    topicAR: '',
    date: '',
    from: '',
    to: '',
    duration: '',
    location: '',
    master: false,
    department: [],
    subDepartments: [],
    employees: [],
    description: '',
    agenda: {},
  })

  useEffect(() => {
    if (showModal.modalName === 'updateMeeting') {
      setOpen(true)
      setType('meetings')

      run(getAllDepartments())
        .then(({data}) => {
          let departmentArr = []
          setAllDepartments(data.data)
          if (data.data.masterDepartments.length > 0) {
            data.data.masterDepartments.map((department, i) => {
              departmentArr.push({
                key: department._id,
                text: department['name' + lang.toUpperCase()],
                value: department._id,
              })
            })
            setDepartments(departmentArr)
            if (Auth.isMaster() !== false) {
              const filtered = getSubDepartments(
                [Auth.isMaster().department],
                data.data,
                lang,
              )

              setSubDepartments(filtered)
            }
          }
        })
        .catch(e => {
          console.log(e)
          if (e === undefined) return
          if (e.errors || e.errors.length > 0) {
            e.errors.map(error => {
              if (
                error.field === 'notAuthorized' ||
                error.field === 'authToken' ||
                error.field === 'token'
              ) {
                Auth.logout()
                // localStorage.removeItem('user')
                addToast(error.message[lang], {appearance: 'error'})
                history.push('/auth/login')
                return
              }
              if (error.field === 'permissionDenied') {
                addToast(error.message[lang], {appearance: 'error'})
                history.push('/requests')
                return
              }
            })
          }
        })

      if (Auth.isManager() !== false) {
        setState({
          ...state,
          master: false,
          subDepartments: [Auth.isManager().department],
        })

        getEmployees([Auth.isManager().department])
      } else if (Auth.isMaster() !== false) {
        setDepartments({
          key: Auth.isMaster().department,
          text: Auth.isMaster()['departmentName' + lang.toUpperCase()],
          value: Auth.isMaster().department,
        })
        setState({
          ...state,
          department: [Auth.isMaster().department],
        })
        setDisabled(true)
      }
    } else {
      setOpen(false)
      setNext(false)
    }
  }, [showModal])

  useEffect(() => {
    if (allDepartments.length === 0) return
    const filterD = showModal.data.department
      .filter(obj => {
        let dep
        if (obj.isMaster) {
          dep = obj._id
        } else {
          dep = obj.masterDepartment
        }
        return dep
      })
      .map(d => {
        return d.masterDepartment
      })

    const filterSD = showModal.data.department
      .filter(obj => {
        let dep
        if (!obj.isMaster) {
          dep = obj._id
        }
        return dep
      })
      .map(d => {
        return d._id
      })

    const filterEmp = showModal.data.employees.map(emp => {
      return emp._id
    })
    getEmployees(filterSD)
    const filter = getSubDepartments(filterD, allDepartments, lang)
    setSubDepartments(filter)
    setState({
      ...state,
      translated: showModal.data.isTranslated,
      topic: showModal.data.meetingTopic,
      topicAR: showModal.data.meetingTopicAR,
      date: showModal.data.meetingDate,
      from: showModal.data.meetingStartTime,
      to: showModal.data.meetingEndTime,
      duration: showModal.data.duration,
      location: showModal.data.meetingLocation,
      department: filterD,
      description: showModal.data.meetingDescription,
      agenda: showModal.data.meetingAgenda,
      subDepartments: filterSD,
      employees: filterEmp,
    })

    setAddAgenda(Object.keys(showModal.data.meetingAgenda).length)
  }, [allDepartments])

  useEffect(() => {
    if (state.from !== '' && state.to !== '') {
      const duration = moment
        .utc(
          moment.duration(moment(state.to).diff(state.from)).asMilliseconds(),
        )
        .format('h:mm')
      setState({...state, duration: duration})
    }
  }, [state.to, state.from])

  const handleOnChangeTime = (value, type) => {
    const modifiedTime = moment(
      `${state.date} ${value}`,
      'DD-MM-YYYY hh:mm A',
    ).format()
    setState({...state, [type]: modifiedTime})
  }

  const handleOnChangeMasterCheckbox = (e, {value, checked}) => {
    setSelectedEmployees([])
    setState({
      ...state,
      employees: [],
    })
    if (checked) {
      setState({...state, master: checked})
      if (state.department.length > 0) {
        setState({...state, subDepartments: [], master: checked})
        getEmployees(state.department)
      }
    }

    if (!checked) {
      setState({...state, master: checked})
      if (state.department.length > 0) {
        const filtered = getSubDepartments(
          state.department,
          allDepartments,
          lang,
        )
        setSubDepartments(filtered)
      }
    }
  }

  const handleOnChangeDepartment = (e, {value}) => {
    setState({...state, department: value})
    if (state.master) {
      getEmployees(value)
      return
    }
    const filtered = getSubDepartments(value, allDepartments)
    setSubDepartments(filtered)
  }

  const handleOnChangeSubDepartment = (e, {value}) => {
    setState({...state, subDepartments: value})
    getEmployees(value)
  }

  const getEmployees = departmentId => {
    run(getEmployeesByDepartment({departmentId: departmentId}))
      .then(({data}) => {
        let employee = []
        data.data.map(emp => {
          if (emp.department.isMaster) {
            if (emp.department.manager) {
              employee.push({
                key: emp.department.manager._id,
                text:
                  emp.department.manager['firstName' + lang.toUpperCase()] +
                  ' ' +
                  emp.department.manager['lastName' + lang.toUpperCase()],
                value: emp.department.manager._id,
              })
            }
          }
          employee.push({
            key: emp._id,
            text:
              emp['firstName' + lang.toUpperCase()] +
              ' ' +
              emp['lastName' + lang.toUpperCase()],
            value: emp._id,
          })
        })
        setSelectedEmployees(employee)
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/auth/login')
              return
            }
            if (error.field === 'permissionDenied') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/requests')
              return
            }
          })
        }
      })
  }

  const handleOnSubmit = () => {
    let updateMeeting

    if (Auth.isManager() !== false) {
      updateMeeting = {
        department: [Auth.isManager().department],
      }
    } else if (Auth.isMaster() !== false) {
      updateMeeting = {
        department: state.subDepartments,
        employees: [...state.employees, Auth.isMaster()._id],
      }
    } else {
      updateMeeting = {
        department: state.master ? state.department : state.subDepartments,
        employees: [...state.employees, Auth.isManager()._id],
      }
    }

    updateMeeting = {
      ...updateMeeting,
      meetingId: showModal.data._id,
      isTranslated: state.translated,
      meetingTopic: state.topic,
      meetingTopicAR: state.topicAR,
      meetingDate: state.date,
      meetingDescription: state.description,
      meetingStartTime: state.from,
      meetingEndTime: state.to,
      duration: state.duration,
      meetingAgenda: state.agenda,
      meetingLocation: state.location,
    }

    run(updateMeetings(updateMeeting))
      .then(({data}) => {
        addToast(data.message[lang], {appearance: 'success'})
        setShowModal({moddalName: '', data: null})
        const userId = JSON.parse(localStorage.getItem('user') || '[]')
        const index = updateMeeting.employees.findIndex(
          obj => obj === userId._id,
        )
        if (index !== -1) {
          storeValue(data.data)
        }
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/auth/login')
              return
            }
            if (error.field === 'permissionDenied') {
              addToast(error.message[lang], {appearance: 'error'})
              history.push('/requests')
              return
            }
          })
        }
      })
  }

  return (
    <Modal
      open={open}
      className="main-font"
      size="tiny"
      closeIcon
      onClose={() => {
        setNext(false)
        setShowModal({modalName: '', data: null})
      }}
    >
      <Modal.Header>
        {/* {next && (
          <Icon
            name="angle left"
            size="large"
            className="cursor-pointer"
            onClick={() => setNext(prev => !prev)}
          />
        )} */}
        {modalLang.update} {modalLang[type]}
      </Modal.Header>
      <Modal.Content>
        <Form loading={isLoading}>
          {!next && (
            <Fragment>
              <Form.Field>
                <Form.Checkbox
                  checked={state.translated}
                  toggle
                  label="Translation"
                  onChange={(e, {value}) =>
                    setState({...state, translated: !state.translated})
                  }
                />
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.Input
                      label={
                        <label className="text-primary">Meeting Topic</label>
                      }
                      defaultValue={state.topic}
                      placeholder="Meeting Topic"
                      onChange={(e, {value}) =>
                        setState({...state, topic: value})
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              {state.translated && (
                <Form.Field>
                  <div className="flex items-center">
                    <Image
                      src={borderIcon}
                      width="5px"
                      height="5px"
                      className="ltr:mr-5 rtl:ml-5"
                      alt="border icon"
                    />
                    <div className="w-full">
                      <Form.Input
                        label={
                          <label className="text-primary">
                            Meeting Topic in Arabic
                          </label>
                        }
                        defaultValue={state.topicAR}
                        placeholder="Meeting Topic in Arabic"
                        onChange={(e, {value}) =>
                          setState({...state, topicAR: value})
                        }
                      />
                    </div>
                  </div>
                </Form.Field>
              )}

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <DateInput
                      value={formatDate(state.date)}
                      label={
                        <label className="text-primary">Meeting Date</label>
                      }
                      onChange={(e, {value}) =>
                        setState({...state, date: stringToDate(value)})
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <div className="flex items-center">
                <Image
                  src={borderIcon}
                  width="5px"
                  height="5px"
                  className="ltr:mr-5 rtl:ml-5"
                  alt="border icon"
                />
                <div className="w-full">
                  <label className="font-bold text-goldPrimary">
                    {modalLang.meetingTime}
                  </label>
                  <Form.Group widths="equal" className="mt-3">
                    <Form.Field>
                      <TimeInput
                        name="from"
                        value={formatTime(state.from)}
                        label={
                          <label className="text-primary">
                            {modalLang.from}
                          </label>
                        }
                        timeFormat="AMPM"
                        onChange={(e, {value}) =>
                          handleOnChangeTime(value, 'from')
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <TimeInput
                        name="to"
                        label={
                          <label className="text-primary">{modalLang.to}</label>
                        }
                        value={formatTime(state.to)}
                        timeFormat="AMPM"
                        onChange={(e, {value}) =>
                          handleOnChangeTime(value, 'to')
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              </div>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <p className="font-bold text-goldPrimary">
                      {modalLang.duration}:{' '}
                      <span className="font-normal text-gray-600">
                        {/invalid/.test(state.duration) ? '' : state.duration}
                      </span>
                    </p>
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <Form.Input
                      label={
                        <label className="text-primary">
                          {modalLang.meetingLocation}
                        </label>
                      }
                      defaultValue={state.location}
                      placeholder="Meeting Location"
                      onChange={(e, {value}) =>
                        setState({...state, location: value})
                      }
                    />
                  </div>
                </div>
              </Form.Field>
            </Fragment>
          )}

          {next && (
            <Fragment>
              {Auth.isManager() === false && (
                <Fragment>
                  <Form.Field>
                    <Form.Checkbox
                      checked={state.master}
                      disabled={disabled}
                      label={<label>{modalLang.isMaster}</label>}
                      onChange={handleOnChangeMasterCheckbox}
                    />
                  </Form.Field>

                  <Form.Field>
                    <div className="flex items-center">
                      <Image
                        src={borderIcon}
                        width="5px"
                        height="5px"
                        className="ltr:mr-5 rtl:ml-5"
                        alt="border icon"
                      />
                      <div className="w-full">
                        <label className="font-bold text-base mt-4 text-primary">
                          {modalLang.department}
                        </label>
                        <Form.Dropdown
                          placeholder={modalLang.department}
                          fluid
                          search
                          clearable
                          disabled={disabled}
                          multiple
                          selection
                          value={state.department}
                          className="mt-2"
                          options={departments}
                          onChange={handleOnChangeDepartment}
                        />
                      </div>
                    </div>
                  </Form.Field>

                  {!state.master && (
                    <Form.Field>
                      <div className="flex items-center">
                        <Image
                          src={borderIcon}
                          width="5px"
                          height="5px"
                          className="ltr:mr-5 rtl:ml-5"
                          alt="border icon"
                        />
                        <div className="w-full">
                          <label className="font-bold text-base mt-4 text-primary">
                            {modalLang.subDepartment}
                          </label>
                          <Form.Dropdown
                            placeholder={modalLang.subDepartment}
                            fluid
                            search
                            clearable
                            multiple
                            value={state.subDepartments}
                            selection
                            className="mt-2"
                            options={subDepartments}
                            onChange={handleOnChangeSubDepartment}
                          />
                        </div>
                      </div>
                    </Form.Field>
                  )}
                </Fragment>
              )}

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <label className="font-bold text-base mt-4 text-primary">
                      {modalLang.employees}
                    </label>
                    <Form.Dropdown
                      placeholder={modalLang.employees}
                      fluid
                      search
                      clearable
                      multiple
                      value={state.employees}
                      selection
                      className="mt-2"
                      options={selectedEmployees}
                      onChange={(e, {value}) =>
                        setState({
                          ...state,
                          employees: value,
                        })
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <label className="font-bold text-base mt-4 text-primary">
                      {modalLang.meetingDescription}
                    </label>
                    <Form.TextArea
                      placeholder={modalLang.description}
                      rows={3}
                      value={state.description}
                      onChange={(e, {value}) =>
                        setState({
                          ...state,
                          description: value,
                        })
                      }
                    />
                  </div>
                </div>
              </Form.Field>

              <Form.Field>
                <div className="flex items-center">
                  <Image
                    src={borderIcon}
                    width="5px"
                    height="5px"
                    className="ltr:mr-5 rtl:ml-5"
                    alt="border icon"
                  />
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-2">
                      <label className="font-bold text-base text-primary">
                        {modalLang.meetingAgenda}
                      </label>
                      <Icon
                        name="plus"
                        className="text-primary cursor-pointer"
                        onClick={() => setAddAgenda(prev => prev + 1)}
                      />
                    </div>

                    {new Array(addAgenda).fill(1).map((k, i) => (
                      <div className="flex items-center w-full mb-3" key={i}>
                        <Form.Input
                          className="w-full mb-0"
                          placeholder={modalLang.meetingAgenda}
                          value={state.agenda[i + 1]}
                          onChange={(e, {value}) =>
                            setState({
                              ...state,
                              agenda: {...state.agenda, [i + 1]: value},
                            })
                          }
                        />
                        {/* <Icon
                          name="times"
                          className="text-red-700 ml-4"
                          onClick={handleDeleteAgenda}
                        /> */}
                      </div>
                    ))}
                  </div>
                </div>
              </Form.Field>
            </Fragment>
          )}

          <div className="text-center">
            <Button
              type={`${next ? 'submit' : 'button'}`}
              className="btn-primary"
              content={
                !next
                  ? modalLang.next
                  : modalLang.add + ' ' + modalLang.meetings
              }
              onClick={() => {
                if (next) {
                  handleOnSubmit()
                } else {
                  setNext(true)
                }
              }}
            />
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default UpdateMeetingModal
