import React from 'react'
import {authAxios} from '../../config/axiosConfig'
import TASK_STATUS from '../../constants/task-status'
import usePagination from '../../hooks/use-pagination'
import useAsync from '../../hooks/useAsync'
import PaginationWithLimit from '../pagination-with-limit'
import TasksTable from './tasks-table'

const DoneTasks = () => {
  const {run, data} = useAsync()
  const {page, limit, onPageChange, onLimitChange} = usePagination()

  React.useEffect(() => {
    if (page)
      run(
        authAxios.get(
          `/tasks?status=${TASK_STATUS.done}&page=${page}&limit=${limit}`,
        ),
      )
  }, [run, page, limit])

  return (
    <>
      <TasksTable tasks={data?.data} />
      <PaginationWithLimit
        page={page}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        totalPages={data?.totalPages}
      />
    </>
  )
}

export default DoneTasks
