import React, {Fragment} from 'react'
import {RiPenNibFill} from 'react-icons/ri'
import {Link, Route, Switch} from 'react-router-dom'
import {Grid, Image} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import routes from '../../../routes'
import updaterequestIcon from '../../../assets/images/icons/requests_user_update.svg'
import askForPermissionIcon from '../../../assets/images/icons/ask_for_permission.svg'

const requests = [
  {
    label: 'permissionRequest',
    link: 'permissionRequest',
    icon: askForPermissionIcon,
  },
  {
    label: 'updateRequest',
    link: 'updateRequest',
    icon: updaterequestIcon,
  },
]

export default function Requests() {
  const [lang] = useLanguage()
  const requestsLang = content[lang]

  return (
    <section>
      <h2 className="text-primary mb-8">{requestsLang.requests}</h2>

      <Grid doubling columns={3}>
        <Grid.Row textAlign="center" className="flex justify-center">
          {requests.map((items, i) => (
            <Grid.Column key={i}>
              <Link
                to={routes[items.link]}
                className={`${
                  i % 2 !== 0 ? 'ml-5' : ''
                } text-primary p-8 text-center mb-3`}
              >
                <Image src={items.icon} className="w-36 mx-auto" />
              </Link>
              <p className="text-lg text-center font-semibold">
                {requestsLang[items.label]}
              </p>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </section>
  )
}
