import {useEffect, useState} from 'react'
import {Button, Form, Icon, Image, Tab} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import useAsync from '../../hooks/useAsync'
import {content} from '../../localization/content'
import userImg from '../../assets/images/user.svg'
import PersonalInfo from '../../components/profileComponents/perosnal'
import JobInfo from '../../components/profileComponents/jobInfo'
import Qualifications from '../../components/profileComponents/qualifications'
import Attachments from '../../components/profileComponents/attachments'
import {myProfile, uploadUserImage} from '../../services/profileService'
import {useHistory} from 'react-router'
import Auth from '../../config/auth'
import {useQuery} from '../../hooks/useQuery'
import EditProfileInfo from '../../components/profileComponents/editProfile/editProfileInfo'
import EditModal from '../../components/profileComponents/editProfile/editModal'
import EditJobInfo from '../../components/profileComponents/editProfile/editJobInfo'
import AttachmentModal from '../../components/profileComponents/editProfile/attachmentsModal'
import UpdateHistory from '../../components/profileComponents/viewProfile/updateHistory'
import {getMyUpdateHistory} from '../../services/requestService'
import {useToasts} from 'react-toast-notifications'
import {useUser} from '../../context/UserContext'
import UserVacation from '../../components/profileComponents/userVacation'

const ProfilePage = () => {
  const {run, isLoading} = useAsync()
  const query = useQuery()
  const [user, setUser] = useUser()
  const parsedUser = JSON.parse(user)
  const [lang] = useLanguage()
  const [userProfile, setUserProfile] = useState('')
  const profileLang = content[lang]
  const {addToast} = useToasts()
  const history = useHistory()
  const [updateHistory, setUpdateHistory] = useState([])

  const panes = [
    {
      menuItem: {
        content: profileLang.personalInfo,
      },

      render: () => {
        if (query.get('edit')) {
          return (
            <EditProfileInfo
              userData={userProfile}
              storeProfile={value => setUserProfile(value)}
            />
          )
        } else {
          return <PersonalInfo userData={userProfile} />
        }
      },
    },
  ]

  if (!Auth.isExUser()) {
    panes.push(
      {
        menuItem: profileLang.jobInfo,

        render: () => {
          if (query.get('edit')) {
            return (
              <EditJobInfo
                userData={userProfile}
                storeProfile={value => {
                  setUpdateHistory(prev => [...prev, value])
                }}
                editType="profile"
              />
            )
          } else {
            return <JobInfo userData={userProfile} />
          }
        },
      },
      {
        menuItem: profileLang.trainings,
        render: () => <Qualifications userData={userProfile} />,
      },
      {
        menuItem: profileLang.attachments,
        render: () => <Attachments userData={userProfile} />,
      },
      {
        menuItem: profileLang.vacations,
        render: () => <UserVacation userData={userProfile} />,
      },
    )
  }

  useEffect(() => {
    run(myProfile())
      .then(({data}) => {
        // console.log(data.data)
        setUserProfile(data.data)
      })
      .catch(e => {
        console.log(e)
        if (e === undefined) return
        if (e.errors && e.errors.length > 0) {
          console.log(e)
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              history.push('/auth/login')
              return
            }
          })
        }
      })

    run(getMyUpdateHistory())
      .then(({data}) => {
        setUpdateHistory(data.data)
      })
      .catch(e => {
        if (e === undefined) return
        if (e.errors || e.errors.length > 0) {
          console.log(e)
          e.errors.map(error => {
            if (
              error.field === 'notAuthorized' ||
              error.field === 'authToken' ||
              error.field === 'token'
            ) {
              Auth.logout()
              // localStorage.removeItem('user')
              history.push('/auth/login')
              return
            }
          })
        }
      })

    return () => {}
  }, [])

  if (!query.get('edit') && !Auth.isExUser()) {
    panes.push({
      menuItem: profileLang.updateHistory,
      render: () => <UpdateHistory userData={updateHistory} />,
    })
  }

  const handleOnSelectedFile = e => {
    const reader = new FileReader()

    reader.onload = () => {
      if (reader.readyState === 2) {
        setUserProfile({
          ...userProfile,
          user: {...userProfile.user, avatar: reader.result},
        })
      }
    }
    reader.readAsDataURL(e.target.files[0])

    const uploadImg = new FormData()
    uploadImg.append('userId', userProfile.user._id)
    uploadImg.append('avatar', e.target.files[0])
    run(uploadUserImage(uploadImg))
      .then(({data}) => {
        // console.log(data)
        setUser(JSON.stringify({...parsedUser, avatar: data.data.avatar}))
        addToast(data.message[lang], {appearance: 'success'})
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleOnClickEdit = type => {
    // console.log(type)
    if (type === 'edit') {
      history.push('/profile?edit=true')
    } else {
      history.push('/profile')
    }
  }
  return (
    <section>
      <EditModal storeValues={value => setUserProfile(value)} />
      <AttachmentModal storeValues={value => setUserProfile(value)} />

      <div className="flex lg:flex-row lg:justify-between justify-center flex-col">
        <div className="flex lg:w-1/2 w-full lg:justify-start lg:flex-row flex-col justify-center lg:mb-0 mb-3 items-center">
          {!query.get('edit') && (
            <Image
              src={
                userProfile !== '' && userProfile.user.avatar
                  ? userProfile.user.avatar
                  : userImg
              }
              alt="user img"
              className="w-24 h-24 mb-3 lg:mb-0 rounded-xl"
            />
          )}
          {query.get('edit') && (
            <Form loading={isLoading}>
              <div className="relative">
                <input
                  type="file"
                  id="img-profile"
                  accept="image/*"
                  className="hidden"
                  onChange={handleOnSelectedFile}
                />
                <label htmlFor="img-profile">
                  <Image
                    src={
                      userProfile !== '' && userProfile.user.avatar
                        ? userProfile.user.avatar
                        : userImg
                    }
                    alt="user img"
                    className="w-24 h-24 mb-3 lg:mb-0 rounded-xl cursor-pointer"
                  />
                  <div className="bg-gray-200 rounded-full w-8 h-8 absolute ltr:right-0 rtl:left-0 ltr:-bottom-1 rtl:-bottom-1 flex justify-center items-center">
                    <Icon name="camera" className="-mt-2 mr-0 text-primary" />
                  </div>
                </label>
              </div>
            </Form>
          )}

          <div className="w-40">
            <p className="text-xl font-bold text-primary text-center">
              {userProfile !== '' &&
                userProfile.user['firstName' + lang.toUpperCase()] +
                  ' ' +
                  userProfile.user['lastName' + lang.toUpperCase()]}
            </p>
            <p className="text-lg font-thin text-gray-400 text-center">
              {userProfile !== '' &&
                userProfile.jobTitle &&
                userProfile.jobTitle['name' + lang.toUpperCase()]}
            </p>
          </div>
        </div>

        <div className={`flex lg:w-1/2 w-full items-center justify-center`}>
          <Button
            content={
              query.get('edit') ? profileLang.goBack : profileLang.editProfile
            }
            className={`${query.get('edit') ? 'btn-danger' : 'btn-primary'}`}
            onClick={() =>
              handleOnClickEdit(query.get('edit') ? 'back' : 'edit')
            }
          />
        </div>
      </div>

      <div className="mt-5">
        <Tab
          menu={{secondary: true, pointing: true, className: 'text-lg'}}
          className="overflow-x-auto"
          renderActiveOnly={true}
          panes={panes}
        />
      </div>
    </section>
  )
}

export default ProfilePage
